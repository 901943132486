// source: atom/v2/global.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
goog.exportSymbol('proto.atom.v2.Device', null, global);
goog.exportSymbol('proto.atom.v2.DeviceKind', null, global);
goog.exportSymbol('proto.atom.v2.JobKind', null, global);
goog.exportSymbol('proto.atom.v2.NodeResources', null, global);
goog.exportSymbol('proto.atom.v2.Resources', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.Resources = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.Resources.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.Resources, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.Resources.displayName = 'proto.atom.v2.Resources';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.NodeResources = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.NodeResources, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.NodeResources.displayName = 'proto.atom.v2.NodeResources';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.Device.displayName = 'proto.atom.v2.Device';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.Resources.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.Resources.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.Resources.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.Resources} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Resources.toObject = function(includeInstance, msg) {
  var f, obj = {
    millicpu: jspb.Message.getFieldWithDefault(msg, 1, 0),
    memory: jspb.Message.getFieldWithDefault(msg, 2, 0),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.atom.v2.Device.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.Resources}
 */
proto.atom.v2.Resources.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.Resources;
  return proto.atom.v2.Resources.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.Resources} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.Resources}
 */
proto.atom.v2.Resources.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMillicpu(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMemory(value);
      break;
    case 3:
      var value = new proto.atom.v2.Device;
      reader.readMessage(value,proto.atom.v2.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.Resources.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.Resources.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.Resources} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Resources.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMillicpu();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMemory();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.atom.v2.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 milliCPU = 1;
 * @return {number}
 */
proto.atom.v2.Resources.prototype.getMillicpu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.Resources} returns this
 */
proto.atom.v2.Resources.prototype.setMillicpu = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 memory = 2;
 * @return {number}
 */
proto.atom.v2.Resources.prototype.getMemory = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.Resources} returns this
 */
proto.atom.v2.Resources.prototype.setMemory = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Device devices = 3;
 * @return {!Array<!proto.atom.v2.Device>}
 */
proto.atom.v2.Resources.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.atom.v2.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.atom.v2.Device, 3));
};


/**
 * @param {!Array<!proto.atom.v2.Device>} value
 * @return {!proto.atom.v2.Resources} returns this
*/
proto.atom.v2.Resources.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.atom.v2.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.Device}
 */
proto.atom.v2.Resources.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.atom.v2.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.Resources} returns this
 */
proto.atom.v2.Resources.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.NodeResources.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.NodeResources.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.NodeResources} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.NodeResources.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocatable: (f = msg.getAllocatable()) && proto.atom.v2.Resources.toObject(includeInstance, f),
    allocated: (f = msg.getAllocated()) && proto.atom.v2.Resources.toObject(includeInstance, f),
    available: (f = msg.getAvailable()) && proto.atom.v2.Resources.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.NodeResources}
 */
proto.atom.v2.NodeResources.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.NodeResources;
  return proto.atom.v2.NodeResources.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.NodeResources} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.NodeResources}
 */
proto.atom.v2.NodeResources.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.Resources;
      reader.readMessage(value,proto.atom.v2.Resources.deserializeBinaryFromReader);
      msg.setAllocatable(value);
      break;
    case 2:
      var value = new proto.atom.v2.Resources;
      reader.readMessage(value,proto.atom.v2.Resources.deserializeBinaryFromReader);
      msg.setAllocated(value);
      break;
    case 3:
      var value = new proto.atom.v2.Resources;
      reader.readMessage(value,proto.atom.v2.Resources.deserializeBinaryFromReader);
      msg.setAvailable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.NodeResources.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.NodeResources.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.NodeResources} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.NodeResources.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocatable();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.atom.v2.Resources.serializeBinaryToWriter
    );
  }
  f = message.getAllocated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.atom.v2.Resources.serializeBinaryToWriter
    );
  }
  f = message.getAvailable();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.atom.v2.Resources.serializeBinaryToWriter
    );
  }
};


/**
 * optional Resources allocatable = 1;
 * @return {?proto.atom.v2.Resources}
 */
proto.atom.v2.NodeResources.prototype.getAllocatable = function() {
  return /** @type{?proto.atom.v2.Resources} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.Resources, 1));
};


/**
 * @param {?proto.atom.v2.Resources|undefined} value
 * @return {!proto.atom.v2.NodeResources} returns this
*/
proto.atom.v2.NodeResources.prototype.setAllocatable = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.NodeResources} returns this
 */
proto.atom.v2.NodeResources.prototype.clearAllocatable = function() {
  return this.setAllocatable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.NodeResources.prototype.hasAllocatable = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Resources allocated = 2;
 * @return {?proto.atom.v2.Resources}
 */
proto.atom.v2.NodeResources.prototype.getAllocated = function() {
  return /** @type{?proto.atom.v2.Resources} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.Resources, 2));
};


/**
 * @param {?proto.atom.v2.Resources|undefined} value
 * @return {!proto.atom.v2.NodeResources} returns this
*/
proto.atom.v2.NodeResources.prototype.setAllocated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.NodeResources} returns this
 */
proto.atom.v2.NodeResources.prototype.clearAllocated = function() {
  return this.setAllocated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.NodeResources.prototype.hasAllocated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Resources available = 3;
 * @return {?proto.atom.v2.Resources}
 */
proto.atom.v2.NodeResources.prototype.getAvailable = function() {
  return /** @type{?proto.atom.v2.Resources} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.Resources, 3));
};


/**
 * @param {?proto.atom.v2.Resources|undefined} value
 * @return {!proto.atom.v2.NodeResources} returns this
*/
proto.atom.v2.NodeResources.prototype.setAvailable = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.NodeResources} returns this
 */
proto.atom.v2.NodeResources.prototype.clearAvailable = function() {
  return this.setAvailable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.NodeResources.prototype.hasAvailable = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.Device}
 */
proto.atom.v2.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.Device;
  return proto.atom.v2.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.Device}
 */
proto.atom.v2.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.atom.v2.DeviceKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional DeviceKind kind = 1;
 * @return {!proto.atom.v2.DeviceKind}
 */
proto.atom.v2.Device.prototype.getKind = function() {
  return /** @type {!proto.atom.v2.DeviceKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.atom.v2.DeviceKind} value
 * @return {!proto.atom.v2.Device} returns this
 */
proto.atom.v2.Device.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.atom.v2.Device.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.Device} returns this
 */
proto.atom.v2.Device.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 count = 3;
 * @return {number}
 */
proto.atom.v2.Device.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.atom.v2.Device} returns this
 */
proto.atom.v2.Device.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.atom.v2.JobKind = {
  UNKNOWNJOB: 0,
  TRAININGJOB: 3,
  DISTRIBUTEDJOB: 5,
  WORKSPACEJOB: 7,
  ANALYZERJOB: 8,
  MODELSHOPJOB: 9,
  INFERENCEJOB: 10,
  MODELSERVICEJOB: 11
};

/**
 * @enum {number}
 */
proto.atom.v2.DeviceKind = {
  DEVICEINVALID: 0,
  DEVICENVIDIAGPU: 1,
  DEVICEASCENDNPU: 2,
  DEVICEMETAXGPU: 3
};

goog.object.extend(exports, proto.atom.v2);
