import React from 'react';
import { Modal, Descriptions, ModalProps, Row, Col, Button, Image } from 'antd';
import { observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';

interface WorkFlowDetailDialogProps {
  curWorkFlow: any;
}
import './index.less';
class Status {
  constructor() {
    makeAutoObservable(this);
  }
}
const status = new Status();
const WorkFlowDetailDialog: React.FC<ModalProps & WorkFlowDetailDialogProps> = observer(
  ({ curWorkFlow, ...modalProps }) => {
    const imageList = [
      {
        id: 1,
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
      },
      {
        id: 2,
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
      },
      {
        id: 3,
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
      },
      {
        id: 4,
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
      },
      {
        id: 5,
        url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
      }
    ];
    return (
      <Modal title="工作流详情" width="60vw" footer={false} {...modalProps}>
        <Row className="workflow-detail">
          <Col className="workflow-detail-image-list" span={16}>
            {imageList.map((image) => {
              return <Image key={image.id} src={image.url} />;
            })}
          </Col>
          <Col span={8} style={{ padding: 20 }}>
            <div style={{ fontWeight: 400, fontSize: 18, marginBottom: 30 }}>漫画文生图</div>
            <div className="workflow-detail-item">作者： 可可屋</div>
            <div className="workflow-detail-item">发布日期： 2024/11/11</div>
            <div className="workflow-detail-item">说明： 本工作流用于对图片进行指定风格的aaaaaaaaaaaaaaaaaaaa</div>
            <div style={{ marginTop: 60 }}>
              <Button style={{ width: 160 }}>添加到我的工作流</Button>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button type="primary" style={{ width: 160 }}>
                下载
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
);

export default WorkFlowDetailDialog;
