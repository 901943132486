/**
 * @fileoverview gRPC-Web generated client stub for dataocean
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var meta_meta_pb = require('../meta/meta_pb.js')

var dataocean_global_pb = require('../dataocean/global_pb.js')

var dataocean_labelx_pb = require('../dataocean/labelx_pb.js')

var dataocean_droplet_pb = require('../dataocean/droplet_pb.js')

var dataocean_trainspot_pb = require('../dataocean/trainspot_pb.js')

var dataocean_modelshop_pb = require('../dataocean/modelshop_pb.js')
const proto = {};
proto.dataocean = require('./dataset_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dataocean.DatasetServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dataocean.DatasetServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.CreateDatasetReq,
 *   !proto.dataocean.Dataset>}
 */
const methodDescriptor_DatasetService_CreateDataset = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/CreateDataset',
  grpc.web.MethodType.UNARY,
  proto.dataocean.CreateDatasetReq,
  proto.dataocean.Dataset,
  /**
   * @param {!proto.dataocean.CreateDatasetReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Dataset.deserializeBinary
);


/**
 * @param {!proto.dataocean.CreateDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Dataset)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Dataset>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.createDataset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/CreateDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_CreateDataset,
      callback);
};


/**
 * @param {!proto.dataocean.CreateDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Dataset>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.createDataset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/CreateDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_CreateDataset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.CreateDatasetVersionReq,
 *   !proto.dataocean.Dataset>}
 */
const methodDescriptor_DatasetService_CreateDatasetVersion = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/CreateDatasetVersion',
  grpc.web.MethodType.UNARY,
  proto.dataocean.CreateDatasetVersionReq,
  proto.dataocean.Dataset,
  /**
   * @param {!proto.dataocean.CreateDatasetVersionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Dataset.deserializeBinary
);


/**
 * @param {!proto.dataocean.CreateDatasetVersionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Dataset)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Dataset>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.createDatasetVersion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/CreateDatasetVersion',
      request,
      metadata || {},
      methodDescriptor_DatasetService_CreateDatasetVersion,
      callback);
};


/**
 * @param {!proto.dataocean.CreateDatasetVersionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Dataset>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.createDatasetVersion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/CreateDatasetVersion',
      request,
      metadata || {},
      methodDescriptor_DatasetService_CreateDatasetVersion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.GetDatasetReq,
 *   !proto.dataocean.Dataset>}
 */
const methodDescriptor_DatasetService_GetDataset = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/GetDataset',
  grpc.web.MethodType.UNARY,
  proto.dataocean.GetDatasetReq,
  proto.dataocean.Dataset,
  /**
   * @param {!proto.dataocean.GetDatasetReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Dataset.deserializeBinary
);


/**
 * @param {!proto.dataocean.GetDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Dataset)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Dataset>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.getDataset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/GetDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_GetDataset,
      callback);
};


/**
 * @param {!proto.dataocean.GetDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Dataset>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.getDataset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/GetDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_GetDataset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.ListDatasetsReq,
 *   !proto.dataocean.ListDatasetsResp>}
 */
const methodDescriptor_DatasetService_ListDatasets = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/ListDatasets',
  grpc.web.MethodType.UNARY,
  proto.dataocean.ListDatasetsReq,
  proto.dataocean.ListDatasetsResp,
  /**
   * @param {!proto.dataocean.ListDatasetsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.ListDatasetsResp.deserializeBinary
);


/**
 * @param {!proto.dataocean.ListDatasetsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.ListDatasetsResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.ListDatasetsResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.listDatasets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/ListDatasets',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ListDatasets,
      callback);
};


/**
 * @param {!proto.dataocean.ListDatasetsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.ListDatasetsResp>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.listDatasets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/ListDatasets',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ListDatasets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.RemoveDatasetReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_DatasetService_RemoveDataset = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/RemoveDataset',
  grpc.web.MethodType.UNARY,
  proto.dataocean.RemoveDatasetReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dataocean.RemoveDatasetReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dataocean.RemoveDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.removeDataset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/RemoveDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_RemoveDataset,
      callback);
};


/**
 * @param {!proto.dataocean.RemoveDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.removeDataset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/RemoveDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_RemoveDataset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.RemoveDatasetVersionReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_DatasetService_RemoveDatasetVersion = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/RemoveDatasetVersion',
  grpc.web.MethodType.UNARY,
  proto.dataocean.RemoveDatasetVersionReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dataocean.RemoveDatasetVersionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dataocean.RemoveDatasetVersionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.removeDatasetVersion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/RemoveDatasetVersion',
      request,
      metadata || {},
      methodDescriptor_DatasetService_RemoveDatasetVersion,
      callback);
};


/**
 * @param {!proto.dataocean.RemoveDatasetVersionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.removeDatasetVersion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/RemoveDatasetVersion',
      request,
      metadata || {},
      methodDescriptor_DatasetService_RemoveDatasetVersion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.EditDatasetReq,
 *   !proto.dataocean.Dataset>}
 */
const methodDescriptor_DatasetService_EditDataset = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/EditDataset',
  grpc.web.MethodType.UNARY,
  proto.dataocean.EditDatasetReq,
  proto.dataocean.Dataset,
  /**
   * @param {!proto.dataocean.EditDatasetReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Dataset.deserializeBinary
);


/**
 * @param {!proto.dataocean.EditDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Dataset)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Dataset>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.editDataset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/EditDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_EditDataset,
      callback);
};


/**
 * @param {!proto.dataocean.EditDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Dataset>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.editDataset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/EditDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_EditDataset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.MergeDatasetsReq,
 *   !proto.dataocean.Dataset>}
 */
const methodDescriptor_DatasetService_MergeDatasets = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/MergeDatasets',
  grpc.web.MethodType.UNARY,
  proto.dataocean.MergeDatasetsReq,
  proto.dataocean.Dataset,
  /**
   * @param {!proto.dataocean.MergeDatasetsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Dataset.deserializeBinary
);


/**
 * @param {!proto.dataocean.MergeDatasetsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Dataset)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Dataset>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.mergeDatasets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/MergeDatasets',
      request,
      metadata || {},
      methodDescriptor_DatasetService_MergeDatasets,
      callback);
};


/**
 * @param {!proto.dataocean.MergeDatasetsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Dataset>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.mergeDatasets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/MergeDatasets',
      request,
      metadata || {},
      methodDescriptor_DatasetService_MergeDatasets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.ReleaseDatasetReq,
 *   !proto.dataocean.Dataset>}
 */
const methodDescriptor_DatasetService_ReleaseDataset = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/ReleaseDataset',
  grpc.web.MethodType.UNARY,
  proto.dataocean.ReleaseDatasetReq,
  proto.dataocean.Dataset,
  /**
   * @param {!proto.dataocean.ReleaseDatasetReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Dataset.deserializeBinary
);


/**
 * @param {!proto.dataocean.ReleaseDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Dataset)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Dataset>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.releaseDataset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/ReleaseDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ReleaseDataset,
      callback);
};


/**
 * @param {!proto.dataocean.ReleaseDatasetReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Dataset>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.releaseDataset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/ReleaseDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ReleaseDataset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.ListDatasetContentsReq,
 *   !proto.dataocean.ListDatasetContentsResp>}
 */
const methodDescriptor_DatasetService_ListDatasetContents = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/ListDatasetContents',
  grpc.web.MethodType.UNARY,
  proto.dataocean.ListDatasetContentsReq,
  proto.dataocean.ListDatasetContentsResp,
  /**
   * @param {!proto.dataocean.ListDatasetContentsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.ListDatasetContentsResp.deserializeBinary
);


/**
 * @param {!proto.dataocean.ListDatasetContentsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.ListDatasetContentsResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.ListDatasetContentsResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.listDatasetContents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/ListDatasetContents',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ListDatasetContents,
      callback);
};


/**
 * @param {!proto.dataocean.ListDatasetContentsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.ListDatasetContentsResp>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.listDatasetContents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/ListDatasetContents',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ListDatasetContents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.ListDatasetVersionsReq,
 *   !proto.dataocean.ListDatasetVersionsResp>}
 */
const methodDescriptor_DatasetService_ListDatasetVersions = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/ListDatasetVersions',
  grpc.web.MethodType.UNARY,
  proto.dataocean.ListDatasetVersionsReq,
  proto.dataocean.ListDatasetVersionsResp,
  /**
   * @param {!proto.dataocean.ListDatasetVersionsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.ListDatasetVersionsResp.deserializeBinary
);


/**
 * @param {!proto.dataocean.ListDatasetVersionsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.ListDatasetVersionsResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.ListDatasetVersionsResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.listDatasetVersions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.DatasetService/ListDatasetVersions',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ListDatasetVersions,
      callback);
};


/**
 * @param {!proto.dataocean.ListDatasetVersionsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.ListDatasetVersionsResp>}
 *     Promise that resolves to the response
 */
proto.dataocean.DatasetServicePromiseClient.prototype.listDatasetVersions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.DatasetService/ListDatasetVersions',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ListDatasetVersions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.ExportDatasetReq,
 *   !proto.dataocean.ExportDatasetResp>}
 */
const methodDescriptor_DatasetService_ExportDataset = new grpc.web.MethodDescriptor(
  '/dataocean.DatasetService/ExportDataset',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.dataocean.ExportDatasetReq,
  proto.dataocean.ExportDatasetResp,
  /**
   * @param {!proto.dataocean.ExportDatasetReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.ExportDatasetResp.deserializeBinary
);


/**
 * @param {!proto.dataocean.ExportDatasetReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.ExportDatasetResp>}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServiceClient.prototype.exportDataset =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/dataocean.DatasetService/ExportDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ExportDataset);
};


/**
 * @param {!proto.dataocean.ExportDatasetReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.ExportDatasetResp>}
 *     The XHR Node Readable Stream
 */
proto.dataocean.DatasetServicePromiseClient.prototype.exportDataset =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/dataocean.DatasetService/ExportDataset',
      request,
      metadata || {},
      methodDescriptor_DatasetService_ExportDataset);
};


module.exports = proto.dataocean;

