import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeAutoObservable, toJS, computed } from 'mobx';
import { Button, Input, message, Modal, PageHeader, Popconfirm, Table, Tabs, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { InfoCircleOutlined } from '@ant-design/icons';
import grpc from 'api/grpc';
import { Key } from 'antd/lib/table/interface';
import { buildObject } from 'utils/grpcUtil';
import {
  ActivatedStatus,
  ListProductsActivationReq,
  ProductActivation,
  UpdateProductActivationReq
} from 'atom-proto/web/atom/v2/product_activation_pb';
import { modelServiceType } from 'utils/resourceUtil';
import { ListProductsReq } from 'atom-proto/web/atom/v2/product_pb';
import globalStore from 'src/stores/globalStore';
import { formatPrice } from 'utils/priceUtil';
import * as _ from 'lodash';
import { getServices } from 'api/grpc/service';
import { Link } from 'react-router-dom';
class Status {
  services: modelServiceType[] = [];
  selectedService: Key[] = [];
  searchKey: string = '';
  constructor() {
    makeAutoObservable(this);
  }
  get filteredServices() {
    return this.services.filter((service) => service.product.product?.getName().includes(this.searchKey));
  }
}

const status = new Status();
const ActivatedState = {
  [ActivatedStatus.ACTIVATEDSTATUSUNKNOWN]: '未知',
  [ActivatedStatus.ACTIVATEDSTATUSON]: '已开通',
  [ActivatedStatus.ACTIVATEDSTATUSOFF]: '已关闭'
};
const ServiceManage: React.FC = observer(() => {
  const [activeKey, setActiveKey] = useState('service');

  const columns: ColumnsType<modelServiceType> = [
    {
      title: '序号',
      align: 'center',
      render: (_, __, index) => index + 1
    },
    {
      title: '服务名称',
      align: 'center',
      render: (text, record) => {
        return record.product.product?.getName();
      }
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      render: (text: any) => {
        return (
          <span style={{ color: text === ActivatedStatus.ACTIVATEDSTATUSON ? '#52c41a' : '#f5222d' }}>
            {ActivatedState[text]}
          </span>
        );
      }
    },
    {
      title: '输入 ￥/1M tokens',
      align: 'center',
      render: (text: any, record) => {
        return formatPrice(record.product.price?.find((price) => price.unit === 1001)?.unitprice || 0, '¥');
      }
    },
    {
      title: '输出 ￥/1M tokens',
      align: 'center',
      render: (text: any, record) => {
        return formatPrice(record.product.price?.find((price) => price.unit === 1002)?.unitprice || 0, '¥');
      }
    },
    {
      title: '并发限制',
      align: 'center',
      render: (text: any, record) => {
        return record.product.product?.getResourcesMap().get('concurrencyLimit') || '-';
      }
    },
    {
      title: '操作',
      align: 'center',
      render(value, record, index) {
        const isActivated = record.status === ActivatedStatus.ACTIVATEDSTATUSON;
        return (
          <div>
            {!isActivated && (
              <Button
                onClick={() => {
                  const useconfig = {
                    title: '您将开通大模型推理服务',
                    content: `您将开通${record.product.product?.getName()}，开通后将根据实际的调用进行收费。是否继续？`,
                    async onOk() {
                      await grpc.productActivation.updateProductActivation(
                        buildObject(new UpdateProductActivationReq(), (req) => {
                          req.setProductidsList([record.productid]);
                          req.setStatus(ActivatedStatus.ACTIVATEDSTATUSON);
                        })
                      );
                      message.success('开通成功');
                      fetchService();
                    }
                  };
                  Modal.confirm(useconfig);
                }}
                type="link"
                size="small"
              >
                开通
              </Button>
            )}
            {isActivated && (
              <Button
                onClick={() => {
                  const useconfig = {
                    title: '您将关闭大模型推理服务',
                    content: `您将关闭${record.productid}，所有的请求将失效，会影响您当前接入的应用服务。是否继续？`,
                    async onOk() {
                      await grpc.productActivation.updateProductActivation(
                        buildObject(new UpdateProductActivationReq(), (req) => {
                          req.setProductidsList([record.productid]);
                          req.setStatus(ActivatedStatus.ACTIVATEDSTATUSOFF);
                        })
                      );
                      message.success('关闭成功');
                      fetchService();
                    }
                  };
                  Modal.confirm(useconfig);
                }}
                type="link"
                size="small"
              >
                关闭
              </Button>
            )}
            <Link target="_blank" to={'https://zq3q7lw7lfa.feishu.cn/wiki/DCGnw10D5iRRbwk58KlcnFlLn5c'}>
              接口文档
            </Link>
          </div>
        );
      }
    }
  ];
  const fetchService = async () => {
    status.services = await getServices(false);
  };
  useEffect(() => {
    fetchService();
  }, []);
  const debouncedSetStatus = _.debounce((value) => {
    status.searchKey = value;
    console.log(value);
  }, 600);
  const items = [
    {
      label: '大模型推理服务',
      key: 'service',
      children: (
        <>
          <Input
            placeholder="输入关键词查询模型"
            style={{ width: 360, marginBottom: 10 }}
            onChange={(event) => {
              debouncedSetStatus(event.target.value);
            }}
          />
          <Table<modelServiceType>
            rowKey={(record) => record.productid}
            dataSource={status.filteredServices}
            bordered
            columns={columns}
            // rowSelection={{
            //   onChange: (selectedRowKeys, selectedRows) => {
            //     console.log(selectedRowKeys);
            //     console.log(selectedRows);
            //     status.selectedService = selectedRowKeys;
            //   }
            // }}
          />
        </>
      )
    }
  ];
  return (
    <PageHeader
      style={{ backgroundColor: '#fff', marginTop: 50, padding: 0, height: 'calc(100% - 50px)' }}
      className="atom-shadow"
    >
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key);
        }}
        type="card"
        items={items}
        className="atom-tabs"
        style={{ marginTop: -60 }}
      ></Tabs>
    </PageHeader>
  );
});

export default ServiceManage;
