// source: dataocean/blob.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js');
goog.object.extend(proto, github_com_envoyproxy_protoc$gen$validate_validate_validate_pb);
var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var meta_meta_pb = require('../meta/meta_pb.js');
goog.object.extend(proto, meta_meta_pb);
var dataocean_global_pb = require('../dataocean/global_pb.js');
goog.object.extend(proto, dataocean_global_pb);
var dataocean_droplet_pb = require('../dataocean/droplet_pb.js');
goog.object.extend(proto, dataocean_droplet_pb);
var dataocean_atwork_pb = require('../dataocean/atwork_pb.js');
goog.object.extend(proto, dataocean_atwork_pb);
var dataocean_labelx_pb = require('../dataocean/labelx_pb.js');
goog.object.extend(proto, dataocean_labelx_pb);
var dataocean_teststand_pb = require('../dataocean/teststand_pb.js');
goog.object.extend(proto, dataocean_teststand_pb);
var dataocean_trainspot_pb = require('../dataocean/trainspot_pb.js');
goog.object.extend(proto, dataocean_trainspot_pb);
var dataocean_modelshop_pb = require('../dataocean/modelshop_pb.js');
goog.object.extend(proto, dataocean_modelshop_pb);
goog.exportSymbol('proto.dataocean.After', null, global);
goog.exportSymbol('proto.dataocean.AggregationPager', null, global);
goog.exportSymbol('proto.dataocean.Blob', null, global);
goog.exportSymbol('proto.dataocean.BlobContent', null, global);
goog.exportSymbol('proto.dataocean.BlobSource', null, global);
goog.exportSymbol('proto.dataocean.BlobSpec', null, global);
goog.exportSymbol('proto.dataocean.BlobSpecKind', null, global);
goog.exportSymbol('proto.dataocean.BlobSpecRelease', null, global);
goog.exportSymbol('proto.dataocean.BlobSpecReleaseName', null, global);
goog.exportSymbol('proto.dataocean.BlobState', null, global);
goog.exportSymbol('proto.dataocean.BlobStatus', null, global);
goog.exportSymbol('proto.dataocean.BlobStorage', null, global);
goog.exportSymbol('proto.dataocean.CheckBlobExistReq', null, global);
goog.exportSymbol('proto.dataocean.CheckBlobExistResp', null, global);
goog.exportSymbol('proto.dataocean.CreateBlobReq', null, global);
goog.exportSymbol('proto.dataocean.GetBlobContentReq', null, global);
goog.exportSymbol('proto.dataocean.GetBlobContentRes', null, global);
goog.exportSymbol('proto.dataocean.GetBlobEnsuredReq', null, global);
goog.exportSymbol('proto.dataocean.GetBlobReq', null, global);
goog.exportSymbol('proto.dataocean.LabelxTask', null, global);
goog.exportSymbol('proto.dataocean.ListBlobsReq', null, global);
goog.exportSymbol('proto.dataocean.ListBlobsResp', null, global);
goog.exportSymbol('proto.dataocean.ListDropletIDsReq', null, global);
goog.exportSymbol('proto.dataocean.ListDropletIDsRes', null, global);
goog.exportSymbol('proto.dataocean.ListLabelxTasksReq', null, global);
goog.exportSymbol('proto.dataocean.ListLabelxTasksResp', null, global);
goog.exportSymbol('proto.dataocean.PublishBlobReq', null, global);
goog.exportSymbol('proto.dataocean.RemoveBlobReq', null, global);
goog.exportSymbol('proto.dataocean.ReplicateBlobReq', null, global);
goog.exportSymbol('proto.dataocean.ReplicateBlobRes', null, global);
goog.exportSymbol('proto.dataocean.StatisticizeDropletsReq', null, global);
goog.exportSymbol('proto.dataocean.StatisticizeDropletsRes', null, global);
goog.exportSymbol('proto.dataocean.TransformBlobReq', null, global);
goog.exportSymbol('proto.dataocean.TransformStatus', null, global);
goog.exportSymbol('proto.dataocean.UpdateBlobReq', null, global);
goog.exportSymbol('proto.dataocean.Volume', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Blob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Blob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Blob.displayName = 'proto.dataocean.Blob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BlobSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.BlobSpec.repeatedFields_, null);
};
goog.inherits(proto.dataocean.BlobSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BlobSpec.displayName = 'proto.dataocean.BlobSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.TransformStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.TransformStatus.repeatedFields_, null);
};
goog.inherits(proto.dataocean.TransformStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.TransformStatus.displayName = 'proto.dataocean.TransformStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BlobSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.BlobSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BlobSource.displayName = 'proto.dataocean.BlobSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BlobStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.BlobStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BlobStatus.displayName = 'proto.dataocean.BlobStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Volume = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Volume, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Volume.displayName = 'proto.dataocean.Volume';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BlobStorage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.BlobStorage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BlobStorage.displayName = 'proto.dataocean.BlobStorage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BlobContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.BlobContent.repeatedFields_, null);
};
goog.inherits(proto.dataocean.BlobContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BlobContent.displayName = 'proto.dataocean.BlobContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BlobSpecRelease = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.BlobSpecRelease, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BlobSpecRelease.displayName = 'proto.dataocean.BlobSpecRelease';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.CreateBlobReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.CreateBlobReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.CreateBlobReq.displayName = 'proto.dataocean.CreateBlobReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GetBlobReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GetBlobReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GetBlobReq.displayName = 'proto.dataocean.GetBlobReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.RemoveBlobReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.RemoveBlobReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.RemoveBlobReq.displayName = 'proto.dataocean.RemoveBlobReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListBlobsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListBlobsReq.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListBlobsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListBlobsReq.displayName = 'proto.dataocean.ListBlobsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListBlobsResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListBlobsResp.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListBlobsResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListBlobsResp.displayName = 'proto.dataocean.ListBlobsResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.UpdateBlobReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.UpdateBlobReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.UpdateBlobReq.displayName = 'proto.dataocean.UpdateBlobReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.PublishBlobReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.PublishBlobReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.PublishBlobReq.displayName = 'proto.dataocean.PublishBlobReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListLabelxTasksReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ListLabelxTasksReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListLabelxTasksReq.displayName = 'proto.dataocean.ListLabelxTasksReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListLabelxTasksResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListLabelxTasksResp.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListLabelxTasksResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListLabelxTasksResp.displayName = 'proto.dataocean.ListLabelxTasksResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.LabelxTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.LabelxTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.LabelxTask.displayName = 'proto.dataocean.LabelxTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.AggregationPager = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.AggregationPager.repeatedFields_, null);
};
goog.inherits(proto.dataocean.AggregationPager, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.AggregationPager.displayName = 'proto.dataocean.AggregationPager';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.After = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.After, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.After.displayName = 'proto.dataocean.After';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.CheckBlobExistReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.CheckBlobExistReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.CheckBlobExistReq.displayName = 'proto.dataocean.CheckBlobExistReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.CheckBlobExistResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.CheckBlobExistResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.CheckBlobExistResp.displayName = 'proto.dataocean.CheckBlobExistResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.TransformBlobReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.TransformBlobReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.TransformBlobReq.displayName = 'proto.dataocean.TransformBlobReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GetBlobEnsuredReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GetBlobEnsuredReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GetBlobEnsuredReq.displayName = 'proto.dataocean.GetBlobEnsuredReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.StatisticizeDropletsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.StatisticizeDropletsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.StatisticizeDropletsReq.displayName = 'proto.dataocean.StatisticizeDropletsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.StatisticizeDropletsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.StatisticizeDropletsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.StatisticizeDropletsRes.displayName = 'proto.dataocean.StatisticizeDropletsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDropletIDsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListDropletIDsReq.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListDropletIDsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDropletIDsReq.displayName = 'proto.dataocean.ListDropletIDsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDropletIDsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListDropletIDsRes.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListDropletIDsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDropletIDsRes.displayName = 'proto.dataocean.ListDropletIDsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GetBlobContentReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GetBlobContentReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GetBlobContentReq.displayName = 'proto.dataocean.GetBlobContentReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GetBlobContentRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GetBlobContentRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GetBlobContentRes.displayName = 'proto.dataocean.GetBlobContentRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ReplicateBlobReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ReplicateBlobReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ReplicateBlobReq.displayName = 'proto.dataocean.ReplicateBlobReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ReplicateBlobRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ReplicateBlobRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ReplicateBlobRes.displayName = 'proto.dataocean.ReplicateBlobRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Blob.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Blob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Blob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Blob.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && meta_meta_pb.Metadata.toObject(includeInstance, f),
    spec: (f = msg.getSpec()) && proto.dataocean.BlobSpec.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.dataocean.BlobStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Blob}
 */
proto.dataocean.Blob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Blob;
  return proto.dataocean.Blob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Blob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Blob}
 */
proto.dataocean.Blob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.Metadata;
      reader.readMessage(value,meta_meta_pb.Metadata.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.dataocean.BlobSpec;
      reader.readMessage(value,proto.dataocean.BlobSpec.deserializeBinaryFromReader);
      msg.setSpec(value);
      break;
    case 3:
      var value = new proto.dataocean.BlobStatus;
      reader.readMessage(value,proto.dataocean.BlobStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Blob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Blob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Blob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Blob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getSpec();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.BlobSpec.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.BlobStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.Metadata meta = 1;
 * @return {?proto.meta.Metadata}
 */
proto.dataocean.Blob.prototype.getMeta = function() {
  return /** @type{?proto.meta.Metadata} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.Metadata, 1));
};


/**
 * @param {?proto.meta.Metadata|undefined} value
 * @return {!proto.dataocean.Blob} returns this
*/
proto.dataocean.Blob.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Blob} returns this
 */
proto.dataocean.Blob.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Blob.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BlobSpec spec = 2;
 * @return {?proto.dataocean.BlobSpec}
 */
proto.dataocean.Blob.prototype.getSpec = function() {
  return /** @type{?proto.dataocean.BlobSpec} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.BlobSpec, 2));
};


/**
 * @param {?proto.dataocean.BlobSpec|undefined} value
 * @return {!proto.dataocean.Blob} returns this
*/
proto.dataocean.Blob.prototype.setSpec = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Blob} returns this
 */
proto.dataocean.Blob.prototype.clearSpec = function() {
  return this.setSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Blob.prototype.hasSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BlobStatus status = 3;
 * @return {?proto.dataocean.BlobStatus}
 */
proto.dataocean.Blob.prototype.getStatus = function() {
  return /** @type{?proto.dataocean.BlobStatus} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.BlobStatus, 3));
};


/**
 * @param {?proto.dataocean.BlobStatus|undefined} value
 * @return {!proto.dataocean.Blob} returns this
*/
proto.dataocean.Blob.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Blob} returns this
 */
proto.dataocean.Blob.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Blob.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.BlobSpec.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BlobSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BlobSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BlobSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    mimeType: (f = msg.getMimeType()) && dataocean_global_pb.MIMEType.toObject(includeInstance, f),
    fromList: jspb.Message.toObjectList(msg.getFromList(),
    meta_meta_pb.ID.toObject, includeInstance),
    pool: jspb.Message.getFieldWithDefault(msg, 1, 0),
    source: (f = msg.getSource()) && proto.dataocean.BlobSource.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 5, 0),
    kind: jspb.Message.getFieldWithDefault(msg, 6, 0),
    label: (f = msg.getLabel()) && dataocean_labelx_pb.TaskLabel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BlobSpec}
 */
proto.dataocean.BlobSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BlobSpec;
  return proto.dataocean.BlobSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BlobSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BlobSpec}
 */
proto.dataocean.BlobSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new dataocean_global_pb.MIMEType;
      reader.readMessage(value,dataocean_global_pb.MIMEType.deserializeBinaryFromReader);
      msg.setMimeType(value);
      break;
    case 4:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addFrom(value);
      break;
    case 1:
      var value = /** @type {!proto.dataocean.Pool} */ (reader.readEnum());
      msg.setPool(value);
      break;
    case 3:
      var value = new proto.dataocean.BlobSource;
      reader.readMessage(value,proto.dataocean.BlobSource.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCount(value);
      break;
    case 6:
      var value = /** @type {!proto.dataocean.BlobSpecKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 7:
      var value = new dataocean_labelx_pb.TaskLabel;
      reader.readMessage(value,dataocean_labelx_pb.TaskLabel.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BlobSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BlobSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BlobSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMimeType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dataocean_global_pb.MIMEType.serializeBinaryToWriter
    );
  }
  f = message.getFromList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getPool();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.BlobSource.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      dataocean_labelx_pb.TaskLabel.serializeBinaryToWriter
    );
  }
};


/**
 * optional MIMEType mime_type = 2;
 * @return {?proto.dataocean.MIMEType}
 */
proto.dataocean.BlobSpec.prototype.getMimeType = function() {
  return /** @type{?proto.dataocean.MIMEType} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MIMEType, 2));
};


/**
 * @param {?proto.dataocean.MIMEType|undefined} value
 * @return {!proto.dataocean.BlobSpec} returns this
*/
proto.dataocean.BlobSpec.prototype.setMimeType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobSpec} returns this
 */
proto.dataocean.BlobSpec.prototype.clearMimeType = function() {
  return this.setMimeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobSpec.prototype.hasMimeType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated meta.ID from = 4;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.BlobSpec.prototype.getFromList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 4));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.BlobSpec} returns this
*/
proto.dataocean.BlobSpec.prototype.setFromList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.BlobSpec.prototype.addFrom = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.BlobSpec} returns this
 */
proto.dataocean.BlobSpec.prototype.clearFromList = function() {
  return this.setFromList([]);
};


/**
 * optional Pool pool = 1;
 * @return {!proto.dataocean.Pool}
 */
proto.dataocean.BlobSpec.prototype.getPool = function() {
  return /** @type {!proto.dataocean.Pool} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataocean.Pool} value
 * @return {!proto.dataocean.BlobSpec} returns this
 */
proto.dataocean.BlobSpec.prototype.setPool = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional BlobSource source = 3;
 * @return {?proto.dataocean.BlobSource}
 */
proto.dataocean.BlobSpec.prototype.getSource = function() {
  return /** @type{?proto.dataocean.BlobSource} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.BlobSource, 3));
};


/**
 * @param {?proto.dataocean.BlobSource|undefined} value
 * @return {!proto.dataocean.BlobSpec} returns this
*/
proto.dataocean.BlobSpec.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobSpec} returns this
 */
proto.dataocean.BlobSpec.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobSpec.prototype.hasSource = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 count = 5;
 * @return {number}
 */
proto.dataocean.BlobSpec.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.BlobSpec} returns this
 */
proto.dataocean.BlobSpec.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional BlobSpecKind kind = 6;
 * @return {!proto.dataocean.BlobSpecKind}
 */
proto.dataocean.BlobSpec.prototype.getKind = function() {
  return /** @type {!proto.dataocean.BlobSpecKind} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.dataocean.BlobSpecKind} value
 * @return {!proto.dataocean.BlobSpec} returns this
 */
proto.dataocean.BlobSpec.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional TaskLabel label = 7;
 * @return {?proto.dataocean.TaskLabel}
 */
proto.dataocean.BlobSpec.prototype.getLabel = function() {
  return /** @type{?proto.dataocean.TaskLabel} */ (
    jspb.Message.getWrapperField(this, dataocean_labelx_pb.TaskLabel, 7));
};


/**
 * @param {?proto.dataocean.TaskLabel|undefined} value
 * @return {!proto.dataocean.BlobSpec} returns this
*/
proto.dataocean.BlobSpec.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobSpec} returns this
 */
proto.dataocean.BlobSpec.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobSpec.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.TransformStatus.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.TransformStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.TransformStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.TransformStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TransformStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    succeeded: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    failedDropletsList: jspb.Message.toObjectList(msg.getFailedDropletsList(),
    meta_meta_pb.ID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.TransformStatus}
 */
proto.dataocean.TransformStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.TransformStatus;
  return proto.dataocean.TransformStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.TransformStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.TransformStatus}
 */
proto.dataocean.TransformStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSucceeded(value);
      break;
    case 2:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addFailedDroplets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.TransformStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.TransformStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.TransformStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TransformStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSucceeded();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFailedDropletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool succeeded = 1;
 * @return {boolean}
 */
proto.dataocean.TransformStatus.prototype.getSucceeded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.TransformStatus} returns this
 */
proto.dataocean.TransformStatus.prototype.setSucceeded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated meta.ID failed_droplets = 2;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.TransformStatus.prototype.getFailedDropletsList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 2));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.TransformStatus} returns this
*/
proto.dataocean.TransformStatus.prototype.setFailedDropletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.TransformStatus.prototype.addFailedDroplets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.TransformStatus} returns this
 */
proto.dataocean.TransformStatus.prototype.clearFailedDropletsList = function() {
  return this.setFailedDropletsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BlobSource.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BlobSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BlobSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    atwork: (f = msg.getAtwork()) && dataocean_atwork_pb.Metal.BlobSource.toObject(includeInstance, f),
    labelx: (f = msg.getLabelx()) && dataocean_labelx_pb.Resource.BlobSource.toObject(includeInstance, f),
    teststand: (f = msg.getTeststand()) && dataocean_teststand_pb.Case.BlobSource.toObject(includeInstance, f),
    trainspot: (f = msg.getTrainspot()) && dataocean_trainspot_pb.Data.BlobSource.toObject(includeInstance, f),
    modelshopGroundTruth: (f = msg.getModelshopGroundTruth()) && dataocean_modelshop_pb.GroundTruth.BlobSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BlobSource}
 */
proto.dataocean.BlobSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BlobSource;
  return proto.dataocean.BlobSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BlobSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BlobSource}
 */
proto.dataocean.BlobSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dataocean_atwork_pb.Metal.BlobSource;
      reader.readMessage(value,dataocean_atwork_pb.Metal.BlobSource.deserializeBinaryFromReader);
      msg.setAtwork(value);
      break;
    case 2:
      var value = new dataocean_labelx_pb.Resource.BlobSource;
      reader.readMessage(value,dataocean_labelx_pb.Resource.BlobSource.deserializeBinaryFromReader);
      msg.setLabelx(value);
      break;
    case 3:
      var value = new dataocean_teststand_pb.Case.BlobSource;
      reader.readMessage(value,dataocean_teststand_pb.Case.BlobSource.deserializeBinaryFromReader);
      msg.setTeststand(value);
      break;
    case 4:
      var value = new dataocean_trainspot_pb.Data.BlobSource;
      reader.readMessage(value,dataocean_trainspot_pb.Data.BlobSource.deserializeBinaryFromReader);
      msg.setTrainspot(value);
      break;
    case 5:
      var value = new dataocean_modelshop_pb.GroundTruth.BlobSource;
      reader.readMessage(value,dataocean_modelshop_pb.GroundTruth.BlobSource.deserializeBinaryFromReader);
      msg.setModelshopGroundTruth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BlobSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BlobSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BlobSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAtwork();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dataocean_atwork_pb.Metal.BlobSource.serializeBinaryToWriter
    );
  }
  f = message.getLabelx();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dataocean_labelx_pb.Resource.BlobSource.serializeBinaryToWriter
    );
  }
  f = message.getTeststand();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      dataocean_teststand_pb.Case.BlobSource.serializeBinaryToWriter
    );
  }
  f = message.getTrainspot();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      dataocean_trainspot_pb.Data.BlobSource.serializeBinaryToWriter
    );
  }
  f = message.getModelshopGroundTruth();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dataocean_modelshop_pb.GroundTruth.BlobSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional Metal.BlobSource atwork = 1;
 * @return {?proto.dataocean.Metal.BlobSource}
 */
proto.dataocean.BlobSource.prototype.getAtwork = function() {
  return /** @type{?proto.dataocean.Metal.BlobSource} */ (
    jspb.Message.getWrapperField(this, dataocean_atwork_pb.Metal.BlobSource, 1));
};


/**
 * @param {?proto.dataocean.Metal.BlobSource|undefined} value
 * @return {!proto.dataocean.BlobSource} returns this
*/
proto.dataocean.BlobSource.prototype.setAtwork = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobSource} returns this
 */
proto.dataocean.BlobSource.prototype.clearAtwork = function() {
  return this.setAtwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobSource.prototype.hasAtwork = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Resource.BlobSource labelx = 2;
 * @return {?proto.dataocean.Resource.BlobSource}
 */
proto.dataocean.BlobSource.prototype.getLabelx = function() {
  return /** @type{?proto.dataocean.Resource.BlobSource} */ (
    jspb.Message.getWrapperField(this, dataocean_labelx_pb.Resource.BlobSource, 2));
};


/**
 * @param {?proto.dataocean.Resource.BlobSource|undefined} value
 * @return {!proto.dataocean.BlobSource} returns this
*/
proto.dataocean.BlobSource.prototype.setLabelx = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobSource} returns this
 */
proto.dataocean.BlobSource.prototype.clearLabelx = function() {
  return this.setLabelx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobSource.prototype.hasLabelx = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Case.BlobSource teststand = 3;
 * @return {?proto.dataocean.Case.BlobSource}
 */
proto.dataocean.BlobSource.prototype.getTeststand = function() {
  return /** @type{?proto.dataocean.Case.BlobSource} */ (
    jspb.Message.getWrapperField(this, dataocean_teststand_pb.Case.BlobSource, 3));
};


/**
 * @param {?proto.dataocean.Case.BlobSource|undefined} value
 * @return {!proto.dataocean.BlobSource} returns this
*/
proto.dataocean.BlobSource.prototype.setTeststand = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobSource} returns this
 */
proto.dataocean.BlobSource.prototype.clearTeststand = function() {
  return this.setTeststand(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobSource.prototype.hasTeststand = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Data.BlobSource trainspot = 4;
 * @return {?proto.dataocean.Data.BlobSource}
 */
proto.dataocean.BlobSource.prototype.getTrainspot = function() {
  return /** @type{?proto.dataocean.Data.BlobSource} */ (
    jspb.Message.getWrapperField(this, dataocean_trainspot_pb.Data.BlobSource, 4));
};


/**
 * @param {?proto.dataocean.Data.BlobSource|undefined} value
 * @return {!proto.dataocean.BlobSource} returns this
*/
proto.dataocean.BlobSource.prototype.setTrainspot = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobSource} returns this
 */
proto.dataocean.BlobSource.prototype.clearTrainspot = function() {
  return this.setTrainspot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobSource.prototype.hasTrainspot = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GroundTruth.BlobSource modelshop_ground_truth = 5;
 * @return {?proto.dataocean.GroundTruth.BlobSource}
 */
proto.dataocean.BlobSource.prototype.getModelshopGroundTruth = function() {
  return /** @type{?proto.dataocean.GroundTruth.BlobSource} */ (
    jspb.Message.getWrapperField(this, dataocean_modelshop_pb.GroundTruth.BlobSource, 5));
};


/**
 * @param {?proto.dataocean.GroundTruth.BlobSource|undefined} value
 * @return {!proto.dataocean.BlobSource} returns this
*/
proto.dataocean.BlobSource.prototype.setModelshopGroundTruth = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobSource} returns this
 */
proto.dataocean.BlobSource.prototype.clearModelshopGroundTruth = function() {
  return this.setModelshopGroundTruth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobSource.prototype.hasModelshopGroundTruth = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BlobStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BlobStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BlobStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    storagesMap: (f = msg.getStoragesMap()) ? f.toObject(includeInstance, proto.dataocean.BlobStorage.toObject) : [],
    content: (f = msg.getContent()) && proto.dataocean.BlobContent.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    size: jspb.Message.getFieldWithDefault(msg, 4, 0),
    state: jspb.Message.getFieldWithDefault(msg, 5, 0),
    transform: (f = msg.getTransform()) && proto.dataocean.TransformStatus.toObject(includeInstance, f),
    releasesMap: (f = msg.getReleasesMap()) ? f.toObject(includeInstance, proto.dataocean.BlobSpecRelease.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BlobStatus}
 */
proto.dataocean.BlobStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BlobStatus;
  return proto.dataocean.BlobStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BlobStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BlobStatus}
 */
proto.dataocean.BlobStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getStoragesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.dataocean.BlobStorage.deserializeBinaryFromReader, "", new proto.dataocean.BlobStorage());
         });
      break;
    case 2:
      var value = new proto.dataocean.BlobContent;
      reader.readMessage(value,proto.dataocean.BlobContent.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 5:
      var value = /** @type {!proto.dataocean.BlobState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 6:
      var value = new proto.dataocean.TransformStatus;
      reader.readMessage(value,proto.dataocean.TransformStatus.deserializeBinaryFromReader);
      msg.setTransform(value);
      break;
    case 8:
      var value = msg.getReleasesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.dataocean.BlobSpecRelease.deserializeBinaryFromReader, "", new proto.dataocean.BlobSpecRelease());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BlobStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BlobStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BlobStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStoragesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.dataocean.BlobStorage.serializeBinaryToWriter);
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.BlobContent.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getTransform();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.dataocean.TransformStatus.serializeBinaryToWriter
    );
  }
  f = message.getReleasesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.dataocean.BlobSpecRelease.serializeBinaryToWriter);
  }
};


/**
 * map<string, BlobStorage> storages = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.dataocean.BlobStorage>}
 */
proto.dataocean.BlobStatus.prototype.getStoragesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.dataocean.BlobStorage>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.dataocean.BlobStorage));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dataocean.BlobStatus} returns this
 */
proto.dataocean.BlobStatus.prototype.clearStoragesMap = function() {
  this.getStoragesMap().clear();
  return this;};


/**
 * optional BlobContent content = 2;
 * @return {?proto.dataocean.BlobContent}
 */
proto.dataocean.BlobStatus.prototype.getContent = function() {
  return /** @type{?proto.dataocean.BlobContent} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.BlobContent, 2));
};


/**
 * @param {?proto.dataocean.BlobContent|undefined} value
 * @return {!proto.dataocean.BlobStatus} returns this
*/
proto.dataocean.BlobStatus.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobStatus} returns this
 */
proto.dataocean.BlobStatus.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobStatus.prototype.hasContent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 count = 3;
 * @return {number}
 */
proto.dataocean.BlobStatus.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.BlobStatus} returns this
 */
proto.dataocean.BlobStatus.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 size = 4;
 * @return {number}
 */
proto.dataocean.BlobStatus.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.BlobStatus} returns this
 */
proto.dataocean.BlobStatus.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional BlobState state = 5;
 * @return {!proto.dataocean.BlobState}
 */
proto.dataocean.BlobStatus.prototype.getState = function() {
  return /** @type {!proto.dataocean.BlobState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.dataocean.BlobState} value
 * @return {!proto.dataocean.BlobStatus} returns this
 */
proto.dataocean.BlobStatus.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional TransformStatus transform = 6;
 * @return {?proto.dataocean.TransformStatus}
 */
proto.dataocean.BlobStatus.prototype.getTransform = function() {
  return /** @type{?proto.dataocean.TransformStatus} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.TransformStatus, 6));
};


/**
 * @param {?proto.dataocean.TransformStatus|undefined} value
 * @return {!proto.dataocean.BlobStatus} returns this
*/
proto.dataocean.BlobStatus.prototype.setTransform = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BlobStatus} returns this
 */
proto.dataocean.BlobStatus.prototype.clearTransform = function() {
  return this.setTransform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BlobStatus.prototype.hasTransform = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * map<string, BlobSpecRelease> releases = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.dataocean.BlobSpecRelease>}
 */
proto.dataocean.BlobStatus.prototype.getReleasesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.dataocean.BlobSpecRelease>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      proto.dataocean.BlobSpecRelease));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dataocean.BlobStatus} returns this
 */
proto.dataocean.BlobStatus.prototype.clearReleasesMap = function() {
  this.getReleasesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Volume.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Volume.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Volume} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Volume.toObject = function(includeInstance, msg) {
  var f, obj = {
    cluster: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namespace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Volume}
 */
proto.dataocean.Volume.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Volume;
  return proto.dataocean.Volume.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Volume} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Volume}
 */
proto.dataocean.Volume.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCluster(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamespace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Volume.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Volume.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Volume} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Volume.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCluster();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamespace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string cluster = 1;
 * @return {string}
 */
proto.dataocean.Volume.prototype.getCluster = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Volume} returns this
 */
proto.dataocean.Volume.prototype.setCluster = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string namespace = 2;
 * @return {string}
 */
proto.dataocean.Volume.prototype.getNamespace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Volume} returns this
 */
proto.dataocean.Volume.prototype.setNamespace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.dataocean.Volume.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Volume} returns this
 */
proto.dataocean.Volume.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BlobStorage.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BlobStorage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BlobStorage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobStorage.toObject = function(includeInstance, msg) {
  var f, obj = {
    storager: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reachable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BlobStorage}
 */
proto.dataocean.BlobStorage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BlobStorage;
  return proto.dataocean.BlobStorage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BlobStorage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BlobStorage}
 */
proto.dataocean.BlobStorage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStorager(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReachable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BlobStorage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BlobStorage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BlobStorage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobStorage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStorager();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReachable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string storager = 1;
 * @return {string}
 */
proto.dataocean.BlobStorage.prototype.getStorager = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.BlobStorage} returns this
 */
proto.dataocean.BlobStorage.prototype.setStorager = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool reachable = 2;
 * @return {boolean}
 */
proto.dataocean.BlobStorage.prototype.getReachable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.BlobStorage} returns this
 */
proto.dataocean.BlobStorage.prototype.setReachable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.BlobContent.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BlobContent.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BlobContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BlobContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dropletsList: jspb.Message.toObjectList(msg.getDropletsList(),
    dataocean_droplet_pb.Droplet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BlobContent}
 */
proto.dataocean.BlobContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BlobContent;
  return proto.dataocean.BlobContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BlobContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BlobContent}
 */
proto.dataocean.BlobContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalSize(value);
      break;
    case 3:
      var value = new dataocean_droplet_pb.Droplet;
      reader.readMessage(value,dataocean_droplet_pb.Droplet.deserializeBinaryFromReader);
      msg.addDroplets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BlobContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BlobContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BlobContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDropletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      dataocean_droplet_pb.Droplet.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.dataocean.BlobContent.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.BlobContent} returns this
 */
proto.dataocean.BlobContent.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 total_size = 2;
 * @return {number}
 */
proto.dataocean.BlobContent.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.BlobContent} returns this
 */
proto.dataocean.BlobContent.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Droplet droplets = 3;
 * @return {!Array<!proto.dataocean.Droplet>}
 */
proto.dataocean.BlobContent.prototype.getDropletsList = function() {
  return /** @type{!Array<!proto.dataocean.Droplet>} */ (
    jspb.Message.getRepeatedWrapperField(this, dataocean_droplet_pb.Droplet, 3));
};


/**
 * @param {!Array<!proto.dataocean.Droplet>} value
 * @return {!proto.dataocean.BlobContent} returns this
*/
proto.dataocean.BlobContent.prototype.setDropletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataocean.Droplet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Droplet}
 */
proto.dataocean.BlobContent.prototype.addDroplets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataocean.Droplet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.BlobContent} returns this
 */
proto.dataocean.BlobContent.prototype.clearDropletsList = function() {
  return this.setDropletsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BlobSpecRelease.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BlobSpecRelease.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BlobSpecRelease} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobSpecRelease.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BlobSpecRelease}
 */
proto.dataocean.BlobSpecRelease.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BlobSpecRelease;
  return proto.dataocean.BlobSpecRelease.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BlobSpecRelease} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BlobSpecRelease}
 */
proto.dataocean.BlobSpecRelease.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dataocean.BlobSpecReleaseName} */ (reader.readEnum());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BlobSpecRelease.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BlobSpecRelease.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BlobSpecRelease} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BlobSpecRelease.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional BlobSpecReleaseName name = 1;
 * @return {!proto.dataocean.BlobSpecReleaseName}
 */
proto.dataocean.BlobSpecRelease.prototype.getName = function() {
  return /** @type {!proto.dataocean.BlobSpecReleaseName} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataocean.BlobSpecReleaseName} value
 * @return {!proto.dataocean.BlobSpecRelease} returns this
 */
proto.dataocean.BlobSpecRelease.prototype.setName = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.CreateBlobReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.CreateBlobReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.CreateBlobReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateBlobReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    blob: (f = msg.getBlob()) && proto.dataocean.Blob.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.CreateBlobReq}
 */
proto.dataocean.CreateBlobReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.CreateBlobReq;
  return proto.dataocean.CreateBlobReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.CreateBlobReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.CreateBlobReq}
 */
proto.dataocean.CreateBlobReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Blob;
      reader.readMessage(value,proto.dataocean.Blob.deserializeBinaryFromReader);
      msg.setBlob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.CreateBlobReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.CreateBlobReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.CreateBlobReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateBlobReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Blob.serializeBinaryToWriter
    );
  }
};


/**
 * optional Blob blob = 1;
 * @return {?proto.dataocean.Blob}
 */
proto.dataocean.CreateBlobReq.prototype.getBlob = function() {
  return /** @type{?proto.dataocean.Blob} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Blob, 1));
};


/**
 * @param {?proto.dataocean.Blob|undefined} value
 * @return {!proto.dataocean.CreateBlobReq} returns this
*/
proto.dataocean.CreateBlobReq.prototype.setBlob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.CreateBlobReq} returns this
 */
proto.dataocean.CreateBlobReq.prototype.clearBlob = function() {
  return this.setBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.CreateBlobReq.prototype.hasBlob = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GetBlobReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GetBlobReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GetBlobReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBlobReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GetBlobReq}
 */
proto.dataocean.GetBlobReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GetBlobReq;
  return proto.dataocean.GetBlobReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GetBlobReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GetBlobReq}
 */
proto.dataocean.GetBlobReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GetBlobReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GetBlobReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GetBlobReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBlobReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.GetBlobReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GetBlobReq} returns this
 */
proto.dataocean.GetBlobReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.RemoveBlobReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.RemoveBlobReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.RemoveBlobReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.RemoveBlobReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.RemoveBlobReq}
 */
proto.dataocean.RemoveBlobReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.RemoveBlobReq;
  return proto.dataocean.RemoveBlobReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.RemoveBlobReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.RemoveBlobReq}
 */
proto.dataocean.RemoveBlobReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.RemoveBlobReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.RemoveBlobReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.RemoveBlobReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.RemoveBlobReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.RemoveBlobReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.RemoveBlobReq} returns this
 */
proto.dataocean.RemoveBlobReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListBlobsReq.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListBlobsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListBlobsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListBlobsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListBlobsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pool: jspb.Message.getFieldWithDefault(msg, 1, 0),
    creator: jspb.Message.getFieldWithDefault(msg, 11, ""),
    keyword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mimeType: (f = msg.getMimeType()) && dataocean_global_pb.MIMEType.toObject(includeInstance, f),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerReq.toObject(includeInstance, f),
    sortByList: jspb.Message.toObjectList(msg.getSortByList(),
    dataocean_global_pb.Sorter.toObject, includeInstance),
    state: jspb.Message.getFieldWithDefault(msg, 7, 0),
    searchCreated: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    tagsMap: (f = msg.getTagsMap()) ? f.toObject(includeInstance, undefined) : [],
    labelx: (f = msg.getLabelx()) && dataocean_labelx_pb.Resource.BlobFilter.toObject(includeInstance, f),
    modelshopGroundTruth: (f = msg.getModelshopGroundTruth()) && dataocean_modelshop_pb.GroundTruth.BlobFilter.toObject(includeInstance, f),
    release: jspb.Message.getFieldWithDefault(msg, 13, 0),
    kind: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListBlobsReq}
 */
proto.dataocean.ListBlobsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListBlobsReq;
  return proto.dataocean.ListBlobsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListBlobsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListBlobsReq}
 */
proto.dataocean.ListBlobsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dataocean.Pool} */ (reader.readEnum());
      msg.setPool(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 4:
      var value = new dataocean_global_pb.MIMEType;
      reader.readMessage(value,dataocean_global_pb.MIMEType.deserializeBinaryFromReader);
      msg.setMimeType(value);
      break;
    case 3:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    case 6:
      var value = new dataocean_global_pb.Sorter;
      reader.readMessage(value,dataocean_global_pb.Sorter.deserializeBinaryFromReader);
      msg.addSortBy(value);
      break;
    case 7:
      var value = /** @type {!proto.dataocean.BlobState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSearchCreated(value);
      break;
    case 8:
      var value = msg.getTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 9:
      var value = new dataocean_labelx_pb.Resource.BlobFilter;
      reader.readMessage(value,dataocean_labelx_pb.Resource.BlobFilter.deserializeBinaryFromReader);
      msg.setLabelx(value);
      break;
    case 10:
      var value = new dataocean_modelshop_pb.GroundTruth.BlobFilter;
      reader.readMessage(value,dataocean_modelshop_pb.GroundTruth.BlobFilter.deserializeBinaryFromReader);
      msg.setModelshopGroundTruth(value);
      break;
    case 13:
      var value = /** @type {!proto.dataocean.BlobSpecReleaseName} */ (reader.readEnum());
      msg.setRelease(value);
      break;
    case 14:
      var value = /** @type {!proto.dataocean.BlobSpecKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListBlobsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListBlobsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListBlobsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListBlobsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPool();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMimeType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      dataocean_global_pb.MIMEType.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
  f = message.getSortByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      dataocean_global_pb.Sorter.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSearchCreated();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getLabelx();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      dataocean_labelx_pb.Resource.BlobFilter.serializeBinaryToWriter
    );
  }
  f = message.getModelshopGroundTruth();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      dataocean_modelshop_pb.GroundTruth.BlobFilter.serializeBinaryToWriter
    );
  }
  f = message.getRelease();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * optional Pool pool = 1;
 * @return {!proto.dataocean.Pool}
 */
proto.dataocean.ListBlobsReq.prototype.getPool = function() {
  return /** @type {!proto.dataocean.Pool} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataocean.Pool} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.setPool = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string creator = 11;
 * @return {string}
 */
proto.dataocean.ListBlobsReq.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string keyword = 2;
 * @return {string}
 */
proto.dataocean.ListBlobsReq.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional MIMEType mime_type = 4;
 * @return {?proto.dataocean.MIMEType}
 */
proto.dataocean.ListBlobsReq.prototype.getMimeType = function() {
  return /** @type{?proto.dataocean.MIMEType} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MIMEType, 4));
};


/**
 * @param {?proto.dataocean.MIMEType|undefined} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
*/
proto.dataocean.ListBlobsReq.prototype.setMimeType = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.clearMimeType = function() {
  return this.setMimeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListBlobsReq.prototype.hasMimeType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional meta.PagerReq pager = 3;
 * @return {?proto.meta.PagerReq}
 */
proto.dataocean.ListBlobsReq.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 3));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
*/
proto.dataocean.ListBlobsReq.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListBlobsReq.prototype.hasPager = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Sorter sort_by = 6;
 * @return {!Array<!proto.dataocean.Sorter>}
 */
proto.dataocean.ListBlobsReq.prototype.getSortByList = function() {
  return /** @type{!Array<!proto.dataocean.Sorter>} */ (
    jspb.Message.getRepeatedWrapperField(this, dataocean_global_pb.Sorter, 6));
};


/**
 * @param {!Array<!proto.dataocean.Sorter>} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
*/
proto.dataocean.ListBlobsReq.prototype.setSortByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.dataocean.Sorter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Sorter}
 */
proto.dataocean.ListBlobsReq.prototype.addSortBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.dataocean.Sorter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.clearSortByList = function() {
  return this.setSortByList([]);
};


/**
 * optional BlobState state = 7;
 * @return {!proto.dataocean.BlobState}
 */
proto.dataocean.ListBlobsReq.prototype.getState = function() {
  return /** @type {!proto.dataocean.BlobState} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.dataocean.BlobState} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool search_created = 12;
 * @return {boolean}
 */
proto.dataocean.ListBlobsReq.prototype.getSearchCreated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.setSearchCreated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * map<string, string> tags = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.dataocean.ListBlobsReq.prototype.getTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.clearTagsMap = function() {
  this.getTagsMap().clear();
  return this;};


/**
 * optional Resource.BlobFilter labelx = 9;
 * @return {?proto.dataocean.Resource.BlobFilter}
 */
proto.dataocean.ListBlobsReq.prototype.getLabelx = function() {
  return /** @type{?proto.dataocean.Resource.BlobFilter} */ (
    jspb.Message.getWrapperField(this, dataocean_labelx_pb.Resource.BlobFilter, 9));
};


/**
 * @param {?proto.dataocean.Resource.BlobFilter|undefined} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
*/
proto.dataocean.ListBlobsReq.prototype.setLabelx = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.clearLabelx = function() {
  return this.setLabelx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListBlobsReq.prototype.hasLabelx = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GroundTruth.BlobFilter modelshop_ground_truth = 10;
 * @return {?proto.dataocean.GroundTruth.BlobFilter}
 */
proto.dataocean.ListBlobsReq.prototype.getModelshopGroundTruth = function() {
  return /** @type{?proto.dataocean.GroundTruth.BlobFilter} */ (
    jspb.Message.getWrapperField(this, dataocean_modelshop_pb.GroundTruth.BlobFilter, 10));
};


/**
 * @param {?proto.dataocean.GroundTruth.BlobFilter|undefined} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
*/
proto.dataocean.ListBlobsReq.prototype.setModelshopGroundTruth = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.clearModelshopGroundTruth = function() {
  return this.setModelshopGroundTruth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListBlobsReq.prototype.hasModelshopGroundTruth = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional BlobSpecReleaseName release = 13;
 * @return {!proto.dataocean.BlobSpecReleaseName}
 */
proto.dataocean.ListBlobsReq.prototype.getRelease = function() {
  return /** @type {!proto.dataocean.BlobSpecReleaseName} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.dataocean.BlobSpecReleaseName} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.setRelease = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional BlobSpecKind kind = 14;
 * @return {!proto.dataocean.BlobSpecKind}
 */
proto.dataocean.ListBlobsReq.prototype.getKind = function() {
  return /** @type {!proto.dataocean.BlobSpecKind} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.dataocean.BlobSpecKind} value
 * @return {!proto.dataocean.ListBlobsReq} returns this
 */
proto.dataocean.ListBlobsReq.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListBlobsResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListBlobsResp.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListBlobsResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListBlobsResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListBlobsResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    blobsList: jspb.Message.toObjectList(msg.getBlobsList(),
    proto.dataocean.Blob.toObject, includeInstance),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListBlobsResp}
 */
proto.dataocean.ListBlobsResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListBlobsResp;
  return proto.dataocean.ListBlobsResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListBlobsResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListBlobsResp}
 */
proto.dataocean.ListBlobsResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Blob;
      reader.readMessage(value,proto.dataocean.Blob.deserializeBinaryFromReader);
      msg.addBlobs(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListBlobsResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListBlobsResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListBlobsResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListBlobsResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlobsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataocean.Blob.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Blob blobs = 1;
 * @return {!Array<!proto.dataocean.Blob>}
 */
proto.dataocean.ListBlobsResp.prototype.getBlobsList = function() {
  return /** @type{!Array<!proto.dataocean.Blob>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.Blob, 1));
};


/**
 * @param {!Array<!proto.dataocean.Blob>} value
 * @return {!proto.dataocean.ListBlobsResp} returns this
*/
proto.dataocean.ListBlobsResp.prototype.setBlobsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.Blob=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Blob}
 */
proto.dataocean.ListBlobsResp.prototype.addBlobs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.Blob, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListBlobsResp} returns this
 */
proto.dataocean.ListBlobsResp.prototype.clearBlobsList = function() {
  return this.setBlobsList([]);
};


/**
 * optional meta.PagerRes pager = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.dataocean.ListBlobsResp.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.dataocean.ListBlobsResp} returns this
*/
proto.dataocean.ListBlobsResp.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListBlobsResp} returns this
 */
proto.dataocean.ListBlobsResp.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListBlobsResp.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.UpdateBlobReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.UpdateBlobReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.UpdateBlobReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.UpdateBlobReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    blob: (f = msg.getBlob()) && proto.dataocean.Blob.toObject(includeInstance, f),
    fieldMask: (f = msg.getFieldMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.UpdateBlobReq}
 */
proto.dataocean.UpdateBlobReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.UpdateBlobReq;
  return proto.dataocean.UpdateBlobReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.UpdateBlobReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.UpdateBlobReq}
 */
proto.dataocean.UpdateBlobReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Blob;
      reader.readMessage(value,proto.dataocean.Blob.deserializeBinaryFromReader);
      msg.setBlob(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setFieldMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.UpdateBlobReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.UpdateBlobReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.UpdateBlobReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.UpdateBlobReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Blob.serializeBinaryToWriter
    );
  }
  f = message.getFieldMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Blob blob = 1;
 * @return {?proto.dataocean.Blob}
 */
proto.dataocean.UpdateBlobReq.prototype.getBlob = function() {
  return /** @type{?proto.dataocean.Blob} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Blob, 1));
};


/**
 * @param {?proto.dataocean.Blob|undefined} value
 * @return {!proto.dataocean.UpdateBlobReq} returns this
*/
proto.dataocean.UpdateBlobReq.prototype.setBlob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.UpdateBlobReq} returns this
 */
proto.dataocean.UpdateBlobReq.prototype.clearBlob = function() {
  return this.setBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.UpdateBlobReq.prototype.hasBlob = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask field_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.dataocean.UpdateBlobReq.prototype.getFieldMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.dataocean.UpdateBlobReq} returns this
*/
proto.dataocean.UpdateBlobReq.prototype.setFieldMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.UpdateBlobReq} returns this
 */
proto.dataocean.UpdateBlobReq.prototype.clearFieldMask = function() {
  return this.setFieldMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.UpdateBlobReq.prototype.hasFieldMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.PublishBlobReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.PublishBlobReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.PublishBlobReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.PublishBlobReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    release: (f = msg.getRelease()) && proto.dataocean.BlobSpecRelease.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.PublishBlobReq}
 */
proto.dataocean.PublishBlobReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.PublishBlobReq;
  return proto.dataocean.PublishBlobReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.PublishBlobReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.PublishBlobReq}
 */
proto.dataocean.PublishBlobReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.dataocean.BlobSpecRelease;
      reader.readMessage(value,proto.dataocean.BlobSpecRelease.deserializeBinaryFromReader);
      msg.setRelease(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.PublishBlobReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.PublishBlobReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.PublishBlobReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.PublishBlobReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRelease();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.BlobSpecRelease.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.PublishBlobReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.PublishBlobReq} returns this
 */
proto.dataocean.PublishBlobReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BlobSpecRelease release = 2;
 * @return {?proto.dataocean.BlobSpecRelease}
 */
proto.dataocean.PublishBlobReq.prototype.getRelease = function() {
  return /** @type{?proto.dataocean.BlobSpecRelease} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.BlobSpecRelease, 2));
};


/**
 * @param {?proto.dataocean.BlobSpecRelease|undefined} value
 * @return {!proto.dataocean.PublishBlobReq} returns this
*/
proto.dataocean.PublishBlobReq.prototype.setRelease = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.PublishBlobReq} returns this
 */
proto.dataocean.PublishBlobReq.prototype.clearRelease = function() {
  return this.setRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.PublishBlobReq.prototype.hasRelease = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListLabelxTasksReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListLabelxTasksReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListLabelxTasksReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListLabelxTasksReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pager: (f = msg.getPager()) && proto.dataocean.AggregationPager.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListLabelxTasksReq}
 */
proto.dataocean.ListLabelxTasksReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListLabelxTasksReq;
  return proto.dataocean.ListLabelxTasksReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListLabelxTasksReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListLabelxTasksReq}
 */
proto.dataocean.ListLabelxTasksReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    case 3:
      var value = new proto.dataocean.AggregationPager;
      reader.readMessage(value,proto.dataocean.AggregationPager.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListLabelxTasksReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListLabelxTasksReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListLabelxTasksReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListLabelxTasksReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.AggregationPager.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dataocean.ListLabelxTasksReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListLabelxTasksReq} returns this
 */
proto.dataocean.ListLabelxTasksReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string creator = 2;
 * @return {string}
 */
proto.dataocean.ListLabelxTasksReq.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListLabelxTasksReq} returns this
 */
proto.dataocean.ListLabelxTasksReq.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AggregationPager pager = 3;
 * @return {?proto.dataocean.AggregationPager}
 */
proto.dataocean.ListLabelxTasksReq.prototype.getPager = function() {
  return /** @type{?proto.dataocean.AggregationPager} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.AggregationPager, 3));
};


/**
 * @param {?proto.dataocean.AggregationPager|undefined} value
 * @return {!proto.dataocean.ListLabelxTasksReq} returns this
*/
proto.dataocean.ListLabelxTasksReq.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListLabelxTasksReq} returns this
 */
proto.dataocean.ListLabelxTasksReq.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListLabelxTasksReq.prototype.hasPager = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListLabelxTasksResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListLabelxTasksResp.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListLabelxTasksResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListLabelxTasksResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListLabelxTasksResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.dataocean.LabelxTask.toObject, includeInstance),
    pager: (f = msg.getPager()) && proto.dataocean.AggregationPager.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListLabelxTasksResp}
 */
proto.dataocean.ListLabelxTasksResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListLabelxTasksResp;
  return proto.dataocean.ListLabelxTasksResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListLabelxTasksResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListLabelxTasksResp}
 */
proto.dataocean.ListLabelxTasksResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.LabelxTask;
      reader.readMessage(value,proto.dataocean.LabelxTask.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 2:
      var value = new proto.dataocean.AggregationPager;
      reader.readMessage(value,proto.dataocean.AggregationPager.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListLabelxTasksResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListLabelxTasksResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListLabelxTasksResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListLabelxTasksResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataocean.LabelxTask.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.AggregationPager.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LabelxTask tasks = 1;
 * @return {!Array<!proto.dataocean.LabelxTask>}
 */
proto.dataocean.ListLabelxTasksResp.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.dataocean.LabelxTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.LabelxTask, 1));
};


/**
 * @param {!Array<!proto.dataocean.LabelxTask>} value
 * @return {!proto.dataocean.ListLabelxTasksResp} returns this
*/
proto.dataocean.ListLabelxTasksResp.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.LabelxTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.LabelxTask}
 */
proto.dataocean.ListLabelxTasksResp.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.LabelxTask, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListLabelxTasksResp} returns this
 */
proto.dataocean.ListLabelxTasksResp.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};


/**
 * optional AggregationPager pager = 2;
 * @return {?proto.dataocean.AggregationPager}
 */
proto.dataocean.ListLabelxTasksResp.prototype.getPager = function() {
  return /** @type{?proto.dataocean.AggregationPager} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.AggregationPager, 2));
};


/**
 * @param {?proto.dataocean.AggregationPager|undefined} value
 * @return {!proto.dataocean.ListLabelxTasksResp} returns this
*/
proto.dataocean.ListLabelxTasksResp.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListLabelxTasksResp} returns this
 */
proto.dataocean.ListLabelxTasksResp.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListLabelxTasksResp.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.LabelxTask.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.LabelxTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.LabelxTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.LabelxTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    creator: jspb.Message.getFieldWithDefault(msg, 3, ""),
    packageCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    packageArchivedCount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.LabelxTask}
 */
proto.dataocean.LabelxTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.LabelxTask;
  return proto.dataocean.LabelxTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.LabelxTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.LabelxTask}
 */
proto.dataocean.LabelxTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackageCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackageArchivedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.LabelxTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.LabelxTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.LabelxTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.LabelxTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPackageCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPackageArchivedCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.LabelxTask.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.LabelxTask} returns this
 */
proto.dataocean.LabelxTask.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dataocean.LabelxTask.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.LabelxTask} returns this
 */
proto.dataocean.LabelxTask.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string creator = 3;
 * @return {string}
 */
proto.dataocean.LabelxTask.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.LabelxTask} returns this
 */
proto.dataocean.LabelxTask.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 package_count = 4;
 * @return {number}
 */
proto.dataocean.LabelxTask.prototype.getPackageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.LabelxTask} returns this
 */
proto.dataocean.LabelxTask.prototype.setPackageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 package_archived_count = 5;
 * @return {number}
 */
proto.dataocean.LabelxTask.prototype.getPackageArchivedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.LabelxTask} returns this
 */
proto.dataocean.LabelxTask.prototype.setPackageArchivedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.AggregationPager.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.AggregationPager.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.AggregationPager.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.AggregationPager} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AggregationPager.toObject = function(includeInstance, msg) {
  var f, obj = {
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    afterList: jspb.Message.toObjectList(msg.getAfterList(),
    proto.dataocean.After.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.AggregationPager}
 */
proto.dataocean.AggregationPager.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.AggregationPager;
  return proto.dataocean.AggregationPager.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.AggregationPager} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.AggregationPager}
 */
proto.dataocean.AggregationPager.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 3:
      var value = new proto.dataocean.After;
      reader.readMessage(value,proto.dataocean.After.deserializeBinaryFromReader);
      msg.addAfter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.AggregationPager.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.AggregationPager.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.AggregationPager} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AggregationPager.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAfterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dataocean.After.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 size = 2;
 * @return {number}
 */
proto.dataocean.AggregationPager.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.AggregationPager} returns this
 */
proto.dataocean.AggregationPager.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated After after = 3;
 * @return {!Array<!proto.dataocean.After>}
 */
proto.dataocean.AggregationPager.prototype.getAfterList = function() {
  return /** @type{!Array<!proto.dataocean.After>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.After, 3));
};


/**
 * @param {!Array<!proto.dataocean.After>} value
 * @return {!proto.dataocean.AggregationPager} returns this
*/
proto.dataocean.AggregationPager.prototype.setAfterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataocean.After=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.After}
 */
proto.dataocean.AggregationPager.prototype.addAfter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataocean.After, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AggregationPager} returns this
 */
proto.dataocean.AggregationPager.prototype.clearAfterList = function() {
  return this.setAfterList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.After.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.After.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.After} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.After.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.After}
 */
proto.dataocean.After.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.After;
  return proto.dataocean.After.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.After} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.After}
 */
proto.dataocean.After.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.After.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.After.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.After} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.After.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.dataocean.After.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.After} returns this
 */
proto.dataocean.After.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.dataocean.After.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.After} returns this
 */
proto.dataocean.After.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.CheckBlobExistReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.CheckBlobExistReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.CheckBlobExistReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CheckBlobExistReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.CheckBlobExistReq}
 */
proto.dataocean.CheckBlobExistReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.CheckBlobExistReq;
  return proto.dataocean.CheckBlobExistReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.CheckBlobExistReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.CheckBlobExistReq}
 */
proto.dataocean.CheckBlobExistReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.CheckBlobExistReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.CheckBlobExistReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.CheckBlobExistReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CheckBlobExistReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dataocean.CheckBlobExistReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.CheckBlobExistReq} returns this
 */
proto.dataocean.CheckBlobExistReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.CheckBlobExistResp.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.CheckBlobExistResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.CheckBlobExistResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CheckBlobExistResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    exist: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.CheckBlobExistResp}
 */
proto.dataocean.CheckBlobExistResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.CheckBlobExistResp;
  return proto.dataocean.CheckBlobExistResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.CheckBlobExistResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.CheckBlobExistResp}
 */
proto.dataocean.CheckBlobExistResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.CheckBlobExistResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.CheckBlobExistResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.CheckBlobExistResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CheckBlobExistResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExist();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool exist = 1;
 * @return {boolean}
 */
proto.dataocean.CheckBlobExistResp.prototype.getExist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.CheckBlobExistResp} returns this
 */
proto.dataocean.CheckBlobExistResp.prototype.setExist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.TransformBlobReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.TransformBlobReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.TransformBlobReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TransformBlobReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    target: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.TransformBlobReq}
 */
proto.dataocean.TransformBlobReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.TransformBlobReq;
  return proto.dataocean.TransformBlobReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.TransformBlobReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.TransformBlobReq}
 */
proto.dataocean.TransformBlobReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.dataocean.Pool} */ (reader.readEnum());
      msg.setTarget(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.TransformBlobReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.TransformBlobReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.TransformBlobReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TransformBlobReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTarget();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.TransformBlobReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.TransformBlobReq} returns this
 */
proto.dataocean.TransformBlobReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Pool target = 2;
 * @return {!proto.dataocean.Pool}
 */
proto.dataocean.TransformBlobReq.prototype.getTarget = function() {
  return /** @type {!proto.dataocean.Pool} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.dataocean.Pool} value
 * @return {!proto.dataocean.TransformBlobReq} returns this
 */
proto.dataocean.TransformBlobReq.prototype.setTarget = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GetBlobEnsuredReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GetBlobEnsuredReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GetBlobEnsuredReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBlobEnsuredReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    blobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dropletId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GetBlobEnsuredReq}
 */
proto.dataocean.GetBlobEnsuredReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GetBlobEnsuredReq;
  return proto.dataocean.GetBlobEnsuredReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GetBlobEnsuredReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GetBlobEnsuredReq}
 */
proto.dataocean.GetBlobEnsuredReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlobId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDropletId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GetBlobEnsuredReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GetBlobEnsuredReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GetBlobEnsuredReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBlobEnsuredReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDropletId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string blob_id = 1;
 * @return {string}
 */
proto.dataocean.GetBlobEnsuredReq.prototype.getBlobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GetBlobEnsuredReq} returns this
 */
proto.dataocean.GetBlobEnsuredReq.prototype.setBlobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string droplet_id = 2;
 * @return {string}
 */
proto.dataocean.GetBlobEnsuredReq.prototype.getDropletId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GetBlobEnsuredReq} returns this
 */
proto.dataocean.GetBlobEnsuredReq.prototype.setDropletId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.StatisticizeDropletsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.StatisticizeDropletsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.StatisticizeDropletsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.StatisticizeDropletsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    blobId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.StatisticizeDropletsReq}
 */
proto.dataocean.StatisticizeDropletsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.StatisticizeDropletsReq;
  return proto.dataocean.StatisticizeDropletsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.StatisticizeDropletsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.StatisticizeDropletsReq}
 */
proto.dataocean.StatisticizeDropletsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.StatisticizeDropletsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.StatisticizeDropletsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.StatisticizeDropletsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.StatisticizeDropletsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string blob_id = 1;
 * @return {string}
 */
proto.dataocean.StatisticizeDropletsReq.prototype.getBlobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.StatisticizeDropletsReq} returns this
 */
proto.dataocean.StatisticizeDropletsReq.prototype.setBlobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.StatisticizeDropletsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.StatisticizeDropletsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.StatisticizeDropletsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.StatisticizeDropletsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.StatisticizeDropletsRes}
 */
proto.dataocean.StatisticizeDropletsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.StatisticizeDropletsRes;
  return proto.dataocean.StatisticizeDropletsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.StatisticizeDropletsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.StatisticizeDropletsRes}
 */
proto.dataocean.StatisticizeDropletsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.StatisticizeDropletsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.StatisticizeDropletsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.StatisticizeDropletsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.StatisticizeDropletsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 count = 1;
 * @return {number}
 */
proto.dataocean.StatisticizeDropletsRes.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.StatisticizeDropletsRes} returns this
 */
proto.dataocean.StatisticizeDropletsRes.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 size = 2;
 * @return {number}
 */
proto.dataocean.StatisticizeDropletsRes.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.StatisticizeDropletsRes} returns this
 */
proto.dataocean.StatisticizeDropletsRes.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListDropletIDsReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDropletIDsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDropletIDsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDropletIDsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletIDsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    blobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerReq.toObject(includeInstance, f),
    sortByList: jspb.Message.toObjectList(msg.getSortByList(),
    dataocean_global_pb.Sorter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDropletIDsReq}
 */
proto.dataocean.ListDropletIDsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDropletIDsReq;
  return proto.dataocean.ListDropletIDsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDropletIDsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDropletIDsReq}
 */
proto.dataocean.ListDropletIDsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlobId(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    case 3:
      var value = new dataocean_global_pb.Sorter;
      reader.readMessage(value,dataocean_global_pb.Sorter.deserializeBinaryFromReader);
      msg.addSortBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDropletIDsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDropletIDsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDropletIDsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletIDsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
  f = message.getSortByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      dataocean_global_pb.Sorter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string blob_id = 1;
 * @return {string}
 */
proto.dataocean.ListDropletIDsReq.prototype.getBlobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListDropletIDsReq} returns this
 */
proto.dataocean.ListDropletIDsReq.prototype.setBlobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional meta.PagerReq pager = 2;
 * @return {?proto.meta.PagerReq}
 */
proto.dataocean.ListDropletIDsReq.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 2));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.dataocean.ListDropletIDsReq} returns this
*/
proto.dataocean.ListDropletIDsReq.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletIDsReq} returns this
 */
proto.dataocean.ListDropletIDsReq.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletIDsReq.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Sorter sort_by = 3;
 * @return {!Array<!proto.dataocean.Sorter>}
 */
proto.dataocean.ListDropletIDsReq.prototype.getSortByList = function() {
  return /** @type{!Array<!proto.dataocean.Sorter>} */ (
    jspb.Message.getRepeatedWrapperField(this, dataocean_global_pb.Sorter, 3));
};


/**
 * @param {!Array<!proto.dataocean.Sorter>} value
 * @return {!proto.dataocean.ListDropletIDsReq} returns this
*/
proto.dataocean.ListDropletIDsReq.prototype.setSortByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataocean.Sorter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Sorter}
 */
proto.dataocean.ListDropletIDsReq.prototype.addSortBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataocean.Sorter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListDropletIDsReq} returns this
 */
proto.dataocean.ListDropletIDsReq.prototype.clearSortByList = function() {
  return this.setSortByList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListDropletIDsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDropletIDsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDropletIDsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDropletIDsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletIDsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    pager: (f = msg.getPager()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDropletIDsRes}
 */
proto.dataocean.ListDropletIDsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDropletIDsRes;
  return proto.dataocean.ListDropletIDsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDropletIDsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDropletIDsRes}
 */
proto.dataocean.ListDropletIDsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDropletIDsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDropletIDsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDropletIDsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletIDsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.dataocean.ListDropletIDsRes.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.ListDropletIDsRes} returns this
 */
proto.dataocean.ListDropletIDsRes.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.ListDropletIDsRes} returns this
 */
proto.dataocean.ListDropletIDsRes.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListDropletIDsRes} returns this
 */
proto.dataocean.ListDropletIDsRes.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional meta.PagerRes pager = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.dataocean.ListDropletIDsRes.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.dataocean.ListDropletIDsRes} returns this
*/
proto.dataocean.ListDropletIDsRes.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletIDsRes} returns this
 */
proto.dataocean.ListDropletIDsRes.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletIDsRes.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GetBlobContentReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GetBlobContentReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GetBlobContentReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBlobContentReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    blob: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GetBlobContentReq}
 */
proto.dataocean.GetBlobContentReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GetBlobContentReq;
  return proto.dataocean.GetBlobContentReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GetBlobContentReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GetBlobContentReq}
 */
proto.dataocean.GetBlobContentReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GetBlobContentReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GetBlobContentReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GetBlobContentReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBlobContentReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlob();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string blob = 1;
 * @return {string}
 */
proto.dataocean.GetBlobContentReq.prototype.getBlob = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GetBlobContentReq} returns this
 */
proto.dataocean.GetBlobContentReq.prototype.setBlob = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GetBlobContentRes.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GetBlobContentRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GetBlobContentRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBlobContentRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    blob: (f = msg.getBlob()) && proto.dataocean.Blob.toObject(includeInstance, f),
    content: msg.getContent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GetBlobContentRes}
 */
proto.dataocean.GetBlobContentRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GetBlobContentRes;
  return proto.dataocean.GetBlobContentRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GetBlobContentRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GetBlobContentRes}
 */
proto.dataocean.GetBlobContentRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Blob;
      reader.readMessage(value,proto.dataocean.Blob.deserializeBinaryFromReader);
      msg.setBlob(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GetBlobContentRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GetBlobContentRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GetBlobContentRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBlobContentRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Blob.serializeBinaryToWriter
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional Blob blob = 1;
 * @return {?proto.dataocean.Blob}
 */
proto.dataocean.GetBlobContentRes.prototype.getBlob = function() {
  return /** @type{?proto.dataocean.Blob} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Blob, 1));
};


/**
 * @param {?proto.dataocean.Blob|undefined} value
 * @return {!proto.dataocean.GetBlobContentRes} returns this
*/
proto.dataocean.GetBlobContentRes.prototype.setBlob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.GetBlobContentRes} returns this
 */
proto.dataocean.GetBlobContentRes.prototype.clearBlob = function() {
  return this.setBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.GetBlobContentRes.prototype.hasBlob = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes content = 2;
 * @return {string}
 */
proto.dataocean.GetBlobContentRes.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.dataocean.GetBlobContentRes.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.dataocean.GetBlobContentRes.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dataocean.GetBlobContentRes} returns this
 */
proto.dataocean.GetBlobContentRes.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ReplicateBlobReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ReplicateBlobReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ReplicateBlobReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ReplicateBlobReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destination: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ReplicateBlobReq}
 */
proto.dataocean.ReplicateBlobReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ReplicateBlobReq;
  return proto.dataocean.ReplicateBlobReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ReplicateBlobReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ReplicateBlobReq}
 */
proto.dataocean.ReplicateBlobReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ReplicateBlobReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ReplicateBlobReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ReplicateBlobReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ReplicateBlobReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestination();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.ReplicateBlobReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ReplicateBlobReq} returns this
 */
proto.dataocean.ReplicateBlobReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string destination = 2;
 * @return {string}
 */
proto.dataocean.ReplicateBlobReq.prototype.getDestination = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ReplicateBlobReq} returns this
 */
proto.dataocean.ReplicateBlobReq.prototype.setDestination = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ReplicateBlobRes.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ReplicateBlobRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ReplicateBlobRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ReplicateBlobRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    blob: (f = msg.getBlob()) && proto.dataocean.Blob.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ReplicateBlobRes}
 */
proto.dataocean.ReplicateBlobRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ReplicateBlobRes;
  return proto.dataocean.ReplicateBlobRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ReplicateBlobRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ReplicateBlobRes}
 */
proto.dataocean.ReplicateBlobRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Blob;
      reader.readMessage(value,proto.dataocean.Blob.deserializeBinaryFromReader);
      msg.setBlob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ReplicateBlobRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ReplicateBlobRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ReplicateBlobRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ReplicateBlobRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlob();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Blob.serializeBinaryToWriter
    );
  }
};


/**
 * optional Blob blob = 1;
 * @return {?proto.dataocean.Blob}
 */
proto.dataocean.ReplicateBlobRes.prototype.getBlob = function() {
  return /** @type{?proto.dataocean.Blob} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Blob, 1));
};


/**
 * @param {?proto.dataocean.Blob|undefined} value
 * @return {!proto.dataocean.ReplicateBlobRes} returns this
*/
proto.dataocean.ReplicateBlobRes.prototype.setBlob = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ReplicateBlobRes} returns this
 */
proto.dataocean.ReplicateBlobRes.prototype.clearBlob = function() {
  return this.setBlob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ReplicateBlobRes.prototype.hasBlob = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.dataocean.BlobState = {
  BLOB_CREATED: 0,
  BLOB_INCOMPLETE: 1,
  BLOB_UNREADY: 2,
  BLOB_READY: 3,
  BLOB_FAILED: 4
};

/**
 * @enum {number}
 */
proto.dataocean.BlobSpecReleaseName = {
  UNKNOWN: 0,
  MODELSHOP: 1,
  TRAINSPOT: 2
};

/**
 * @enum {number}
 */
proto.dataocean.BlobSpecKind = {
  UNKNOWN_KIND: 0,
  RAW_DATA: 1,
  WORKBENCH: 2,
  MODELSHOP: 3,
  OTHER: 4,
  TRAINSPOT: 5
};

goog.object.extend(exports, proto.dataocean);
