// source: atom/v2/device.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js');
goog.object.extend(proto, github_com_envoyproxy_protoc$gen$validate_validate_validate_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var meta_meta_pb = require('../../meta/meta_pb.js');
goog.object.extend(proto, meta_meta_pb);
var atom_v2_global_pb = require('../../atom/v2/global_pb.js');
goog.object.extend(proto, atom_v2_global_pb);
goog.exportSymbol('proto.atom.v2.CreateDeviceCategoryReq', null, global);
goog.exportSymbol('proto.atom.v2.DeviceCategory', null, global);
goog.exportSymbol('proto.atom.v2.DeviceCategorySpec', null, global);
goog.exportSymbol('proto.atom.v2.DeviceCategoryStatus', null, global);
goog.exportSymbol('proto.atom.v2.GetDeviceCategoryReq', null, global);
goog.exportSymbol('proto.atom.v2.ListDeviceCategoriesReq', null, global);
goog.exportSymbol('proto.atom.v2.ListDeviceCategoriesRes', null, global);
goog.exportSymbol('proto.atom.v2.ListDeviceCategoryKindsReq', null, global);
goog.exportSymbol('proto.atom.v2.ListDeviceCategoryKindsRes', null, global);
goog.exportSymbol('proto.atom.v2.RemoveDeviceCategoryReq', null, global);
goog.exportSymbol('proto.atom.v2.UpdateDeviceCategoryReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.DeviceCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.DeviceCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.DeviceCategory.displayName = 'proto.atom.v2.DeviceCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.DeviceCategorySpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.DeviceCategorySpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.DeviceCategorySpec.displayName = 'proto.atom.v2.DeviceCategorySpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.DeviceCategoryStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.DeviceCategoryStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.DeviceCategoryStatus.displayName = 'proto.atom.v2.DeviceCategoryStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListDeviceCategoryKindsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.ListDeviceCategoryKindsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListDeviceCategoryKindsReq.displayName = 'proto.atom.v2.ListDeviceCategoryKindsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListDeviceCategoryKindsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.ListDeviceCategoryKindsRes.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.ListDeviceCategoryKindsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListDeviceCategoryKindsRes.displayName = 'proto.atom.v2.ListDeviceCategoryKindsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListDeviceCategoriesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.ListDeviceCategoriesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListDeviceCategoriesReq.displayName = 'proto.atom.v2.ListDeviceCategoriesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListDeviceCategoriesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.ListDeviceCategoriesRes.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.ListDeviceCategoriesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListDeviceCategoriesRes.displayName = 'proto.atom.v2.ListDeviceCategoriesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.GetDeviceCategoryReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.GetDeviceCategoryReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.GetDeviceCategoryReq.displayName = 'proto.atom.v2.GetDeviceCategoryReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.CreateDeviceCategoryReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.CreateDeviceCategoryReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.CreateDeviceCategoryReq.displayName = 'proto.atom.v2.CreateDeviceCategoryReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.UpdateDeviceCategoryReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.UpdateDeviceCategoryReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.UpdateDeviceCategoryReq.displayName = 'proto.atom.v2.UpdateDeviceCategoryReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.RemoveDeviceCategoryReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.RemoveDeviceCategoryReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.RemoveDeviceCategoryReq.displayName = 'proto.atom.v2.RemoveDeviceCategoryReq';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.DeviceCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.DeviceCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.DeviceCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.DeviceCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && meta_meta_pb.Metadata.toObject(includeInstance, f),
    spec: (f = msg.getSpec()) && proto.atom.v2.DeviceCategorySpec.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.atom.v2.DeviceCategoryStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.DeviceCategory}
 */
proto.atom.v2.DeviceCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.DeviceCategory;
  return proto.atom.v2.DeviceCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.DeviceCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.DeviceCategory}
 */
proto.atom.v2.DeviceCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.Metadata;
      reader.readMessage(value,meta_meta_pb.Metadata.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.atom.v2.DeviceCategorySpec;
      reader.readMessage(value,proto.atom.v2.DeviceCategorySpec.deserializeBinaryFromReader);
      msg.setSpec(value);
      break;
    case 3:
      var value = new proto.atom.v2.DeviceCategoryStatus;
      reader.readMessage(value,proto.atom.v2.DeviceCategoryStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.DeviceCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.DeviceCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.DeviceCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.DeviceCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getSpec();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.atom.v2.DeviceCategorySpec.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.atom.v2.DeviceCategoryStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.Metadata meta = 1;
 * @return {?proto.meta.Metadata}
 */
proto.atom.v2.DeviceCategory.prototype.getMeta = function() {
  return /** @type{?proto.meta.Metadata} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.Metadata, 1));
};


/**
 * @param {?proto.meta.Metadata|undefined} value
 * @return {!proto.atom.v2.DeviceCategory} returns this
*/
proto.atom.v2.DeviceCategory.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.DeviceCategory} returns this
 */
proto.atom.v2.DeviceCategory.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.DeviceCategory.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceCategorySpec spec = 2;
 * @return {?proto.atom.v2.DeviceCategorySpec}
 */
proto.atom.v2.DeviceCategory.prototype.getSpec = function() {
  return /** @type{?proto.atom.v2.DeviceCategorySpec} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.DeviceCategorySpec, 2));
};


/**
 * @param {?proto.atom.v2.DeviceCategorySpec|undefined} value
 * @return {!proto.atom.v2.DeviceCategory} returns this
*/
proto.atom.v2.DeviceCategory.prototype.setSpec = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.DeviceCategory} returns this
 */
proto.atom.v2.DeviceCategory.prototype.clearSpec = function() {
  return this.setSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.DeviceCategory.prototype.hasSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeviceCategoryStatus status = 3;
 * @return {?proto.atom.v2.DeviceCategoryStatus}
 */
proto.atom.v2.DeviceCategory.prototype.getStatus = function() {
  return /** @type{?proto.atom.v2.DeviceCategoryStatus} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.DeviceCategoryStatus, 3));
};


/**
 * @param {?proto.atom.v2.DeviceCategoryStatus|undefined} value
 * @return {!proto.atom.v2.DeviceCategory} returns this
*/
proto.atom.v2.DeviceCategory.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.DeviceCategory} returns this
 */
proto.atom.v2.DeviceCategory.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.DeviceCategory.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.DeviceCategorySpec.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.DeviceCategorySpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.DeviceCategorySpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.DeviceCategorySpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.DeviceCategorySpec}
 */
proto.atom.v2.DeviceCategorySpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.DeviceCategorySpec;
  return proto.atom.v2.DeviceCategorySpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.DeviceCategorySpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.DeviceCategorySpec}
 */
proto.atom.v2.DeviceCategorySpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.atom.v2.DeviceKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.DeviceCategorySpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.DeviceCategorySpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.DeviceCategorySpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.DeviceCategorySpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional DeviceKind kind = 1;
 * @return {!proto.atom.v2.DeviceKind}
 */
proto.atom.v2.DeviceCategorySpec.prototype.getKind = function() {
  return /** @type {!proto.atom.v2.DeviceKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.atom.v2.DeviceKind} value
 * @return {!proto.atom.v2.DeviceCategorySpec} returns this
 */
proto.atom.v2.DeviceCategorySpec.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.DeviceCategoryStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.DeviceCategoryStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.DeviceCategoryStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.DeviceCategoryStatus.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.DeviceCategoryStatus}
 */
proto.atom.v2.DeviceCategoryStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.DeviceCategoryStatus;
  return proto.atom.v2.DeviceCategoryStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.DeviceCategoryStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.DeviceCategoryStatus}
 */
proto.atom.v2.DeviceCategoryStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.DeviceCategoryStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.DeviceCategoryStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.DeviceCategoryStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.DeviceCategoryStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListDeviceCategoryKindsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListDeviceCategoryKindsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListDeviceCategoryKindsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListDeviceCategoryKindsReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListDeviceCategoryKindsReq}
 */
proto.atom.v2.ListDeviceCategoryKindsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListDeviceCategoryKindsReq;
  return proto.atom.v2.ListDeviceCategoryKindsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListDeviceCategoryKindsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListDeviceCategoryKindsReq}
 */
proto.atom.v2.ListDeviceCategoryKindsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListDeviceCategoryKindsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListDeviceCategoryKindsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListDeviceCategoryKindsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListDeviceCategoryKindsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.ListDeviceCategoryKindsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListDeviceCategoryKindsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListDeviceCategoryKindsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListDeviceCategoryKindsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListDeviceCategoryKindsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    kindsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListDeviceCategoryKindsRes}
 */
proto.atom.v2.ListDeviceCategoryKindsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListDeviceCategoryKindsRes;
  return proto.atom.v2.ListDeviceCategoryKindsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListDeviceCategoryKindsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListDeviceCategoryKindsRes}
 */
proto.atom.v2.ListDeviceCategoryKindsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.atom.v2.DeviceKind>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addKinds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListDeviceCategoryKindsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListDeviceCategoryKindsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListDeviceCategoryKindsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListDeviceCategoryKindsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKindsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated DeviceKind kinds = 1;
 * @return {!Array<!proto.atom.v2.DeviceKind>}
 */
proto.atom.v2.ListDeviceCategoryKindsRes.prototype.getKindsList = function() {
  return /** @type {!Array<!proto.atom.v2.DeviceKind>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.atom.v2.DeviceKind>} value
 * @return {!proto.atom.v2.ListDeviceCategoryKindsRes} returns this
 */
proto.atom.v2.ListDeviceCategoryKindsRes.prototype.setKindsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.atom.v2.DeviceKind} value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.ListDeviceCategoryKindsRes} returns this
 */
proto.atom.v2.ListDeviceCategoryKindsRes.prototype.addKinds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListDeviceCategoryKindsRes} returns this
 */
proto.atom.v2.ListDeviceCategoryKindsRes.prototype.clearKindsList = function() {
  return this.setKindsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListDeviceCategoriesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListDeviceCategoriesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListDeviceCategoriesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListDeviceCategoriesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListDeviceCategoriesReq}
 */
proto.atom.v2.ListDeviceCategoriesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListDeviceCategoriesReq;
  return proto.atom.v2.ListDeviceCategoriesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListDeviceCategoriesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListDeviceCategoriesReq}
 */
proto.atom.v2.ListDeviceCategoriesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.atom.v2.DeviceKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListDeviceCategoriesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListDeviceCategoriesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListDeviceCategoriesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListDeviceCategoriesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceKind kind = 1;
 * @return {!proto.atom.v2.DeviceKind}
 */
proto.atom.v2.ListDeviceCategoriesReq.prototype.getKind = function() {
  return /** @type {!proto.atom.v2.DeviceKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.atom.v2.DeviceKind} value
 * @return {!proto.atom.v2.ListDeviceCategoriesReq} returns this
 */
proto.atom.v2.ListDeviceCategoriesReq.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional meta.PagerReq pager = 3;
 * @return {?proto.meta.PagerReq}
 */
proto.atom.v2.ListDeviceCategoriesReq.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 3));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.atom.v2.ListDeviceCategoriesReq} returns this
*/
proto.atom.v2.ListDeviceCategoriesReq.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListDeviceCategoriesReq} returns this
 */
proto.atom.v2.ListDeviceCategoriesReq.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListDeviceCategoriesReq.prototype.hasPager = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.ListDeviceCategoriesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListDeviceCategoriesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListDeviceCategoriesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListDeviceCategoriesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListDeviceCategoriesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicecategoriesList: jspb.Message.toObjectList(msg.getDevicecategoriesList(),
    proto.atom.v2.DeviceCategory.toObject, includeInstance),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListDeviceCategoriesRes}
 */
proto.atom.v2.ListDeviceCategoriesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListDeviceCategoriesRes;
  return proto.atom.v2.ListDeviceCategoriesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListDeviceCategoriesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListDeviceCategoriesRes}
 */
proto.atom.v2.ListDeviceCategoriesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.DeviceCategory;
      reader.readMessage(value,proto.atom.v2.DeviceCategory.deserializeBinaryFromReader);
      msg.addDevicecategories(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListDeviceCategoriesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListDeviceCategoriesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListDeviceCategoriesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListDeviceCategoriesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicecategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.atom.v2.DeviceCategory.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeviceCategory deviceCategories = 1;
 * @return {!Array<!proto.atom.v2.DeviceCategory>}
 */
proto.atom.v2.ListDeviceCategoriesRes.prototype.getDevicecategoriesList = function() {
  return /** @type{!Array<!proto.atom.v2.DeviceCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.atom.v2.DeviceCategory, 1));
};


/**
 * @param {!Array<!proto.atom.v2.DeviceCategory>} value
 * @return {!proto.atom.v2.ListDeviceCategoriesRes} returns this
*/
proto.atom.v2.ListDeviceCategoriesRes.prototype.setDevicecategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.atom.v2.DeviceCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.DeviceCategory}
 */
proto.atom.v2.ListDeviceCategoriesRes.prototype.addDevicecategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.atom.v2.DeviceCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListDeviceCategoriesRes} returns this
 */
proto.atom.v2.ListDeviceCategoriesRes.prototype.clearDevicecategoriesList = function() {
  return this.setDevicecategoriesList([]);
};


/**
 * optional meta.PagerRes pager = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.atom.v2.ListDeviceCategoriesRes.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.atom.v2.ListDeviceCategoriesRes} returns this
*/
proto.atom.v2.ListDeviceCategoriesRes.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ListDeviceCategoriesRes} returns this
 */
proto.atom.v2.ListDeviceCategoriesRes.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ListDeviceCategoriesRes.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.GetDeviceCategoryReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.GetDeviceCategoryReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.GetDeviceCategoryReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetDeviceCategoryReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.GetDeviceCategoryReq}
 */
proto.atom.v2.GetDeviceCategoryReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.GetDeviceCategoryReq;
  return proto.atom.v2.GetDeviceCategoryReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.GetDeviceCategoryReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.GetDeviceCategoryReq}
 */
proto.atom.v2.GetDeviceCategoryReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.atom.v2.DeviceKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.GetDeviceCategoryReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.GetDeviceCategoryReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.GetDeviceCategoryReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetDeviceCategoryReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DeviceKind kind = 1;
 * @return {!proto.atom.v2.DeviceKind}
 */
proto.atom.v2.GetDeviceCategoryReq.prototype.getKind = function() {
  return /** @type {!proto.atom.v2.DeviceKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.atom.v2.DeviceKind} value
 * @return {!proto.atom.v2.GetDeviceCategoryReq} returns this
 */
proto.atom.v2.GetDeviceCategoryReq.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.atom.v2.GetDeviceCategoryReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.GetDeviceCategoryReq} returns this
 */
proto.atom.v2.GetDeviceCategoryReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.CreateDeviceCategoryReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.CreateDeviceCategoryReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.CreateDeviceCategoryReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.CreateDeviceCategoryReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicecategory: (f = msg.getDevicecategory()) && proto.atom.v2.DeviceCategory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.CreateDeviceCategoryReq}
 */
proto.atom.v2.CreateDeviceCategoryReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.CreateDeviceCategoryReq;
  return proto.atom.v2.CreateDeviceCategoryReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.CreateDeviceCategoryReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.CreateDeviceCategoryReq}
 */
proto.atom.v2.CreateDeviceCategoryReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.DeviceCategory;
      reader.readMessage(value,proto.atom.v2.DeviceCategory.deserializeBinaryFromReader);
      msg.setDevicecategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.CreateDeviceCategoryReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.CreateDeviceCategoryReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.CreateDeviceCategoryReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.CreateDeviceCategoryReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicecategory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.atom.v2.DeviceCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceCategory deviceCategory = 1;
 * @return {?proto.atom.v2.DeviceCategory}
 */
proto.atom.v2.CreateDeviceCategoryReq.prototype.getDevicecategory = function() {
  return /** @type{?proto.atom.v2.DeviceCategory} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.DeviceCategory, 1));
};


/**
 * @param {?proto.atom.v2.DeviceCategory|undefined} value
 * @return {!proto.atom.v2.CreateDeviceCategoryReq} returns this
*/
proto.atom.v2.CreateDeviceCategoryReq.prototype.setDevicecategory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.CreateDeviceCategoryReq} returns this
 */
proto.atom.v2.CreateDeviceCategoryReq.prototype.clearDevicecategory = function() {
  return this.setDevicecategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.CreateDeviceCategoryReq.prototype.hasDevicecategory = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.UpdateDeviceCategoryReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.UpdateDeviceCategoryReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.UpdateDeviceCategoryReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateDeviceCategoryReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicecategory: (f = msg.getDevicecategory()) && proto.atom.v2.DeviceCategory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.UpdateDeviceCategoryReq}
 */
proto.atom.v2.UpdateDeviceCategoryReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.UpdateDeviceCategoryReq;
  return proto.atom.v2.UpdateDeviceCategoryReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.UpdateDeviceCategoryReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.UpdateDeviceCategoryReq}
 */
proto.atom.v2.UpdateDeviceCategoryReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.DeviceCategory;
      reader.readMessage(value,proto.atom.v2.DeviceCategory.deserializeBinaryFromReader);
      msg.setDevicecategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.UpdateDeviceCategoryReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.UpdateDeviceCategoryReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.UpdateDeviceCategoryReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateDeviceCategoryReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicecategory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.atom.v2.DeviceCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceCategory deviceCategory = 1;
 * @return {?proto.atom.v2.DeviceCategory}
 */
proto.atom.v2.UpdateDeviceCategoryReq.prototype.getDevicecategory = function() {
  return /** @type{?proto.atom.v2.DeviceCategory} */ (
    jspb.Message.getWrapperField(this, proto.atom.v2.DeviceCategory, 1));
};


/**
 * @param {?proto.atom.v2.DeviceCategory|undefined} value
 * @return {!proto.atom.v2.UpdateDeviceCategoryReq} returns this
*/
proto.atom.v2.UpdateDeviceCategoryReq.prototype.setDevicecategory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.UpdateDeviceCategoryReq} returns this
 */
proto.atom.v2.UpdateDeviceCategoryReq.prototype.clearDevicecategory = function() {
  return this.setDevicecategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.UpdateDeviceCategoryReq.prototype.hasDevicecategory = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.RemoveDeviceCategoryReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.RemoveDeviceCategoryReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.RemoveDeviceCategoryReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.RemoveDeviceCategoryReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.RemoveDeviceCategoryReq}
 */
proto.atom.v2.RemoveDeviceCategoryReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.RemoveDeviceCategoryReq;
  return proto.atom.v2.RemoveDeviceCategoryReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.RemoveDeviceCategoryReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.RemoveDeviceCategoryReq}
 */
proto.atom.v2.RemoveDeviceCategoryReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.atom.v2.DeviceKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.RemoveDeviceCategoryReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.RemoveDeviceCategoryReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.RemoveDeviceCategoryReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.RemoveDeviceCategoryReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional DeviceKind kind = 1;
 * @return {!proto.atom.v2.DeviceKind}
 */
proto.atom.v2.RemoveDeviceCategoryReq.prototype.getKind = function() {
  return /** @type {!proto.atom.v2.DeviceKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.atom.v2.DeviceKind} value
 * @return {!proto.atom.v2.RemoveDeviceCategoryReq} returns this
 */
proto.atom.v2.RemoveDeviceCategoryReq.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.atom.v2.RemoveDeviceCategoryReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.RemoveDeviceCategoryReq} returns this
 */
proto.atom.v2.RemoveDeviceCategoryReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.atom.v2);
