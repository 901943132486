import grpc from 'api/grpc';
import { ActivatedStatus, ListProductsActivationReq } from 'atom-proto/web/atom/v2/product_activation_pb';
import { buildObject } from 'utils/grpcUtil';
import { ListProductsReq } from 'atom-proto/web/atom/v2/product_pb';
import globalStore from 'src/stores/globalStore';
import { modelServiceType } from 'utils/resourceUtil';
import { toJS } from 'mobx';

export const getServices = async (showActivated: boolean = true) => {
  const productActivationRes = await grpc.productActivation.listProductsActivation(
    buildObject(new ListProductsActivationReq(), (req) => {
      if (showActivated) {
        req.setStatus(ActivatedStatus.ACTIVATEDSTATUSON);
      }
    })
  );
  const productActivationList = productActivationRes.toObject().productsactivationList;
  const productListReq = productActivationList.map((instance) => instance.productid);

  const listProductsRes = await grpc.product.listProducts(
    buildObject(new ListProductsReq(), (req) => {
      req.setProductidList(productListReq);
    })
  );
  const prodcutList = listProductsRes.getProductsList().map((product) => {
    return {
      product,
      price: toJS(globalStore.pricingList).filter((price) => {
        return price.productid === product.getProductid();
      })
    };
  });
  const data = productActivationList.map((instance) => {
    return {
      ...instance,
      product: prodcutList.find((product) => product.product.getProductid() === instance.productid)
    };
  });
  return data;
};
