// source: dataocean/trainspot.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var dataocean_global_pb = require('../dataocean/global_pb.js');
goog.object.extend(proto, dataocean_global_pb);
goog.exportSymbol('proto.dataocean.Data', null, global);
goog.exportSymbol('proto.dataocean.Data.Annotation', null, global);
goog.exportSymbol('proto.dataocean.Data.BlobSource', null, global);
goog.exportSymbol('proto.dataocean.Data.Classification', null, global);
goog.exportSymbol('proto.dataocean.Data.ClassificationTool', null, global);
goog.exportSymbol('proto.dataocean.Data.Detection', null, global);
goog.exportSymbol('proto.dataocean.Data.DetectionTool', null, global);
goog.exportSymbol('proto.dataocean.Data.Filter', null, global);
goog.exportSymbol('proto.dataocean.Data.Source', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Data.displayName = 'proto.dataocean.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Data.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Data.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Data.Source.displayName = 'proto.dataocean.Data.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Data.Annotation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Data.Annotation.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Data.Annotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Data.Annotation.displayName = 'proto.dataocean.Data.Annotation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Data.Detection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Data.Detection.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Data.Detection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Data.Detection.displayName = 'proto.dataocean.Data.Detection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Data.Classification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Data.Classification.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Data.Classification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Data.Classification.displayName = 'proto.dataocean.Data.Classification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Data.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Data.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Data.Filter.displayName = 'proto.dataocean.Data.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Data.BlobSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Data.BlobSource.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Data.BlobSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Data.BlobSource.displayName = 'proto.dataocean.Data.BlobSource';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Data}
 */
proto.dataocean.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Data;
  return proto.dataocean.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Data}
 */
proto.dataocean.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.dataocean.Data.DetectionTool = {
  UNKNOWN_DETECTION_TOOL: 0,
  DETECTION_TOOL_RECTANGLE: 1,
  DETECTION_TOOL_DOT: 2,
  DETECTION_TOOL_PATH: 3
};

/**
 * @enum {number}
 */
proto.dataocean.Data.ClassificationTool = {
  UNKNOWN_CLASSIFICATION_TOOL: 0,
  CLASSIFICATION_TOOL_SWITCH: 1,
  CLASSIFICATION_TOOL_CHECKBOX: 2,
  CLASSIFICATION_TOOL_RADIO: 3,
  CLASSIFICATION_TOOL_TEXT: 4
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Data.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Data.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Data.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotation: (f = msg.getAnnotation()) && proto.dataocean.Data.Annotation.toObject(includeInstance, f),
    meta: (f = msg.getMeta()) && dataocean_global_pb.MediaMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Data.Source}
 */
proto.dataocean.Data.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Data.Source;
  return proto.dataocean.Data.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Data.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Data.Source}
 */
proto.dataocean.Data.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Data.Annotation;
      reader.readMessage(value,proto.dataocean.Data.Annotation.deserializeBinaryFromReader);
      msg.setAnnotation(value);
      break;
    case 2:
      var value = new dataocean_global_pb.MediaMeta;
      reader.readMessage(value,dataocean_global_pb.MediaMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Data.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Data.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Data.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Data.Annotation.serializeBinaryToWriter
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dataocean_global_pb.MediaMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional Annotation annotation = 1;
 * @return {?proto.dataocean.Data.Annotation}
 */
proto.dataocean.Data.Source.prototype.getAnnotation = function() {
  return /** @type{?proto.dataocean.Data.Annotation} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Data.Annotation, 1));
};


/**
 * @param {?proto.dataocean.Data.Annotation|undefined} value
 * @return {!proto.dataocean.Data.Source} returns this
*/
proto.dataocean.Data.Source.prototype.setAnnotation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Data.Source} returns this
 */
proto.dataocean.Data.Source.prototype.clearAnnotation = function() {
  return this.setAnnotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Data.Source.prototype.hasAnnotation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MediaMeta meta = 2;
 * @return {?proto.dataocean.MediaMeta}
 */
proto.dataocean.Data.Source.prototype.getMeta = function() {
  return /** @type{?proto.dataocean.MediaMeta} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MediaMeta, 2));
};


/**
 * @param {?proto.dataocean.MediaMeta|undefined} value
 * @return {!proto.dataocean.Data.Source} returns this
*/
proto.dataocean.Data.Source.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Data.Source} returns this
 */
proto.dataocean.Data.Source.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Data.Source.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Data.Annotation.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Data.Annotation.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Data.Annotation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Data.Annotation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Annotation.toObject = function(includeInstance, msg) {
  var f, obj = {
    detectionsList: jspb.Message.toObjectList(msg.getDetectionsList(),
    proto.dataocean.Data.Detection.toObject, includeInstance),
    classificationsList: jspb.Message.toObjectList(msg.getClassificationsList(),
    proto.dataocean.Data.Classification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Data.Annotation}
 */
proto.dataocean.Data.Annotation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Data.Annotation;
  return proto.dataocean.Data.Annotation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Data.Annotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Data.Annotation}
 */
proto.dataocean.Data.Annotation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Data.Detection;
      reader.readMessage(value,proto.dataocean.Data.Detection.deserializeBinaryFromReader);
      msg.addDetections(value);
      break;
    case 2:
      var value = new proto.dataocean.Data.Classification;
      reader.readMessage(value,proto.dataocean.Data.Classification.deserializeBinaryFromReader);
      msg.addClassifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Data.Annotation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Data.Annotation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Data.Annotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Annotation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataocean.Data.Detection.serializeBinaryToWriter
    );
  }
  f = message.getClassificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dataocean.Data.Classification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Detection detections = 1;
 * @return {!Array<!proto.dataocean.Data.Detection>}
 */
proto.dataocean.Data.Annotation.prototype.getDetectionsList = function() {
  return /** @type{!Array<!proto.dataocean.Data.Detection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.Data.Detection, 1));
};


/**
 * @param {!Array<!proto.dataocean.Data.Detection>} value
 * @return {!proto.dataocean.Data.Annotation} returns this
*/
proto.dataocean.Data.Annotation.prototype.setDetectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.Data.Detection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Data.Detection}
 */
proto.dataocean.Data.Annotation.prototype.addDetections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.Data.Detection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Data.Annotation} returns this
 */
proto.dataocean.Data.Annotation.prototype.clearDetectionsList = function() {
  return this.setDetectionsList([]);
};


/**
 * repeated Classification classifications = 2;
 * @return {!Array<!proto.dataocean.Data.Classification>}
 */
proto.dataocean.Data.Annotation.prototype.getClassificationsList = function() {
  return /** @type{!Array<!proto.dataocean.Data.Classification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.Data.Classification, 2));
};


/**
 * @param {!Array<!proto.dataocean.Data.Classification>} value
 * @return {!proto.dataocean.Data.Annotation} returns this
*/
proto.dataocean.Data.Annotation.prototype.setClassificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dataocean.Data.Classification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Data.Classification}
 */
proto.dataocean.Data.Annotation.prototype.addClassifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dataocean.Data.Classification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Data.Annotation} returns this
 */
proto.dataocean.Data.Annotation.prototype.clearClassificationsList = function() {
  return this.setClassificationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Data.Detection.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Data.Detection.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Data.Detection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Data.Detection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Detection.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tool: jspb.Message.getFieldWithDefault(msg, 3, 0),
    rectangle: (f = msg.getRectangle()) && dataocean_global_pb.Rectangle.toObject(includeInstance, f),
    polygon: (f = msg.getPolygon()) && dataocean_global_pb.Polygon.toObject(includeInstance, f),
    point: (f = msg.getPoint()) && dataocean_global_pb.Point.toObject(includeInstance, f),
    classificationsList: jspb.Message.toObjectList(msg.getClassificationsList(),
    proto.dataocean.Data.Classification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Data.Detection}
 */
proto.dataocean.Data.Detection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Data.Detection;
  return proto.dataocean.Data.Detection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Data.Detection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Data.Detection}
 */
proto.dataocean.Data.Detection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {!proto.dataocean.Data.DetectionTool} */ (reader.readEnum());
      msg.setTool(value);
      break;
    case 4:
      var value = new dataocean_global_pb.Rectangle;
      reader.readMessage(value,dataocean_global_pb.Rectangle.deserializeBinaryFromReader);
      msg.setRectangle(value);
      break;
    case 5:
      var value = new dataocean_global_pb.Polygon;
      reader.readMessage(value,dataocean_global_pb.Polygon.deserializeBinaryFromReader);
      msg.setPolygon(value);
      break;
    case 6:
      var value = new dataocean_global_pb.Point;
      reader.readMessage(value,dataocean_global_pb.Point.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 7:
      var value = new proto.dataocean.Data.Classification;
      reader.readMessage(value,proto.dataocean.Data.Classification.deserializeBinaryFromReader);
      msg.addClassifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Data.Detection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Data.Detection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Data.Detection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Detection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTool();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRectangle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      dataocean_global_pb.Rectangle.serializeBinaryToWriter
    );
  }
  f = message.getPolygon();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dataocean_global_pb.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      dataocean_global_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getClassificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.dataocean.Data.Classification.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.Data.Detection.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Data.Detection} returns this
 */
proto.dataocean.Data.Detection.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.dataocean.Data.Detection.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Data.Detection} returns this
 */
proto.dataocean.Data.Detection.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DetectionTool tool = 3;
 * @return {!proto.dataocean.Data.DetectionTool}
 */
proto.dataocean.Data.Detection.prototype.getTool = function() {
  return /** @type {!proto.dataocean.Data.DetectionTool} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.dataocean.Data.DetectionTool} value
 * @return {!proto.dataocean.Data.Detection} returns this
 */
proto.dataocean.Data.Detection.prototype.setTool = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Rectangle rectangle = 4;
 * @return {?proto.dataocean.Rectangle}
 */
proto.dataocean.Data.Detection.prototype.getRectangle = function() {
  return /** @type{?proto.dataocean.Rectangle} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Rectangle, 4));
};


/**
 * @param {?proto.dataocean.Rectangle|undefined} value
 * @return {!proto.dataocean.Data.Detection} returns this
*/
proto.dataocean.Data.Detection.prototype.setRectangle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Data.Detection} returns this
 */
proto.dataocean.Data.Detection.prototype.clearRectangle = function() {
  return this.setRectangle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Data.Detection.prototype.hasRectangle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Polygon polygon = 5;
 * @return {?proto.dataocean.Polygon}
 */
proto.dataocean.Data.Detection.prototype.getPolygon = function() {
  return /** @type{?proto.dataocean.Polygon} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Polygon, 5));
};


/**
 * @param {?proto.dataocean.Polygon|undefined} value
 * @return {!proto.dataocean.Data.Detection} returns this
*/
proto.dataocean.Data.Detection.prototype.setPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Data.Detection} returns this
 */
proto.dataocean.Data.Detection.prototype.clearPolygon = function() {
  return this.setPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Data.Detection.prototype.hasPolygon = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Point point = 6;
 * @return {?proto.dataocean.Point}
 */
proto.dataocean.Data.Detection.prototype.getPoint = function() {
  return /** @type{?proto.dataocean.Point} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Point, 6));
};


/**
 * @param {?proto.dataocean.Point|undefined} value
 * @return {!proto.dataocean.Data.Detection} returns this
*/
proto.dataocean.Data.Detection.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Data.Detection} returns this
 */
proto.dataocean.Data.Detection.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Data.Detection.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated Classification classifications = 7;
 * @return {!Array<!proto.dataocean.Data.Classification>}
 */
proto.dataocean.Data.Detection.prototype.getClassificationsList = function() {
  return /** @type{!Array<!proto.dataocean.Data.Classification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.Data.Classification, 7));
};


/**
 * @param {!Array<!proto.dataocean.Data.Classification>} value
 * @return {!proto.dataocean.Data.Detection} returns this
*/
proto.dataocean.Data.Detection.prototype.setClassificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.dataocean.Data.Classification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Data.Classification}
 */
proto.dataocean.Data.Detection.prototype.addClassifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.dataocean.Data.Classification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Data.Detection} returns this
 */
proto.dataocean.Data.Detection.prototype.clearClassificationsList = function() {
  return this.setClassificationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Data.Classification.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Data.Classification.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Data.Classification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Data.Classification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Classification.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tool: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pb_switch: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    checkboxList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    radio: jspb.Message.getFieldWithDefault(msg, 5, ""),
    text: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Data.Classification}
 */
proto.dataocean.Data.Classification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Data.Classification;
  return proto.dataocean.Data.Classification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Data.Classification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Data.Classification}
 */
proto.dataocean.Data.Classification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {!proto.dataocean.Data.ClassificationTool} */ (reader.readEnum());
      msg.setTool(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwitch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCheckbox(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRadio(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Data.Classification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Data.Classification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Data.Classification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Classification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTool();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSwitch();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCheckboxList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getRadio();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.dataocean.Data.Classification.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Data.Classification} returns this
 */
proto.dataocean.Data.Classification.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ClassificationTool tool = 2;
 * @return {!proto.dataocean.Data.ClassificationTool}
 */
proto.dataocean.Data.Classification.prototype.getTool = function() {
  return /** @type {!proto.dataocean.Data.ClassificationTool} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.dataocean.Data.ClassificationTool} value
 * @return {!proto.dataocean.Data.Classification} returns this
 */
proto.dataocean.Data.Classification.prototype.setTool = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool switch = 3;
 * @return {boolean}
 */
proto.dataocean.Data.Classification.prototype.getSwitch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.Data.Classification} returns this
 */
proto.dataocean.Data.Classification.prototype.setSwitch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated string checkbox = 4;
 * @return {!Array<string>}
 */
proto.dataocean.Data.Classification.prototype.getCheckboxList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Data.Classification} returns this
 */
proto.dataocean.Data.Classification.prototype.setCheckboxList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Data.Classification} returns this
 */
proto.dataocean.Data.Classification.prototype.addCheckbox = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Data.Classification} returns this
 */
proto.dataocean.Data.Classification.prototype.clearCheckboxList = function() {
  return this.setCheckboxList([]);
};


/**
 * optional string radio = 5;
 * @return {string}
 */
proto.dataocean.Data.Classification.prototype.getRadio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Data.Classification} returns this
 */
proto.dataocean.Data.Classification.prototype.setRadio = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string text = 6;
 * @return {string}
 */
proto.dataocean.Data.Classification.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Data.Classification} returns this
 */
proto.dataocean.Data.Classification.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Data.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Data.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Data.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotation: (f = msg.getAnnotation()) && proto.dataocean.Data.Annotation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Data.Filter}
 */
proto.dataocean.Data.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Data.Filter;
  return proto.dataocean.Data.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Data.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Data.Filter}
 */
proto.dataocean.Data.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Data.Annotation;
      reader.readMessage(value,proto.dataocean.Data.Annotation.deserializeBinaryFromReader);
      msg.setAnnotation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Data.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Data.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Data.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Data.Annotation.serializeBinaryToWriter
    );
  }
};


/**
 * optional Annotation annotation = 1;
 * @return {?proto.dataocean.Data.Annotation}
 */
proto.dataocean.Data.Filter.prototype.getAnnotation = function() {
  return /** @type{?proto.dataocean.Data.Annotation} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Data.Annotation, 1));
};


/**
 * @param {?proto.dataocean.Data.Annotation|undefined} value
 * @return {!proto.dataocean.Data.Filter} returns this
*/
proto.dataocean.Data.Filter.prototype.setAnnotation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Data.Filter} returns this
 */
proto.dataocean.Data.Filter.prototype.clearAnnotation = function() {
  return this.setAnnotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Data.Filter.prototype.hasAnnotation = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Data.BlobSource.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Data.BlobSource.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Data.BlobSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Data.BlobSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.BlobSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    packageIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Data.BlobSource}
 */
proto.dataocean.Data.BlobSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Data.BlobSource;
  return proto.dataocean.Data.BlobSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Data.BlobSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Data.BlobSource}
 */
proto.dataocean.Data.BlobSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPackageIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Data.BlobSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Data.BlobSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Data.BlobSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Data.BlobSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPackageIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.dataocean.Data.BlobSource.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Data.BlobSource} returns this
 */
proto.dataocean.Data.BlobSource.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string package_ids = 2;
 * @return {!Array<string>}
 */
proto.dataocean.Data.BlobSource.prototype.getPackageIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Data.BlobSource} returns this
 */
proto.dataocean.Data.BlobSource.prototype.setPackageIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Data.BlobSource} returns this
 */
proto.dataocean.Data.BlobSource.prototype.addPackageIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Data.BlobSource} returns this
 */
proto.dataocean.Data.BlobSource.prototype.clearPackageIdsList = function() {
  return this.setPackageIdsList([]);
};


goog.object.extend(exports, proto.dataocean);
