import React, { useEffect, useRef, useState } from 'react';

import './index.less';
import { makeAutoObservable, toJS } from 'mobx';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, PageHeader, Row, Tag } from 'antd';
import { observer, useLocalObservable } from 'mobx-react';
import { useNavigate } from 'react-router';
import { modelServiceType } from 'utils/resourceUtil';
import { getServices } from 'api/grpc/service';

const ModelShop = observer(() => {
  const navigate = useNavigate();
  const status = useLocalObservable(() => ({
    selectedProvider: 0,
    selectedType: 0,
    selectedContext: 0,
    data: [] as modelServiceType[],
    get filteredData() {
      return this.data;
    }
  }));
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    status.data = await getServices();
    console.log(toJS(status.data));
  };
  const provider = [
    { provider: 'OpenAI', index: 1 },
    { provider: 'Google', index: 2 },
    { provider: '百度', index: 3 },
    { provider: '阿里', index: 4 },
    { provider: '百川智能', index: 5 },
    { provider: '零一万物', index: 6 },
    { provider: '元语智能', index: 7 },
    { provider: '智谱AI', index: 8 },
    { provider: 'BELLE', index: 9 },
    { provider: 'BiLLa', index: 10 },
    { provider: 'Black Forest Labs', index: 11 },
    { provider: 'Databricks', index: 12 },
    { provider: 'IDEA研究院', index: 13 },
    { provider: 'Meta', index: 14 },
    { provider: 'MiniMax', index: 15 },
    { provider: 'Stability AI', index: 16 },
    { provider: '其他', index: 17 }
  ];
  const modelType = [
    { type: '文本生成', index: 1 },
    { type: '视频理解', index: 2 },
    { type: '视频生成', index: 3 },
    { type: '图片处理', index: 4 },
    { type: '图片理解', index: 5 },
    { type: '图片生成', index: 6 },
    { type: '向量模型', index: 7 },
    { type: '语音合成', index: 8 },
    { type: '语音识别', index: 9 }
  ];
  const modelContext = [
    { context: '6K以下', index: 1 },
    { context: '16K-64K', index: 2 },
    { context: '64K以上', index: 3 }
  ];

  useEffect(() => {}, [status.data]);
  return (
    <PageHeader style={{ paddingTop: 0 }}>
      <Tag
        style={{
          paddingLeft: 20,
          width: '100%',
          marginBottom: 20,
          height: '40px',
          lineHeight: '40px',
          fontSize: 14,
          display: 'none'
        }}
        icon={<InfoCircleOutlined />}
        color="processing"
      >
        <span style={{ color: '#000' }}>
          模型商城中提供多种国内外主流模型供选择使用，包括chatgpt、claude、通义千问等，您可以根据业务需求选择合适的模型进行接入
        </span>
        <Button
          onClick={() => {
            navigate('/user_portal/service/chat');
          }}
          type="link"
        >
          立即前往体验↗
        </Button>
      </Tag>
      <div style={{ background: '#fff', padding: '20px 30px 20px 30px', display: 'none' }}>
        <div style={{ fontSize: 14, fontWeight: 400, marginBottom: 20 }}>模型筛选</div>
        <Row>
          <Col span={2}>供应商</Col>
          <Col span={22}>
            <Row>
              <Col span={2}>
                <div
                  className="common-button"
                  style={status.selectedProvider === 0 ? { color: '#1890ff' } : {}}
                  onClick={() => (status.selectedProvider = 0)}
                >
                  全部
                </div>
              </Col>
              <Col span={22} style={{ display: 'flex', flexWrap: 'wrap' }}>
                {provider.map((item) => (
                  <div
                    key={item.index}
                    className="common-button"
                    style={status.selectedProvider === item.index ? { color: '#1890ff' } : {}}
                    onClick={() => {
                      status.selectedProvider = item.index;
                    }}
                  >
                    {item.provider}
                  </div>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={2}>模型类型</Col>
          <Col span={22}>
            <Row>
              <Col span={2}>
                <div
                  className="common-button"
                  style={status.selectedType === 0 ? { color: '#1890ff' } : {}}
                  onClick={() => (status.selectedType = 0)}
                >
                  全部
                </div>
              </Col>
              <Col span={22} style={{ display: 'flex', flexWrap: 'wrap' }}>
                {modelType.map((item) => (
                  <div
                    key={item.index}
                    className="common-button"
                    style={status.selectedType === item.index ? { color: '#1890ff' } : {}}
                    onClick={() => {
                      status.selectedType = item.index;
                    }}
                  >
                    {item.type}
                  </div>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={2}>上下文长度</Col>
          <Col span={22}>
            <Row>
              <Col span={2}>
                <div
                  className="common-button"
                  style={status.selectedContext === 0 ? { color: '#1890ff' } : {}}
                  onClick={() => (status.selectedContext = 0)}
                >
                  全部
                </div>
              </Col>
              <Col span={22} style={{ display: 'flex', flexWrap: 'wrap' }}>
                {modelContext.map((item) => (
                  <div
                    key={item.index}
                    className="common-button"
                    style={status.selectedContext === item.index ? { color: '#1890ff' } : {}}
                    onClick={() => {
                      status.selectedContext = item.index;
                    }}
                  >
                    {item.context}
                  </div>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div style={{ margin: '10px 0' }}>共 {status.filteredData.length} 个模型</div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' }}>
        {status.filteredData.map((model) => {
          const label = model.product.product?.getResourcesMap()?.get('label');
          const name = model.product.product?.getName();
          const desc = model.product.product?.getDescription();
          return (
            <div key={model.productid} style={{ background: '#fff', position: 'relative' }}>
              <div style={{ padding: '30px 20px' }}>
                <div style={{ fontSize: 16, fontWeight: 700 }}>{name}</div>
                {label ? (
                  <div>
                    <Tag color="default">{label}</Tag>
                  </div>
                ) : (
                  <div style={{ minHeight: 20 }}></div>
                )}

                <div style={{ margin: '20px 0', minHeight: 60 }}>{desc}</div>
              </div>
              <div
                style={{
                  background: '#fff',
                  color: '#666666',
                  cursor: 'pointer',
                  borderTop: 'solid 1px rgba(233, 233, 233, 1)',
                  textAlign: 'center',
                  padding: '20px 0',
                  height: 80
                }}
                onClick={() => {
                  navigate('/user_portal/service/chat?model=' + model.productid);
                }}
              >
                <span>立即体验↗</span>
              </div>
            </div>
          );
        })}
      </div>
    </PageHeader>
  );
});

export default ModelShop;
