import { PauseCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Model } from 'types/chat';

type IChatInputProps = {
  style?: React.CSSProperties;
  onSend: (value: string) => void;
  onChange: (value: string) => void;
  handleUpload?: (file: any) => void;
  abort?: () => void;
  disabled?: boolean;
  answering?: boolean;
  value?: string;
};

const ChatInput: React.FC<IChatInputProps> = ({
  style,
  onSend,
  onChange: onChangeProp,
  disabled,
  answering,
  value: valueProp,
  handleUpload,
  abort
}) => {
  const [valueState, setValue] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const value = valueProp !== undefined ? valueProp : valueState;

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (valueProp !== undefined) {
      onChangeProp(value);
    } else {
      setValue(value);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (isTyping) {
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!disabled) {
        onSend(value);
        setValue('');
        onChangeProp('');
      }
    }
  };

  // useEffect(() => {
  //   if (textareaRef && textareaRef.current) {
  //     textareaRef.current.style.height = 'inherit';
  //     textareaRef.current.style.height = `${textareaRef.current?.scrollHeight}px`;
  //     textareaRef.current.style.overflow = `${textareaRef?.current?.scrollHeight > 400 ? 'auto' : 'hidden'}`;
  //   }
  // }, [value]);
  const handlePaste = async (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const items = e.clipboardData.items;
    const pastedFiles = [];
    for (const item of items) {
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        if (file) {
          pastedFiles.push(file);
        }
      }
    }
    if (pastedFiles.length > 0 && handleUpload) {
      handleUpload(pastedFiles);
    }
  };
  return (
    <div className="chat-input" style={style}>
      {/* <Button
        disabled={disabled}
        className="btn-chose"
        type="text"
        style={{ padding: '2px 6px', height: 'auto' }}
        onClick={() => onSend(value)}
      >
        <SendOutlined></SendOutlined>
      </Button> */}
      <Input.TextArea
        ref={textareaRef}
        style={{
          bottom: `${textareaRef?.current?.scrollHeight}px`,
          maxHeight: 400,
          overflow: `${textareaRef.current && textareaRef.current.scrollHeight > 400 ? 'auto' : 'hidden'}`
        }}
        placeholder={'请在这里提问'}
        value={value}
        rows={1}
        onCompositionStart={() => setIsTyping(true)}
        onCompositionEnd={() => setIsTyping(false)}
        onChange={onChange}
        onKeyDown={onKeyDown}
        // onPaste={handlePaste}
      />
      {answering ? (
        <Button className="btn-send" type="text" style={{ padding: '2px 6px', height: 'auto' }} onClick={abort}>
          <PauseCircleOutlined />
        </Button>
      ) : (
        <Button
          disabled={disabled}
          className="btn-send"
          type="text"
          style={{ padding: '2px 6px', height: 'auto' }}
          onClick={() => {
            onSend(value);
            setValue('');
            onChangeProp('');
          }}
        >
          <SendOutlined></SendOutlined>
        </Button>
      )}
    </div>
  );
};

export default observer(ChatInput);
