// source: dataocean/annotation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var dataocean_global_pb = require('../dataocean/global_pb.js');
goog.object.extend(proto, dataocean_global_pb);
var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js');
goog.object.extend(proto, github_com_envoyproxy_protoc$gen$validate_validate_validate_pb);
goog.exportSymbol('proto.dataocean.Annotation', null, global);
goog.exportSymbol('proto.dataocean.AnnotationProperty', null, global);
goog.exportSymbol('proto.dataocean.AnnotationPropertyValue', null, global);
goog.exportSymbol('proto.dataocean.AnnotationTask', null, global);
goog.exportSymbol('proto.dataocean.AnnotationTaskCandidate', null, global);
goog.exportSymbol('proto.dataocean.AnnotationTaskObject', null, global);
goog.exportSymbol('proto.dataocean.Digest', null, global);
goog.exportSymbol('proto.dataocean.DigestFunc', null, global);
goog.exportSymbol('proto.dataocean.Trail', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Annotation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Annotation.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Annotation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Annotation.displayName = 'proto.dataocean.Annotation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.AnnotationProperty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.AnnotationProperty.repeatedFields_, null);
};
goog.inherits(proto.dataocean.AnnotationProperty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.AnnotationProperty.displayName = 'proto.dataocean.AnnotationProperty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.AnnotationPropertyValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.AnnotationPropertyValue.repeatedFields_, null);
};
goog.inherits(proto.dataocean.AnnotationPropertyValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.AnnotationPropertyValue.displayName = 'proto.dataocean.AnnotationPropertyValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.AnnotationTask = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.AnnotationTask.repeatedFields_, null);
};
goog.inherits(proto.dataocean.AnnotationTask, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.AnnotationTask.displayName = 'proto.dataocean.AnnotationTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.AnnotationTaskCandidate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.AnnotationTaskCandidate.repeatedFields_, null);
};
goog.inherits(proto.dataocean.AnnotationTaskCandidate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.AnnotationTaskCandidate.displayName = 'proto.dataocean.AnnotationTaskCandidate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.AnnotationTaskObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.AnnotationTaskObject.repeatedFields_, null);
};
goog.inherits(proto.dataocean.AnnotationTaskObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.AnnotationTaskObject.displayName = 'proto.dataocean.AnnotationTaskObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Trail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Trail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Trail.displayName = 'proto.dataocean.Trail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Digest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Digest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Digest.displayName = 'proto.dataocean.Digest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Annotation.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Annotation.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Annotation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Annotation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Annotation.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.dataocean.AnnotationPropertyValue.toObject, includeInstance),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto.dataocean.AnnotationTask.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Annotation}
 */
proto.dataocean.Annotation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Annotation;
  return proto.dataocean.Annotation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Annotation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Annotation}
 */
proto.dataocean.Annotation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.AnnotationPropertyValue;
      reader.readMessage(value,proto.dataocean.AnnotationPropertyValue.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    case 2:
      var value = new proto.dataocean.AnnotationTask;
      reader.readMessage(value,proto.dataocean.AnnotationTask.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Annotation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Annotation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Annotation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Annotation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataocean.AnnotationPropertyValue.serializeBinaryToWriter
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dataocean.AnnotationTask.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnnotationPropertyValue properties = 1;
 * @return {!Array<!proto.dataocean.AnnotationPropertyValue>}
 */
proto.dataocean.Annotation.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.dataocean.AnnotationPropertyValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.AnnotationPropertyValue, 1));
};


/**
 * @param {!Array<!proto.dataocean.AnnotationPropertyValue>} value
 * @return {!proto.dataocean.Annotation} returns this
*/
proto.dataocean.Annotation.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.AnnotationPropertyValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationPropertyValue}
 */
proto.dataocean.Annotation.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.AnnotationPropertyValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Annotation} returns this
 */
proto.dataocean.Annotation.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * repeated AnnotationTask tasks = 2;
 * @return {!Array<!proto.dataocean.AnnotationTask>}
 */
proto.dataocean.Annotation.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.dataocean.AnnotationTask>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.AnnotationTask, 2));
};


/**
 * @param {!Array<!proto.dataocean.AnnotationTask>} value
 * @return {!proto.dataocean.Annotation} returns this
*/
proto.dataocean.Annotation.prototype.setTasksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dataocean.AnnotationTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationTask}
 */
proto.dataocean.Annotation.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dataocean.AnnotationTask, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Annotation} returns this
 */
proto.dataocean.Annotation.prototype.clearTasksList = function() {
  return this.setTasksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.AnnotationProperty.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.AnnotationProperty.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.AnnotationProperty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.AnnotationProperty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationProperty.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    optionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.AnnotationProperty}
 */
proto.dataocean.AnnotationProperty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.AnnotationProperty;
  return proto.dataocean.AnnotationProperty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.AnnotationProperty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.AnnotationProperty}
 */
proto.dataocean.AnnotationProperty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.AnnotationProperty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.AnnotationProperty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.AnnotationProperty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationProperty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.dataocean.AnnotationProperty.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationProperty} returns this
 */
proto.dataocean.AnnotationProperty.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.dataocean.AnnotationProperty.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationProperty} returns this
 */
proto.dataocean.AnnotationProperty.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string options = 3;
 * @return {!Array<string>}
 */
proto.dataocean.AnnotationProperty.prototype.getOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.AnnotationProperty} returns this
 */
proto.dataocean.AnnotationProperty.prototype.setOptionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationProperty} returns this
 */
proto.dataocean.AnnotationProperty.prototype.addOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AnnotationProperty} returns this
 */
proto.dataocean.AnnotationProperty.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.AnnotationPropertyValue.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.AnnotationPropertyValue.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.AnnotationPropertyValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.AnnotationPropertyValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationPropertyValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    switchValue: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    radioValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    checkboxValueList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    textValue: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.AnnotationPropertyValue}
 */
proto.dataocean.AnnotationPropertyValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.AnnotationPropertyValue;
  return proto.dataocean.AnnotationPropertyValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.AnnotationPropertyValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.AnnotationPropertyValue}
 */
proto.dataocean.AnnotationPropertyValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwitchValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRadioValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addCheckboxValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.AnnotationPropertyValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.AnnotationPropertyValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.AnnotationPropertyValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationPropertyValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSwitchValue();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRadioValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCheckboxValueList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getTextValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.dataocean.AnnotationPropertyValue.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationPropertyValue} returns this
 */
proto.dataocean.AnnotationPropertyValue.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.dataocean.AnnotationPropertyValue.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationPropertyValue} returns this
 */
proto.dataocean.AnnotationPropertyValue.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool switch_value = 3;
 * @return {boolean}
 */
proto.dataocean.AnnotationPropertyValue.prototype.getSwitchValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.AnnotationPropertyValue} returns this
 */
proto.dataocean.AnnotationPropertyValue.prototype.setSwitchValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string radio_value = 4;
 * @return {string}
 */
proto.dataocean.AnnotationPropertyValue.prototype.getRadioValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationPropertyValue} returns this
 */
proto.dataocean.AnnotationPropertyValue.prototype.setRadioValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string checkbox_value = 5;
 * @return {!Array<string>}
 */
proto.dataocean.AnnotationPropertyValue.prototype.getCheckboxValueList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.AnnotationPropertyValue} returns this
 */
proto.dataocean.AnnotationPropertyValue.prototype.setCheckboxValueList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationPropertyValue} returns this
 */
proto.dataocean.AnnotationPropertyValue.prototype.addCheckboxValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AnnotationPropertyValue} returns this
 */
proto.dataocean.AnnotationPropertyValue.prototype.clearCheckboxValueList = function() {
  return this.setCheckboxValueList([]);
};


/**
 * optional string text_value = 6;
 * @return {string}
 */
proto.dataocean.AnnotationPropertyValue.prototype.getTextValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationPropertyValue} returns this
 */
proto.dataocean.AnnotationPropertyValue.prototype.setTextValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.AnnotationTask.repeatedFields_ = [4,7,9,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.AnnotationTask.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.AnnotationTask.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.AnnotationTask} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationTask.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tool: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    switchValue: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    radioValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
    checkboxValueList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    textValue: jspb.Message.getFieldWithDefault(msg, 8, ""),
    candidatesList: jspb.Message.toObjectList(msg.getCandidatesList(),
    proto.dataocean.AnnotationTaskCandidate.toObject, includeInstance),
    objectsList: jspb.Message.toObjectList(msg.getObjectsList(),
    proto.dataocean.AnnotationTaskObject.toObject, includeInstance),
    confidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.AnnotationTask}
 */
proto.dataocean.AnnotationTask.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.AnnotationTask;
  return proto.dataocean.AnnotationTask.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.AnnotationTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.AnnotationTask}
 */
proto.dataocean.AnnotationTask.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTool(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addOptions(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwitchValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRadioValue(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addCheckboxValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextValue(value);
      break;
    case 9:
      var value = new proto.dataocean.AnnotationTaskCandidate;
      reader.readMessage(value,proto.dataocean.AnnotationTaskCandidate.deserializeBinaryFromReader);
      msg.addCandidates(value);
      break;
    case 10:
      var value = new proto.dataocean.AnnotationTaskObject;
      reader.readMessage(value,proto.dataocean.AnnotationTaskObject.deserializeBinaryFromReader);
      msg.addObjects(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.AnnotationTask.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.AnnotationTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.AnnotationTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationTask.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTool();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getSwitchValue();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getRadioValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCheckboxValueList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getTextValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCandidatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.dataocean.AnnotationTaskCandidate.serializeBinaryToWriter
    );
  }
  f = message.getObjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.dataocean.AnnotationTaskObject.serializeBinaryToWriter
    );
  }
  f = message.getConfidence();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
};


/**
 * optional string task_type = 1;
 * @return {string}
 */
proto.dataocean.AnnotationTask.prototype.getTaskType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.setTaskType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_name = 2;
 * @return {string}
 */
proto.dataocean.AnnotationTask.prototype.getTaskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.setTaskName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tool = 3;
 * @return {string}
 */
proto.dataocean.AnnotationTask.prototype.getTool = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.setTool = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string options = 4;
 * @return {!Array<string>}
 */
proto.dataocean.AnnotationTask.prototype.getOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.setOptionsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.addOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional bool switch_value = 5;
 * @return {boolean}
 */
proto.dataocean.AnnotationTask.prototype.getSwitchValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.setSwitchValue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string radio_value = 6;
 * @return {string}
 */
proto.dataocean.AnnotationTask.prototype.getRadioValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.setRadioValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string checkbox_value = 7;
 * @return {!Array<string>}
 */
proto.dataocean.AnnotationTask.prototype.getCheckboxValueList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.setCheckboxValueList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.addCheckboxValue = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.clearCheckboxValueList = function() {
  return this.setCheckboxValueList([]);
};


/**
 * optional string text_value = 8;
 * @return {string}
 */
proto.dataocean.AnnotationTask.prototype.getTextValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.setTextValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated AnnotationTaskCandidate candidates = 9;
 * @return {!Array<!proto.dataocean.AnnotationTaskCandidate>}
 */
proto.dataocean.AnnotationTask.prototype.getCandidatesList = function() {
  return /** @type{!Array<!proto.dataocean.AnnotationTaskCandidate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.AnnotationTaskCandidate, 9));
};


/**
 * @param {!Array<!proto.dataocean.AnnotationTaskCandidate>} value
 * @return {!proto.dataocean.AnnotationTask} returns this
*/
proto.dataocean.AnnotationTask.prototype.setCandidatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.dataocean.AnnotationTaskCandidate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationTaskCandidate}
 */
proto.dataocean.AnnotationTask.prototype.addCandidates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.dataocean.AnnotationTaskCandidate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.clearCandidatesList = function() {
  return this.setCandidatesList([]);
};


/**
 * repeated AnnotationTaskObject objects = 10;
 * @return {!Array<!proto.dataocean.AnnotationTaskObject>}
 */
proto.dataocean.AnnotationTask.prototype.getObjectsList = function() {
  return /** @type{!Array<!proto.dataocean.AnnotationTaskObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.AnnotationTaskObject, 10));
};


/**
 * @param {!Array<!proto.dataocean.AnnotationTaskObject>} value
 * @return {!proto.dataocean.AnnotationTask} returns this
*/
proto.dataocean.AnnotationTask.prototype.setObjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.dataocean.AnnotationTaskObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationTaskObject}
 */
proto.dataocean.AnnotationTask.prototype.addObjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.dataocean.AnnotationTaskObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.clearObjectsList = function() {
  return this.setObjectsList([]);
};


/**
 * optional double confidence = 11;
 * @return {number}
 */
proto.dataocean.AnnotationTask.prototype.getConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.AnnotationTask} returns this
 */
proto.dataocean.AnnotationTask.prototype.setConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.AnnotationTaskCandidate.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.AnnotationTaskCandidate.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.AnnotationTaskCandidate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.AnnotationTaskCandidate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationTaskCandidate.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_class: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.dataocean.AnnotationProperty.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.AnnotationTaskCandidate}
 */
proto.dataocean.AnnotationTaskCandidate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.AnnotationTaskCandidate;
  return proto.dataocean.AnnotationTaskCandidate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.AnnotationTaskCandidate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.AnnotationTaskCandidate}
 */
proto.dataocean.AnnotationTaskCandidate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClass(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = new proto.dataocean.AnnotationProperty;
      reader.readMessage(value,proto.dataocean.AnnotationProperty.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.AnnotationTaskCandidate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.AnnotationTaskCandidate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.AnnotationTaskCandidate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationTaskCandidate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClass();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dataocean.AnnotationProperty.serializeBinaryToWriter
    );
  }
};


/**
 * optional string class = 1;
 * @return {string}
 */
proto.dataocean.AnnotationTaskCandidate.prototype.getClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTaskCandidate} returns this
 */
proto.dataocean.AnnotationTaskCandidate.prototype.setClass = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.dataocean.AnnotationTaskCandidate.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTaskCandidate} returns this
 */
proto.dataocean.AnnotationTaskCandidate.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AnnotationProperty properties = 3;
 * @return {!Array<!proto.dataocean.AnnotationProperty>}
 */
proto.dataocean.AnnotationTaskCandidate.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.dataocean.AnnotationProperty>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.AnnotationProperty, 3));
};


/**
 * @param {!Array<!proto.dataocean.AnnotationProperty>} value
 * @return {!proto.dataocean.AnnotationTaskCandidate} returns this
*/
proto.dataocean.AnnotationTaskCandidate.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataocean.AnnotationProperty=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationProperty}
 */
proto.dataocean.AnnotationTaskCandidate.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataocean.AnnotationProperty, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AnnotationTaskCandidate} returns this
 */
proto.dataocean.AnnotationTaskCandidate.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.AnnotationTaskObject.repeatedFields_ = [6,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.AnnotationTaskObject.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.AnnotationTaskObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.AnnotationTaskObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationTaskObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pb_class: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    layer: jspb.Message.getFieldWithDefault(msg, 4, 0),
    confidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    proto.dataocean.AnnotationPropertyValue.toObject, includeInstance),
    point: (f = msg.getPoint()) && dataocean_global_pb.Point.toObject(includeInstance, f),
    rectangle: (f = msg.getRectangle()) && dataocean_global_pb.Rectangle.toObject(includeInstance, f),
    polygon: (f = msg.getPolygon()) && dataocean_global_pb.Polygon.toObject(includeInstance, f),
    chunk: (f = msg.getChunk()) && dataocean_global_pb.VideoChunk.toObject(includeInstance, f),
    trailsList: jspb.Message.toObjectList(msg.getTrailsList(),
    proto.dataocean.Trail.toObject, includeInstance),
    tool: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.AnnotationTaskObject}
 */
proto.dataocean.AnnotationTaskObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.AnnotationTaskObject;
  return proto.dataocean.AnnotationTaskObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.AnnotationTaskObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.AnnotationTaskObject}
 */
proto.dataocean.AnnotationTaskObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClass(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLayer(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConfidence(value);
      break;
    case 6:
      var value = new proto.dataocean.AnnotationPropertyValue;
      reader.readMessage(value,proto.dataocean.AnnotationPropertyValue.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    case 7:
      var value = new dataocean_global_pb.Point;
      reader.readMessage(value,dataocean_global_pb.Point.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 8:
      var value = new dataocean_global_pb.Rectangle;
      reader.readMessage(value,dataocean_global_pb.Rectangle.deserializeBinaryFromReader);
      msg.setRectangle(value);
      break;
    case 9:
      var value = new dataocean_global_pb.Polygon;
      reader.readMessage(value,dataocean_global_pb.Polygon.deserializeBinaryFromReader);
      msg.setPolygon(value);
      break;
    case 10:
      var value = new dataocean_global_pb.VideoChunk;
      reader.readMessage(value,dataocean_global_pb.VideoChunk.deserializeBinaryFromReader);
      msg.setChunk(value);
      break;
    case 11:
      var value = new proto.dataocean.Trail;
      reader.readMessage(value,proto.dataocean.Trail.deserializeBinaryFromReader);
      msg.addTrails(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTool(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.AnnotationTaskObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.AnnotationTaskObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.AnnotationTaskObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.AnnotationTaskObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClass();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLayer();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getConfidence();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.dataocean.AnnotationPropertyValue.serializeBinaryToWriter
    );
  }
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      dataocean_global_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getRectangle();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      dataocean_global_pb.Rectangle.serializeBinaryToWriter
    );
  }
  f = message.getPolygon();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      dataocean_global_pb.Polygon.serializeBinaryToWriter
    );
  }
  f = message.getChunk();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      dataocean_global_pb.VideoChunk.serializeBinaryToWriter
    );
  }
  f = message.getTrailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.dataocean.Trail.serializeBinaryToWriter
    );
  }
  f = message.getTool();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dataocean.AnnotationTaskObject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string class = 2;
 * @return {string}
 */
proto.dataocean.AnnotationTaskObject.prototype.getClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.setClass = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.dataocean.AnnotationTaskObject.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 layer = 4;
 * @return {number}
 */
proto.dataocean.AnnotationTaskObject.prototype.getLayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.setLayer = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double confidence = 5;
 * @return {number}
 */
proto.dataocean.AnnotationTaskObject.prototype.getConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.setConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated AnnotationPropertyValue properties = 6;
 * @return {!Array<!proto.dataocean.AnnotationPropertyValue>}
 */
proto.dataocean.AnnotationTaskObject.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.dataocean.AnnotationPropertyValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.AnnotationPropertyValue, 6));
};


/**
 * @param {!Array<!proto.dataocean.AnnotationPropertyValue>} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
*/
proto.dataocean.AnnotationTaskObject.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.dataocean.AnnotationPropertyValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.AnnotationPropertyValue}
 */
proto.dataocean.AnnotationTaskObject.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.dataocean.AnnotationPropertyValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * optional Point point = 7;
 * @return {?proto.dataocean.Point}
 */
proto.dataocean.AnnotationTaskObject.prototype.getPoint = function() {
  return /** @type{?proto.dataocean.Point} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Point, 7));
};


/**
 * @param {?proto.dataocean.Point|undefined} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
*/
proto.dataocean.AnnotationTaskObject.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.AnnotationTaskObject.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Rectangle rectangle = 8;
 * @return {?proto.dataocean.Rectangle}
 */
proto.dataocean.AnnotationTaskObject.prototype.getRectangle = function() {
  return /** @type{?proto.dataocean.Rectangle} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Rectangle, 8));
};


/**
 * @param {?proto.dataocean.Rectangle|undefined} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
*/
proto.dataocean.AnnotationTaskObject.prototype.setRectangle = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.clearRectangle = function() {
  return this.setRectangle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.AnnotationTaskObject.prototype.hasRectangle = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Polygon polygon = 9;
 * @return {?proto.dataocean.Polygon}
 */
proto.dataocean.AnnotationTaskObject.prototype.getPolygon = function() {
  return /** @type{?proto.dataocean.Polygon} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Polygon, 9));
};


/**
 * @param {?proto.dataocean.Polygon|undefined} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
*/
proto.dataocean.AnnotationTaskObject.prototype.setPolygon = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.clearPolygon = function() {
  return this.setPolygon(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.AnnotationTaskObject.prototype.hasPolygon = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional VideoChunk chunk = 10;
 * @return {?proto.dataocean.VideoChunk}
 */
proto.dataocean.AnnotationTaskObject.prototype.getChunk = function() {
  return /** @type{?proto.dataocean.VideoChunk} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.VideoChunk, 10));
};


/**
 * @param {?proto.dataocean.VideoChunk|undefined} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
*/
proto.dataocean.AnnotationTaskObject.prototype.setChunk = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.clearChunk = function() {
  return this.setChunk(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.AnnotationTaskObject.prototype.hasChunk = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated Trail trails = 11;
 * @return {!Array<!proto.dataocean.Trail>}
 */
proto.dataocean.AnnotationTaskObject.prototype.getTrailsList = function() {
  return /** @type{!Array<!proto.dataocean.Trail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.Trail, 11));
};


/**
 * @param {!Array<!proto.dataocean.Trail>} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
*/
proto.dataocean.AnnotationTaskObject.prototype.setTrailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.dataocean.Trail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Trail}
 */
proto.dataocean.AnnotationTaskObject.prototype.addTrails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.dataocean.Trail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.clearTrailsList = function() {
  return this.setTrailsList([]);
};


/**
 * optional string tool = 12;
 * @return {string}
 */
proto.dataocean.AnnotationTaskObject.prototype.getTool = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.AnnotationTaskObject} returns this
 */
proto.dataocean.AnnotationTaskObject.prototype.setTool = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Trail.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Trail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Trail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Trail.toObject = function(includeInstance, msg) {
  var f, obj = {
    frame: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keyFrame: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cover: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    inview: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    point: (f = msg.getPoint()) && dataocean_global_pb.Point.toObject(includeInstance, f),
    rectangle: (f = msg.getRectangle()) && dataocean_global_pb.Rectangle.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Trail}
 */
proto.dataocean.Trail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Trail;
  return proto.dataocean.Trail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Trail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Trail}
 */
proto.dataocean.Trail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrame(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setKeyFrame(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCover(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInview(value);
      break;
    case 5:
      var value = new dataocean_global_pb.Point;
      reader.readMessage(value,dataocean_global_pb.Point.deserializeBinaryFromReader);
      msg.setPoint(value);
      break;
    case 6:
      var value = new dataocean_global_pb.Rectangle;
      reader.readMessage(value,dataocean_global_pb.Rectangle.deserializeBinaryFromReader);
      msg.setRectangle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Trail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Trail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Trail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Trail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrame();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getKeyFrame();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCover();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getInview();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPoint();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dataocean_global_pb.Point.serializeBinaryToWriter
    );
  }
  f = message.getRectangle();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      dataocean_global_pb.Rectangle.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 frame = 1;
 * @return {number}
 */
proto.dataocean.Trail.prototype.getFrame = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Trail} returns this
 */
proto.dataocean.Trail.prototype.setFrame = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 key_frame = 2;
 * @return {number}
 */
proto.dataocean.Trail.prototype.getKeyFrame = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Trail} returns this
 */
proto.dataocean.Trail.prototype.setKeyFrame = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool cover = 3;
 * @return {boolean}
 */
proto.dataocean.Trail.prototype.getCover = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.Trail} returns this
 */
proto.dataocean.Trail.prototype.setCover = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool inview = 4;
 * @return {boolean}
 */
proto.dataocean.Trail.prototype.getInview = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.Trail} returns this
 */
proto.dataocean.Trail.prototype.setInview = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional Point point = 5;
 * @return {?proto.dataocean.Point}
 */
proto.dataocean.Trail.prototype.getPoint = function() {
  return /** @type{?proto.dataocean.Point} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Point, 5));
};


/**
 * @param {?proto.dataocean.Point|undefined} value
 * @return {!proto.dataocean.Trail} returns this
*/
proto.dataocean.Trail.prototype.setPoint = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Trail} returns this
 */
proto.dataocean.Trail.prototype.clearPoint = function() {
  return this.setPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Trail.prototype.hasPoint = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Rectangle rectangle = 6;
 * @return {?proto.dataocean.Rectangle}
 */
proto.dataocean.Trail.prototype.getRectangle = function() {
  return /** @type{?proto.dataocean.Rectangle} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Rectangle, 6));
};


/**
 * @param {?proto.dataocean.Rectangle|undefined} value
 * @return {!proto.dataocean.Trail} returns this
*/
proto.dataocean.Trail.prototype.setRectangle = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Trail} returns this
 */
proto.dataocean.Trail.prototype.clearRectangle = function() {
  return this.setRectangle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Trail.prototype.hasRectangle = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Digest.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Digest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Digest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Digest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_function: jspb.Message.getFieldWithDefault(msg, 1, 0),
    encoding: jspb.Message.getFieldWithDefault(msg, 2, ""),
    digest: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Digest}
 */
proto.dataocean.Digest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Digest;
  return proto.dataocean.Digest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Digest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Digest}
 */
proto.dataocean.Digest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dataocean.DigestFunc} */ (reader.readEnum());
      msg.setFunction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncoding(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDigest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Digest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Digest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Digest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Digest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFunction();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEncoding();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDigest();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional DigestFunc function = 1;
 * @return {!proto.dataocean.DigestFunc}
 */
proto.dataocean.Digest.prototype.getFunction = function() {
  return /** @type {!proto.dataocean.DigestFunc} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataocean.DigestFunc} value
 * @return {!proto.dataocean.Digest} returns this
 */
proto.dataocean.Digest.prototype.setFunction = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string encoding = 2;
 * @return {string}
 */
proto.dataocean.Digest.prototype.getEncoding = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Digest} returns this
 */
proto.dataocean.Digest.prototype.setEncoding = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string digest = 3;
 * @return {string}
 */
proto.dataocean.Digest.prototype.getDigest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Digest} returns this
 */
proto.dataocean.Digest.prototype.setDigest = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.dataocean.DigestFunc = {
  UNKNOW: 0,
  MD5: 1,
  SHA1: 2,
  SHA256: 3,
  SHA384: 4,
  SHA512: 5
};

goog.object.extend(exports, proto.dataocean);
