import React, { useEffect } from 'react';
import { Modal, Form, Input, Button, ModalProps, Tooltip, Upload } from 'antd';
import { observer } from 'mobx-react-lite';
import { InfoCircleOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

interface PublishWorkFlowDialogProps {
  curWorkFlow: any;
  editFlag: boolean;
}
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 }
};

const PublishWorkFlowDialog: React.FC<ModalProps & PublishWorkFlowDialogProps> = observer(
  ({ curWorkFlow, editFlag, ...modalProps }) => {
    const [form] = Form.useForm();
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>上传</div>
      </div>
    );
    useEffect(() => {
      if (!modalProps.open) {
        form.resetFields();
      }
    }, [modalProps.open]);
    return (
      <Modal title={editFlag ? '修改发布' : '发布工作流'} width="40vw" {...modalProps} footer="">
        <Form form={form} {...layout} style={{ padding: 'auto', marginTop: 40 }}>
          <Form.Item
            name="name"
            label={
              <span>
                工作流名称&nbsp;
                <Tooltip title="输入">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Input placeholder="请输入工作流名称" />
          </Form.Item>
          <Form.Item
            name="description"
            label={
              <span>
                说明&nbsp;
                <Tooltip title="输入">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Input.TextArea placeholder="请输入说明" />
          </Form.Item>
          <Form.Item name="upload" label="上传展示图片">
            <Upload accept="image/*" action="" listType="picture-card">
              {uploadButton}
            </Upload>
          </Form.Item>
        </Form>

        <div style={{ display: 'flex', justifyContent: 'center', gap: 20, margin: '40px 0' }}>
          <Button onClick={modalProps.onCancel}>取消</Button>
          {editFlag ? (
            <>
              <Button type="primary">保存</Button>
              <Button>下架</Button>
            </>
          ) : (
            <>
              <Button type="primary">发布</Button>
            </>
          )}
        </div>
      </Modal>
    );
  }
);

export default PublishWorkFlowDialog;
