const stringUtil = {
  randomString: (length = 8) => {
    const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let target = '';
    for (let i = 0; i < length; i++) {
      target += str.charAt(Math.round(Math.random() * str.length));
    }
    return target;
  },
  extractSubstringAfterDelimiter(sourceText: string, delimiter: string): string {
    const delimiterPosition = sourceText.indexOf(delimiter);
    if (delimiterPosition === -1) {
      // 如果未找到分隔符，则返回原始字符串
      return sourceText;
    }
    return sourceText.slice(delimiterPosition + delimiter.length);
  }
};

export default stringUtil;
