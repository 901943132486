import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';
import { Avatar, Button, message, Modal, PageHeader, Select, Table, Tabs, Tag } from 'antd';
import {
  CaretRightOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  PictureOutlined,
  PlayCircleOutlined,
  UserOutlined
} from '@ant-design/icons';
import { render } from 'react-dom';
import { formatTime } from 'utils/timeUtil';
import { ColumnsType } from 'antd/lib/table';
import WorkFlowDetailDialog from './components/WorkFlowDetailDialog';
import PublishWorkFlowDialog from './components/PublishWorkFlowDialog';
import InfiniteScroll from 'react-infinite-scroll-component';
class Status {
  workflowDetailDialog = {
    open: false,
    curWorkFlow: undefined
  };
  publishWorkFlowDialog = {
    open: false,
    edit: false,
    curWorkFlow: undefined
  };
  constructor() {
    makeAutoObservable(this);
  }
}
const status = new Status();

const ComfyUI: React.FC = observer(() => {
  const [activeKey, setActiveKey] = useState('common');
  const renderCommon = () => {
    let cards = [
      {
        id: 1,
        height: 200,
        content: '视频卡片 1',
        url: 'https://grewer.github.io/JsDemo/waterfallLayout/imgs/img_0.png'
      },
      {
        id: 2,
        height: 300,
        content: '视频卡片 2',
        url: 'https://grewer.github.io/JsDemo/waterfallLayout/imgs/img_1.png'
      },
      {
        id: 3,
        height: 250,
        content: '视频卡片 3',
        url: 'https://grewer.github.io/JsDemo/waterfallLayout/imgs/img_2.png'
      },
      {
        id: 4,
        height: 180,
        content: '视频卡片 4',
        url: 'https://grewer.github.io/JsDemo/waterfallLayout/imgs/img_3.png'
      },
      {
        id: 5,
        height: 320,
        content: '视频卡片 5',
        url: 'https://grewer.github.io/JsDemo/waterfallLayout/imgs/img_4.png'
      },
      {
        id: 6,
        height: 220,
        content: '视频卡片 6',
        url: 'https://grewer.github.io/JsDemo/waterfallLayout/imgs/img_5.png'
      }
    ];
    return (
      <div>
        <InfiniteScroll
          dataLength={cards.length}
          next={() => {
            console.log(123);
            cards = [...cards, ...cards];
          }}
          hasMore={true}
          loader={null}
          scrollableTarget="mainContent"
        >
          <div className="waterfall-container">
            {cards.map((card) => (
              <div
                onClick={() => {
                  status.workflowDetailDialog.open = true;
                  status.workflowDetailDialog.curWorkFlow = card;
                }}
                key={card.id}
                className="waterfall-container-card"
                // style={{ background: `url(${card.url})` }}
                style={{ position: 'relative' }}
              >
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={card.url} />
                {/* {card.content} */}
                <div
                  style={{
                    position: 'absolute',
                    top: 10,
                    left: 5,
                    background: 'rgba(71,78,94, 0.5)',
                    color: '#fff',
                    padding: '4px 6px',
                    borderRadius: 12,
                    fontSize: 10
                  }}
                >
                  工作流
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: 10,
                    padding: '0 10px',
                    borderRadius: 12,
                    color: '#fff',
                    fontSize: 12,
                    width: '100%'
                  }}
                >
                  <div>{card.content}</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <Avatar size="small" icon={<UserOutlined />} /> 热点推荐官员
                    </div>
                    <div>
                      <PlayCircleOutlined /> 77&nbsp;&nbsp;
                      <CloudDownloadOutlined /> 59&nbsp;&nbsp;
                      <PictureOutlined /> 13
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>

        <WorkFlowDetailDialog
          onCancel={() => (status.workflowDetailDialog.open = false)}
          open={status.workflowDetailDialog.open}
          curWorkFlow={status.workflowDetailDialog.curWorkFlow}
        />
      </div>
    );
  };
  const workflowData = [
    {
      id: 'gzl12120',
      name: '文生图',
      createTime: 1735282421
    }
  ];
  const workflowColumns: ColumnsType<any> = [
    {
      title: '工作流ID',
      align: 'center',
      dataIndex: 'id'
    },
    {
      title: '工作流名称',
      align: 'center',
      dataIndex: 'name'
    },
    {
      title: '创建时间',
      align: 'center',
      dataIndex: 'createTime',
      render: (text: any) => formatTime(text * 1000)
    },
    {
      title: '操作',
      align: 'center',
      width: 300,
      render: (text: any, record: any) => {
        return (
          <div>
            <Button
              onClick={() => {
                status.publishWorkFlowDialog.open = true;
                status.publishWorkFlowDialog.curWorkFlow = record;
                status.publishWorkFlowDialog.edit = true;
              }}
              type="link"
            >
              在线编辑
            </Button>
            <Button type="link">下载</Button>
            <Button
              onClick={() => {
                status.publishWorkFlowDialog.open = true;
                status.publishWorkFlowDialog.curWorkFlow = record;
                status.publishWorkFlowDialog.edit = false;
              }}
              type="link"
            >
              发布
            </Button>
            <Button
              type="link"
              onClick={() => {
                const useconfig = {
                  title: '您将删除工作流',
                  content: '删除后无法使用，是否继续？',
                  async onOk() {
                    message.success('删除成功');
                  }
                };
                Modal.confirm(useconfig);
              }}
            >
              删除
            </Button>
          </div>
        );
      }
    }
  ];
  const renderWorkflow = () => {
    return (
      <div>
        <div>
          选择开发环境：
          <Select style={{ width: 200, margin: '0 20px 0 0' }}>
            <Select.Option value="1">官方试用环境1</Select.Option>
            <Select.Option value="2">官方试用环境2</Select.Option>
          </Select>
          <Button type="primary">新建工作流</Button>
        </div>
        <Tag
          style={{ paddingLeft: 20, width: '100%', margin: '20px 0', height: '40px', lineHeight: '40px', fontSize: 14 }}
          icon={<InfoCircleOutlined />}
          color="processing"
        >
          <span style={{ color: '#000' }}>
            官方试用环境运行等待时间较长，点击
            <Button type="link" style={{ padding: 0, margin: 0 }}>
              此处
            </Button>
            创建专属ComfyUI开发环境
          </span>
        </Tag>
        <Table rowKey="id" dataSource={workflowData} columns={workflowColumns} />
        <PublishWorkFlowDialog
          curWorkFlow={status.publishWorkFlowDialog.curWorkFlow}
          open={status.publishWorkFlowDialog.open}
          editFlag={status.publishWorkFlowDialog.edit}
          onCancel={() => (status.publishWorkFlowDialog.open = false)}
        />
      </div>
    );
  };
  const sharedColumns: ColumnsType<any> = [
    {
      title: '工作流ID',
      align: 'center',
      dataIndex: 'id'
    },
    {
      title: '工作流名称',
      align: 'center',
      dataIndex: 'name'
    },
    {
      title: '创建人',
      align: 'center',
      dataIndex: 'creator'
    },
    {
      title: '创建时间',
      align: 'center',
      dataIndex: 'createTime',
      render: (text: any) => formatTime(text * 1000)
    },
    {
      title: '操作',
      align: 'center',
      width: 200,
      render: (text: any, record: any) => {
        return (
          <div>
            <Button type="link">克隆</Button>
            <Button type="link">下载</Button>
          </div>
        );
      }
    }
  ];

  const sharedData = [
    {
      id: 'gzl12120',
      name: '文生图',
      creator: 'nick name',
      createTime: 1735282421
    }
  ];
  const renderShared = () => {
    return <Table rowKey="id" dataSource={sharedData} columns={sharedColumns} />;
  };
  const items = [
    { key: 'common', label: '公共作品', children: renderCommon() },
    { key: 'workflow', label: '我的工作流', children: renderWorkflow() },
    { key: 'shared', label: '单位共享', children: renderShared() }
  ];
  return (
    <PageHeader
      style={{ backgroundColor: '#fff', marginTop: 50, padding: 0, height: 'calc(100% - 50px)' }}
      className="atom-shadow"
    >
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key);
        }}
        defaultActiveKey="common"
        type="card"
        items={items}
        className="atom-tabs"
        style={{ marginTop: -60 }}
      ></Tabs>
    </PageHeader>
  );
});

export default ComfyUI;
