import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import { Message } from 'types/chat';
import Markdown from './Markdown';
import icon1 from 'src/assets/service/icon-a.png';
import icon2 from 'src/assets/service/icon-u.png';
import { Image } from 'antd';

type IChatMessageProps = {
  message: Message;
};

// Helper function to extract thinking content
const extractThinking = (
  content: string,
  isComplete: boolean = false
): { mainContent: string; thinkingContent: string | null } => {
  const thinkStartTag = '<think>';
  const thinkEndTag = '</think>';

  const startIndex = content.indexOf(thinkStartTag);

  // No thinking tag found
  if (startIndex === -1) {
    return {
      mainContent: content,
      thinkingContent: null
    };
  }

  const endIndex = content.indexOf(thinkEndTag, startIndex);

  // Handle incomplete thinking block (closing tag not received yet)
  if (endIndex === -1) {
    // If message is complete but no closing tag, treat everything as main content
    if (isComplete) {
      return {
        mainContent: content.substring(startIndex + thinkStartTag.length),
        thinkingContent: null
      };
    }

    // Extract thinking content so far (without end tag)
    const thinkingContent = content.substring(startIndex + thinkStartTag.length);

    // Remove thinking part from main content
    const mainContent = content.substring(0, startIndex).trim();

    return {
      mainContent,
      thinkingContent
    };
  }

  // Complete thinking block
  const thinkingContent = content.substring(startIndex + thinkStartTag.length, endIndex);

  // Remove the thinking part from the content
  const mainContent = (content.substring(0, startIndex) + content.substring(endIndex + thinkEndTag.length)).trim();

  return {
    mainContent,
    thinkingContent
  };
};

const ChatMessage: React.FC<IChatMessageProps> = ({ message }) => {
  // Pass true to indicate message is complete (assuming all messages in ChatMessage are complete)
  const { mainContent, thinkingContent } = extractThinking(message.content, true);

  return (
    <div className={classNames(['chat-message', message.role])}>
      <div className="inner">
        <div className="avatar">
          <img src={message.role === 'assistant' ? icon1 : icon2} alt="" />
        </div>
        <div style={{ flex: 1 }}>
          {thinkingContent && thinkingContent.trim() !== '' && (
            <div
              className="thinking-content"
              style={{
                marginBottom: '10px',
                padding: '10px',
                backgroundColor: '#f8f9fa',
                borderLeft: '3px solid #6c757d',
                borderRadius: '4px',
                fontSize: '0.9em',
                color: '#666'
              }}
            >
              <h4 style={{ margin: '0 0 8px 0', fontSize: '14px', fontWeight: 'bold' }}>思考:</h4>
              <Markdown content={thinkingContent}></Markdown>
            </div>
          )}

          <div className="content">
            <Markdown content={mainContent}></Markdown>
            {message.fileList?.map((item) => {
              console.log(item);
              return (
                <div style={{ marginTop: 10 }}>
                  <Image
                    width={300}
                    src={item.url || URL.createObjectURL(item.originFileObj)}
                    alt={item.name}
                    preview={{ mask: false }}
                  />
                </div>
              );
            })}
          </div>
          {/* <div className="date" style={{ textAlign: 'right' }}>
            2023-09-12
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default observer(ChatMessage);
