// source: dataocean/dataset.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var meta_meta_pb = require('../meta/meta_pb.js');
goog.object.extend(proto, meta_meta_pb);
var dataocean_global_pb = require('../dataocean/global_pb.js');
goog.object.extend(proto, dataocean_global_pb);
var dataocean_labelx_pb = require('../dataocean/labelx_pb.js');
goog.object.extend(proto, dataocean_labelx_pb);
var dataocean_droplet_pb = require('../dataocean/droplet_pb.js');
goog.object.extend(proto, dataocean_droplet_pb);
var dataocean_trainspot_pb = require('../dataocean/trainspot_pb.js');
goog.object.extend(proto, dataocean_trainspot_pb);
var dataocean_modelshop_pb = require('../dataocean/modelshop_pb.js');
goog.object.extend(proto, dataocean_modelshop_pb);
goog.exportSymbol('proto.dataocean.CommitDatasetReq', null, global);
goog.exportSymbol('proto.dataocean.CreateDatasetReq', null, global);
goog.exportSymbol('proto.dataocean.CreateDatasetVersionReq', null, global);
goog.exportSymbol('proto.dataocean.Dataset', null, global);
goog.exportSymbol('proto.dataocean.DatasetChange', null, global);
goog.exportSymbol('proto.dataocean.DatasetKind', null, global);
goog.exportSymbol('proto.dataocean.DatasetSource', null, global);
goog.exportSymbol('proto.dataocean.DatasetSpec', null, global);
goog.exportSymbol('proto.dataocean.DatasetStatus', null, global);
goog.exportSymbol('proto.dataocean.DatasetVersion', null, global);
goog.exportSymbol('proto.dataocean.EditDatasetReq', null, global);
goog.exportSymbol('proto.dataocean.ExportDatasetReq', null, global);
goog.exportSymbol('proto.dataocean.ExportDatasetResp', null, global);
goog.exportSymbol('proto.dataocean.GetDatasetReq', null, global);
goog.exportSymbol('proto.dataocean.ListDatasetContentsReq', null, global);
goog.exportSymbol('proto.dataocean.ListDatasetContentsResp', null, global);
goog.exportSymbol('proto.dataocean.ListDatasetVersionsReq', null, global);
goog.exportSymbol('proto.dataocean.ListDatasetVersionsResp', null, global);
goog.exportSymbol('proto.dataocean.ListDatasetsReq', null, global);
goog.exportSymbol('proto.dataocean.ListDatasetsResp', null, global);
goog.exportSymbol('proto.dataocean.MergeDatasetsReq', null, global);
goog.exportSymbol('proto.dataocean.ReleaseDatasetReq', null, global);
goog.exportSymbol('proto.dataocean.RemoveDatasetReq', null, global);
goog.exportSymbol('proto.dataocean.RemoveDatasetVersionReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Dataset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Dataset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Dataset.displayName = 'proto.dataocean.Dataset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DatasetSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.DatasetSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DatasetSpec.displayName = 'proto.dataocean.DatasetSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DatasetSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.DatasetSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DatasetSource.displayName = 'proto.dataocean.DatasetSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DatasetStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.DatasetStatus.repeatedFields_, null);
};
goog.inherits(proto.dataocean.DatasetStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DatasetStatus.displayName = 'proto.dataocean.DatasetStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DatasetVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.DatasetVersion.repeatedFields_, null);
};
goog.inherits(proto.dataocean.DatasetVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DatasetVersion.displayName = 'proto.dataocean.DatasetVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.CreateDatasetReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.CreateDatasetReq.repeatedFields_, null);
};
goog.inherits(proto.dataocean.CreateDatasetReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.CreateDatasetReq.displayName = 'proto.dataocean.CreateDatasetReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.CreateDatasetVersionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.CreateDatasetVersionReq.repeatedFields_, null);
};
goog.inherits(proto.dataocean.CreateDatasetVersionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.CreateDatasetVersionReq.displayName = 'proto.dataocean.CreateDatasetVersionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GetDatasetReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GetDatasetReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GetDatasetReq.displayName = 'proto.dataocean.GetDatasetReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDatasetsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ListDatasetsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDatasetsReq.displayName = 'proto.dataocean.ListDatasetsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDatasetsResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListDatasetsResp.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListDatasetsResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDatasetsResp.displayName = 'proto.dataocean.ListDatasetsResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.RemoveDatasetReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.RemoveDatasetReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.RemoveDatasetReq.displayName = 'proto.dataocean.RemoveDatasetReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.RemoveDatasetVersionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.RemoveDatasetVersionReq.repeatedFields_, null);
};
goog.inherits(proto.dataocean.RemoveDatasetVersionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.RemoveDatasetVersionReq.displayName = 'proto.dataocean.RemoveDatasetVersionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.EditDatasetReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.EditDatasetReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.EditDatasetReq.displayName = 'proto.dataocean.EditDatasetReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.MergeDatasetsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.MergeDatasetsReq.repeatedFields_, null);
};
goog.inherits(proto.dataocean.MergeDatasetsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.MergeDatasetsReq.displayName = 'proto.dataocean.MergeDatasetsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DatasetChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.DatasetChange.repeatedFields_, null);
};
goog.inherits(proto.dataocean.DatasetChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DatasetChange.displayName = 'proto.dataocean.DatasetChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.CommitDatasetReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.CommitDatasetReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.CommitDatasetReq.displayName = 'proto.dataocean.CommitDatasetReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ReleaseDatasetReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ReleaseDatasetReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ReleaseDatasetReq.displayName = 'proto.dataocean.ReleaseDatasetReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDatasetContentsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ListDatasetContentsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDatasetContentsReq.displayName = 'proto.dataocean.ListDatasetContentsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDatasetContentsResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListDatasetContentsResp.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListDatasetContentsResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDatasetContentsResp.displayName = 'proto.dataocean.ListDatasetContentsResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDatasetVersionsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ListDatasetVersionsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDatasetVersionsReq.displayName = 'proto.dataocean.ListDatasetVersionsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDatasetVersionsResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListDatasetVersionsResp.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListDatasetVersionsResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDatasetVersionsResp.displayName = 'proto.dataocean.ListDatasetVersionsResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ExportDatasetReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ExportDatasetReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ExportDatasetReq.displayName = 'proto.dataocean.ExportDatasetReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ExportDatasetResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ExportDatasetResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ExportDatasetResp.displayName = 'proto.dataocean.ExportDatasetResp';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Dataset.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Dataset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Dataset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Dataset.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && meta_meta_pb.Metadata.toObject(includeInstance, f),
    spec: (f = msg.getSpec()) && proto.dataocean.DatasetSpec.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.dataocean.DatasetStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Dataset}
 */
proto.dataocean.Dataset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Dataset;
  return proto.dataocean.Dataset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Dataset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Dataset}
 */
proto.dataocean.Dataset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.Metadata;
      reader.readMessage(value,meta_meta_pb.Metadata.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.dataocean.DatasetSpec;
      reader.readMessage(value,proto.dataocean.DatasetSpec.deserializeBinaryFromReader);
      msg.setSpec(value);
      break;
    case 3:
      var value = new proto.dataocean.DatasetStatus;
      reader.readMessage(value,proto.dataocean.DatasetStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Dataset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Dataset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Dataset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Dataset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getSpec();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.DatasetSpec.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.DatasetStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.Metadata meta = 1;
 * @return {?proto.meta.Metadata}
 */
proto.dataocean.Dataset.prototype.getMeta = function() {
  return /** @type{?proto.meta.Metadata} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.Metadata, 1));
};


/**
 * @param {?proto.meta.Metadata|undefined} value
 * @return {!proto.dataocean.Dataset} returns this
*/
proto.dataocean.Dataset.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Dataset} returns this
 */
proto.dataocean.Dataset.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Dataset.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DatasetSpec spec = 2;
 * @return {?proto.dataocean.DatasetSpec}
 */
proto.dataocean.Dataset.prototype.getSpec = function() {
  return /** @type{?proto.dataocean.DatasetSpec} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DatasetSpec, 2));
};


/**
 * @param {?proto.dataocean.DatasetSpec|undefined} value
 * @return {!proto.dataocean.Dataset} returns this
*/
proto.dataocean.Dataset.prototype.setSpec = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Dataset} returns this
 */
proto.dataocean.Dataset.prototype.clearSpec = function() {
  return this.setSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Dataset.prototype.hasSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DatasetStatus status = 3;
 * @return {?proto.dataocean.DatasetStatus}
 */
proto.dataocean.Dataset.prototype.getStatus = function() {
  return /** @type{?proto.dataocean.DatasetStatus} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DatasetStatus, 3));
};


/**
 * @param {?proto.dataocean.DatasetStatus|undefined} value
 * @return {!proto.dataocean.Dataset} returns this
*/
proto.dataocean.Dataset.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Dataset} returns this
 */
proto.dataocean.Dataset.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Dataset.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DatasetSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DatasetSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DatasetSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    kind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    parent: (f = msg.getParent()) && meta_meta_pb.ID.toObject(includeInstance, f),
    mimeType: (f = msg.getMimeType()) && dataocean_global_pb.MIMEType.toObject(includeInstance, f),
    source: (f = msg.getSource()) && proto.dataocean.DatasetSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DatasetSpec}
 */
proto.dataocean.DatasetSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DatasetSpec;
  return proto.dataocean.DatasetSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DatasetSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DatasetSpec}
 */
proto.dataocean.DatasetSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dataocean.DatasetKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 2:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setParent(value);
      break;
    case 3:
      var value = new dataocean_global_pb.MIMEType;
      reader.readMessage(value,dataocean_global_pb.MIMEType.deserializeBinaryFromReader);
      msg.setMimeType(value);
      break;
    case 4:
      var value = new proto.dataocean.DatasetSource;
      reader.readMessage(value,proto.dataocean.DatasetSource.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DatasetSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DatasetSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DatasetSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getParent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getMimeType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      dataocean_global_pb.MIMEType.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dataocean.DatasetSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional DatasetKind kind = 1;
 * @return {!proto.dataocean.DatasetKind}
 */
proto.dataocean.DatasetSpec.prototype.getKind = function() {
  return /** @type {!proto.dataocean.DatasetKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataocean.DatasetKind} value
 * @return {!proto.dataocean.DatasetSpec} returns this
 */
proto.dataocean.DatasetSpec.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional meta.ID parent = 2;
 * @return {?proto.meta.ID}
 */
proto.dataocean.DatasetSpec.prototype.getParent = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 2));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.DatasetSpec} returns this
*/
proto.dataocean.DatasetSpec.prototype.setParent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DatasetSpec} returns this
 */
proto.dataocean.DatasetSpec.prototype.clearParent = function() {
  return this.setParent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DatasetSpec.prototype.hasParent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MIMEType mime_type = 3;
 * @return {?proto.dataocean.MIMEType}
 */
proto.dataocean.DatasetSpec.prototype.getMimeType = function() {
  return /** @type{?proto.dataocean.MIMEType} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MIMEType, 3));
};


/**
 * @param {?proto.dataocean.MIMEType|undefined} value
 * @return {!proto.dataocean.DatasetSpec} returns this
*/
proto.dataocean.DatasetSpec.prototype.setMimeType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DatasetSpec} returns this
 */
proto.dataocean.DatasetSpec.prototype.clearMimeType = function() {
  return this.setMimeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DatasetSpec.prototype.hasMimeType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DatasetSource source = 4;
 * @return {?proto.dataocean.DatasetSource}
 */
proto.dataocean.DatasetSpec.prototype.getSource = function() {
  return /** @type{?proto.dataocean.DatasetSource} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DatasetSource, 4));
};


/**
 * @param {?proto.dataocean.DatasetSource|undefined} value
 * @return {!proto.dataocean.DatasetSpec} returns this
*/
proto.dataocean.DatasetSpec.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DatasetSpec} returns this
 */
proto.dataocean.DatasetSpec.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DatasetSpec.prototype.hasSource = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DatasetSource.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DatasetSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DatasetSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelTask: (f = msg.getLabelTask()) && dataocean_labelx_pb.Resource.BlobSource.toObject(includeInstance, f),
    trainSet: (f = msg.getTrainSet()) && dataocean_trainspot_pb.Data.BlobSource.toObject(includeInstance, f),
    testSet: (f = msg.getTestSet()) && dataocean_modelshop_pb.GroundTruth.BlobSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DatasetSource}
 */
proto.dataocean.DatasetSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DatasetSource;
  return proto.dataocean.DatasetSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DatasetSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DatasetSource}
 */
proto.dataocean.DatasetSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dataocean_labelx_pb.Resource.BlobSource;
      reader.readMessage(value,dataocean_labelx_pb.Resource.BlobSource.deserializeBinaryFromReader);
      msg.setLabelTask(value);
      break;
    case 2:
      var value = new dataocean_trainspot_pb.Data.BlobSource;
      reader.readMessage(value,dataocean_trainspot_pb.Data.BlobSource.deserializeBinaryFromReader);
      msg.setTrainSet(value);
      break;
    case 3:
      var value = new dataocean_modelshop_pb.GroundTruth.BlobSource;
      reader.readMessage(value,dataocean_modelshop_pb.GroundTruth.BlobSource.deserializeBinaryFromReader);
      msg.setTestSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DatasetSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DatasetSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DatasetSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelTask();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dataocean_labelx_pb.Resource.BlobSource.serializeBinaryToWriter
    );
  }
  f = message.getTrainSet();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dataocean_trainspot_pb.Data.BlobSource.serializeBinaryToWriter
    );
  }
  f = message.getTestSet();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      dataocean_modelshop_pb.GroundTruth.BlobSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional Resource.BlobSource label_task = 1;
 * @return {?proto.dataocean.Resource.BlobSource}
 */
proto.dataocean.DatasetSource.prototype.getLabelTask = function() {
  return /** @type{?proto.dataocean.Resource.BlobSource} */ (
    jspb.Message.getWrapperField(this, dataocean_labelx_pb.Resource.BlobSource, 1));
};


/**
 * @param {?proto.dataocean.Resource.BlobSource|undefined} value
 * @return {!proto.dataocean.DatasetSource} returns this
*/
proto.dataocean.DatasetSource.prototype.setLabelTask = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DatasetSource} returns this
 */
proto.dataocean.DatasetSource.prototype.clearLabelTask = function() {
  return this.setLabelTask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DatasetSource.prototype.hasLabelTask = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Data.BlobSource train_set = 2;
 * @return {?proto.dataocean.Data.BlobSource}
 */
proto.dataocean.DatasetSource.prototype.getTrainSet = function() {
  return /** @type{?proto.dataocean.Data.BlobSource} */ (
    jspb.Message.getWrapperField(this, dataocean_trainspot_pb.Data.BlobSource, 2));
};


/**
 * @param {?proto.dataocean.Data.BlobSource|undefined} value
 * @return {!proto.dataocean.DatasetSource} returns this
*/
proto.dataocean.DatasetSource.prototype.setTrainSet = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DatasetSource} returns this
 */
proto.dataocean.DatasetSource.prototype.clearTrainSet = function() {
  return this.setTrainSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DatasetSource.prototype.hasTrainSet = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GroundTruth.BlobSource test_set = 3;
 * @return {?proto.dataocean.GroundTruth.BlobSource}
 */
proto.dataocean.DatasetSource.prototype.getTestSet = function() {
  return /** @type{?proto.dataocean.GroundTruth.BlobSource} */ (
    jspb.Message.getWrapperField(this, dataocean_modelshop_pb.GroundTruth.BlobSource, 3));
};


/**
 * @param {?proto.dataocean.GroundTruth.BlobSource|undefined} value
 * @return {!proto.dataocean.DatasetSource} returns this
*/
proto.dataocean.DatasetSource.prototype.setTestSet = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DatasetSource} returns this
 */
proto.dataocean.DatasetSource.prototype.clearTestSet = function() {
  return this.setTestSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DatasetSource.prototype.hasTestSet = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.DatasetStatus.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DatasetStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DatasetStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DatasetStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    committed: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contentSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.dataocean.DatasetVersion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DatasetStatus}
 */
proto.dataocean.DatasetStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DatasetStatus;
  return proto.dataocean.DatasetStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DatasetStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DatasetStatus}
 */
proto.dataocean.DatasetStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCommitted(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setContentSize(value);
      break;
    case 5:
      var value = new proto.dataocean.DatasetVersion;
      reader.readMessage(value,proto.dataocean.DatasetVersion.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DatasetStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DatasetStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DatasetStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommitted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getContentSize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.dataocean.DatasetVersion.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool committed = 1;
 * @return {boolean}
 */
proto.dataocean.DatasetStatus.prototype.getCommitted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.DatasetStatus} returns this
 */
proto.dataocean.DatasetStatus.prototype.setCommitted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int64 count = 3;
 * @return {number}
 */
proto.dataocean.DatasetStatus.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.DatasetStatus} returns this
 */
proto.dataocean.DatasetStatus.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 content_size = 4;
 * @return {number}
 */
proto.dataocean.DatasetStatus.prototype.getContentSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.DatasetStatus} returns this
 */
proto.dataocean.DatasetStatus.prototype.setContentSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated DatasetVersion versions = 5;
 * @return {!Array<!proto.dataocean.DatasetVersion>}
 */
proto.dataocean.DatasetStatus.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.dataocean.DatasetVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.DatasetVersion, 5));
};


/**
 * @param {!Array<!proto.dataocean.DatasetVersion>} value
 * @return {!proto.dataocean.DatasetStatus} returns this
*/
proto.dataocean.DatasetStatus.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.dataocean.DatasetVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.DatasetVersion}
 */
proto.dataocean.DatasetStatus.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.dataocean.DatasetVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.DatasetStatus} returns this
 */
proto.dataocean.DatasetStatus.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.DatasetVersion.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DatasetVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DatasetVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DatasetVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    blobId: (f = msg.getBlobId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contentSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    committed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    dropletsList: jspb.Message.toObjectList(msg.getDropletsList(),
    dataocean_droplet_pb.Droplet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DatasetVersion}
 */
proto.dataocean.DatasetVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DatasetVersion;
  return proto.dataocean.DatasetVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DatasetVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DatasetVersion}
 */
proto.dataocean.DatasetVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setBlobId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setContentSize(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCommitted(value);
      break;
    case 7:
      var value = new dataocean_droplet_pb.Droplet;
      reader.readMessage(value,dataocean_droplet_pb.Droplet.deserializeBinaryFromReader);
      msg.addDroplets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DatasetVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DatasetVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DatasetVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBlobId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getContentSize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCommitted();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getDropletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      dataocean_droplet_pb.Droplet.serializeBinaryToWriter
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.dataocean.DatasetVersion.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.DatasetVersion} returns this
 */
proto.dataocean.DatasetVersion.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional meta.ID blob_id = 2;
 * @return {?proto.meta.ID}
 */
proto.dataocean.DatasetVersion.prototype.getBlobId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 2));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.DatasetVersion} returns this
*/
proto.dataocean.DatasetVersion.prototype.setBlobId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DatasetVersion} returns this
 */
proto.dataocean.DatasetVersion.prototype.clearBlobId = function() {
  return this.setBlobId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DatasetVersion.prototype.hasBlobId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 count = 3;
 * @return {number}
 */
proto.dataocean.DatasetVersion.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.DatasetVersion} returns this
 */
proto.dataocean.DatasetVersion.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 content_size = 4;
 * @return {number}
 */
proto.dataocean.DatasetVersion.prototype.getContentSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.DatasetVersion} returns this
 */
proto.dataocean.DatasetVersion.prototype.setContentSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.DatasetVersion.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.DatasetVersion} returns this
*/
proto.dataocean.DatasetVersion.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DatasetVersion} returns this
 */
proto.dataocean.DatasetVersion.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DatasetVersion.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool committed = 6;
 * @return {boolean}
 */
proto.dataocean.DatasetVersion.prototype.getCommitted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.DatasetVersion} returns this
 */
proto.dataocean.DatasetVersion.prototype.setCommitted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated Droplet droplets = 7;
 * @return {!Array<!proto.dataocean.Droplet>}
 */
proto.dataocean.DatasetVersion.prototype.getDropletsList = function() {
  return /** @type{!Array<!proto.dataocean.Droplet>} */ (
    jspb.Message.getRepeatedWrapperField(this, dataocean_droplet_pb.Droplet, 7));
};


/**
 * @param {!Array<!proto.dataocean.Droplet>} value
 * @return {!proto.dataocean.DatasetVersion} returns this
*/
proto.dataocean.DatasetVersion.prototype.setDropletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.dataocean.Droplet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Droplet}
 */
proto.dataocean.DatasetVersion.prototype.addDroplets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.dataocean.Droplet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.DatasetVersion} returns this
 */
proto.dataocean.DatasetVersion.prototype.clearDropletsList = function() {
  return this.setDropletsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.CreateDatasetReq.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.CreateDatasetReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.CreateDatasetReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.CreateDatasetReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateDatasetReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    meta_meta_pb.ID.toObject, includeInstance),
    source: (f = msg.getSource()) && proto.dataocean.DatasetSource.toObject(includeInstance, f),
    fromDatasetsList: jspb.Message.toObjectList(msg.getFromDatasetsList(),
    meta_meta_pb.ID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.CreateDatasetReq}
 */
proto.dataocean.CreateDatasetReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.CreateDatasetReq;
  return proto.dataocean.CreateDatasetReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.CreateDatasetReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.CreateDatasetReq}
 */
proto.dataocean.CreateDatasetReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.dataocean.DatasetKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    case 4:
      var value = new proto.dataocean.DatasetSource;
      reader.readMessage(value,proto.dataocean.DatasetSource.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 5:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addFromDatasets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.CreateDatasetReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.CreateDatasetReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.CreateDatasetReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateDatasetReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dataocean.DatasetSource.serializeBinaryToWriter
    );
  }
  f = message.getFromDatasetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dataocean.CreateDatasetReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.CreateDatasetReq} returns this
 */
proto.dataocean.CreateDatasetReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DatasetKind kind = 2;
 * @return {!proto.dataocean.DatasetKind}
 */
proto.dataocean.CreateDatasetReq.prototype.getKind = function() {
  return /** @type {!proto.dataocean.DatasetKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.dataocean.DatasetKind} value
 * @return {!proto.dataocean.CreateDatasetReq} returns this
 */
proto.dataocean.CreateDatasetReq.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated meta.ID contents = 3;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.CreateDatasetReq.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 3));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.CreateDatasetReq} returns this
*/
proto.dataocean.CreateDatasetReq.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.CreateDatasetReq.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.CreateDatasetReq} returns this
 */
proto.dataocean.CreateDatasetReq.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};


/**
 * optional DatasetSource source = 4;
 * @return {?proto.dataocean.DatasetSource}
 */
proto.dataocean.CreateDatasetReq.prototype.getSource = function() {
  return /** @type{?proto.dataocean.DatasetSource} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DatasetSource, 4));
};


/**
 * @param {?proto.dataocean.DatasetSource|undefined} value
 * @return {!proto.dataocean.CreateDatasetReq} returns this
*/
proto.dataocean.CreateDatasetReq.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.CreateDatasetReq} returns this
 */
proto.dataocean.CreateDatasetReq.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.CreateDatasetReq.prototype.hasSource = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated meta.ID from_datasets = 5;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.CreateDatasetReq.prototype.getFromDatasetsList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 5));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.CreateDatasetReq} returns this
*/
proto.dataocean.CreateDatasetReq.prototype.setFromDatasetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.CreateDatasetReq.prototype.addFromDatasets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.CreateDatasetReq} returns this
 */
proto.dataocean.CreateDatasetReq.prototype.clearFromDatasetsList = function() {
  return this.setFromDatasetsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.CreateDatasetVersionReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.CreateDatasetVersionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.CreateDatasetVersionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.CreateDatasetVersionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateDatasetVersionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 2, ""),
    s3Path: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contentsList: jspb.Message.toObjectList(msg.getContentsList(),
    meta_meta_pb.ID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.CreateDatasetVersionReq}
 */
proto.dataocean.CreateDatasetVersionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.CreateDatasetVersionReq;
  return proto.dataocean.CreateDatasetVersionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.CreateDatasetVersionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.CreateDatasetVersionReq}
 */
proto.dataocean.CreateDatasetVersionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setS3Path(value);
      break;
    case 4:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addContents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.CreateDatasetVersionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.CreateDatasetVersionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.CreateDatasetVersionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateDatasetVersionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getS3Path();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.CreateDatasetVersionReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.CreateDatasetVersionReq} returns this
*/
proto.dataocean.CreateDatasetVersionReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.CreateDatasetVersionReq} returns this
 */
proto.dataocean.CreateDatasetVersionReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.CreateDatasetVersionReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string version = 2;
 * @return {string}
 */
proto.dataocean.CreateDatasetVersionReq.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.CreateDatasetVersionReq} returns this
 */
proto.dataocean.CreateDatasetVersionReq.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string s3_path = 3;
 * @return {string}
 */
proto.dataocean.CreateDatasetVersionReq.prototype.getS3Path = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.CreateDatasetVersionReq} returns this
 */
proto.dataocean.CreateDatasetVersionReq.prototype.setS3Path = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated meta.ID contents = 4;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.CreateDatasetVersionReq.prototype.getContentsList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 4));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.CreateDatasetVersionReq} returns this
*/
proto.dataocean.CreateDatasetVersionReq.prototype.setContentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.CreateDatasetVersionReq.prototype.addContents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.CreateDatasetVersionReq} returns this
 */
proto.dataocean.CreateDatasetVersionReq.prototype.clearContentsList = function() {
  return this.setContentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GetDatasetReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GetDatasetReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GetDatasetReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetDatasetReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GetDatasetReq}
 */
proto.dataocean.GetDatasetReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GetDatasetReq;
  return proto.dataocean.GetDatasetReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GetDatasetReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GetDatasetReq}
 */
proto.dataocean.GetDatasetReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GetDatasetReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GetDatasetReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GetDatasetReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetDatasetReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.GetDatasetReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.GetDatasetReq} returns this
*/
proto.dataocean.GetDatasetReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.GetDatasetReq} returns this
 */
proto.dataocean.GetDatasetReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.GetDatasetReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDatasetsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDatasetsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDatasetsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    creator: jspb.Message.getFieldWithDefault(msg, 1, ""),
    namePattern: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 3, 0),
    committedOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    uncommittedOnly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDatasetsReq}
 */
proto.dataocean.ListDatasetsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDatasetsReq;
  return proto.dataocean.ListDatasetsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDatasetsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDatasetsReq}
 */
proto.dataocean.ListDatasetsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNamePattern(value);
      break;
    case 3:
      var value = /** @type {!proto.dataocean.DatasetKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCommittedOnly(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUncommittedOnly(value);
      break;
    case 6:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDatasetsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDatasetsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDatasetsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNamePattern();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCommittedOnly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUncommittedOnly();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional string creator = 1;
 * @return {string}
 */
proto.dataocean.ListDatasetsReq.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListDatasetsReq} returns this
 */
proto.dataocean.ListDatasetsReq.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name_pattern = 2;
 * @return {string}
 */
proto.dataocean.ListDatasetsReq.prototype.getNamePattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListDatasetsReq} returns this
 */
proto.dataocean.ListDatasetsReq.prototype.setNamePattern = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DatasetKind kind = 3;
 * @return {!proto.dataocean.DatasetKind}
 */
proto.dataocean.ListDatasetsReq.prototype.getKind = function() {
  return /** @type {!proto.dataocean.DatasetKind} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.dataocean.DatasetKind} value
 * @return {!proto.dataocean.ListDatasetsReq} returns this
 */
proto.dataocean.ListDatasetsReq.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool committed_only = 4;
 * @return {boolean}
 */
proto.dataocean.ListDatasetsReq.prototype.getCommittedOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.ListDatasetsReq} returns this
 */
proto.dataocean.ListDatasetsReq.prototype.setCommittedOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool uncommitted_only = 5;
 * @return {boolean}
 */
proto.dataocean.ListDatasetsReq.prototype.getUncommittedOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.ListDatasetsReq} returns this
 */
proto.dataocean.ListDatasetsReq.prototype.setUncommittedOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional meta.PagerReq pager = 6;
 * @return {?proto.meta.PagerReq}
 */
proto.dataocean.ListDatasetsReq.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 6));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.dataocean.ListDatasetsReq} returns this
*/
proto.dataocean.ListDatasetsReq.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDatasetsReq} returns this
 */
proto.dataocean.ListDatasetsReq.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDatasetsReq.prototype.hasPager = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListDatasetsResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDatasetsResp.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDatasetsResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDatasetsResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetsResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    datasetsList: jspb.Message.toObjectList(msg.getDatasetsList(),
    proto.dataocean.Dataset.toObject, includeInstance),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDatasetsResp}
 */
proto.dataocean.ListDatasetsResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDatasetsResp;
  return proto.dataocean.ListDatasetsResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDatasetsResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDatasetsResp}
 */
proto.dataocean.ListDatasetsResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Dataset;
      reader.readMessage(value,proto.dataocean.Dataset.deserializeBinaryFromReader);
      msg.addDatasets(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDatasetsResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDatasetsResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDatasetsResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetsResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatasetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataocean.Dataset.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Dataset datasets = 1;
 * @return {!Array<!proto.dataocean.Dataset>}
 */
proto.dataocean.ListDatasetsResp.prototype.getDatasetsList = function() {
  return /** @type{!Array<!proto.dataocean.Dataset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.Dataset, 1));
};


/**
 * @param {!Array<!proto.dataocean.Dataset>} value
 * @return {!proto.dataocean.ListDatasetsResp} returns this
*/
proto.dataocean.ListDatasetsResp.prototype.setDatasetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.Dataset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Dataset}
 */
proto.dataocean.ListDatasetsResp.prototype.addDatasets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.Dataset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListDatasetsResp} returns this
 */
proto.dataocean.ListDatasetsResp.prototype.clearDatasetsList = function() {
  return this.setDatasetsList([]);
};


/**
 * optional meta.PagerRes pager = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.dataocean.ListDatasetsResp.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.dataocean.ListDatasetsResp} returns this
*/
proto.dataocean.ListDatasetsResp.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDatasetsResp} returns this
 */
proto.dataocean.ListDatasetsResp.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDatasetsResp.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.RemoveDatasetReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.RemoveDatasetReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.RemoveDatasetReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.RemoveDatasetReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.RemoveDatasetReq}
 */
proto.dataocean.RemoveDatasetReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.RemoveDatasetReq;
  return proto.dataocean.RemoveDatasetReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.RemoveDatasetReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.RemoveDatasetReq}
 */
proto.dataocean.RemoveDatasetReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.RemoveDatasetReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.RemoveDatasetReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.RemoveDatasetReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.RemoveDatasetReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.RemoveDatasetReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.RemoveDatasetReq} returns this
*/
proto.dataocean.RemoveDatasetReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.RemoveDatasetReq} returns this
 */
proto.dataocean.RemoveDatasetReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.RemoveDatasetReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.RemoveDatasetVersionReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.RemoveDatasetVersionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.RemoveDatasetVersionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.RemoveDatasetVersionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.RemoveDatasetVersionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    versionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.RemoveDatasetVersionReq}
 */
proto.dataocean.RemoveDatasetVersionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.RemoveDatasetVersionReq;
  return proto.dataocean.RemoveDatasetVersionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.RemoveDatasetVersionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.RemoveDatasetVersionReq}
 */
proto.dataocean.RemoveDatasetVersionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addVersions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.RemoveDatasetVersionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.RemoveDatasetVersionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.RemoveDatasetVersionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.RemoveDatasetVersionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.RemoveDatasetVersionReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.RemoveDatasetVersionReq} returns this
*/
proto.dataocean.RemoveDatasetVersionReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.RemoveDatasetVersionReq} returns this
 */
proto.dataocean.RemoveDatasetVersionReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.RemoveDatasetVersionReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string versions = 2;
 * @return {!Array<string>}
 */
proto.dataocean.RemoveDatasetVersionReq.prototype.getVersionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.RemoveDatasetVersionReq} returns this
 */
proto.dataocean.RemoveDatasetVersionReq.prototype.setVersionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.RemoveDatasetVersionReq} returns this
 */
proto.dataocean.RemoveDatasetVersionReq.prototype.addVersions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.RemoveDatasetVersionReq} returns this
 */
proto.dataocean.RemoveDatasetVersionReq.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.EditDatasetReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.EditDatasetReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.EditDatasetReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.EditDatasetReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    change: (f = msg.getChange()) && proto.dataocean.DatasetChange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.EditDatasetReq}
 */
proto.dataocean.EditDatasetReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.EditDatasetReq;
  return proto.dataocean.EditDatasetReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.EditDatasetReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.EditDatasetReq}
 */
proto.dataocean.EditDatasetReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new proto.dataocean.DatasetChange;
      reader.readMessage(value,proto.dataocean.DatasetChange.deserializeBinaryFromReader);
      msg.setChange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.EditDatasetReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.EditDatasetReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.EditDatasetReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.EditDatasetReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getChange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.DatasetChange.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.EditDatasetReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.EditDatasetReq} returns this
*/
proto.dataocean.EditDatasetReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.EditDatasetReq} returns this
 */
proto.dataocean.EditDatasetReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.EditDatasetReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DatasetChange change = 2;
 * @return {?proto.dataocean.DatasetChange}
 */
proto.dataocean.EditDatasetReq.prototype.getChange = function() {
  return /** @type{?proto.dataocean.DatasetChange} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DatasetChange, 2));
};


/**
 * @param {?proto.dataocean.DatasetChange|undefined} value
 * @return {!proto.dataocean.EditDatasetReq} returns this
*/
proto.dataocean.EditDatasetReq.prototype.setChange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.EditDatasetReq} returns this
 */
proto.dataocean.EditDatasetReq.prototype.clearChange = function() {
  return this.setChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.EditDatasetReq.prototype.hasChange = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.MergeDatasetsReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.MergeDatasetsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.MergeDatasetsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.MergeDatasetsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.MergeDatasetsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceDatasetsList: jspb.Message.toObjectList(msg.getSourceDatasetsList(),
    meta_meta_pb.ID.toObject, includeInstance),
    targetDataset: (f = msg.getTargetDataset()) && meta_meta_pb.ID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.MergeDatasetsReq}
 */
proto.dataocean.MergeDatasetsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.MergeDatasetsReq;
  return proto.dataocean.MergeDatasetsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.MergeDatasetsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.MergeDatasetsReq}
 */
proto.dataocean.MergeDatasetsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addSourceDatasets(value);
      break;
    case 2:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setTargetDataset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.MergeDatasetsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.MergeDatasetsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.MergeDatasetsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.MergeDatasetsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceDatasetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getTargetDataset();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * repeated meta.ID source_datasets = 1;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.MergeDatasetsReq.prototype.getSourceDatasetsList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.MergeDatasetsReq} returns this
*/
proto.dataocean.MergeDatasetsReq.prototype.setSourceDatasetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.MergeDatasetsReq.prototype.addSourceDatasets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.MergeDatasetsReq} returns this
 */
proto.dataocean.MergeDatasetsReq.prototype.clearSourceDatasetsList = function() {
  return this.setSourceDatasetsList([]);
};


/**
 * optional meta.ID target_dataset = 2;
 * @return {?proto.meta.ID}
 */
proto.dataocean.MergeDatasetsReq.prototype.getTargetDataset = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 2));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.MergeDatasetsReq} returns this
*/
proto.dataocean.MergeDatasetsReq.prototype.setTargetDataset = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.MergeDatasetsReq} returns this
 */
proto.dataocean.MergeDatasetsReq.prototype.clearTargetDataset = function() {
  return this.setTargetDataset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.MergeDatasetsReq.prototype.hasTargetDataset = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.DatasetChange.repeatedFields_ = [1,2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DatasetChange.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DatasetChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DatasetChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    addList: jspb.Message.toObjectList(msg.getAddList(),
    meta_meta_pb.ID.toObject, includeInstance),
    removeList: jspb.Message.toObjectList(msg.getRemoveList(),
    meta_meta_pb.ID.toObject, includeInstance),
    replaceList: jspb.Message.toObjectList(msg.getReplaceList(),
    meta_meta_pb.ID.toObject, includeInstance),
    version: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DatasetChange}
 */
proto.dataocean.DatasetChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DatasetChange;
  return proto.dataocean.DatasetChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DatasetChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DatasetChange}
 */
proto.dataocean.DatasetChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addAdd(value);
      break;
    case 2:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addRemove(value);
      break;
    case 4:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addReplace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DatasetChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DatasetChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DatasetChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DatasetChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getRemoveList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getReplaceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated meta.ID add = 1;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.DatasetChange.prototype.getAddList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.DatasetChange} returns this
*/
proto.dataocean.DatasetChange.prototype.setAddList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.DatasetChange.prototype.addAdd = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.DatasetChange} returns this
 */
proto.dataocean.DatasetChange.prototype.clearAddList = function() {
  return this.setAddList([]);
};


/**
 * repeated meta.ID remove = 2;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.DatasetChange.prototype.getRemoveList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 2));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.DatasetChange} returns this
*/
proto.dataocean.DatasetChange.prototype.setRemoveList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.DatasetChange.prototype.addRemove = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.DatasetChange} returns this
 */
proto.dataocean.DatasetChange.prototype.clearRemoveList = function() {
  return this.setRemoveList([]);
};


/**
 * repeated meta.ID replace = 4;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.DatasetChange.prototype.getReplaceList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 4));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.DatasetChange} returns this
*/
proto.dataocean.DatasetChange.prototype.setReplaceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.DatasetChange.prototype.addReplace = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.DatasetChange} returns this
 */
proto.dataocean.DatasetChange.prototype.clearReplaceList = function() {
  return this.setReplaceList([]);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.dataocean.DatasetChange.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.DatasetChange} returns this
 */
proto.dataocean.DatasetChange.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.CommitDatasetReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.CommitDatasetReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.CommitDatasetReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CommitDatasetReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.CommitDatasetReq}
 */
proto.dataocean.CommitDatasetReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.CommitDatasetReq;
  return proto.dataocean.CommitDatasetReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.CommitDatasetReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.CommitDatasetReq}
 */
proto.dataocean.CommitDatasetReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.CommitDatasetReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.CommitDatasetReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.CommitDatasetReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CommitDatasetReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.CommitDatasetReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.CommitDatasetReq} returns this
*/
proto.dataocean.CommitDatasetReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.CommitDatasetReq} returns this
 */
proto.dataocean.CommitDatasetReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.CommitDatasetReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ReleaseDatasetReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ReleaseDatasetReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ReleaseDatasetReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ReleaseDatasetReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 3, 0),
    source: (f = msg.getSource()) && proto.dataocean.DatasetSource.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ReleaseDatasetReq}
 */
proto.dataocean.ReleaseDatasetReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ReleaseDatasetReq;
  return proto.dataocean.ReleaseDatasetReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ReleaseDatasetReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ReleaseDatasetReq}
 */
proto.dataocean.ReleaseDatasetReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.dataocean.DatasetKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 4:
      var value = new proto.dataocean.DatasetSource;
      reader.readMessage(value,proto.dataocean.DatasetSource.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ReleaseDatasetReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ReleaseDatasetReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ReleaseDatasetReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ReleaseDatasetReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dataocean.DatasetSource.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.ReleaseDatasetReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.ReleaseDatasetReq} returns this
*/
proto.dataocean.ReleaseDatasetReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ReleaseDatasetReq} returns this
 */
proto.dataocean.ReleaseDatasetReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ReleaseDatasetReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.dataocean.ReleaseDatasetReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ReleaseDatasetReq} returns this
 */
proto.dataocean.ReleaseDatasetReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DatasetKind kind = 3;
 * @return {!proto.dataocean.DatasetKind}
 */
proto.dataocean.ReleaseDatasetReq.prototype.getKind = function() {
  return /** @type {!proto.dataocean.DatasetKind} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.dataocean.DatasetKind} value
 * @return {!proto.dataocean.ReleaseDatasetReq} returns this
 */
proto.dataocean.ReleaseDatasetReq.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional DatasetSource source = 4;
 * @return {?proto.dataocean.DatasetSource}
 */
proto.dataocean.ReleaseDatasetReq.prototype.getSource = function() {
  return /** @type{?proto.dataocean.DatasetSource} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DatasetSource, 4));
};


/**
 * @param {?proto.dataocean.DatasetSource|undefined} value
 * @return {!proto.dataocean.ReleaseDatasetReq} returns this
*/
proto.dataocean.ReleaseDatasetReq.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ReleaseDatasetReq} returns this
 */
proto.dataocean.ReleaseDatasetReq.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ReleaseDatasetReq.prototype.hasSource = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDatasetContentsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDatasetContentsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDatasetContentsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetContentsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    page: (f = msg.getPage()) && meta_meta_pb.PagerReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDatasetContentsReq}
 */
proto.dataocean.ListDatasetContentsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDatasetContentsReq;
  return proto.dataocean.ListDatasetContentsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDatasetContentsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDatasetContentsReq}
 */
proto.dataocean.ListDatasetContentsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDatasetContentsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDatasetContentsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDatasetContentsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetContentsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.ListDatasetContentsReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.ListDatasetContentsReq} returns this
*/
proto.dataocean.ListDatasetContentsReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDatasetContentsReq} returns this
 */
proto.dataocean.ListDatasetContentsReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDatasetContentsReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional meta.PagerReq page = 2;
 * @return {?proto.meta.PagerReq}
 */
proto.dataocean.ListDatasetContentsReq.prototype.getPage = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 2));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.dataocean.ListDatasetContentsReq} returns this
*/
proto.dataocean.ListDatasetContentsReq.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDatasetContentsReq} returns this
 */
proto.dataocean.ListDatasetContentsReq.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDatasetContentsReq.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListDatasetContentsResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDatasetContentsResp.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDatasetContentsResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDatasetContentsResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetContentsResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    dropletsList: jspb.Message.toObjectList(msg.getDropletsList(),
    dataocean_droplet_pb.Droplet.toObject, includeInstance),
    page: (f = msg.getPage()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDatasetContentsResp}
 */
proto.dataocean.ListDatasetContentsResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDatasetContentsResp;
  return proto.dataocean.ListDatasetContentsResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDatasetContentsResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDatasetContentsResp}
 */
proto.dataocean.ListDatasetContentsResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dataocean_droplet_pb.Droplet;
      reader.readMessage(value,dataocean_droplet_pb.Droplet.deserializeBinaryFromReader);
      msg.addDroplets(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDatasetContentsResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDatasetContentsResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDatasetContentsResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetContentsResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDropletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      dataocean_droplet_pb.Droplet.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Droplet droplets = 1;
 * @return {!Array<!proto.dataocean.Droplet>}
 */
proto.dataocean.ListDatasetContentsResp.prototype.getDropletsList = function() {
  return /** @type{!Array<!proto.dataocean.Droplet>} */ (
    jspb.Message.getRepeatedWrapperField(this, dataocean_droplet_pb.Droplet, 1));
};


/**
 * @param {!Array<!proto.dataocean.Droplet>} value
 * @return {!proto.dataocean.ListDatasetContentsResp} returns this
*/
proto.dataocean.ListDatasetContentsResp.prototype.setDropletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.Droplet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Droplet}
 */
proto.dataocean.ListDatasetContentsResp.prototype.addDroplets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.Droplet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListDatasetContentsResp} returns this
 */
proto.dataocean.ListDatasetContentsResp.prototype.clearDropletsList = function() {
  return this.setDropletsList([]);
};


/**
 * optional meta.PagerRes page = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.dataocean.ListDatasetContentsResp.prototype.getPage = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.dataocean.ListDatasetContentsResp} returns this
*/
proto.dataocean.ListDatasetContentsResp.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDatasetContentsResp} returns this
 */
proto.dataocean.ListDatasetContentsResp.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDatasetContentsResp.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDatasetVersionsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDatasetVersionsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDatasetVersionsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetVersionsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    withDroplets: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    dropletPage: (f = msg.getDropletPage()) && meta_meta_pb.PagerReq.toObject(includeInstance, f),
    versionPage: (f = msg.getVersionPage()) && meta_meta_pb.PagerReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDatasetVersionsReq}
 */
proto.dataocean.ListDatasetVersionsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDatasetVersionsReq;
  return proto.dataocean.ListDatasetVersionsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDatasetVersionsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDatasetVersionsReq}
 */
proto.dataocean.ListDatasetVersionsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithDroplets(value);
      break;
    case 3:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setDropletPage(value);
      break;
    case 4:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setVersionPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDatasetVersionsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDatasetVersionsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDatasetVersionsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetVersionsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getWithDroplets();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDropletPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
  f = message.getVersionPage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.ListDatasetVersionsReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.ListDatasetVersionsReq} returns this
*/
proto.dataocean.ListDatasetVersionsReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDatasetVersionsReq} returns this
 */
proto.dataocean.ListDatasetVersionsReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDatasetVersionsReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool with_droplets = 2;
 * @return {boolean}
 */
proto.dataocean.ListDatasetVersionsReq.prototype.getWithDroplets = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.ListDatasetVersionsReq} returns this
 */
proto.dataocean.ListDatasetVersionsReq.prototype.setWithDroplets = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional meta.PagerReq droplet_page = 3;
 * @return {?proto.meta.PagerReq}
 */
proto.dataocean.ListDatasetVersionsReq.prototype.getDropletPage = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 3));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.dataocean.ListDatasetVersionsReq} returns this
*/
proto.dataocean.ListDatasetVersionsReq.prototype.setDropletPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDatasetVersionsReq} returns this
 */
proto.dataocean.ListDatasetVersionsReq.prototype.clearDropletPage = function() {
  return this.setDropletPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDatasetVersionsReq.prototype.hasDropletPage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional meta.PagerReq version_page = 4;
 * @return {?proto.meta.PagerReq}
 */
proto.dataocean.ListDatasetVersionsReq.prototype.getVersionPage = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 4));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.dataocean.ListDatasetVersionsReq} returns this
*/
proto.dataocean.ListDatasetVersionsReq.prototype.setVersionPage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDatasetVersionsReq} returns this
 */
proto.dataocean.ListDatasetVersionsReq.prototype.clearVersionPage = function() {
  return this.setVersionPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDatasetVersionsReq.prototype.hasVersionPage = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListDatasetVersionsResp.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDatasetVersionsResp.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDatasetVersionsResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDatasetVersionsResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetVersionsResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    versionsList: jspb.Message.toObjectList(msg.getVersionsList(),
    proto.dataocean.DatasetVersion.toObject, includeInstance),
    page: (f = msg.getPage()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDatasetVersionsResp}
 */
proto.dataocean.ListDatasetVersionsResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDatasetVersionsResp;
  return proto.dataocean.ListDatasetVersionsResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDatasetVersionsResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDatasetVersionsResp}
 */
proto.dataocean.ListDatasetVersionsResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.DatasetVersion;
      reader.readMessage(value,proto.dataocean.DatasetVersion.deserializeBinaryFromReader);
      msg.addVersions(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDatasetVersionsResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDatasetVersionsResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDatasetVersionsResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDatasetVersionsResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataocean.DatasetVersion.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DatasetVersion versions = 1;
 * @return {!Array<!proto.dataocean.DatasetVersion>}
 */
proto.dataocean.ListDatasetVersionsResp.prototype.getVersionsList = function() {
  return /** @type{!Array<!proto.dataocean.DatasetVersion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.DatasetVersion, 1));
};


/**
 * @param {!Array<!proto.dataocean.DatasetVersion>} value
 * @return {!proto.dataocean.ListDatasetVersionsResp} returns this
*/
proto.dataocean.ListDatasetVersionsResp.prototype.setVersionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.DatasetVersion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.DatasetVersion}
 */
proto.dataocean.ListDatasetVersionsResp.prototype.addVersions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.DatasetVersion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListDatasetVersionsResp} returns this
 */
proto.dataocean.ListDatasetVersionsResp.prototype.clearVersionsList = function() {
  return this.setVersionsList([]);
};


/**
 * optional meta.PagerRes page = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.dataocean.ListDatasetVersionsResp.prototype.getPage = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.dataocean.ListDatasetVersionsResp} returns this
*/
proto.dataocean.ListDatasetVersionsResp.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDatasetVersionsResp} returns this
 */
proto.dataocean.ListDatasetVersionsResp.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDatasetVersionsResp.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ExportDatasetReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ExportDatasetReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ExportDatasetReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ExportDatasetReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ExportDatasetReq}
 */
proto.dataocean.ExportDatasetReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ExportDatasetReq;
  return proto.dataocean.ExportDatasetReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ExportDatasetReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ExportDatasetReq}
 */
proto.dataocean.ExportDatasetReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ExportDatasetReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ExportDatasetReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ExportDatasetReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ExportDatasetReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.dataocean.ExportDatasetReq.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.dataocean.ExportDatasetReq} returns this
*/
proto.dataocean.ExportDatasetReq.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ExportDatasetReq} returns this
 */
proto.dataocean.ExportDatasetReq.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ExportDatasetReq.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ExportDatasetResp.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ExportDatasetResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ExportDatasetResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ExportDatasetResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ExportDatasetResp}
 */
proto.dataocean.ExportDatasetResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ExportDatasetResp;
  return proto.dataocean.ExportDatasetResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ExportDatasetResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ExportDatasetResp}
 */
proto.dataocean.ExportDatasetResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ExportDatasetResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ExportDatasetResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ExportDatasetResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ExportDatasetResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes data = 1;
 * @return {string}
 */
proto.dataocean.ExportDatasetResp.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.dataocean.ExportDatasetResp.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.dataocean.ExportDatasetResp.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dataocean.ExportDatasetResp} returns this
 */
proto.dataocean.ExportDatasetResp.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.dataocean.DatasetKind = {
  DATASET_KIND_UNKNOWN: 0,
  DATASET_KIND_WORK_BENCH: 1,
  DATASET_KIND_TRAIN_SET: 2,
  DATASET_KIND_TEST_SET: 3,
  DATASET_KIND_LABEL_TASK: 4,
  DATASET_KIND_MODEL: 5
};

goog.object.extend(exports, proto.dataocean);
