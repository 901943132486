/**
 * @fileoverview gRPC-Web generated client stub for dataocean
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js')

var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js')

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var meta_meta_pb = require('../meta/meta_pb.js')

var dataocean_global_pb = require('../dataocean/global_pb.js')

var dataocean_droplet_pb = require('../dataocean/droplet_pb.js')

var dataocean_atwork_pb = require('../dataocean/atwork_pb.js')

var dataocean_labelx_pb = require('../dataocean/labelx_pb.js')

var dataocean_teststand_pb = require('../dataocean/teststand_pb.js')

var dataocean_trainspot_pb = require('../dataocean/trainspot_pb.js')

var dataocean_modelshop_pb = require('../dataocean/modelshop_pb.js')
const proto = {};
proto.dataocean = require('./blob_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dataocean.BlobServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dataocean.BlobServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.CreateBlobReq,
 *   !proto.dataocean.Blob>}
 */
const methodDescriptor_BlobService_CreateBlob = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/CreateBlob',
  grpc.web.MethodType.UNARY,
  proto.dataocean.CreateBlobReq,
  proto.dataocean.Blob,
  /**
   * @param {!proto.dataocean.CreateBlobReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Blob.deserializeBinary
);


/**
 * @param {!proto.dataocean.CreateBlobReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Blob)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Blob>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.createBlob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/CreateBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_CreateBlob,
      callback);
};


/**
 * @param {!proto.dataocean.CreateBlobReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Blob>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.createBlob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/CreateBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_CreateBlob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.GetBlobReq,
 *   !proto.dataocean.Blob>}
 */
const methodDescriptor_BlobService_GetBlob = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/GetBlob',
  grpc.web.MethodType.UNARY,
  proto.dataocean.GetBlobReq,
  proto.dataocean.Blob,
  /**
   * @param {!proto.dataocean.GetBlobReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Blob.deserializeBinary
);


/**
 * @param {!proto.dataocean.GetBlobReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Blob)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Blob>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.getBlob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/GetBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_GetBlob,
      callback);
};


/**
 * @param {!proto.dataocean.GetBlobReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Blob>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.getBlob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/GetBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_GetBlob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.RemoveBlobReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_BlobService_RemoveBlob = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/RemoveBlob',
  grpc.web.MethodType.UNARY,
  proto.dataocean.RemoveBlobReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.dataocean.RemoveBlobReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.dataocean.RemoveBlobReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.removeBlob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/RemoveBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_RemoveBlob,
      callback);
};


/**
 * @param {!proto.dataocean.RemoveBlobReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.removeBlob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/RemoveBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_RemoveBlob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.ListBlobsReq,
 *   !proto.dataocean.ListBlobsResp>}
 */
const methodDescriptor_BlobService_ListBlobs = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/ListBlobs',
  grpc.web.MethodType.UNARY,
  proto.dataocean.ListBlobsReq,
  proto.dataocean.ListBlobsResp,
  /**
   * @param {!proto.dataocean.ListBlobsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.ListBlobsResp.deserializeBinary
);


/**
 * @param {!proto.dataocean.ListBlobsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.ListBlobsResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.ListBlobsResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.listBlobs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/ListBlobs',
      request,
      metadata || {},
      methodDescriptor_BlobService_ListBlobs,
      callback);
};


/**
 * @param {!proto.dataocean.ListBlobsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.ListBlobsResp>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.listBlobs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/ListBlobs',
      request,
      metadata || {},
      methodDescriptor_BlobService_ListBlobs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.UpdateBlobReq,
 *   !proto.dataocean.Blob>}
 */
const methodDescriptor_BlobService_UpdateBlob = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/UpdateBlob',
  grpc.web.MethodType.UNARY,
  proto.dataocean.UpdateBlobReq,
  proto.dataocean.Blob,
  /**
   * @param {!proto.dataocean.UpdateBlobReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Blob.deserializeBinary
);


/**
 * @param {!proto.dataocean.UpdateBlobReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Blob)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Blob>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.updateBlob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/UpdateBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_UpdateBlob,
      callback);
};


/**
 * @param {!proto.dataocean.UpdateBlobReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Blob>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.updateBlob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/UpdateBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_UpdateBlob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.PublishBlobReq,
 *   !proto.dataocean.Blob>}
 */
const methodDescriptor_BlobService_PublishBlob = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/PublishBlob',
  grpc.web.MethodType.UNARY,
  proto.dataocean.PublishBlobReq,
  proto.dataocean.Blob,
  /**
   * @param {!proto.dataocean.PublishBlobReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Blob.deserializeBinary
);


/**
 * @param {!proto.dataocean.PublishBlobReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Blob)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Blob>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.publishBlob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/PublishBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_PublishBlob,
      callback);
};


/**
 * @param {!proto.dataocean.PublishBlobReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Blob>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.publishBlob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/PublishBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_PublishBlob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.ListLabelxTasksReq,
 *   !proto.dataocean.ListLabelxTasksResp>}
 */
const methodDescriptor_BlobService_ListLabelxTasks = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/ListLabelxTasks',
  grpc.web.MethodType.UNARY,
  proto.dataocean.ListLabelxTasksReq,
  proto.dataocean.ListLabelxTasksResp,
  /**
   * @param {!proto.dataocean.ListLabelxTasksReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.ListLabelxTasksResp.deserializeBinary
);


/**
 * @param {!proto.dataocean.ListLabelxTasksReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.ListLabelxTasksResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.ListLabelxTasksResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.listLabelxTasks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/ListLabelxTasks',
      request,
      metadata || {},
      methodDescriptor_BlobService_ListLabelxTasks,
      callback);
};


/**
 * @param {!proto.dataocean.ListLabelxTasksReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.ListLabelxTasksResp>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.listLabelxTasks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/ListLabelxTasks',
      request,
      metadata || {},
      methodDescriptor_BlobService_ListLabelxTasks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.CheckBlobExistReq,
 *   !proto.dataocean.CheckBlobExistResp>}
 */
const methodDescriptor_BlobService_CheckBlobExist = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/CheckBlobExist',
  grpc.web.MethodType.UNARY,
  proto.dataocean.CheckBlobExistReq,
  proto.dataocean.CheckBlobExistResp,
  /**
   * @param {!proto.dataocean.CheckBlobExistReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.CheckBlobExistResp.deserializeBinary
);


/**
 * @param {!proto.dataocean.CheckBlobExistReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.CheckBlobExistResp)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.CheckBlobExistResp>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.checkBlobExist =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/CheckBlobExist',
      request,
      metadata || {},
      methodDescriptor_BlobService_CheckBlobExist,
      callback);
};


/**
 * @param {!proto.dataocean.CheckBlobExistReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.CheckBlobExistResp>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.checkBlobExist =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/CheckBlobExist',
      request,
      metadata || {},
      methodDescriptor_BlobService_CheckBlobExist);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.TransformBlobReq,
 *   !proto.dataocean.Blob>}
 */
const methodDescriptor_BlobService_TransformBlob = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/TransformBlob',
  grpc.web.MethodType.UNARY,
  proto.dataocean.TransformBlobReq,
  proto.dataocean.Blob,
  /**
   * @param {!proto.dataocean.TransformBlobReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Blob.deserializeBinary
);


/**
 * @param {!proto.dataocean.TransformBlobReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Blob)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Blob>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.transformBlob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/TransformBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_TransformBlob,
      callback);
};


/**
 * @param {!proto.dataocean.TransformBlobReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Blob>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.transformBlob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/TransformBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_TransformBlob);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.GetBlobEnsuredReq,
 *   !proto.dataocean.Blob>}
 */
const methodDescriptor_BlobService_GetBlobEnsured = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/GetBlobEnsured',
  grpc.web.MethodType.UNARY,
  proto.dataocean.GetBlobEnsuredReq,
  proto.dataocean.Blob,
  /**
   * @param {!proto.dataocean.GetBlobEnsuredReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.Blob.deserializeBinary
);


/**
 * @param {!proto.dataocean.GetBlobEnsuredReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.Blob)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.Blob>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.getBlobEnsured =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/GetBlobEnsured',
      request,
      metadata || {},
      methodDescriptor_BlobService_GetBlobEnsured,
      callback);
};


/**
 * @param {!proto.dataocean.GetBlobEnsuredReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.Blob>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.getBlobEnsured =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/GetBlobEnsured',
      request,
      metadata || {},
      methodDescriptor_BlobService_GetBlobEnsured);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.StatisticizeDropletsReq,
 *   !proto.dataocean.StatisticizeDropletsRes>}
 */
const methodDescriptor_BlobService_StatisticizeDroplets = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/StatisticizeDroplets',
  grpc.web.MethodType.UNARY,
  proto.dataocean.StatisticizeDropletsReq,
  proto.dataocean.StatisticizeDropletsRes,
  /**
   * @param {!proto.dataocean.StatisticizeDropletsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.StatisticizeDropletsRes.deserializeBinary
);


/**
 * @param {!proto.dataocean.StatisticizeDropletsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.StatisticizeDropletsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.StatisticizeDropletsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.statisticizeDroplets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/StatisticizeDroplets',
      request,
      metadata || {},
      methodDescriptor_BlobService_StatisticizeDroplets,
      callback);
};


/**
 * @param {!proto.dataocean.StatisticizeDropletsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.StatisticizeDropletsRes>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.statisticizeDroplets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/StatisticizeDroplets',
      request,
      metadata || {},
      methodDescriptor_BlobService_StatisticizeDroplets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.ListDropletIDsReq,
 *   !proto.dataocean.ListDropletIDsRes>}
 */
const methodDescriptor_BlobService_ListDropletIDs = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/ListDropletIDs',
  grpc.web.MethodType.UNARY,
  proto.dataocean.ListDropletIDsReq,
  proto.dataocean.ListDropletIDsRes,
  /**
   * @param {!proto.dataocean.ListDropletIDsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.ListDropletIDsRes.deserializeBinary
);


/**
 * @param {!proto.dataocean.ListDropletIDsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.ListDropletIDsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.ListDropletIDsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.listDropletIDs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/ListDropletIDs',
      request,
      metadata || {},
      methodDescriptor_BlobService_ListDropletIDs,
      callback);
};


/**
 * @param {!proto.dataocean.ListDropletIDsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.ListDropletIDsRes>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.listDropletIDs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/ListDropletIDs',
      request,
      metadata || {},
      methodDescriptor_BlobService_ListDropletIDs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.GetBlobContentReq,
 *   !proto.dataocean.GetBlobContentRes>}
 */
const methodDescriptor_BlobService_GetBlobContent = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/GetBlobContent',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.dataocean.GetBlobContentReq,
  proto.dataocean.GetBlobContentRes,
  /**
   * @param {!proto.dataocean.GetBlobContentReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.GetBlobContentRes.deserializeBinary
);


/**
 * @param {!proto.dataocean.GetBlobContentReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.GetBlobContentRes>}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.getBlobContent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/dataocean.BlobService/GetBlobContent',
      request,
      metadata || {},
      methodDescriptor_BlobService_GetBlobContent);
};


/**
 * @param {!proto.dataocean.GetBlobContentReq} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.GetBlobContentRes>}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServicePromiseClient.prototype.getBlobContent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/dataocean.BlobService/GetBlobContent',
      request,
      metadata || {},
      methodDescriptor_BlobService_GetBlobContent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataocean.ReplicateBlobReq,
 *   !proto.dataocean.ReplicateBlobRes>}
 */
const methodDescriptor_BlobService_ReplicateBlob = new grpc.web.MethodDescriptor(
  '/dataocean.BlobService/ReplicateBlob',
  grpc.web.MethodType.UNARY,
  proto.dataocean.ReplicateBlobReq,
  proto.dataocean.ReplicateBlobRes,
  /**
   * @param {!proto.dataocean.ReplicateBlobReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataocean.ReplicateBlobRes.deserializeBinary
);


/**
 * @param {!proto.dataocean.ReplicateBlobReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataocean.ReplicateBlobRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataocean.ReplicateBlobRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataocean.BlobServiceClient.prototype.replicateBlob =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataocean.BlobService/ReplicateBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_ReplicateBlob,
      callback);
};


/**
 * @param {!proto.dataocean.ReplicateBlobReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataocean.ReplicateBlobRes>}
 *     Promise that resolves to the response
 */
proto.dataocean.BlobServicePromiseClient.prototype.replicateBlob =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataocean.BlobService/ReplicateBlob',
      request,
      metadata || {},
      methodDescriptor_BlobService_ReplicateBlob);
};


module.exports = proto.dataocean;

