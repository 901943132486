// source: dataocean/modelshop.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var dataocean_global_pb = require('../dataocean/global_pb.js');
goog.object.extend(proto, dataocean_global_pb);
goog.exportSymbol('proto.dataocean.Artifact', null, global);
goog.exportSymbol('proto.dataocean.Artifact.ArtifactType', null, global);
goog.exportSymbol('proto.dataocean.Artifact.Source', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth.BlobFilter', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth.BlobSource', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth.BlobUsage', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth.ClassSet', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth.Data', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth.Filter', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth.Label', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth.Source', null, global);
goog.exportSymbol('proto.dataocean.GroundTruth.TaskKind', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GroundTruth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GroundTruth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GroundTruth.displayName = 'proto.dataocean.GroundTruth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GroundTruth.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.GroundTruth.Source.repeatedFields_, null);
};
goog.inherits(proto.dataocean.GroundTruth.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GroundTruth.Source.displayName = 'proto.dataocean.GroundTruth.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GroundTruth.Label = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.GroundTruth.Label.repeatedFields_, null);
};
goog.inherits(proto.dataocean.GroundTruth.Label, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GroundTruth.Label.displayName = 'proto.dataocean.GroundTruth.Label';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GroundTruth.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.GroundTruth.Data.repeatedFields_, null);
};
goog.inherits(proto.dataocean.GroundTruth.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GroundTruth.Data.displayName = 'proto.dataocean.GroundTruth.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GroundTruth.BlobSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.GroundTruth.BlobSource.repeatedFields_, null);
};
goog.inherits(proto.dataocean.GroundTruth.BlobSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GroundTruth.BlobSource.displayName = 'proto.dataocean.GroundTruth.BlobSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GroundTruth.BlobUsage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GroundTruth.BlobUsage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GroundTruth.BlobUsage.displayName = 'proto.dataocean.GroundTruth.BlobUsage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GroundTruth.ClassSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.GroundTruth.ClassSet.repeatedFields_, null);
};
goog.inherits(proto.dataocean.GroundTruth.ClassSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GroundTruth.ClassSet.displayName = 'proto.dataocean.GroundTruth.ClassSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GroundTruth.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GroundTruth.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GroundTruth.Filter.displayName = 'proto.dataocean.GroundTruth.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GroundTruth.BlobFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GroundTruth.BlobFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GroundTruth.BlobFilter.displayName = 'proto.dataocean.GroundTruth.BlobFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Artifact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Artifact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Artifact.displayName = 'proto.dataocean.Artifact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Artifact.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Artifact.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Artifact.Source.displayName = 'proto.dataocean.Artifact.Source';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GroundTruth.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GroundTruth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GroundTruth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GroundTruth}
 */
proto.dataocean.GroundTruth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GroundTruth;
  return proto.dataocean.GroundTruth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GroundTruth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GroundTruth}
 */
proto.dataocean.GroundTruth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GroundTruth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GroundTruth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.dataocean.GroundTruth.TaskKind = {
  UNKNOWN_TASK: 0,
  TASK_KIND_CLASSIFICATION: 1,
  TASK_KIND_DETECTION: 2,
  TASK_KIND_HOI: 3,
  TASK_KIND_LANDMARK: 4,
  TASK_KIND_OCR: 5
};


/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.GroundTruth.Source.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GroundTruth.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GroundTruth.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GroundTruth.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    invalid: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.dataocean.GroundTruth.Label.toObject, includeInstance),
    raw: msg.getRaw_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GroundTruth.Source}
 */
proto.dataocean.GroundTruth.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GroundTruth.Source;
  return proto.dataocean.GroundTruth.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GroundTruth.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GroundTruth.Source}
 */
proto.dataocean.GroundTruth.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvalid(value);
      break;
    case 3:
      var value = new proto.dataocean.GroundTruth.Label;
      reader.readMessage(value,proto.dataocean.GroundTruth.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRaw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GroundTruth.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GroundTruth.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInvalid();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dataocean.GroundTruth.Label.serializeBinaryToWriter
    );
  }
  f = message.getRaw_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.dataocean.GroundTruth.Source.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GroundTruth.Source} returns this
 */
proto.dataocean.GroundTruth.Source.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool invalid = 2;
 * @return {boolean}
 */
proto.dataocean.GroundTruth.Source.prototype.getInvalid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.GroundTruth.Source} returns this
 */
proto.dataocean.GroundTruth.Source.prototype.setInvalid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated Label labels = 3;
 * @return {!Array<!proto.dataocean.GroundTruth.Label>}
 */
proto.dataocean.GroundTruth.Source.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.dataocean.GroundTruth.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.GroundTruth.Label, 3));
};


/**
 * @param {!Array<!proto.dataocean.GroundTruth.Label>} value
 * @return {!proto.dataocean.GroundTruth.Source} returns this
*/
proto.dataocean.GroundTruth.Source.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataocean.GroundTruth.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.GroundTruth.Label}
 */
proto.dataocean.GroundTruth.Source.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataocean.GroundTruth.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.GroundTruth.Source} returns this
 */
proto.dataocean.GroundTruth.Source.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional bytes raw = 4;
 * @return {string}
 */
proto.dataocean.GroundTruth.Source.prototype.getRaw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes raw = 4;
 * This is a type-conversion wrapper around `getRaw()`
 * @return {string}
 */
proto.dataocean.GroundTruth.Source.prototype.getRaw_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRaw()));
};


/**
 * optional bytes raw = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRaw()`
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.Source.prototype.getRaw_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRaw()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dataocean.GroundTruth.Source} returns this
 */
proto.dataocean.GroundTruth.Source.prototype.setRaw = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.GroundTruth.Label.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GroundTruth.Label.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GroundTruth.Label.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GroundTruth.Label} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.Label.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.dataocean.GroundTruth.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GroundTruth.Label}
 */
proto.dataocean.GroundTruth.Label.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GroundTruth.Label;
  return proto.dataocean.GroundTruth.Label.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GroundTruth.Label} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GroundTruth.Label}
 */
proto.dataocean.GroundTruth.Label.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 4:
      var value = new proto.dataocean.GroundTruth.Data;
      reader.readMessage(value,proto.dataocean.GroundTruth.Data.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.Label.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GroundTruth.Label.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GroundTruth.Label} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.Label.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dataocean.GroundTruth.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dataocean.GroundTruth.Label.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GroundTruth.Label} returns this
 */
proto.dataocean.GroundTruth.Label.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.dataocean.GroundTruth.Label.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GroundTruth.Label} returns this
 */
proto.dataocean.GroundTruth.Label.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string version = 3;
 * @return {string}
 */
proto.dataocean.GroundTruth.Label.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GroundTruth.Label} returns this
 */
proto.dataocean.GroundTruth.Label.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Data data = 4;
 * @return {!Array<!proto.dataocean.GroundTruth.Data>}
 */
proto.dataocean.GroundTruth.Label.prototype.getDataList = function() {
  return /** @type{!Array<!proto.dataocean.GroundTruth.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.GroundTruth.Data, 4));
};


/**
 * @param {!Array<!proto.dataocean.GroundTruth.Data>} value
 * @return {!proto.dataocean.GroundTruth.Label} returns this
*/
proto.dataocean.GroundTruth.Label.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dataocean.GroundTruth.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.GroundTruth.Data}
 */
proto.dataocean.GroundTruth.Label.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dataocean.GroundTruth.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.GroundTruth.Label} returns this
 */
proto.dataocean.GroundTruth.Label.prototype.clearDataList = function() {
  return this.setDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.GroundTruth.Data.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GroundTruth.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GroundTruth.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GroundTruth.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    scoresList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 1)) == null ? undefined : f,
    classesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    bboxList: jspb.Message.toObjectList(msg.getBboxList(),
    dataocean_global_pb.Point.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GroundTruth.Data}
 */
proto.dataocean.GroundTruth.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GroundTruth.Data;
  return proto.dataocean.GroundTruth.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GroundTruth.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GroundTruth.Data}
 */
proto.dataocean.GroundTruth.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addScores(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addClasses(value);
      break;
    case 3:
      var value = new dataocean_global_pb.Point;
      reader.readMessage(value,dataocean_global_pb.Point.deserializeBinaryFromReader);
      msg.addBbox(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GroundTruth.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GroundTruth.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScoresList();
  if (f.length > 0) {
    writer.writePackedFloat(
      1,
      f
    );
  }
  f = message.getClassesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getBboxList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      dataocean_global_pb.Point.serializeBinaryToWriter
    );
  }
};


/**
 * repeated float scores = 1;
 * @return {!Array<number>}
 */
proto.dataocean.GroundTruth.Data.prototype.getScoresList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.dataocean.GroundTruth.Data} returns this
 */
proto.dataocean.GroundTruth.Data.prototype.setScoresList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.GroundTruth.Data} returns this
 */
proto.dataocean.GroundTruth.Data.prototype.addScores = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.GroundTruth.Data} returns this
 */
proto.dataocean.GroundTruth.Data.prototype.clearScoresList = function() {
  return this.setScoresList([]);
};


/**
 * repeated string classes = 2;
 * @return {!Array<string>}
 */
proto.dataocean.GroundTruth.Data.prototype.getClassesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.GroundTruth.Data} returns this
 */
proto.dataocean.GroundTruth.Data.prototype.setClassesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.GroundTruth.Data} returns this
 */
proto.dataocean.GroundTruth.Data.prototype.addClasses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.GroundTruth.Data} returns this
 */
proto.dataocean.GroundTruth.Data.prototype.clearClassesList = function() {
  return this.setClassesList([]);
};


/**
 * repeated Point bbox = 3;
 * @return {!Array<!proto.dataocean.Point>}
 */
proto.dataocean.GroundTruth.Data.prototype.getBboxList = function() {
  return /** @type{!Array<!proto.dataocean.Point>} */ (
    jspb.Message.getRepeatedWrapperField(this, dataocean_global_pb.Point, 3));
};


/**
 * @param {!Array<!proto.dataocean.Point>} value
 * @return {!proto.dataocean.GroundTruth.Data} returns this
*/
proto.dataocean.GroundTruth.Data.prototype.setBboxList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataocean.Point=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Point}
 */
proto.dataocean.GroundTruth.Data.prototype.addBbox = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataocean.Point, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.GroundTruth.Data} returns this
 */
proto.dataocean.GroundTruth.Data.prototype.clearBboxList = function() {
  return this.setBboxList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.GroundTruth.BlobSource.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GroundTruth.BlobSource.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GroundTruth.BlobSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GroundTruth.BlobSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.BlobSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    classSetsList: jspb.Message.toObjectList(msg.getClassSetsList(),
    proto.dataocean.GroundTruth.ClassSet.toObject, includeInstance),
    kind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usage: (f = msg.getUsage()) && proto.dataocean.GroundTruth.BlobUsage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GroundTruth.BlobSource}
 */
proto.dataocean.GroundTruth.BlobSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GroundTruth.BlobSource;
  return proto.dataocean.GroundTruth.BlobSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GroundTruth.BlobSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GroundTruth.BlobSource}
 */
proto.dataocean.GroundTruth.BlobSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.GroundTruth.ClassSet;
      reader.readMessage(value,proto.dataocean.GroundTruth.ClassSet.deserializeBinaryFromReader);
      msg.addClassSets(value);
      break;
    case 2:
      var value = /** @type {!proto.dataocean.GroundTruth.TaskKind} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 3:
      var value = new proto.dataocean.GroundTruth.BlobUsage;
      reader.readMessage(value,proto.dataocean.GroundTruth.BlobUsage.deserializeBinaryFromReader);
      msg.setUsage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.BlobSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GroundTruth.BlobSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GroundTruth.BlobSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.BlobSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassSetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataocean.GroundTruth.ClassSet.serializeBinaryToWriter
    );
  }
  f = message.getKind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getUsage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.GroundTruth.BlobUsage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ClassSet class_sets = 1;
 * @return {!Array<!proto.dataocean.GroundTruth.ClassSet>}
 */
proto.dataocean.GroundTruth.BlobSource.prototype.getClassSetsList = function() {
  return /** @type{!Array<!proto.dataocean.GroundTruth.ClassSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.GroundTruth.ClassSet, 1));
};


/**
 * @param {!Array<!proto.dataocean.GroundTruth.ClassSet>} value
 * @return {!proto.dataocean.GroundTruth.BlobSource} returns this
*/
proto.dataocean.GroundTruth.BlobSource.prototype.setClassSetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.GroundTruth.ClassSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.GroundTruth.ClassSet}
 */
proto.dataocean.GroundTruth.BlobSource.prototype.addClassSets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.GroundTruth.ClassSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.GroundTruth.BlobSource} returns this
 */
proto.dataocean.GroundTruth.BlobSource.prototype.clearClassSetsList = function() {
  return this.setClassSetsList([]);
};


/**
 * optional TaskKind kind = 2;
 * @return {!proto.dataocean.GroundTruth.TaskKind}
 */
proto.dataocean.GroundTruth.BlobSource.prototype.getKind = function() {
  return /** @type {!proto.dataocean.GroundTruth.TaskKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.dataocean.GroundTruth.TaskKind} value
 * @return {!proto.dataocean.GroundTruth.BlobSource} returns this
 */
proto.dataocean.GroundTruth.BlobSource.prototype.setKind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional BlobUsage usage = 3;
 * @return {?proto.dataocean.GroundTruth.BlobUsage}
 */
proto.dataocean.GroundTruth.BlobSource.prototype.getUsage = function() {
  return /** @type{?proto.dataocean.GroundTruth.BlobUsage} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.GroundTruth.BlobUsage, 3));
};


/**
 * @param {?proto.dataocean.GroundTruth.BlobUsage|undefined} value
 * @return {!proto.dataocean.GroundTruth.BlobSource} returns this
*/
proto.dataocean.GroundTruth.BlobSource.prototype.setUsage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.GroundTruth.BlobSource} returns this
 */
proto.dataocean.GroundTruth.BlobSource.prototype.clearUsage = function() {
  return this.setUsage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.GroundTruth.BlobSource.prototype.hasUsage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GroundTruth.BlobUsage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GroundTruth.BlobUsage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.BlobUsage.toObject = function(includeInstance, msg) {
  var f, obj = {
    groundTruth: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    inferenceResult: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    dedicated: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    dedicatedJobId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GroundTruth.BlobUsage}
 */
proto.dataocean.GroundTruth.BlobUsage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GroundTruth.BlobUsage;
  return proto.dataocean.GroundTruth.BlobUsage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GroundTruth.BlobUsage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GroundTruth.BlobUsage}
 */
proto.dataocean.GroundTruth.BlobUsage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGroundTruth(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInferenceResult(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDedicated(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDedicatedJobId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GroundTruth.BlobUsage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GroundTruth.BlobUsage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.BlobUsage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroundTruth();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getInferenceResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDedicated();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDedicatedJobId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool ground_truth = 1;
 * @return {boolean}
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.getGroundTruth = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.GroundTruth.BlobUsage} returns this
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.setGroundTruth = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool inference_result = 2;
 * @return {boolean}
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.getInferenceResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.GroundTruth.BlobUsage} returns this
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.setInferenceResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool dedicated = 3;
 * @return {boolean}
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.getDedicated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.GroundTruth.BlobUsage} returns this
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.setDedicated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string dedicated_job_id = 4;
 * @return {string}
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.getDedicatedJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GroundTruth.BlobUsage} returns this
 */
proto.dataocean.GroundTruth.BlobUsage.prototype.setDedicatedJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.GroundTruth.ClassSet.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GroundTruth.ClassSet.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GroundTruth.ClassSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GroundTruth.ClassSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.ClassSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    classList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GroundTruth.ClassSet}
 */
proto.dataocean.GroundTruth.ClassSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GroundTruth.ClassSet;
  return proto.dataocean.GroundTruth.ClassSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GroundTruth.ClassSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GroundTruth.ClassSet}
 */
proto.dataocean.GroundTruth.ClassSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addClass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.ClassSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GroundTruth.ClassSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GroundTruth.ClassSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.ClassSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string class = 1;
 * @return {!Array<string>}
 */
proto.dataocean.GroundTruth.ClassSet.prototype.getClassList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.GroundTruth.ClassSet} returns this
 */
proto.dataocean.GroundTruth.ClassSet.prototype.setClassList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.GroundTruth.ClassSet} returns this
 */
proto.dataocean.GroundTruth.ClassSet.prototype.addClass = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.GroundTruth.ClassSet} returns this
 */
proto.dataocean.GroundTruth.ClassSet.prototype.clearClassList = function() {
  return this.setClassList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GroundTruth.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GroundTruth.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GroundTruth.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GroundTruth.Filter}
 */
proto.dataocean.GroundTruth.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GroundTruth.Filter;
  return proto.dataocean.GroundTruth.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GroundTruth.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GroundTruth.Filter}
 */
proto.dataocean.GroundTruth.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GroundTruth.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GroundTruth.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.dataocean.GroundTruth.Filter.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GroundTruth.Filter} returns this
 */
proto.dataocean.GroundTruth.Filter.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GroundTruth.BlobFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GroundTruth.BlobFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GroundTruth.BlobFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.BlobFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    groundTruth: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GroundTruth.BlobFilter}
 */
proto.dataocean.GroundTruth.BlobFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GroundTruth.BlobFilter;
  return proto.dataocean.GroundTruth.BlobFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GroundTruth.BlobFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GroundTruth.BlobFilter}
 */
proto.dataocean.GroundTruth.BlobFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGroundTruth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GroundTruth.BlobFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GroundTruth.BlobFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GroundTruth.BlobFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GroundTruth.BlobFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroundTruth();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool ground_truth = 1;
 * @return {boolean}
 */
proto.dataocean.GroundTruth.BlobFilter.prototype.getGroundTruth = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.GroundTruth.BlobFilter} returns this
 */
proto.dataocean.GroundTruth.BlobFilter.prototype.setGroundTruth = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Artifact.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Artifact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Artifact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Artifact.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Artifact}
 */
proto.dataocean.Artifact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Artifact;
  return proto.dataocean.Artifact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Artifact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Artifact}
 */
proto.dataocean.Artifact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Artifact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Artifact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Artifact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Artifact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.dataocean.Artifact.ArtifactType = {
  UNKNOWN_ARTIFACT_TYPE: 0,
  ARTIFACT_TYPE_ARCHITECTURE: 1,
  ARTIFACT_TYPE_PTH_MODEL: 2,
  ARTIFACT_TYPE_RAW_TRON_MODEL: 3,
  ARTIFACT_TYPE_QUANTIZED_TRON_MODEL: 4,
  ARTIFACT_TYPE_OM_MODEL: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Artifact.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Artifact.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Artifact.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Artifact.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    artifactType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Artifact.Source}
 */
proto.dataocean.Artifact.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Artifact.Source;
  return proto.dataocean.Artifact.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Artifact.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Artifact.Source}
 */
proto.dataocean.Artifact.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.dataocean.Artifact.ArtifactType} */ (reader.readEnum());
      msg.setArtifactType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Artifact.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Artifact.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Artifact.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Artifact.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getArtifactType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.Artifact.Source.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Artifact.Source} returns this
 */
proto.dataocean.Artifact.Source.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ArtifactType artifact_type = 2;
 * @return {!proto.dataocean.Artifact.ArtifactType}
 */
proto.dataocean.Artifact.Source.prototype.getArtifactType = function() {
  return /** @type {!proto.dataocean.Artifact.ArtifactType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.dataocean.Artifact.ArtifactType} value
 * @return {!proto.dataocean.Artifact.Source} returns this
 */
proto.dataocean.Artifact.Source.prototype.setArtifactType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


goog.object.extend(exports, proto.dataocean);
