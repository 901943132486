import { Badge, Breadcrumb, Dropdown, Layout, Menu, Row } from 'antd';
import Api from 'api/Api';
import { cloneDeep, compact, last, reverse } from 'lodash';
import { observer } from 'mobx-react';
import React, { PropsWithChildren, useEffect } from 'react';
import { Link, Outlet, matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/components/RequireLogin';
import { userPortalStore } from 'src/stores/userPortal';
import logo from '../../assets/atom.svg';
import userIcon from '../../assets/user.svg';

import './UserPortal.less';
import classNames from 'classnames';
import { Role } from 'utils/constants';

const { Header, Sider, Content } = Layout;

const joinPaths = (paths: string[]): string => paths.join('/').replace(/\/\/+/g, '/');

const fullPath = (route: any, routes: any[]) => {
  const paths = [];
  while (route) {
    paths.unshift(route.path);
    route = findNode(routes, route.parentId);
  }

  function findNode(data: any[], id: string): any {
    // 遍历数据中的每一项
    for (const item of data) {
      // 如果找到匹配的节点ID，直接返回该节点
      if (item.id === id) {
        return item;
      }

      // 如果当前项有子项（通常是一个数组），递归调用 findNodeById 函数
      if (item.children && item.children.length > 0) {
        const foundNode = findNode(item.children, id);
        if (foundNode) {
          return foundNode;
        }
      }
    }

    // 如果没有找到匹配的节点，返回 null 或者适合你的错误处理方式
    return null;
  }

  return joinPaths.call(null, [...paths]);
};

const addParentId = (data: any[]) => {
  let id = 0;

  function addId(data: any[], parentId = null) {
    // 遍历数据中的每一项
    data.forEach((item) => {
      item.parentId = parentId;
      item.id = id++;

      // 如果当前项有子项（通常是一个数组），递归调用 addParentId 函数
      if (item.children && item.children.length > 0) {
        addId(item.children, item.id); // 使用 id 作为 parentId
      }
    });
  }

  addId(data);
};

export const UserPortal: React.FC<PropsWithChildren> = observer(() => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const location = useLocation();

  const menuRoutes = userPortalStore.routes.find((route) => route.path === '/user_portal')?.children!;

  addParentId(menuRoutes);

  const matches = matchRoutes(userPortalStore.routes, location);

  const generateMenu = (menus?: any[]) => {
    return menus
      ?.filter((v) => v.name && v.visible !== false)
      .map((item: any, index: number) => {
        if (Array.isArray(item.children) && item.children.some((v: any) => v.name)) {
          return (
            <Menu.SubMenu
              icon={item.icon}
              className={item.className}
              key={fullPath(item, menuRoutes)}
              title={item.name}
              onTitleClick={() => {
                if (item.children.some((v: any) => v.index === true)) {
                  navigate(item.path);
                }
              }}
            >
              {generateMenu(item.children)}
            </Menu.SubMenu>
          );
        }

        return (
          <Menu.Item icon={item.icon} key={fullPath(item, menuRoutes)}>
            <Link target={item.target || undefined} to={item.to ? item.to : fullPath(item, menuRoutes)}>
              {item.name}
            </Link>
          </Menu.Item>
        );
      });
  };

  const loadData = async () => {
    if (userPortalStore.user.tenant_name) {
      userPortalStore.fetchApplications();
      userPortalStore.fetchOrganization();
    }

    userPortalStore.fetchInvitations();
  };

  useEffect(() => {
    if (matches) {
      const routes = reverse(cloneDeep(matches));

      const [current, ...rest] = routes;

      userPortalStore.selectedKey = fullPath(current.route, menuRoutes);
      userPortalStore.openKeys = rest.map((v) => fullPath(v.route, menuRoutes));

      // @ts-ignore
      userPortalStore.breadcrumbs = compact(matches.map((v) => v.route.name));
    }
  }, [location.pathname]);

  useEffect(() => {
    loadData();
  }, []);

  const hasService =
    Reflect.has(userPortalStore.org, 'name') &&
    Reflect.has(userPortalStore.org, 'services') &&
    userPortalStore.org.services?.length;

  const noticeCount = userPortalStore.invitations.length;

  return (
    <Layout className="user-portal">
      <Header className="header">
        <div className="logo">
          <img src={logo} alt="logo" style={{ marginRight: 10 }} />
          Atom 用户中心
        </div>
        <Row justify={'end'}>
          {/* <Space> */}
          <Dropdown
            overlay={
              <Menu
                className="atom-menu"
                onClick={async (param) => {
                  if (param.key === 'logout') {
                    await Api.user.logout();
                    navigate('/');
                  }
                }}
              >
                <Menu.Item key="account">
                  <Link to="account">账号信息</Link>
                </Menu.Item>
                <Menu.Item key="org">
                  <Link to="orgs">隶属单位</Link>
                  <Badge count={userPortalStore.invitations.length} size="small">
                    &nbsp;
                  </Badge>
                </Menu.Item>
                {(userPortalStore.user?.system_roles?.includes(Role.UserAdmin) ||
                  userPortalStore.user?.system_roles?.includes(Role.OrganizationAdmin) ||
                  userPortalStore.user?.system_roles?.includes(Role.ImpersonationAdmin)) && (
                  <Menu.Item key="admin">
                    <Link to="/admin_portal">管理控制台</Link>
                  </Menu.Item>
                )}
                <Menu.Item key="help">
                  <Link to="https://atom.supremind.com/hc/usercenter.html">帮助中心</Link>
                </Menu.Item>
                <Menu.Item key="logout">
                  <span>登出</span>
                </Menu.Item>
              </Menu>
            }
          >
            <span>
              {/* <Avatar size="small" alt="avatar"><UserOutlined /></Avatar> */}
              <Badge count={noticeCount} dot>
                <img src={userIcon} alt="" />
              </Badge>
              <span style={{ marginLeft: 10 }}>{userPortalStore.user?.fullname}</span>
            </span>
          </Dropdown>
          {/* </Space> */}
        </Row>
      </Header>
      <Layout className="ant-layout-has-sider">
        <Sider width={200} className="site-layout-side" style={{ paddingTop: 10 }}>
          <Menu
            className="nav"
            mode="inline"
            selectedKeys={[userPortalStore.selectedKey]}
            openKeys={userPortalStore.openKeys}
            style={{ height: '100%', borderRight: 0 }}
            onOpenChange={(openKeys) => {
              userPortalStore.openKeys = openKeys as string[];
            }}
          >
            {generateMenu(menuRoutes)}
          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px', backgroundColor: '#f5f7fb' }}>
          {last(matches)?.route.breadcrumbVisible !== false && (
            <Breadcrumb
              className={classNames('atom-breadcrumb', userPortalStore.breadcrumbs.length > 1 ? 'multi' : '')}
              style={{ margin: '16px 0' }}
            >
              {userPortalStore.breadcrumbs.map((v, idx) => {
                return <Breadcrumb.Item key={idx}>{v}</Breadcrumb.Item>;
              })}
            </Breadcrumb>
          )}
          <Content
            id="mainContent"
            className="site-layout-content"
            style={{
              margin: 0,
              minHeight: 280
            }}
          >
            {/* {!hasService && <Navigate replace to="welcome" />} */}
            <Outlet></Outlet>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
});
