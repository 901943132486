/**
 * @fileoverview gRPC-Web generated client stub for atom.v2
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js')

var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var meta_meta_pb = require('../../meta/meta_pb.js')
const proto = {};
proto.atom = {};
proto.atom.v2 = require('./product_key_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.ProductKeyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.ProductKeyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.ListProductsKeyReq,
 *   !proto.atom.v2.ListProductsKeyRes>}
 */
const methodDescriptor_ProductKeyService_ListProductsKey = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductKeyService/ListProductsKey',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.ListProductsKeyReq,
  proto.atom.v2.ListProductsKeyRes,
  /**
   * @param {!proto.atom.v2.ListProductsKeyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ListProductsKeyRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.ListProductsKeyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ListProductsKeyRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ListProductsKeyRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductKeyServiceClient.prototype.listProductsKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductKeyService/ListProductsKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_ListProductsKey,
      callback);
};


/**
 * @param {!proto.atom.v2.ListProductsKeyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ListProductsKeyRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductKeyServicePromiseClient.prototype.listProductsKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductKeyService/ListProductsKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_ListProductsKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.GetProductKeyReq,
 *   !proto.atom.v2.ProductKey>}
 */
const methodDescriptor_ProductKeyService_GetProductKey = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductKeyService/GetProductKey',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.GetProductKeyReq,
  proto.atom.v2.ProductKey,
  /**
   * @param {!proto.atom.v2.GetProductKeyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ProductKey.deserializeBinary
);


/**
 * @param {!proto.atom.v2.GetProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ProductKey)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ProductKey>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductKeyServiceClient.prototype.getProductKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductKeyService/GetProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_GetProductKey,
      callback);
};


/**
 * @param {!proto.atom.v2.GetProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ProductKey>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductKeyServicePromiseClient.prototype.getProductKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductKeyService/GetProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_GetProductKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.CreateProductKeyReq,
 *   !proto.atom.v2.ProductKey>}
 */
const methodDescriptor_ProductKeyService_CreateProductKey = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductKeyService/CreateProductKey',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.CreateProductKeyReq,
  proto.atom.v2.ProductKey,
  /**
   * @param {!proto.atom.v2.CreateProductKeyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ProductKey.deserializeBinary
);


/**
 * @param {!proto.atom.v2.CreateProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ProductKey)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ProductKey>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductKeyServiceClient.prototype.createProductKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductKeyService/CreateProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_CreateProductKey,
      callback);
};


/**
 * @param {!proto.atom.v2.CreateProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ProductKey>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductKeyServicePromiseClient.prototype.createProductKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductKeyService/CreateProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_CreateProductKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.UpdateProductKeyReq,
 *   !proto.atom.v2.ProductKey>}
 */
const methodDescriptor_ProductKeyService_UpdateProductKey = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductKeyService/UpdateProductKey',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.UpdateProductKeyReq,
  proto.atom.v2.ProductKey,
  /**
   * @param {!proto.atom.v2.UpdateProductKeyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ProductKey.deserializeBinary
);


/**
 * @param {!proto.atom.v2.UpdateProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ProductKey)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ProductKey>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductKeyServiceClient.prototype.updateProductKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductKeyService/UpdateProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_UpdateProductKey,
      callback);
};


/**
 * @param {!proto.atom.v2.UpdateProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ProductKey>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductKeyServicePromiseClient.prototype.updateProductKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductKeyService/UpdateProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_UpdateProductKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.RemoveProductKeyReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ProductKeyService_RemoveProductKey = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductKeyService/RemoveProductKey',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.RemoveProductKeyReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.atom.v2.RemoveProductKeyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.atom.v2.RemoveProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductKeyServiceClient.prototype.removeProductKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductKeyService/RemoveProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_RemoveProductKey,
      callback);
};


/**
 * @param {!proto.atom.v2.RemoveProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductKeyServicePromiseClient.prototype.removeProductKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductKeyService/RemoveProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_RemoveProductKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.SearchProductKeyReq,
 *   !proto.atom.v2.SearchProductKeyRes>}
 */
const methodDescriptor_ProductKeyService_SearchProductKey = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductKeyService/SearchProductKey',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.SearchProductKeyReq,
  proto.atom.v2.SearchProductKeyRes,
  /**
   * @param {!proto.atom.v2.SearchProductKeyReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.SearchProductKeyRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.SearchProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.SearchProductKeyRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.SearchProductKeyRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductKeyServiceClient.prototype.searchProductKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductKeyService/SearchProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_SearchProductKey,
      callback);
};


/**
 * @param {!proto.atom.v2.SearchProductKeyReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.SearchProductKeyRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductKeyServicePromiseClient.prototype.searchProductKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductKeyService/SearchProductKey',
      request,
      metadata || {},
      methodDescriptor_ProductKeyService_SearchProductKey);
};


module.exports = proto.atom.v2;

