import Api from 'api/Api';
import { Pricing } from 'atom-proto/web/atom/v2/pricing_pb';
import { makeAutoObservable } from 'mobx';
import { initGRPC } from 'utils/grpcUtil';

export class GlobalStore {
  constructor(
    public pricingList: Pricing.AsObject[] = [],
    public grpc_endpoint: string = '',
    public oauth_login_url: string = '',
    public chat_host: string = ''
  ) {
    makeAutoObservable(this);
  }
  async init() {
    const appConfig = await Api.config.getAppConfig();
    const configResult = (await appConfig.json()) as {
      env: string;
      grpc_endpoint: string;
      oauth_login_url: string;
      chat_host: string;
    };
    this.grpc_endpoint = configResult.grpc_endpoint;
    this.oauth_login_url = configResult.oauth_login_url;
    this.chat_host = configResult.chat_host;
    initGRPC(configResult.grpc_endpoint);
  }
  set(pricingList: Pricing.AsObject[]) {
    this.pricingList = pricingList;
  }
}

// 导出实例
const globalStore = new GlobalStore();
export default globalStore;
