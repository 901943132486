/**
 * @fileoverview gRPC-Web generated client stub for atom.v2
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js')

var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var meta_meta_pb = require('../../meta/meta_pb.js')
const proto = {};
proto.atom = {};
proto.atom.v2 = require('./pricing_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.PricingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.PricingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.ListPricingsReq,
 *   !proto.atom.v2.ListPricingsRes>}
 */
const methodDescriptor_PricingService_ListPricings = new grpc.web.MethodDescriptor(
  '/atom.v2.PricingService/ListPricings',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.ListPricingsReq,
  proto.atom.v2.ListPricingsRes,
  /**
   * @param {!proto.atom.v2.ListPricingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ListPricingsRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.ListPricingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ListPricingsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ListPricingsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.PricingServiceClient.prototype.listPricings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.PricingService/ListPricings',
      request,
      metadata || {},
      methodDescriptor_PricingService_ListPricings,
      callback);
};


/**
 * @param {!proto.atom.v2.ListPricingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ListPricingsRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.PricingServicePromiseClient.prototype.listPricings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.PricingService/ListPricings',
      request,
      metadata || {},
      methodDescriptor_PricingService_ListPricings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.GetPricingReq,
 *   !proto.atom.v2.Pricing>}
 */
const methodDescriptor_PricingService_GetPricing = new grpc.web.MethodDescriptor(
  '/atom.v2.PricingService/GetPricing',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.GetPricingReq,
  proto.atom.v2.Pricing,
  /**
   * @param {!proto.atom.v2.GetPricingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Pricing.deserializeBinary
);


/**
 * @param {!proto.atom.v2.GetPricingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Pricing)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Pricing>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.PricingServiceClient.prototype.getPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.PricingService/GetPricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_GetPricing,
      callback);
};


/**
 * @param {!proto.atom.v2.GetPricingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Pricing>}
 *     Promise that resolves to the response
 */
proto.atom.v2.PricingServicePromiseClient.prototype.getPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.PricingService/GetPricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_GetPricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.CreatePricingReq,
 *   !proto.atom.v2.Pricing>}
 */
const methodDescriptor_PricingService_CreatePricing = new grpc.web.MethodDescriptor(
  '/atom.v2.PricingService/CreatePricing',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.CreatePricingReq,
  proto.atom.v2.Pricing,
  /**
   * @param {!proto.atom.v2.CreatePricingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Pricing.deserializeBinary
);


/**
 * @param {!proto.atom.v2.CreatePricingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Pricing)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Pricing>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.PricingServiceClient.prototype.createPricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.PricingService/CreatePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_CreatePricing,
      callback);
};


/**
 * @param {!proto.atom.v2.CreatePricingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Pricing>}
 *     Promise that resolves to the response
 */
proto.atom.v2.PricingServicePromiseClient.prototype.createPricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.PricingService/CreatePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_CreatePricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.UpdatePricingReq,
 *   !proto.atom.v2.Pricing>}
 */
const methodDescriptor_PricingService_UpdatePricing = new grpc.web.MethodDescriptor(
  '/atom.v2.PricingService/UpdatePricing',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.UpdatePricingReq,
  proto.atom.v2.Pricing,
  /**
   * @param {!proto.atom.v2.UpdatePricingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.Pricing.deserializeBinary
);


/**
 * @param {!proto.atom.v2.UpdatePricingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.Pricing)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.Pricing>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.PricingServiceClient.prototype.updatePricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.PricingService/UpdatePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_UpdatePricing,
      callback);
};


/**
 * @param {!proto.atom.v2.UpdatePricingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.Pricing>}
 *     Promise that resolves to the response
 */
proto.atom.v2.PricingServicePromiseClient.prototype.updatePricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.PricingService/UpdatePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_UpdatePricing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.RemovePricingReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_PricingService_RemovePricing = new grpc.web.MethodDescriptor(
  '/atom.v2.PricingService/RemovePricing',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.RemovePricingReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.atom.v2.RemovePricingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.atom.v2.RemovePricingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.PricingServiceClient.prototype.removePricing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.PricingService/RemovePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_RemovePricing,
      callback);
};


/**
 * @param {!proto.atom.v2.RemovePricingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.atom.v2.PricingServicePromiseClient.prototype.removePricing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.PricingService/RemovePricing',
      request,
      metadata || {},
      methodDescriptor_PricingService_RemovePricing);
};


module.exports = proto.atom.v2;

