import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { makeAutoObservable, toJS } from 'mobx';
import { Button, DatePicker, Input, PageHeader, Select } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { BarChart } from 'components/charts/BarChart';
import { getNormalizedPrice } from 'utils/priceUtil';
import * as _ from 'lodash';
const { RangePicker } = DatePicker;
class Status {
  calendarDate: moment.Moment[] = [moment().subtract(1, 'months'), moment()];
  seriesData: { value: number[]; name: string; type: string; stack: string }[] = [];
  xAxisData: string[] = [];
  constructor() {
    makeAutoObservable(this);
  }
}

const status = new Status();

const MonitorPage: React.FC = observer(() => {
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    const data = {
      a: [
        { date: 1736956800, data: 10 },
        { date: 1737043200, data: 2 },
        { date: 1737129600, data: 3 }
      ],
      b: [
        { date: 1736956800, data: 25 },
        { date: 1737043200, data: 1 },
        { date: 1737129600, data: 35 }
      ],
      c: [
        { date: 1736956800, data: 2 },
        { date: 1737043200, data: 45 },
        { date: 1737129600, data: 3 }
      ]
    };
    getSeriesData(data);
  };

  const getSeriesData = (data: { [key: string]: { date: number; data: number }[] }): any => {
    const calendarDate = _.cloneDeep(status.calendarDate);
    const start = calendarDate?.[0];
    const end = calendarDate?.[1];

    if (!start || !end) {
      return;
    }

    const uniqueDates: string[] = [];
    let currentDate = start;
    while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
      uniqueDates.push(currentDate.format('MM/DD'));
      currentDate = currentDate.add(1, 'day');
    }

    const completeData = Object.entries(data).reduce(
      (acc, [category, records]) => {
        const dateMap = new Map(records.map(({ date, data }) => [moment(date * 1000).format('MM/DD'), data]));
        const filledData = uniqueDates.map((date) => dateMap.get(date) || 0);
        acc[category] = filledData;
        return acc;
      },
      {} as { [key: string]: number[] }
    );

    const total = uniqueDates.map((_, index) =>
      Object.values(completeData).reduce((sum, values) => sum + values[index], 0)
    );

    const seriesData = Object.keys(data).map((category) => ({
      value: completeData[category],
      name: category,
      type: 'line',
      stack: category
    }));

    seriesData.unshift({
      value: total,
      name: 'total',
      type: 'bar',
      stack: 'total'
    });
    status.xAxisData = [...uniqueDates];
    status.seriesData = seriesData;
  };
  return (
    <PageHeader style={{ background: '#fff' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <div>
          <span>选择日期：</span>{' '}
          <RangePicker
            value={toJS(status.calendarDate) as any}
            style={{ maxWidth: 600, marginRight: 10 }}
            disabledDate={disabledDate}
            onChange={(date) => {
              if (date?.[0] && date[1] && date[0].isSame(date[1], 'day')) {
                date[0] = moment(date[0]).subtract(1, 'day');
              }
              status.calendarDate = date;
              fetchData();
            }}
          />
          <Button
            type="primary"
            onClick={() => {
              status.calendarDate = [moment().subtract(1, 'months'), moment()];
              fetchData();
            }}
          >
            最近30天
          </Button>
        </div>
      </div>
      <div style={{ margin: '40px 0', color: '#7F7F7F', fontWeight: 650, fontSize: 14 }}>调用量统计</div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <BarChart
          key="overview"
          noData={false}
          xAxisData={status.xAxisData}
          data={status.seriesData}
          showLegend={true}
        />
      </div>
    </PageHeader>
  );
});

export default MonitorPage;
