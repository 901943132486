// source: dataocean/bubble.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js');
goog.object.extend(proto, github_com_envoyproxy_protoc$gen$validate_validate_validate_pb);
var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var meta_meta_pb = require('../meta/meta_pb.js');
goog.object.extend(proto, meta_meta_pb);
var dataocean_global_pb = require('../dataocean/global_pb.js');
goog.object.extend(proto, dataocean_global_pb);
goog.exportSymbol('proto.dataocean.Bubble', null, global);
goog.exportSymbol('proto.dataocean.BubbleLocation', null, global);
goog.exportSymbol('proto.dataocean.BubblePhase', null, global);
goog.exportSymbol('proto.dataocean.BubbleSpec', null, global);
goog.exportSymbol('proto.dataocean.BubbleStatus', null, global);
goog.exportSymbol('proto.dataocean.CreateBubbleReq', null, global);
goog.exportSymbol('proto.dataocean.GetBubbleReq', null, global);
goog.exportSymbol('proto.dataocean.UpdateBubbleReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Bubble = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Bubble, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Bubble.displayName = 'proto.dataocean.Bubble';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BubbleSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.BubbleSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BubbleSpec.displayName = 'proto.dataocean.BubbleSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BubbleStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.BubbleStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BubbleStatus.displayName = 'proto.dataocean.BubbleStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BubbleLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.BubbleLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BubbleLocation.displayName = 'proto.dataocean.BubbleLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.CreateBubbleReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.CreateBubbleReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.CreateBubbleReq.displayName = 'proto.dataocean.CreateBubbleReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GetBubbleReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GetBubbleReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GetBubbleReq.displayName = 'proto.dataocean.GetBubbleReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.UpdateBubbleReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.UpdateBubbleReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.UpdateBubbleReq.displayName = 'proto.dataocean.UpdateBubbleReq';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Bubble.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Bubble.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Bubble} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Bubble.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && meta_meta_pb.Metadata.toObject(includeInstance, f),
    spec: (f = msg.getSpec()) && proto.dataocean.BubbleSpec.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.dataocean.BubbleStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Bubble}
 */
proto.dataocean.Bubble.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Bubble;
  return proto.dataocean.Bubble.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Bubble} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Bubble}
 */
proto.dataocean.Bubble.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.Metadata;
      reader.readMessage(value,meta_meta_pb.Metadata.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.dataocean.BubbleSpec;
      reader.readMessage(value,proto.dataocean.BubbleSpec.deserializeBinaryFromReader);
      msg.setSpec(value);
      break;
    case 3:
      var value = new proto.dataocean.BubbleStatus;
      reader.readMessage(value,proto.dataocean.BubbleStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Bubble.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Bubble.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Bubble} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Bubble.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getSpec();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.BubbleSpec.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.BubbleStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.Metadata meta = 1;
 * @return {?proto.meta.Metadata}
 */
proto.dataocean.Bubble.prototype.getMeta = function() {
  return /** @type{?proto.meta.Metadata} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.Metadata, 1));
};


/**
 * @param {?proto.meta.Metadata|undefined} value
 * @return {!proto.dataocean.Bubble} returns this
*/
proto.dataocean.Bubble.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Bubble} returns this
 */
proto.dataocean.Bubble.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Bubble.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BubbleSpec spec = 2;
 * @return {?proto.dataocean.BubbleSpec}
 */
proto.dataocean.Bubble.prototype.getSpec = function() {
  return /** @type{?proto.dataocean.BubbleSpec} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.BubbleSpec, 2));
};


/**
 * @param {?proto.dataocean.BubbleSpec|undefined} value
 * @return {!proto.dataocean.Bubble} returns this
*/
proto.dataocean.Bubble.prototype.setSpec = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Bubble} returns this
 */
proto.dataocean.Bubble.prototype.clearSpec = function() {
  return this.setSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Bubble.prototype.hasSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional BubbleStatus status = 3;
 * @return {?proto.dataocean.BubbleStatus}
 */
proto.dataocean.Bubble.prototype.getStatus = function() {
  return /** @type{?proto.dataocean.BubbleStatus} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.BubbleStatus, 3));
};


/**
 * @param {?proto.dataocean.BubbleStatus|undefined} value
 * @return {!proto.dataocean.Bubble} returns this
*/
proto.dataocean.Bubble.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Bubble} returns this
 */
proto.dataocean.Bubble.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Bubble.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BubbleSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BubbleSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BubbleSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BubbleSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    filename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    mime: (f = msg.getMime()) && dataocean_global_pb.MIMEType.toObject(includeInstance, f),
    digestsMap: (f = msg.getDigestsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BubbleSpec}
 */
proto.dataocean.BubbleSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BubbleSpec;
  return proto.dataocean.BubbleSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BubbleSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BubbleSpec}
 */
proto.dataocean.BubbleSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 3:
      var value = new dataocean_global_pb.MIMEType;
      reader.readMessage(value,dataocean_global_pb.MIMEType.deserializeBinaryFromReader);
      msg.setMime(value);
      break;
    case 4:
      var value = msg.getDigestsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BubbleSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BubbleSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BubbleSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BubbleSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getMime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      dataocean_global_pb.MIMEType.serializeBinaryToWriter
    );
  }
  f = message.getDigestsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string filename = 1;
 * @return {string}
 */
proto.dataocean.BubbleSpec.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.BubbleSpec} returns this
 */
proto.dataocean.BubbleSpec.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 size = 2;
 * @return {number}
 */
proto.dataocean.BubbleSpec.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.BubbleSpec} returns this
 */
proto.dataocean.BubbleSpec.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional MIMEType mime = 3;
 * @return {?proto.dataocean.MIMEType}
 */
proto.dataocean.BubbleSpec.prototype.getMime = function() {
  return /** @type{?proto.dataocean.MIMEType} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MIMEType, 3));
};


/**
 * @param {?proto.dataocean.MIMEType|undefined} value
 * @return {!proto.dataocean.BubbleSpec} returns this
*/
proto.dataocean.BubbleSpec.prototype.setMime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BubbleSpec} returns this
 */
proto.dataocean.BubbleSpec.prototype.clearMime = function() {
  return this.setMime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BubbleSpec.prototype.hasMime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, string> digests = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.dataocean.BubbleSpec.prototype.getDigestsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dataocean.BubbleSpec} returns this
 */
proto.dataocean.BubbleSpec.prototype.clearDigestsMap = function() {
  this.getDigestsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BubbleStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BubbleStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BubbleStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BubbleStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationsMap: (f = msg.getLocationsMap()) ? f.toObject(includeInstance, proto.dataocean.BubbleLocation.toObject) : [],
    downloadUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BubbleStatus}
 */
proto.dataocean.BubbleStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BubbleStatus;
  return proto.dataocean.BubbleStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BubbleStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BubbleStatus}
 */
proto.dataocean.BubbleStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getLocationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.dataocean.BubbleLocation.deserializeBinaryFromReader, "", new proto.dataocean.BubbleLocation());
         });
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BubbleStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BubbleStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BubbleStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BubbleStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.dataocean.BubbleLocation.serializeBinaryToWriter);
  }
  f = message.getDownloadUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * map<string, BubbleLocation> locations = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.dataocean.BubbleLocation>}
 */
proto.dataocean.BubbleStatus.prototype.getLocationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.dataocean.BubbleLocation>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.dataocean.BubbleLocation));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dataocean.BubbleStatus} returns this
 */
proto.dataocean.BubbleStatus.prototype.clearLocationsMap = function() {
  this.getLocationsMap().clear();
  return this;};


/**
 * optional string download_url = 2;
 * @return {string}
 */
proto.dataocean.BubbleStatus.prototype.getDownloadUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.BubbleStatus} returns this
 */
proto.dataocean.BubbleStatus.prototype.setDownloadUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BubbleLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BubbleLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BubbleLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BubbleLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    sink: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phase: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eTag: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BubbleLocation}
 */
proto.dataocean.BubbleLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BubbleLocation;
  return proto.dataocean.BubbleLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BubbleLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BubbleLocation}
 */
proto.dataocean.BubbleLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSink(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {!proto.dataocean.BubblePhase} */ (reader.readEnum());
      msg.setPhase(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setETag(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BubbleLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BubbleLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BubbleLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BubbleLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSink();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhase();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getETag();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string sink = 1;
 * @return {string}
 */
proto.dataocean.BubbleLocation.prototype.getSink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.BubbleLocation} returns this
 */
proto.dataocean.BubbleLocation.prototype.setSink = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.dataocean.BubbleLocation.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.BubbleLocation} returns this
 */
proto.dataocean.BubbleLocation.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BubblePhase phase = 3;
 * @return {!proto.dataocean.BubblePhase}
 */
proto.dataocean.BubbleLocation.prototype.getPhase = function() {
  return /** @type {!proto.dataocean.BubblePhase} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.dataocean.BubblePhase} value
 * @return {!proto.dataocean.BubbleLocation} returns this
 */
proto.dataocean.BubbleLocation.prototype.setPhase = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string e_tag = 4;
 * @return {string}
 */
proto.dataocean.BubbleLocation.prototype.getETag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.BubbleLocation} returns this
 */
proto.dataocean.BubbleLocation.prototype.setETag = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp update_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.BubbleLocation.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.BubbleLocation} returns this
*/
proto.dataocean.BubbleLocation.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BubbleLocation} returns this
 */
proto.dataocean.BubbleLocation.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BubbleLocation.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.CreateBubbleReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.CreateBubbleReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.CreateBubbleReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateBubbleReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bubble: (f = msg.getBubble()) && proto.dataocean.Bubble.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.CreateBubbleReq}
 */
proto.dataocean.CreateBubbleReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.CreateBubbleReq;
  return proto.dataocean.CreateBubbleReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.CreateBubbleReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.CreateBubbleReq}
 */
proto.dataocean.CreateBubbleReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Bubble;
      reader.readMessage(value,proto.dataocean.Bubble.deserializeBinaryFromReader);
      msg.setBubble(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.CreateBubbleReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.CreateBubbleReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.CreateBubbleReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateBubbleReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBubble();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Bubble.serializeBinaryToWriter
    );
  }
};


/**
 * optional Bubble bubble = 1;
 * @return {?proto.dataocean.Bubble}
 */
proto.dataocean.CreateBubbleReq.prototype.getBubble = function() {
  return /** @type{?proto.dataocean.Bubble} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Bubble, 1));
};


/**
 * @param {?proto.dataocean.Bubble|undefined} value
 * @return {!proto.dataocean.CreateBubbleReq} returns this
*/
proto.dataocean.CreateBubbleReq.prototype.setBubble = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.CreateBubbleReq} returns this
 */
proto.dataocean.CreateBubbleReq.prototype.clearBubble = function() {
  return this.setBubble(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.CreateBubbleReq.prototype.hasBubble = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GetBubbleReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GetBubbleReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GetBubbleReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBubbleReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GetBubbleReq}
 */
proto.dataocean.GetBubbleReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GetBubbleReq;
  return proto.dataocean.GetBubbleReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GetBubbleReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GetBubbleReq}
 */
proto.dataocean.GetBubbleReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GetBubbleReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GetBubbleReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GetBubbleReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetBubbleReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dataocean.GetBubbleReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GetBubbleReq} returns this
 */
proto.dataocean.GetBubbleReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.UpdateBubbleReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.UpdateBubbleReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.UpdateBubbleReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.UpdateBubbleReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bubble: (f = msg.getBubble()) && proto.dataocean.Bubble.toObject(includeInstance, f),
    fieldMask: (f = msg.getFieldMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.UpdateBubbleReq}
 */
proto.dataocean.UpdateBubbleReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.UpdateBubbleReq;
  return proto.dataocean.UpdateBubbleReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.UpdateBubbleReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.UpdateBubbleReq}
 */
proto.dataocean.UpdateBubbleReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Bubble;
      reader.readMessage(value,proto.dataocean.Bubble.deserializeBinaryFromReader);
      msg.setBubble(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setFieldMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.UpdateBubbleReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.UpdateBubbleReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.UpdateBubbleReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.UpdateBubbleReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBubble();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Bubble.serializeBinaryToWriter
    );
  }
  f = message.getFieldMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Bubble bubble = 1;
 * @return {?proto.dataocean.Bubble}
 */
proto.dataocean.UpdateBubbleReq.prototype.getBubble = function() {
  return /** @type{?proto.dataocean.Bubble} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Bubble, 1));
};


/**
 * @param {?proto.dataocean.Bubble|undefined} value
 * @return {!proto.dataocean.UpdateBubbleReq} returns this
*/
proto.dataocean.UpdateBubbleReq.prototype.setBubble = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.UpdateBubbleReq} returns this
 */
proto.dataocean.UpdateBubbleReq.prototype.clearBubble = function() {
  return this.setBubble(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.UpdateBubbleReq.prototype.hasBubble = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask field_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.dataocean.UpdateBubbleReq.prototype.getFieldMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.dataocean.UpdateBubbleReq} returns this
*/
proto.dataocean.UpdateBubbleReq.prototype.setFieldMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.UpdateBubbleReq} returns this
 */
proto.dataocean.UpdateBubbleReq.prototype.clearFieldMask = function() {
  return this.setFieldMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.UpdateBubbleReq.prototype.hasFieldMask = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.dataocean.BubblePhase = {
  BUBBLE_PENDING: 0,
  BUBBLE_FILLING: 1,
  BUBBLE_READY: 2,
  BUBBLE_ERROR: 3
};

goog.object.extend(exports, proto.dataocean);
