import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, PageHeader, Popconfirm, Tabs, Tag, Table, Select, Spin, Card, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { makeAutoObservable, toJS } from 'mobx';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react';
import Api from 'api/Api';
import { useEffect, useState } from 'react';
import { Billing, ListBillingsReq, UpdateBillingReq } from 'atom-proto/web/atom/v2/billing_pb';
import { BarChart } from 'components/charts/BarChart';
import { PieChart } from 'components/charts/PieChart';
import { BillStatusTag } from 'components/BillingStatusTag';
import { TableFilter } from 'components/TableFilter';
import { BlllingStatus, ResourceTypeMap, ResourceUnit } from 'utils/constants';
import { ColumnsType } from 'antd/lib/table';
import grpc from 'api/grpc';
import { buildObject } from 'utils/grpcUtil';
import { PagerReq } from 'atom-proto/web/meta/meta_pb';
import { formatPrice, getNormalizedPrice } from 'utils/priceUtil';
import { formatMonth, formatTime } from 'utils/timeUtil';
import { ListProductsReq } from 'atom-proto/web/atom/v2/product_pb';
import { formatBillingDetail, formatChartData, BillingChartData } from 'utils/billingUtil';
import BillDetailDialog from 'components/BillingDetailDialog';
import EditBillDialog from 'components/EditBillingDialog';
class Status {
  billing = {
    data: [] as Billing.AsObject[],
    filtedData: [] as Billing.AsObject[]
  };
  barBillingList: BillingChartData[] = [];
  page = 0;
  page_size = 50;
  loading: boolean = true;
  editDialog = {
    data: {} as Billing.AsObject,
    open: false
  };
  detailDialog = {
    data: {} as Billing.AsObject,
    open: false
  };
  total: number | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }
}

const status = new Status();
export const OrgBilling = observer(() => {
  const { billing } = status;
  const navigate = useNavigate();
  const params = useParams();
  const fetchData = async () => {
    const listBillingsRes = await grpc.billing.listBillings(
      buildObject(new ListBillingsReq(), (req) => {
        req.setAlltenants(true);
      })
    );
    const listProductsRes = await grpc.product.listProducts(
      buildObject(new ListProductsReq(), (req) => {
        req.setProductidList([]);
      })
    );
    const resourceList = listProductsRes.toObject().productsList;
    const billingList = listBillingsRes.toObject().billingsList.filter((item) => item.tenantid === params.tenantid);
    const billings = formatBillingDetail(billingList, resourceList);

    billing.data = billings.sort((a, b) => {
      return b.billingmonth.seconds - a.billingmonth.seconds;
    });
    billing.filtedData = billing.data;

    const barListData = (
      await grpc.billing.listBillings(
        buildObject(new ListBillingsReq(), (req) => {
          req.setAlltenants(true);
          req.setPager(
            buildObject(new PagerReq(), (pager) => {
              pager.setPage(0);
              pager.setLimit(24);
            })
          );
        })
      )
    )
      .toObject()
      .billingsList.filter((item) => item.tenantid === params.tenantid);
    if (barListData.length === 0) {
      status.loading = false;
      status.barBillingList = [];
      return;
    }
    status.barBillingList = formatChartData(
      barListData?.sort((a, b) => {
        return a.billingmonth.seconds - b.billingmonth.seconds;
      })
    );
  };
  const columns: ColumnsType<Billing.AsObject> = [
    {
      title: '账单周期',
      width: 120,
      align: 'center',
      dataIndex: 'billingmonth',
      filterDropdown: () => {
        return (
          <TableFilter
            defaultValue={''}
            onQuery={(queryValue) => {
              billing.filtedData = billing.data.filter((item) => {
                const date = new Date(item?.billingmonth?.seconds * 1000);
                const month = formatMonth(date);
                return month.indexOf(queryValue) > -1;
              });
            }}
            onReset={() => {
              billing.filtedData = billing.data.filter((item) => {
                const date = new Date(item?.billingmonth?.seconds * 1000);
                const month = formatMonth(date);
                return month.indexOf('') > -1;
              });
            }}
          />
        );
      },
      filterIcon: (filtered) => <SearchOutlined />,
      render(value, reocerd) {
        const date = new Date(value?.seconds * 1000);
        return formatMonth(date);
      }
    },
    {
      title: '单位',
      width: 120,
      align: 'center',
      dataIndex: 'tenantname',
      filterDropdown: () => {
        return (
          <TableFilter
            defaultValue={''}
            onQuery={(queryValue) => {
              billing.filtedData = billing.data.filter((item) => {
                return item?.tenantname.indexOf(queryValue) > -1;
              });
            }}
            onReset={() => {
              billing.filtedData = billing.data.filter((item) => {
                return item?.tenantname.indexOf('') > -1;
              });
            }}
          />
        );
      },
      filterIcon: (filtered) => <SearchOutlined />
    },
    {
      title: '状态',
      align: 'center',
      dataIndex: 'status',
      filters: (() => {
        return Object.getOwnPropertyNames(BlllingStatus).map((key) => {
          return { text: BlllingStatus[key], value: key };
        });
      })(),
      onFilter: (value, billing: Billing.AsObject) => {
        return billing.status == value;
      },

      render(value) {
        return <BillStatusTag status={value} />;
      }
    },
    {
      title: '目录总价',
      dataIndex: 'total',
      ellipsis: true,
      align: 'center',
      render(value) {
        return formatPrice(value, '¥');
      }
    },
    {
      title: '折扣优惠',
      dataIndex: 'discount',
      ellipsis: true,
      align: 'center',
      render(value) {
        return formatPrice(value, '¥');
      }
    },
    {
      title: '应付金额',
      ellipsis: true,
      align: 'center',
      render(value, record) {
        return formatPrice(record.total - record.discount, '¥');
      }
    },
    {
      title: '已支付金额',
      ellipsis: true,
      dataIndex: 'paid',
      align: 'center',
      render(value) {
        return formatPrice(value, '¥');
      }
    },
    {
      title: '欠款',
      ellipsis: true,
      align: 'center',
      render(value, record) {
        const cost = record.total - record.discount - record.paid;
        return formatPrice(cost, '¥');
      }
    },
    {
      title: '操作',
      align: 'center',
      render: (value, record: Billing.AsObject) => {
        return (
          <div>
            <Button
              onClick={() => {
                status.detailDialog.data = record;
                status.detailDialog.open = true;
              }}
              type="link"
              size="small"
            >
              明细
            </Button>

            <Button
              onClick={() => {
                status.editDialog.data = record;
                status.editDialog.open = true;
              }}
              type="link"
              size="small"
            >
              编辑
            </Button>
          </div>
        );
      }
    }
  ];
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageHeader
      title="账单"
      onBack={() => {
        navigate(-1);
      }}
    >
      <Card>
        <BarChart
          noData={!status.barBillingList || status.barBillingList.length === 0}
          xAxisData={toJS(status.barBillingList)?.map((item) => item.date)}
          data={[{ value: status.barBillingList?.map((item) => getNormalizedPrice(item.total)), name: 'total' }]}
        />
        <Tag
          style={{ paddingLeft: 20, width: '100%', margin: '20px 0', height: '40px', lineHeight: '40px', fontSize: 14 }}
          icon={<InfoCircleOutlined />}
          color="processing"
        >
          <span style={{ color: '#000' }}>总计：{billing.data.length || 0} 条记录</span>
        </Tag>
        <Table<Billing.AsObject>
          rowKey={(record: Billing.AsObject) => record?.id?.hex}
          columns={columns}
          dataSource={billing.filtedData}
          bordered={false}
          pagination={{
            total: status.total,
            pageSize: status.page_size,
            showSizeChanger: false
          }}
        />
      </Card>
      <BillDetailDialog
        onCancel={() => (status.detailDialog.open = false)}
        open={status.detailDialog.open}
        billing={status.detailDialog.data}
      />
      <EditBillDialog
        onSubmit={async (value: Billing.AsObject) => {
          try {
            const updateBillingReq = buildObject(new UpdateBillingReq(), (req) => {
              req.setId(value.id.hex);
              req.setTenantid(value.tenantid);
              req.setTotal(value.total);
              req.setDiscount(value.discount);
              req.setPaid(value.paid);
              req.setStatus(value.status);
            });
            status.editDialog.open = false;
            await grpc.billing.updateBilling(updateBillingReq);
            fetchData();
            message.success('修改成功');
          } catch (e) {
            console.log(e);
            message.error('修改失败');
          }
        }}
        onCancel={() => (status.editDialog.open = false)}
        open={status.editDialog.open}
        value={status.editDialog.data}
      />
    </PageHeader>
  );
});
