// source: dataocean/atwork.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js');
goog.object.extend(proto, github_com_envoyproxy_protoc$gen$validate_validate_validate_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var dataocean_global_pb = require('../dataocean/global_pb.js');
goog.object.extend(proto, dataocean_global_pb);
goog.exportSymbol('proto.dataocean.Metal', null, global);
goog.exportSymbol('proto.dataocean.Metal.BlobSource', null, global);
goog.exportSymbol('proto.dataocean.Metal.Division', null, global);
goog.exportSymbol('proto.dataocean.Metal.Extension', null, global);
goog.exportSymbol('proto.dataocean.Metal.Filter', null, global);
goog.exportSymbol('proto.dataocean.Metal.Filter.Collecting', null, global);
goog.exportSymbol('proto.dataocean.Metal.Filter.Event', null, global);
goog.exportSymbol('proto.dataocean.Metal.Filter.File', null, global);
goog.exportSymbol('proto.dataocean.Metal.Filter.Media', null, global);
goog.exportSymbol('proto.dataocean.Metal.Filter.Project', null, global);
goog.exportSymbol('proto.dataocean.Metal.Filter.Video', null, global);
goog.exportSymbol('proto.dataocean.Metal.OreType', null, global);
goog.exportSymbol('proto.dataocean.Metal.Resolution', null, global);
goog.exportSymbol('proto.dataocean.Metal.Source', null, global);
goog.exportSymbol('proto.dataocean.Metal.Source.Collecting', null, global);
goog.exportSymbol('proto.dataocean.Metal.Source.Event', null, global);
goog.exportSymbol('proto.dataocean.Metal.Source.File', null, global);
goog.exportSymbol('proto.dataocean.Metal.Source.Media', null, global);
goog.exportSymbol('proto.dataocean.Metal.Source.Project', null, global);
goog.exportSymbol('proto.dataocean.Metal.Source.Video', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.displayName = 'proto.dataocean.Metal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Source.displayName = 'proto.dataocean.Metal.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Source.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Source.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Source.Event.displayName = 'proto.dataocean.Metal.Source.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Source.Project = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Source.Project, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Source.Project.displayName = 'proto.dataocean.Metal.Source.Project';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Source.Collecting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Source.Collecting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Source.Collecting.displayName = 'proto.dataocean.Metal.Source.Collecting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Source.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Metal.Source.File.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Metal.Source.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Source.File.displayName = 'proto.dataocean.Metal.Source.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Source.Media = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Source.Media, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Source.Media.displayName = 'proto.dataocean.Metal.Source.Media';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Source.Video = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Source.Video, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Source.Video.displayName = 'proto.dataocean.Metal.Source.Video';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Extension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Metal.Extension.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Metal.Extension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Extension.displayName = 'proto.dataocean.Metal.Extension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Filter.displayName = 'proto.dataocean.Metal.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Filter.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Metal.Filter.Event.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Metal.Filter.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Filter.Event.displayName = 'proto.dataocean.Metal.Filter.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Filter.Project = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Metal.Filter.Project.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Metal.Filter.Project, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Filter.Project.displayName = 'proto.dataocean.Metal.Filter.Project';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Filter.Collecting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Metal.Filter.Collecting.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Metal.Filter.Collecting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Filter.Collecting.displayName = 'proto.dataocean.Metal.Filter.Collecting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Filter.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.Metal.Filter.File.repeatedFields_, null);
};
goog.inherits(proto.dataocean.Metal.Filter.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Filter.File.displayName = 'proto.dataocean.Metal.Filter.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Filter.Media = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Filter.Media, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Filter.Media.displayName = 'proto.dataocean.Metal.Filter.Media';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Filter.Video = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Filter.Video, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Filter.Video.displayName = 'proto.dataocean.Metal.Filter.Video';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Division = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Division, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Division.displayName = 'proto.dataocean.Metal.Division';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.Resolution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.Resolution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.Resolution.displayName = 'proto.dataocean.Metal.Resolution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Metal.BlobSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Metal.BlobSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Metal.BlobSource.displayName = 'proto.dataocean.Metal.BlobSource';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal}
 */
proto.dataocean.Metal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal;
  return proto.dataocean.Metal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal}
 */
proto.dataocean.Metal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.dataocean.Metal.OreType = {
  ORE_FILE: 0,
  ORE_EVENT: 1,
  ORE_ILLEGAL: 2,
  ORE_DISCARD: 3,
  ORE_MISS: 4,
  ORE_FILTERED: 5
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && proto.dataocean.Metal.Source.Event.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.dataocean.Metal.Source.Project.toObject(includeInstance, f),
    collecting: (f = msg.getCollecting()) && proto.dataocean.Metal.Source.Collecting.toObject(includeInstance, f),
    file: (f = msg.getFile()) && proto.dataocean.Metal.Source.File.toObject(includeInstance, f),
    media: (f = msg.getMedia()) && proto.dataocean.Metal.Source.Media.toObject(includeInstance, f),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    metal: msg.getMetal_asB64(),
    metadata: msg.getMetadata_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Source}
 */
proto.dataocean.Metal.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Source;
  return proto.dataocean.Metal.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Source}
 */
proto.dataocean.Metal.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Metal.Source.Event;
      reader.readMessage(value,proto.dataocean.Metal.Source.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = new proto.dataocean.Metal.Source.Project;
      reader.readMessage(value,proto.dataocean.Metal.Source.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.dataocean.Metal.Source.Collecting;
      reader.readMessage(value,proto.dataocean.Metal.Source.Collecting.deserializeBinaryFromReader);
      msg.setCollecting(value);
      break;
    case 4:
      var value = new proto.dataocean.Metal.Source.File;
      reader.readMessage(value,proto.dataocean.Metal.Source.File.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    case 5:
      var value = new proto.dataocean.Metal.Source.Media;
      reader.readMessage(value,proto.dataocean.Metal.Source.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMetal(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Metal.Source.Event.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.Metal.Source.Project.serializeBinaryToWriter
    );
  }
  f = message.getCollecting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.Metal.Source.Collecting.serializeBinaryToWriter
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dataocean.Metal.Source.File.serializeBinaryToWriter
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dataocean.Metal.Source.Media.serializeBinaryToWriter
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMetal_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getMetadata_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      9,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Source.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Source.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Source.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    productCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    productName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    platformVersion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    appVersionMap: (f = msg.getAppVersionMap()) ? f.toObject(includeInstance, undefined) : [],
    cameraName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    eventStartTime: (f = msg.getEventStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    eventType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    oreType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    discardReason: jspb.Message.getFieldWithDefault(msg, 9, ""),
    eventId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Source.Event}
 */
proto.dataocean.Metal.Source.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Source.Event;
  return proto.dataocean.Metal.Source.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Source.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Source.Event}
 */
proto.dataocean.Metal.Source.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatformVersion(value);
      break;
    case 4:
      var value = msg.getAppVersionMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraName(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEventStartTime(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventType(value);
      break;
    case 8:
      var value = /** @type {!proto.dataocean.Metal.OreType} */ (reader.readEnum());
      msg.setOreType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscardReason(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Source.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Source.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Source.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProductName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlatformVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAppVersionMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getCameraName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventStartTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOreType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getDiscardReason();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string product_code = 1;
 * @return {string}
 */
proto.dataocean.Metal.Source.Event.prototype.getProductCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.setProductCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string product_name = 2;
 * @return {string}
 */
proto.dataocean.Metal.Source.Event.prototype.getProductName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.setProductName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string platform_version = 3;
 * @return {string}
 */
proto.dataocean.Metal.Source.Event.prototype.getPlatformVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.setPlatformVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, string> app_version = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.dataocean.Metal.Source.Event.prototype.getAppVersionMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.clearAppVersionMap = function() {
  this.getAppVersionMap().clear();
  return this;};


/**
 * optional string camera_name = 5;
 * @return {string}
 */
proto.dataocean.Metal.Source.Event.prototype.getCameraName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.setCameraName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp event_start_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.Metal.Source.Event.prototype.getEventStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.Metal.Source.Event} returns this
*/
proto.dataocean.Metal.Source.Event.prototype.setEventStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.clearEventStartTime = function() {
  return this.setEventStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.Event.prototype.hasEventStartTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string event_type = 7;
 * @return {string}
 */
proto.dataocean.Metal.Source.Event.prototype.getEventType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.setEventType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional OreType ore_type = 8;
 * @return {!proto.dataocean.Metal.OreType}
 */
proto.dataocean.Metal.Source.Event.prototype.getOreType = function() {
  return /** @type {!proto.dataocean.Metal.OreType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.dataocean.Metal.OreType} value
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.setOreType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string discard_reason = 9;
 * @return {string}
 */
proto.dataocean.Metal.Source.Event.prototype.getDiscardReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.setDiscardReason = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string event_id = 10;
 * @return {string}
 */
proto.dataocean.Metal.Source.Event.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Event} returns this
 */
proto.dataocean.Metal.Source.Event.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Source.Project.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Source.Project.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Source.Project} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Project.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    projectName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    customerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    division: (f = msg.getDivision()) && proto.dataocean.Metal.Division.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Source.Project}
 */
proto.dataocean.Metal.Source.Project.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Source.Project;
  return proto.dataocean.Metal.Source.Project.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Source.Project} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Source.Project}
 */
proto.dataocean.Metal.Source.Project.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerName(value);
      break;
    case 5:
      var value = new proto.dataocean.Metal.Division;
      reader.readMessage(value,proto.dataocean.Metal.Division.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Source.Project.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Source.Project.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Source.Project} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Project.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCustomerName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dataocean.Metal.Division.serializeBinaryToWriter
    );
  }
};


/**
 * optional string project_code = 1;
 * @return {string}
 */
proto.dataocean.Metal.Source.Project.prototype.getProjectCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Project} returns this
 */
proto.dataocean.Metal.Source.Project.prototype.setProjectCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string project_name = 2;
 * @return {string}
 */
proto.dataocean.Metal.Source.Project.prototype.getProjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Project} returns this
 */
proto.dataocean.Metal.Source.Project.prototype.setProjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string customer_code = 3;
 * @return {string}
 */
proto.dataocean.Metal.Source.Project.prototype.getCustomerCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Project} returns this
 */
proto.dataocean.Metal.Source.Project.prototype.setCustomerCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string customer_name = 4;
 * @return {string}
 */
proto.dataocean.Metal.Source.Project.prototype.getCustomerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Project} returns this
 */
proto.dataocean.Metal.Source.Project.prototype.setCustomerName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Division division = 5;
 * @return {?proto.dataocean.Metal.Division}
 */
proto.dataocean.Metal.Source.Project.prototype.getDivision = function() {
  return /** @type{?proto.dataocean.Metal.Division} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Division, 5));
};


/**
 * @param {?proto.dataocean.Metal.Division|undefined} value
 * @return {!proto.dataocean.Metal.Source.Project} returns this
*/
proto.dataocean.Metal.Source.Project.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source.Project} returns this
 */
proto.dataocean.Metal.Source.Project.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.Project.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Source.Collecting.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Source.Collecting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Source.Collecting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Collecting.toObject = function(includeInstance, msg) {
  var f, obj = {
    batch: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dataType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    uploadTime: (f = msg.getUploadTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    uploaderUsername: jspb.Message.getFieldWithDefault(msg, 4, ""),
    uploaderName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    remark: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Source.Collecting}
 */
proto.dataocean.Metal.Source.Collecting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Source.Collecting;
  return proto.dataocean.Metal.Source.Collecting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Source.Collecting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Source.Collecting}
 */
proto.dataocean.Metal.Source.Collecting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatch(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataType(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUploadTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploaderUsername(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploaderName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Source.Collecting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Source.Collecting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Source.Collecting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Collecting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatch();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDataType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUploadTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUploaderUsername();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUploaderName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string batch = 1;
 * @return {string}
 */
proto.dataocean.Metal.Source.Collecting.prototype.getBatch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Collecting} returns this
 */
proto.dataocean.Metal.Source.Collecting.prototype.setBatch = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data_type = 2;
 * @return {string}
 */
proto.dataocean.Metal.Source.Collecting.prototype.getDataType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Collecting} returns this
 */
proto.dataocean.Metal.Source.Collecting.prototype.setDataType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp upload_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.Metal.Source.Collecting.prototype.getUploadTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.Metal.Source.Collecting} returns this
*/
proto.dataocean.Metal.Source.Collecting.prototype.setUploadTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source.Collecting} returns this
 */
proto.dataocean.Metal.Source.Collecting.prototype.clearUploadTime = function() {
  return this.setUploadTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.Collecting.prototype.hasUploadTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string uploader_username = 4;
 * @return {string}
 */
proto.dataocean.Metal.Source.Collecting.prototype.getUploaderUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Collecting} returns this
 */
proto.dataocean.Metal.Source.Collecting.prototype.setUploaderUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string uploader_name = 5;
 * @return {string}
 */
proto.dataocean.Metal.Source.Collecting.prototype.getUploaderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Collecting} returns this
 */
proto.dataocean.Metal.Source.Collecting.prototype.setUploaderName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string remark = 6;
 * @return {string}
 */
proto.dataocean.Metal.Source.Collecting.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Collecting} returns this
 */
proto.dataocean.Metal.Source.Collecting.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Metal.Source.File.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Source.File.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Source.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Source.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    volume: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    size: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mimeType: (f = msg.getMimeType()) && dataocean_global_pb.MIMEType.toObject(includeInstance, f),
    fileName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Source.File}
 */
proto.dataocean.Metal.Source.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Source.File;
  return proto.dataocean.Metal.Source.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Source.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Source.File}
 */
proto.dataocean.Metal.Source.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVolume(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 5:
      var value = new dataocean_global_pb.MIMEType;
      reader.readMessage(value,dataocean_global_pb.MIMEType.deserializeBinaryFromReader);
      msg.setMimeType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Source.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Source.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Source.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVolume();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getMimeType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dataocean_global_pb.MIMEType.serializeBinaryToWriter
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.dataocean.Metal.Source.File.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.File} returns this
 */
proto.dataocean.Metal.Source.File.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string volume = 2;
 * @return {string}
 */
proto.dataocean.Metal.Source.File.prototype.getVolume = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.File} returns this
 */
proto.dataocean.Metal.Source.File.prototype.setVolume = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string tags = 3;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Source.File.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Source.File} returns this
 */
proto.dataocean.Metal.Source.File.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Source.File} returns this
 */
proto.dataocean.Metal.Source.File.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Source.File} returns this
 */
proto.dataocean.Metal.Source.File.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional uint64 size = 4;
 * @return {number}
 */
proto.dataocean.Metal.Source.File.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Metal.Source.File} returns this
 */
proto.dataocean.Metal.Source.File.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional MIMEType mime_type = 5;
 * @return {?proto.dataocean.MIMEType}
 */
proto.dataocean.Metal.Source.File.prototype.getMimeType = function() {
  return /** @type{?proto.dataocean.MIMEType} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MIMEType, 5));
};


/**
 * @param {?proto.dataocean.MIMEType|undefined} value
 * @return {!proto.dataocean.Metal.Source.File} returns this
*/
proto.dataocean.Metal.Source.File.prototype.setMimeType = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source.File} returns this
 */
proto.dataocean.Metal.Source.File.prototype.clearMimeType = function() {
  return this.setMimeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.File.prototype.hasMimeType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string file_name = 6;
 * @return {string}
 */
proto.dataocean.Metal.Source.File.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.File} returns this
 */
proto.dataocean.Metal.Source.File.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Source.Media.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Source.Media.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Source.Media} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Media.toObject = function(includeInstance, msg) {
  var f, obj = {
    resolution: (f = msg.getResolution()) && proto.dataocean.Metal.Resolution.toObject(includeInstance, f),
    video: (f = msg.getVideo()) && proto.dataocean.Metal.Source.Video.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Source.Media}
 */
proto.dataocean.Metal.Source.Media.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Source.Media;
  return proto.dataocean.Metal.Source.Media.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Source.Media} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Source.Media}
 */
proto.dataocean.Metal.Source.Media.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Metal.Resolution;
      reader.readMessage(value,proto.dataocean.Metal.Resolution.deserializeBinaryFromReader);
      msg.setResolution(value);
      break;
    case 2:
      var value = new proto.dataocean.Metal.Source.Video;
      reader.readMessage(value,proto.dataocean.Metal.Source.Video.deserializeBinaryFromReader);
      msg.setVideo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Source.Media.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Source.Media.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Source.Media} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Media.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResolution();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Metal.Resolution.serializeBinaryToWriter
    );
  }
  f = message.getVideo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.Metal.Source.Video.serializeBinaryToWriter
    );
  }
};


/**
 * optional Resolution resolution = 1;
 * @return {?proto.dataocean.Metal.Resolution}
 */
proto.dataocean.Metal.Source.Media.prototype.getResolution = function() {
  return /** @type{?proto.dataocean.Metal.Resolution} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Resolution, 1));
};


/**
 * @param {?proto.dataocean.Metal.Resolution|undefined} value
 * @return {!proto.dataocean.Metal.Source.Media} returns this
*/
proto.dataocean.Metal.Source.Media.prototype.setResolution = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source.Media} returns this
 */
proto.dataocean.Metal.Source.Media.prototype.clearResolution = function() {
  return this.setResolution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.Media.prototype.hasResolution = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Video video = 2;
 * @return {?proto.dataocean.Metal.Source.Video}
 */
proto.dataocean.Metal.Source.Media.prototype.getVideo = function() {
  return /** @type{?proto.dataocean.Metal.Source.Video} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Source.Video, 2));
};


/**
 * @param {?proto.dataocean.Metal.Source.Video|undefined} value
 * @return {!proto.dataocean.Metal.Source.Media} returns this
*/
proto.dataocean.Metal.Source.Media.prototype.setVideo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source.Media} returns this
 */
proto.dataocean.Metal.Source.Media.prototype.clearVideo = function() {
  return this.setVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.Media.prototype.hasVideo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Source.Video.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Source.Video.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Source.Video} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Video.toObject = function(includeInstance, msg) {
  var f, obj = {
    bitrate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frameRate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    duration: jspb.Message.getFieldWithDefault(msg, 3, 0),
    encoding: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Source.Video}
 */
proto.dataocean.Metal.Source.Video.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Source.Video;
  return proto.dataocean.Metal.Source.Video.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Source.Video} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Source.Video}
 */
proto.dataocean.Metal.Source.Video.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBitrate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrameRate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDuration(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEncoding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Source.Video.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Source.Video.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Source.Video} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Source.Video.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBitrate();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFrameRate();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getEncoding();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 bitrate = 1;
 * @return {number}
 */
proto.dataocean.Metal.Source.Video.prototype.getBitrate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Metal.Source.Video} returns this
 */
proto.dataocean.Metal.Source.Video.prototype.setBitrate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 frame_rate = 2;
 * @return {number}
 */
proto.dataocean.Metal.Source.Video.prototype.getFrameRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Metal.Source.Video} returns this
 */
proto.dataocean.Metal.Source.Video.prototype.setFrameRate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 duration = 3;
 * @return {number}
 */
proto.dataocean.Metal.Source.Video.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Metal.Source.Video} returns this
 */
proto.dataocean.Metal.Source.Video.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string encoding = 4;
 * @return {string}
 */
proto.dataocean.Metal.Source.Video.prototype.getEncoding = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Source.Video} returns this
 */
proto.dataocean.Metal.Source.Video.prototype.setEncoding = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Event event = 1;
 * @return {?proto.dataocean.Metal.Source.Event}
 */
proto.dataocean.Metal.Source.prototype.getEvent = function() {
  return /** @type{?proto.dataocean.Metal.Source.Event} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Source.Event, 1));
};


/**
 * @param {?proto.dataocean.Metal.Source.Event|undefined} value
 * @return {!proto.dataocean.Metal.Source} returns this
*/
proto.dataocean.Metal.Source.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source} returns this
 */
proto.dataocean.Metal.Source.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Project project = 2;
 * @return {?proto.dataocean.Metal.Source.Project}
 */
proto.dataocean.Metal.Source.prototype.getProject = function() {
  return /** @type{?proto.dataocean.Metal.Source.Project} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Source.Project, 2));
};


/**
 * @param {?proto.dataocean.Metal.Source.Project|undefined} value
 * @return {!proto.dataocean.Metal.Source} returns this
*/
proto.dataocean.Metal.Source.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source} returns this
 */
proto.dataocean.Metal.Source.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Collecting collecting = 3;
 * @return {?proto.dataocean.Metal.Source.Collecting}
 */
proto.dataocean.Metal.Source.prototype.getCollecting = function() {
  return /** @type{?proto.dataocean.Metal.Source.Collecting} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Source.Collecting, 3));
};


/**
 * @param {?proto.dataocean.Metal.Source.Collecting|undefined} value
 * @return {!proto.dataocean.Metal.Source} returns this
*/
proto.dataocean.Metal.Source.prototype.setCollecting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source} returns this
 */
proto.dataocean.Metal.Source.prototype.clearCollecting = function() {
  return this.setCollecting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.prototype.hasCollecting = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional File file = 4;
 * @return {?proto.dataocean.Metal.Source.File}
 */
proto.dataocean.Metal.Source.prototype.getFile = function() {
  return /** @type{?proto.dataocean.Metal.Source.File} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Source.File, 4));
};


/**
 * @param {?proto.dataocean.Metal.Source.File|undefined} value
 * @return {!proto.dataocean.Metal.Source} returns this
*/
proto.dataocean.Metal.Source.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source} returns this
 */
proto.dataocean.Metal.Source.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.prototype.hasFile = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Media media = 5;
 * @return {?proto.dataocean.Metal.Source.Media}
 */
proto.dataocean.Metal.Source.prototype.getMedia = function() {
  return /** @type{?proto.dataocean.Metal.Source.Media} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Source.Media, 5));
};


/**
 * @param {?proto.dataocean.Metal.Source.Media|undefined} value
 * @return {!proto.dataocean.Metal.Source} returns this
*/
proto.dataocean.Metal.Source.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source} returns this
 */
proto.dataocean.Metal.Source.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp create_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.Metal.Source.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.Metal.Source} returns this
*/
proto.dataocean.Metal.Source.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source} returns this
 */
proto.dataocean.Metal.Source.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.Metal.Source.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.Metal.Source} returns this
*/
proto.dataocean.Metal.Source.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Source} returns this
 */
proto.dataocean.Metal.Source.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Source.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bytes metal = 8;
 * @return {string}
 */
proto.dataocean.Metal.Source.prototype.getMetal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes metal = 8;
 * This is a type-conversion wrapper around `getMetal()`
 * @return {string}
 */
proto.dataocean.Metal.Source.prototype.getMetal_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMetal()));
};


/**
 * optional bytes metal = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMetal()`
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Source.prototype.getMetal_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMetal()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dataocean.Metal.Source} returns this
 */
proto.dataocean.Metal.Source.prototype.setMetal = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional bytes metadata = 9;
 * @return {string}
 */
proto.dataocean.Metal.Source.prototype.getMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * optional bytes metadata = 9;
 * This is a type-conversion wrapper around `getMetadata()`
 * @return {string}
 */
proto.dataocean.Metal.Source.prototype.getMetadata_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMetadata()));
};


/**
 * optional bytes metadata = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMetadata()`
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Source.prototype.getMetadata_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMetadata()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dataocean.Metal.Source} returns this
 */
proto.dataocean.Metal.Source.prototype.setMetadata = function(value) {
  return jspb.Message.setProto3BytesField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Metal.Extension.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Extension.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Extension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Extension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Extension.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && proto.dataocean.Metal.Source.Event.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.dataocean.Metal.Source.Project.toObject(includeInstance, f),
    collecting: (f = msg.getCollecting()) && proto.dataocean.Metal.Source.Collecting.toObject(includeInstance, f),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Extension}
 */
proto.dataocean.Metal.Extension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Extension;
  return proto.dataocean.Metal.Extension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Extension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Extension}
 */
proto.dataocean.Metal.Extension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Metal.Source.Event;
      reader.readMessage(value,proto.dataocean.Metal.Source.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = new proto.dataocean.Metal.Source.Project;
      reader.readMessage(value,proto.dataocean.Metal.Source.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.dataocean.Metal.Source.Collecting;
      reader.readMessage(value,proto.dataocean.Metal.Source.Collecting.deserializeBinaryFromReader);
      msg.setCollecting(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Extension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Extension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Extension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Extension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Metal.Source.Event.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.Metal.Source.Project.serializeBinaryToWriter
    );
  }
  f = message.getCollecting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.Metal.Source.Collecting.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional Source.Event event = 1;
 * @return {?proto.dataocean.Metal.Source.Event}
 */
proto.dataocean.Metal.Extension.prototype.getEvent = function() {
  return /** @type{?proto.dataocean.Metal.Source.Event} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Source.Event, 1));
};


/**
 * @param {?proto.dataocean.Metal.Source.Event|undefined} value
 * @return {!proto.dataocean.Metal.Extension} returns this
*/
proto.dataocean.Metal.Extension.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Extension} returns this
 */
proto.dataocean.Metal.Extension.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Extension.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Source.Project project = 2;
 * @return {?proto.dataocean.Metal.Source.Project}
 */
proto.dataocean.Metal.Extension.prototype.getProject = function() {
  return /** @type{?proto.dataocean.Metal.Source.Project} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Source.Project, 2));
};


/**
 * @param {?proto.dataocean.Metal.Source.Project|undefined} value
 * @return {!proto.dataocean.Metal.Extension} returns this
*/
proto.dataocean.Metal.Extension.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Extension} returns this
 */
proto.dataocean.Metal.Extension.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Extension.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Source.Collecting collecting = 3;
 * @return {?proto.dataocean.Metal.Source.Collecting}
 */
proto.dataocean.Metal.Extension.prototype.getCollecting = function() {
  return /** @type{?proto.dataocean.Metal.Source.Collecting} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Source.Collecting, 3));
};


/**
 * @param {?proto.dataocean.Metal.Source.Collecting|undefined} value
 * @return {!proto.dataocean.Metal.Extension} returns this
*/
proto.dataocean.Metal.Extension.prototype.setCollecting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Extension} returns this
 */
proto.dataocean.Metal.Extension.prototype.clearCollecting = function() {
  return this.setCollecting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Extension.prototype.hasCollecting = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string tags = 4;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Extension.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Extension} returns this
 */
proto.dataocean.Metal.Extension.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Extension} returns this
 */
proto.dataocean.Metal.Extension.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Extension} returns this
 */
proto.dataocean.Metal.Extension.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional google.protobuf.Timestamp create_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.Metal.Extension.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.Metal.Extension} returns this
*/
proto.dataocean.Metal.Extension.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Extension} returns this
 */
proto.dataocean.Metal.Extension.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Extension.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp update_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.Metal.Extension.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.Metal.Extension} returns this
*/
proto.dataocean.Metal.Extension.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Extension} returns this
 */
proto.dataocean.Metal.Extension.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Extension.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && proto.dataocean.Metal.Filter.Event.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.dataocean.Metal.Filter.Project.toObject(includeInstance, f),
    collecting: (f = msg.getCollecting()) && proto.dataocean.Metal.Filter.Collecting.toObject(includeInstance, f),
    file: (f = msg.getFile()) && proto.dataocean.Metal.Filter.File.toObject(includeInstance, f),
    media: (f = msg.getMedia()) && proto.dataocean.Metal.Filter.Media.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Filter}
 */
proto.dataocean.Metal.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Filter;
  return proto.dataocean.Metal.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Filter}
 */
proto.dataocean.Metal.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Metal.Filter.Event;
      reader.readMessage(value,proto.dataocean.Metal.Filter.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = new proto.dataocean.Metal.Filter.Project;
      reader.readMessage(value,proto.dataocean.Metal.Filter.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 3:
      var value = new proto.dataocean.Metal.Filter.Collecting;
      reader.readMessage(value,proto.dataocean.Metal.Filter.Collecting.deserializeBinaryFromReader);
      msg.setCollecting(value);
      break;
    case 4:
      var value = new proto.dataocean.Metal.Filter.File;
      reader.readMessage(value,proto.dataocean.Metal.Filter.File.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    case 5:
      var value = new proto.dataocean.Metal.Filter.Media;
      reader.readMessage(value,proto.dataocean.Metal.Filter.Media.deserializeBinaryFromReader);
      msg.setMedia(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Metal.Filter.Event.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.Metal.Filter.Project.serializeBinaryToWriter
    );
  }
  f = message.getCollecting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.Metal.Filter.Collecting.serializeBinaryToWriter
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dataocean.Metal.Filter.File.serializeBinaryToWriter
    );
  }
  f = message.getMedia();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.dataocean.Metal.Filter.Media.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Metal.Filter.Event.repeatedFields_ = [1,2,3,4,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Filter.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Filter.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Filter.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    productCodesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    platformVersionsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    vmrVersionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    cameraNamesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    eventStartTime: (f = msg.getEventStartTime()) && dataocean_global_pb.TimeSpan.toObject(includeInstance, f),
    eventTypesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    oreTypesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    discardReasonsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    eventId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Filter.Event}
 */
proto.dataocean.Metal.Filter.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Filter.Event;
  return proto.dataocean.Metal.Filter.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Filter.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Filter.Event}
 */
proto.dataocean.Metal.Filter.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductCodes(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlatformVersions(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addVmrVersions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addCameraNames(value);
      break;
    case 5:
      var value = new dataocean_global_pb.TimeSpan;
      reader.readMessage(value,dataocean_global_pb.TimeSpan.deserializeBinaryFromReader);
      msg.setEventStartTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addEventTypes(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.dataocean.Metal.OreType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOreTypes(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addDiscardReasons(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Filter.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Filter.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Filter.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPlatformVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getVmrVersionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getCameraNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getEventStartTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dataocean_global_pb.TimeSpan.serializeBinaryToWriter
    );
  }
  f = message.getEventTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getOreTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = message.getDiscardReasonsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * repeated string product_codes = 1;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Event.prototype.getProductCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.setProductCodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.addProductCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.clearProductCodesList = function() {
  return this.setProductCodesList([]);
};


/**
 * repeated string platform_versions = 2;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Event.prototype.getPlatformVersionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.setPlatformVersionsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.addPlatformVersions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.clearPlatformVersionsList = function() {
  return this.setPlatformVersionsList([]);
};


/**
 * repeated string vmr_versions = 3;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Event.prototype.getVmrVersionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.setVmrVersionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.addVmrVersions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.clearVmrVersionsList = function() {
  return this.setVmrVersionsList([]);
};


/**
 * repeated string camera_names = 4;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Event.prototype.getCameraNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.setCameraNamesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.addCameraNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.clearCameraNamesList = function() {
  return this.setCameraNamesList([]);
};


/**
 * optional TimeSpan event_start_time = 5;
 * @return {?proto.dataocean.TimeSpan}
 */
proto.dataocean.Metal.Filter.Event.prototype.getEventStartTime = function() {
  return /** @type{?proto.dataocean.TimeSpan} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.TimeSpan, 5));
};


/**
 * @param {?proto.dataocean.TimeSpan|undefined} value
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
*/
proto.dataocean.Metal.Filter.Event.prototype.setEventStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.clearEventStartTime = function() {
  return this.setEventStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.Event.prototype.hasEventStartTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string event_types = 6;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Event.prototype.getEventTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.setEventTypesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.addEventTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.clearEventTypesList = function() {
  return this.setEventTypesList([]);
};


/**
 * repeated OreType ore_types = 7;
 * @return {!Array<!proto.dataocean.Metal.OreType>}
 */
proto.dataocean.Metal.Filter.Event.prototype.getOreTypesList = function() {
  return /** @type {!Array<!proto.dataocean.Metal.OreType>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.dataocean.Metal.OreType>} value
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.setOreTypesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.dataocean.Metal.OreType} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.addOreTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.clearOreTypesList = function() {
  return this.setOreTypesList([]);
};


/**
 * repeated string discard_reasons = 8;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Event.prototype.getDiscardReasonsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.setDiscardReasonsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.addDiscardReasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.clearDiscardReasonsList = function() {
  return this.setDiscardReasonsList([]);
};


/**
 * optional string event_id = 9;
 * @return {string}
 */
proto.dataocean.Metal.Filter.Event.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Filter.Event} returns this
 */
proto.dataocean.Metal.Filter.Event.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Metal.Filter.Project.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Filter.Project.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Filter.Project.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Filter.Project} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Project.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectCodesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    customerNamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    division: (f = msg.getDivision()) && proto.dataocean.Metal.Division.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Filter.Project}
 */
proto.dataocean.Metal.Filter.Project.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Filter.Project;
  return proto.dataocean.Metal.Filter.Project.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Filter.Project} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Filter.Project}
 */
proto.dataocean.Metal.Filter.Project.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProjectCodes(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCustomerNames(value);
      break;
    case 3:
      var value = new proto.dataocean.Metal.Division;
      reader.readMessage(value,proto.dataocean.Metal.Division.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Filter.Project.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Filter.Project.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Filter.Project} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Project.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCustomerNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.Metal.Division.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string project_codes = 1;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Project.prototype.getProjectCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Project} returns this
 */
proto.dataocean.Metal.Filter.Project.prototype.setProjectCodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Project} returns this
 */
proto.dataocean.Metal.Filter.Project.prototype.addProjectCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Project} returns this
 */
proto.dataocean.Metal.Filter.Project.prototype.clearProjectCodesList = function() {
  return this.setProjectCodesList([]);
};


/**
 * repeated string customer_names = 2;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Project.prototype.getCustomerNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Project} returns this
 */
proto.dataocean.Metal.Filter.Project.prototype.setCustomerNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Project} returns this
 */
proto.dataocean.Metal.Filter.Project.prototype.addCustomerNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Project} returns this
 */
proto.dataocean.Metal.Filter.Project.prototype.clearCustomerNamesList = function() {
  return this.setCustomerNamesList([]);
};


/**
 * optional Division division = 3;
 * @return {?proto.dataocean.Metal.Division}
 */
proto.dataocean.Metal.Filter.Project.prototype.getDivision = function() {
  return /** @type{?proto.dataocean.Metal.Division} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Division, 3));
};


/**
 * @param {?proto.dataocean.Metal.Division|undefined} value
 * @return {!proto.dataocean.Metal.Filter.Project} returns this
*/
proto.dataocean.Metal.Filter.Project.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.Project} returns this
 */
proto.dataocean.Metal.Filter.Project.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.Project.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Metal.Filter.Collecting.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Filter.Collecting.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Filter.Collecting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Filter.Collecting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Collecting.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dataTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    uploaderNamesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    uploadTime: (f = msg.getUploadTime()) && dataocean_global_pb.TimeSpan.toObject(includeInstance, f),
    remark: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Filter.Collecting}
 */
proto.dataocean.Metal.Filter.Collecting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Filter.Collecting;
  return proto.dataocean.Metal.Filter.Collecting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Filter.Collecting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Filter.Collecting}
 */
proto.dataocean.Metal.Filter.Collecting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addBatches(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDataTypes(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addUploaderNames(value);
      break;
    case 4:
      var value = new dataocean_global_pb.TimeSpan;
      reader.readMessage(value,dataocean_global_pb.TimeSpan.deserializeBinaryFromReader);
      msg.setUploadTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Filter.Collecting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Filter.Collecting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Filter.Collecting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Collecting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDataTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getUploaderNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getUploadTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      dataocean_global_pb.TimeSpan.serializeBinaryToWriter
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated string batches = 1;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Collecting.prototype.getBatchesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.setBatchesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.addBatches = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.clearBatchesList = function() {
  return this.setBatchesList([]);
};


/**
 * repeated string data_types = 2;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Collecting.prototype.getDataTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.setDataTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.addDataTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.clearDataTypesList = function() {
  return this.setDataTypesList([]);
};


/**
 * repeated string uploader_names = 3;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.Collecting.prototype.getUploaderNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.setUploaderNamesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.addUploaderNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.clearUploaderNamesList = function() {
  return this.setUploaderNamesList([]);
};


/**
 * optional TimeSpan upload_time = 4;
 * @return {?proto.dataocean.TimeSpan}
 */
proto.dataocean.Metal.Filter.Collecting.prototype.getUploadTime = function() {
  return /** @type{?proto.dataocean.TimeSpan} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.TimeSpan, 4));
};


/**
 * @param {?proto.dataocean.TimeSpan|undefined} value
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
*/
proto.dataocean.Metal.Filter.Collecting.prototype.setUploadTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.clearUploadTime = function() {
  return this.setUploadTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.Collecting.prototype.hasUploadTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string remark = 5;
 * @return {string}
 */
proto.dataocean.Metal.Filter.Collecting.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Filter.Collecting} returns this
 */
proto.dataocean.Metal.Filter.Collecting.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.Metal.Filter.File.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Filter.File.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Filter.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Filter.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    size: (f = msg.getSize()) && dataocean_global_pb.SizeRange.toObject(includeInstance, f),
    mimeType: (f = msg.getMimeType()) && dataocean_global_pb.MIMEType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Filter.File}
 */
proto.dataocean.Metal.Filter.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Filter.File;
  return proto.dataocean.Metal.Filter.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Filter.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Filter.File}
 */
proto.dataocean.Metal.Filter.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 2:
      var value = new dataocean_global_pb.SizeRange;
      reader.readMessage(value,dataocean_global_pb.SizeRange.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 3:
      var value = new dataocean_global_pb.MIMEType;
      reader.readMessage(value,dataocean_global_pb.MIMEType.deserializeBinaryFromReader);
      msg.setMimeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Filter.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Filter.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Filter.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dataocean_global_pb.SizeRange.serializeBinaryToWriter
    );
  }
  f = message.getMimeType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      dataocean_global_pb.MIMEType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string tags = 1;
 * @return {!Array<string>}
 */
proto.dataocean.Metal.Filter.File.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.dataocean.Metal.Filter.File} returns this
 */
proto.dataocean.Metal.Filter.File.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Metal.Filter.File} returns this
 */
proto.dataocean.Metal.Filter.File.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.Metal.Filter.File} returns this
 */
proto.dataocean.Metal.Filter.File.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional SizeRange size = 2;
 * @return {?proto.dataocean.SizeRange}
 */
proto.dataocean.Metal.Filter.File.prototype.getSize = function() {
  return /** @type{?proto.dataocean.SizeRange} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.SizeRange, 2));
};


/**
 * @param {?proto.dataocean.SizeRange|undefined} value
 * @return {!proto.dataocean.Metal.Filter.File} returns this
*/
proto.dataocean.Metal.Filter.File.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.File} returns this
 */
proto.dataocean.Metal.Filter.File.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.File.prototype.hasSize = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MIMEType mime_type = 3;
 * @return {?proto.dataocean.MIMEType}
 */
proto.dataocean.Metal.Filter.File.prototype.getMimeType = function() {
  return /** @type{?proto.dataocean.MIMEType} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MIMEType, 3));
};


/**
 * @param {?proto.dataocean.MIMEType|undefined} value
 * @return {!proto.dataocean.Metal.Filter.File} returns this
*/
proto.dataocean.Metal.Filter.File.prototype.setMimeType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.File} returns this
 */
proto.dataocean.Metal.Filter.File.prototype.clearMimeType = function() {
  return this.setMimeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.File.prototype.hasMimeType = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Filter.Media.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Filter.Media.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Filter.Media} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Media.toObject = function(includeInstance, msg) {
  var f, obj = {
    resolution: (f = msg.getResolution()) && proto.dataocean.Metal.Resolution.toObject(includeInstance, f),
    video: (f = msg.getVideo()) && proto.dataocean.Metal.Filter.Video.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Filter.Media}
 */
proto.dataocean.Metal.Filter.Media.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Filter.Media;
  return proto.dataocean.Metal.Filter.Media.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Filter.Media} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Filter.Media}
 */
proto.dataocean.Metal.Filter.Media.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Metal.Resolution;
      reader.readMessage(value,proto.dataocean.Metal.Resolution.deserializeBinaryFromReader);
      msg.setResolution(value);
      break;
    case 2:
      var value = new proto.dataocean.Metal.Filter.Video;
      reader.readMessage(value,proto.dataocean.Metal.Filter.Video.deserializeBinaryFromReader);
      msg.setVideo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Filter.Media.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Filter.Media.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Filter.Media} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Media.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResolution();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Metal.Resolution.serializeBinaryToWriter
    );
  }
  f = message.getVideo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.Metal.Filter.Video.serializeBinaryToWriter
    );
  }
};


/**
 * optional Resolution resolution = 1;
 * @return {?proto.dataocean.Metal.Resolution}
 */
proto.dataocean.Metal.Filter.Media.prototype.getResolution = function() {
  return /** @type{?proto.dataocean.Metal.Resolution} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Resolution, 1));
};


/**
 * @param {?proto.dataocean.Metal.Resolution|undefined} value
 * @return {!proto.dataocean.Metal.Filter.Media} returns this
*/
proto.dataocean.Metal.Filter.Media.prototype.setResolution = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.Media} returns this
 */
proto.dataocean.Metal.Filter.Media.prototype.clearResolution = function() {
  return this.setResolution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.Media.prototype.hasResolution = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Video video = 2;
 * @return {?proto.dataocean.Metal.Filter.Video}
 */
proto.dataocean.Metal.Filter.Media.prototype.getVideo = function() {
  return /** @type{?proto.dataocean.Metal.Filter.Video} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Filter.Video, 2));
};


/**
 * @param {?proto.dataocean.Metal.Filter.Video|undefined} value
 * @return {!proto.dataocean.Metal.Filter.Media} returns this
*/
proto.dataocean.Metal.Filter.Media.prototype.setVideo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.Media} returns this
 */
proto.dataocean.Metal.Filter.Media.prototype.clearVideo = function() {
  return this.setVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.Media.prototype.hasVideo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Filter.Video.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Filter.Video.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Filter.Video} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Video.toObject = function(includeInstance, msg) {
  var f, obj = {
    duration: (f = msg.getDuration()) && dataocean_global_pb.Range64.toObject(includeInstance, f),
    frameRate: (f = msg.getFrameRate()) && dataocean_global_pb.Range.toObject(includeInstance, f),
    bitrate: (f = msg.getBitrate()) && dataocean_global_pb.Range.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Filter.Video}
 */
proto.dataocean.Metal.Filter.Video.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Filter.Video;
  return proto.dataocean.Metal.Filter.Video.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Filter.Video} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Filter.Video}
 */
proto.dataocean.Metal.Filter.Video.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dataocean_global_pb.Range64;
      reader.readMessage(value,dataocean_global_pb.Range64.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    case 2:
      var value = new dataocean_global_pb.Range;
      reader.readMessage(value,dataocean_global_pb.Range.deserializeBinaryFromReader);
      msg.setFrameRate(value);
      break;
    case 3:
      var value = new dataocean_global_pb.Range;
      reader.readMessage(value,dataocean_global_pb.Range.deserializeBinaryFromReader);
      msg.setBitrate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Filter.Video.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Filter.Video.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Filter.Video} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Filter.Video.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dataocean_global_pb.Range64.serializeBinaryToWriter
    );
  }
  f = message.getFrameRate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dataocean_global_pb.Range.serializeBinaryToWriter
    );
  }
  f = message.getBitrate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      dataocean_global_pb.Range.serializeBinaryToWriter
    );
  }
};


/**
 * optional Range64 duration = 1;
 * @return {?proto.dataocean.Range64}
 */
proto.dataocean.Metal.Filter.Video.prototype.getDuration = function() {
  return /** @type{?proto.dataocean.Range64} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Range64, 1));
};


/**
 * @param {?proto.dataocean.Range64|undefined} value
 * @return {!proto.dataocean.Metal.Filter.Video} returns this
*/
proto.dataocean.Metal.Filter.Video.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.Video} returns this
 */
proto.dataocean.Metal.Filter.Video.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.Video.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Range frame_rate = 2;
 * @return {?proto.dataocean.Range}
 */
proto.dataocean.Metal.Filter.Video.prototype.getFrameRate = function() {
  return /** @type{?proto.dataocean.Range} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Range, 2));
};


/**
 * @param {?proto.dataocean.Range|undefined} value
 * @return {!proto.dataocean.Metal.Filter.Video} returns this
*/
proto.dataocean.Metal.Filter.Video.prototype.setFrameRate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.Video} returns this
 */
proto.dataocean.Metal.Filter.Video.prototype.clearFrameRate = function() {
  return this.setFrameRate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.Video.prototype.hasFrameRate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Range bitrate = 3;
 * @return {?proto.dataocean.Range}
 */
proto.dataocean.Metal.Filter.Video.prototype.getBitrate = function() {
  return /** @type{?proto.dataocean.Range} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Range, 3));
};


/**
 * @param {?proto.dataocean.Range|undefined} value
 * @return {!proto.dataocean.Metal.Filter.Video} returns this
*/
proto.dataocean.Metal.Filter.Video.prototype.setBitrate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter.Video} returns this
 */
proto.dataocean.Metal.Filter.Video.prototype.clearBitrate = function() {
  return this.setBitrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.Video.prototype.hasBitrate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Event event = 1;
 * @return {?proto.dataocean.Metal.Filter.Event}
 */
proto.dataocean.Metal.Filter.prototype.getEvent = function() {
  return /** @type{?proto.dataocean.Metal.Filter.Event} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Filter.Event, 1));
};


/**
 * @param {?proto.dataocean.Metal.Filter.Event|undefined} value
 * @return {!proto.dataocean.Metal.Filter} returns this
*/
proto.dataocean.Metal.Filter.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter} returns this
 */
proto.dataocean.Metal.Filter.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Project project = 2;
 * @return {?proto.dataocean.Metal.Filter.Project}
 */
proto.dataocean.Metal.Filter.prototype.getProject = function() {
  return /** @type{?proto.dataocean.Metal.Filter.Project} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Filter.Project, 2));
};


/**
 * @param {?proto.dataocean.Metal.Filter.Project|undefined} value
 * @return {!proto.dataocean.Metal.Filter} returns this
*/
proto.dataocean.Metal.Filter.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter} returns this
 */
proto.dataocean.Metal.Filter.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.prototype.hasProject = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Collecting collecting = 3;
 * @return {?proto.dataocean.Metal.Filter.Collecting}
 */
proto.dataocean.Metal.Filter.prototype.getCollecting = function() {
  return /** @type{?proto.dataocean.Metal.Filter.Collecting} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Filter.Collecting, 3));
};


/**
 * @param {?proto.dataocean.Metal.Filter.Collecting|undefined} value
 * @return {!proto.dataocean.Metal.Filter} returns this
*/
proto.dataocean.Metal.Filter.prototype.setCollecting = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter} returns this
 */
proto.dataocean.Metal.Filter.prototype.clearCollecting = function() {
  return this.setCollecting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.prototype.hasCollecting = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional File file = 4;
 * @return {?proto.dataocean.Metal.Filter.File}
 */
proto.dataocean.Metal.Filter.prototype.getFile = function() {
  return /** @type{?proto.dataocean.Metal.Filter.File} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Filter.File, 4));
};


/**
 * @param {?proto.dataocean.Metal.Filter.File|undefined} value
 * @return {!proto.dataocean.Metal.Filter} returns this
*/
proto.dataocean.Metal.Filter.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter} returns this
 */
proto.dataocean.Metal.Filter.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.prototype.hasFile = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Media media = 5;
 * @return {?proto.dataocean.Metal.Filter.Media}
 */
proto.dataocean.Metal.Filter.prototype.getMedia = function() {
  return /** @type{?proto.dataocean.Metal.Filter.Media} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Metal.Filter.Media, 5));
};


/**
 * @param {?proto.dataocean.Metal.Filter.Media|undefined} value
 * @return {!proto.dataocean.Metal.Filter} returns this
*/
proto.dataocean.Metal.Filter.prototype.setMedia = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Metal.Filter} returns this
 */
proto.dataocean.Metal.Filter.prototype.clearMedia = function() {
  return this.setMedia(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Metal.Filter.prototype.hasMedia = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Division.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Division.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Division} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Division.toObject = function(includeInstance, msg) {
  var f, obj = {
    province: jspb.Message.getFieldWithDefault(msg, 1, ""),
    city: jspb.Message.getFieldWithDefault(msg, 2, ""),
    county: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Division}
 */
proto.dataocean.Metal.Division.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Division;
  return proto.dataocean.Metal.Division.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Division} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Division}
 */
proto.dataocean.Metal.Division.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvince(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCounty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Division.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Division.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Division} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Division.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProvince();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCounty();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string province = 1;
 * @return {string}
 */
proto.dataocean.Metal.Division.prototype.getProvince = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Division} returns this
 */
proto.dataocean.Metal.Division.prototype.setProvince = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string city = 2;
 * @return {string}
 */
proto.dataocean.Metal.Division.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Division} returns this
 */
proto.dataocean.Metal.Division.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string county = 3;
 * @return {string}
 */
proto.dataocean.Metal.Division.prototype.getCounty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Metal.Division} returns this
 */
proto.dataocean.Metal.Division.prototype.setCounty = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.Resolution.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.Resolution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.Resolution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Resolution.toObject = function(includeInstance, msg) {
  var f, obj = {
    height: jspb.Message.getFieldWithDefault(msg, 1, 0),
    width: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.Resolution}
 */
proto.dataocean.Metal.Resolution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.Resolution;
  return proto.dataocean.Metal.Resolution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.Resolution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.Resolution}
 */
proto.dataocean.Metal.Resolution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHeight(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.Resolution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.Resolution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.Resolution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.Resolution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeight();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getWidth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 height = 1;
 * @return {number}
 */
proto.dataocean.Metal.Resolution.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Metal.Resolution} returns this
 */
proto.dataocean.Metal.Resolution.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 width = 2;
 * @return {number}
 */
proto.dataocean.Metal.Resolution.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Metal.Resolution} returns this
 */
proto.dataocean.Metal.Resolution.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Metal.BlobSource.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Metal.BlobSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Metal.BlobSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.BlobSource.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Metal.BlobSource}
 */
proto.dataocean.Metal.BlobSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Metal.BlobSource;
  return proto.dataocean.Metal.BlobSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Metal.BlobSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Metal.BlobSource}
 */
proto.dataocean.Metal.BlobSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Metal.BlobSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Metal.BlobSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Metal.BlobSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Metal.BlobSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.dataocean);
