import { RefObject, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import React from 'react';
import { observer } from 'mobx-react';
import 'property-information';

const MarkdownContent = observer((props: { content: string }) => {
  return (
    <ReactMarkdown
      components={{
        p: (pProps) => <p {...pProps} dir="auto" />,
        a: (aProps) => {
          const href = aProps.href || '';
          const isInternal = /^\/#/i.test(href);
          const target = isInternal ? '_self' : aProps.target ?? '_blank';
          return <a {...aProps} target={target} />;
        }
      }}
    >
      {props.content}
    </ReactMarkdown>
  );
});

const Markdown = observer(
  (
    props: {
      content: string;
      loading?: boolean;
      fontSize?: number;
      parentRef?: RefObject<HTMLDivElement>;
      defaultShow?: boolean;
    } & React.DOMAttributes<HTMLDivElement>
  ) => {
    const mdRef = useRef<HTMLDivElement>(null);

    return (
      <div
        className="markdown-body"
        style={{
          fontSize: `${props.fontSize ?? 14}px`
        }}
        ref={mdRef}
        onContextMenu={props.onContextMenu}
        onDoubleClickCapture={props.onDoubleClickCapture}
        dir="auto"
      >
        <MarkdownContent content={props.content} />
      </div>
    );
  }
);

export default Markdown;
