import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, PageHeader, Popconfirm, Tabs, Tag, Table, Select, Spin, Modal, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { makeAutoObservable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { formatTime } from 'utils/timeUtil';
import grpc from 'api/grpc';
import { useEffect } from 'react';
import { buildObject } from 'utils/grpcUtil';
import copy from 'copy-to-clipboard';
import { KeyStatus } from 'atom-proto/web/atom/v2/product_key_pb';
import {
  CreateProductKeyReq,
  ListProductsKeyReq,
  ProductKey,
  RemoveProductKeyReq,
  UpdateProductKeyReq
} from 'atom-proto/web/atom/v2/product_key_pb';
class Status {
  productsKey: ProductKey[] = [];
  isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }
}
const status = new Status();
const KeyState = {
  [KeyStatus.KEYSTATUSUNKNOWN]: '未知',
  [KeyStatus.KEYSTATUSAVAILABLE]: '已启用',
  [KeyStatus.KEYSTATUSDISABLE]: '已禁用'
};
const AccessKey = observer(() => {
  const columns: ColumnsType<ProductKey> = [
    {
      title: 'key',
      align: 'center',
      render: (text, record) => {
        return record.getId()?.getHex();
      }
    },
    {
      title: '状态',
      align: 'center',
      render: (text, record) => {
        return (
          <span style={{ color: record.getStatus() === KeyStatus.KEYSTATUSAVAILABLE ? '#52c41a' : '#f5222d' }}>
            {KeyState[record.getStatus()]}
          </span>
        );
      }
    },
    {
      title: '最后使用时间',
      align: 'center',
      render: (text, record) => formatTime(record.getUpdatedat().getSeconds() * 1000)
    },
    {
      title: '创建时间',
      align: 'center',
      dataIndex: 'createdat',
      render: (text, record) => formatTime(record.getCreatedat().getSeconds() * 1000)
    },
    {
      title: '已创建时间',
      align: 'center',
      render: (text, record) => {
        // 使用 moment 计算距离现在已经过去了多久
        const duration = moment.duration(moment().diff(moment(record.getCreatedat().getSeconds() * 1000)));

        // 获取具体的时间差
        const days = duration.days();

        return days + '天';
      }
    },
    {
      title: '操作',
      align: 'center',
      width: 200,
      render(value, record: ProductKey) {
        if (record.getStatus() === KeyStatus.KEYSTATUSAVAILABLE) {
          return (
            <div>
              <Button
                onClick={() => {
                  const useconfig = {
                    title: '您将禁用密钥',
                    content: '禁用后无法使用，是否继续？',
                    async onOk() {
                      await grpc.productsKey.updateProductKey(
                        buildObject(new UpdateProductKeyReq(), (req) => {
                          record.setStatus(KeyStatus.KEYSTATUSDISABLE);
                          req.setProductkey(record);
                        })
                      );
                      message.success('禁用成功');
                      fetchProductsKey();
                    }
                  };
                  Modal.confirm(useconfig);
                }}
                type="link"
                size="small"
              >
                禁用
              </Button>
            </div>
          );
        }
        return (
          <div>
            <Button
              onClick={async () => {
                await grpc.productsKey.updateProductKey(
                  buildObject(new UpdateProductKeyReq(), (req) => {
                    record.setStatus(KeyStatus.KEYSTATUSAVAILABLE);
                    req.setProductkey(record);
                  })
                );
                message.success('启用成功');
                fetchProductsKey();
              }}
              type="link"
              size="small"
            >
              启用
            </Button>
            <Button
              onClick={() => {
                const useconfig = {
                  title: '您将删除密钥',
                  content: '删除后不再显示，是否继续？',
                  async onOk() {
                    await grpc.productsKey.removeProductKey(
                      buildObject(new RemoveProductKeyReq(), (req) => {
                        req.setId(record.getId()?.getHex());
                      })
                    );
                    message.success('删除成功');
                    fetchProductsKey();
                  }
                };
                Modal.confirm(useconfig);
              }}
              type="link"
              size="small"
            >
              删除
            </Button>
          </div>
        );
      }
    }
  ];
  const config = (token: string) => {
    return {
      title: 'Accesskey 创建成功',
      okText: '点击复制密钥',
      onOk: () => {
        copy(token);
        message.success('复制密钥成功');
        fetchProductsKey();
      },
      content: (
        <>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>密钥: {token}</div>
          <Tag icon={<InfoCircleOutlined />} color="warning">
            请妥善保存密钥，密钥只显示一次
          </Tag>
        </>
      )
    };
  };
  const fetchProductsKey = async () => {
    status.isLoading = true;
    const productsKeyReq = await grpc.productsKey.listProductsKey(buildObject(new ListProductsKeyReq(), (req) => {}));
    status.productsKey = productsKeyReq
      .getProductskeyList()
      .sort((a, b) => b.getCreatedat().getSeconds() - a.getCreatedat().getSeconds());
    status.isLoading = false;
  };
  useEffect(() => {
    fetchProductsKey();
  }, []);
  return (
    <PageHeader style={{ background: '#fff' }}>
      <Tag
        style={{ paddingLeft: 20, width: '100%', marginBottom: 20, height: '40px', lineHeight: '40px', fontSize: 14 }}
        icon={<InfoCircleOutlined />}
        color="processing"
      >
        <span style={{ color: '#000' }}>AccessKey Secret 是您访问使用atom api的秘钥，请务必妥善保管！</span>
      </Tag>
      <Button
        onClick={async () => {
          const createProdcutKeyReq = await grpc.productsKey.createProductKey(
            buildObject(new CreateProductKeyReq(), (req) => {})
          );

          const token = createProdcutKeyReq.toObject().token;
          Modal.success(config(token));
        }}
        style={{ marginBottom: 20 }}
        type="primary"
      >
        创建 key
      </Button>
      <Table<ProductKey>
        loading={status.isLoading}
        bordered
        rowKey={(record) => record.getId()?.getHex()}
        dataSource={status.productsKey}
        columns={columns}
        pagination={{
          pageSize: 50
        }}
      />
    </PageHeader>
  );
});

export default AccessKey;
