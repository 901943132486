// source: dataocean/labelx.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var dataocean_global_pb = require('../dataocean/global_pb.js');
goog.object.extend(proto, dataocean_global_pb);
goog.exportSymbol('proto.dataocean.Resource', null, global);
goog.exportSymbol('proto.dataocean.Resource.BlobFilter', null, global);
goog.exportSymbol('proto.dataocean.Resource.BlobSource', null, global);
goog.exportSymbol('proto.dataocean.Resource.Extension', null, global);
goog.exportSymbol('proto.dataocean.Resource.Source', null, global);
goog.exportSymbol('proto.dataocean.TaskLabel', null, global);
goog.exportSymbol('proto.dataocean.TaskLabelItem', null, global);
goog.exportSymbol('proto.dataocean.TaskLabelOption', null, global);
goog.exportSymbol('proto.dataocean.TaskType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.TaskLabelOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.TaskLabelOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.TaskLabelOption.displayName = 'proto.dataocean.TaskLabelOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.TaskLabelItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.TaskLabelItem.repeatedFields_, null);
};
goog.inherits(proto.dataocean.TaskLabelItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.TaskLabelItem.displayName = 'proto.dataocean.TaskLabelItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.TaskLabel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.TaskLabel.repeatedFields_, null);
};
goog.inherits(proto.dataocean.TaskLabel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.TaskLabel.displayName = 'proto.dataocean.TaskLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Resource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Resource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Resource.displayName = 'proto.dataocean.Resource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Resource.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Resource.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Resource.Source.displayName = 'proto.dataocean.Resource.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Resource.BlobSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Resource.BlobSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Resource.BlobSource.displayName = 'proto.dataocean.Resource.BlobSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Resource.BlobFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Resource.BlobFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Resource.BlobFilter.displayName = 'proto.dataocean.Resource.BlobFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Resource.Extension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Resource.Extension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Resource.Extension.displayName = 'proto.dataocean.Resource.Extension';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.TaskLabelOption.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.TaskLabelOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.TaskLabelOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TaskLabelOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    color: jspb.Message.getFieldWithDefault(msg, 2, ""),
    local: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.TaskLabelOption}
 */
proto.dataocean.TaskLabelOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.TaskLabelOption;
  return proto.dataocean.TaskLabelOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.TaskLabelOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.TaskLabelOption}
 */
proto.dataocean.TaskLabelOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.TaskLabelOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.TaskLabelOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.TaskLabelOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TaskLabelOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLocal();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.dataocean.TaskLabelOption.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.TaskLabelOption} returns this
 */
proto.dataocean.TaskLabelOption.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string color = 2;
 * @return {string}
 */
proto.dataocean.TaskLabelOption.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.TaskLabelOption} returns this
 */
proto.dataocean.TaskLabelOption.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string local = 3;
 * @return {string}
 */
proto.dataocean.TaskLabelOption.prototype.getLocal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.TaskLabelOption} returns this
 */
proto.dataocean.TaskLabelOption.prototype.setLocal = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.TaskLabelItem.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.TaskLabelItem.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.TaskLabelItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.TaskLabelItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TaskLabelItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tool: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.dataocean.TaskLabelOption.toObject, includeInstance),
    classificationsList: jspb.Message.toObjectList(msg.getClassificationsList(),
    proto.dataocean.TaskLabelItem.toObject, includeInstance),
    layer: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.TaskLabelItem}
 */
proto.dataocean.TaskLabelItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.TaskLabelItem;
  return proto.dataocean.TaskLabelItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.TaskLabelItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.TaskLabelItem}
 */
proto.dataocean.TaskLabelItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTool(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = new proto.dataocean.TaskLabelOption;
      reader.readMessage(value,proto.dataocean.TaskLabelOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 5:
      var value = new proto.dataocean.TaskLabelItem;
      reader.readMessage(value,proto.dataocean.TaskLabelItem.deserializeBinaryFromReader);
      msg.addClassifications(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLayer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.TaskLabelItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.TaskLabelItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.TaskLabelItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TaskLabelItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTool();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dataocean.TaskLabelOption.serializeBinaryToWriter
    );
  }
  f = message.getClassificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.dataocean.TaskLabelItem.serializeBinaryToWriter
    );
  }
  f = message.getLayer();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.dataocean.TaskLabelItem.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.TaskLabelItem} returns this
 */
proto.dataocean.TaskLabelItem.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tool = 2;
 * @return {string}
 */
proto.dataocean.TaskLabelItem.prototype.getTool = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.TaskLabelItem} returns this
 */
proto.dataocean.TaskLabelItem.prototype.setTool = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.dataocean.TaskLabelItem.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.TaskLabelItem} returns this
 */
proto.dataocean.TaskLabelItem.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated TaskLabelOption options = 4;
 * @return {!Array<!proto.dataocean.TaskLabelOption>}
 */
proto.dataocean.TaskLabelItem.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.dataocean.TaskLabelOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.TaskLabelOption, 4));
};


/**
 * @param {!Array<!proto.dataocean.TaskLabelOption>} value
 * @return {!proto.dataocean.TaskLabelItem} returns this
*/
proto.dataocean.TaskLabelItem.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dataocean.TaskLabelOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.TaskLabelOption}
 */
proto.dataocean.TaskLabelItem.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dataocean.TaskLabelOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.TaskLabelItem} returns this
 */
proto.dataocean.TaskLabelItem.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * repeated TaskLabelItem classifications = 5;
 * @return {!Array<!proto.dataocean.TaskLabelItem>}
 */
proto.dataocean.TaskLabelItem.prototype.getClassificationsList = function() {
  return /** @type{!Array<!proto.dataocean.TaskLabelItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.TaskLabelItem, 5));
};


/**
 * @param {!Array<!proto.dataocean.TaskLabelItem>} value
 * @return {!proto.dataocean.TaskLabelItem} returns this
*/
proto.dataocean.TaskLabelItem.prototype.setClassificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.dataocean.TaskLabelItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.TaskLabelItem}
 */
proto.dataocean.TaskLabelItem.prototype.addClassifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.dataocean.TaskLabelItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.TaskLabelItem} returns this
 */
proto.dataocean.TaskLabelItem.prototype.clearClassificationsList = function() {
  return this.setClassificationsList([]);
};


/**
 * optional int32 layer = 6;
 * @return {number}
 */
proto.dataocean.TaskLabelItem.prototype.getLayer = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.TaskLabelItem} returns this
 */
proto.dataocean.TaskLabelItem.prototype.setLayer = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.TaskLabel.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.TaskLabel.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.TaskLabel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.TaskLabel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TaskLabel.toObject = function(includeInstance, msg) {
  var f, obj = {
    classificationsList: jspb.Message.toObjectList(msg.getClassificationsList(),
    proto.dataocean.TaskLabelItem.toObject, includeInstance),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.dataocean.TaskLabelItem.toObject, includeInstance),
    detectionsList: jspb.Message.toObjectList(msg.getDetectionsList(),
    proto.dataocean.TaskLabelItem.toObject, includeInstance),
    anchorsList: jspb.Message.toObjectList(msg.getAnchorsList(),
    proto.dataocean.TaskLabelItem.toObject, includeInstance),
    segments3dList: jspb.Message.toObjectList(msg.getSegments3dList(),
    proto.dataocean.TaskLabelItem.toObject, includeInstance),
    detections3dList: jspb.Message.toObjectList(msg.getDetections3dList(),
    proto.dataocean.TaskLabelItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.TaskLabel}
 */
proto.dataocean.TaskLabel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.TaskLabel;
  return proto.dataocean.TaskLabel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.TaskLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.TaskLabel}
 */
proto.dataocean.TaskLabel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.TaskLabelItem;
      reader.readMessage(value,proto.dataocean.TaskLabelItem.deserializeBinaryFromReader);
      msg.addClassifications(value);
      break;
    case 2:
      var value = new proto.dataocean.TaskLabelItem;
      reader.readMessage(value,proto.dataocean.TaskLabelItem.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 3:
      var value = new proto.dataocean.TaskLabelItem;
      reader.readMessage(value,proto.dataocean.TaskLabelItem.deserializeBinaryFromReader);
      msg.addDetections(value);
      break;
    case 4:
      var value = new proto.dataocean.TaskLabelItem;
      reader.readMessage(value,proto.dataocean.TaskLabelItem.deserializeBinaryFromReader);
      msg.addAnchors(value);
      break;
    case 5:
      var value = new proto.dataocean.TaskLabelItem;
      reader.readMessage(value,proto.dataocean.TaskLabelItem.deserializeBinaryFromReader);
      msg.addSegments3d(value);
      break;
    case 6:
      var value = new proto.dataocean.TaskLabelItem;
      reader.readMessage(value,proto.dataocean.TaskLabelItem.deserializeBinaryFromReader);
      msg.addDetections3d(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.TaskLabel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.TaskLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.TaskLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.TaskLabel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataocean.TaskLabelItem.serializeBinaryToWriter
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.dataocean.TaskLabelItem.serializeBinaryToWriter
    );
  }
  f = message.getDetectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.dataocean.TaskLabelItem.serializeBinaryToWriter
    );
  }
  f = message.getAnchorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.dataocean.TaskLabelItem.serializeBinaryToWriter
    );
  }
  f = message.getSegments3dList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.dataocean.TaskLabelItem.serializeBinaryToWriter
    );
  }
  f = message.getDetections3dList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.dataocean.TaskLabelItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TaskLabelItem classifications = 1;
 * @return {!Array<!proto.dataocean.TaskLabelItem>}
 */
proto.dataocean.TaskLabel.prototype.getClassificationsList = function() {
  return /** @type{!Array<!proto.dataocean.TaskLabelItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.TaskLabelItem, 1));
};


/**
 * @param {!Array<!proto.dataocean.TaskLabelItem>} value
 * @return {!proto.dataocean.TaskLabel} returns this
*/
proto.dataocean.TaskLabel.prototype.setClassificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.TaskLabelItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.TaskLabelItem}
 */
proto.dataocean.TaskLabel.prototype.addClassifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.TaskLabelItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.TaskLabel} returns this
 */
proto.dataocean.TaskLabel.prototype.clearClassificationsList = function() {
  return this.setClassificationsList([]);
};


/**
 * repeated TaskLabelItem segments = 2;
 * @return {!Array<!proto.dataocean.TaskLabelItem>}
 */
proto.dataocean.TaskLabel.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.dataocean.TaskLabelItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.TaskLabelItem, 2));
};


/**
 * @param {!Array<!proto.dataocean.TaskLabelItem>} value
 * @return {!proto.dataocean.TaskLabel} returns this
*/
proto.dataocean.TaskLabel.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.dataocean.TaskLabelItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.TaskLabelItem}
 */
proto.dataocean.TaskLabel.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.dataocean.TaskLabelItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.TaskLabel} returns this
 */
proto.dataocean.TaskLabel.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * repeated TaskLabelItem detections = 3;
 * @return {!Array<!proto.dataocean.TaskLabelItem>}
 */
proto.dataocean.TaskLabel.prototype.getDetectionsList = function() {
  return /** @type{!Array<!proto.dataocean.TaskLabelItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.TaskLabelItem, 3));
};


/**
 * @param {!Array<!proto.dataocean.TaskLabelItem>} value
 * @return {!proto.dataocean.TaskLabel} returns this
*/
proto.dataocean.TaskLabel.prototype.setDetectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataocean.TaskLabelItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.TaskLabelItem}
 */
proto.dataocean.TaskLabel.prototype.addDetections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataocean.TaskLabelItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.TaskLabel} returns this
 */
proto.dataocean.TaskLabel.prototype.clearDetectionsList = function() {
  return this.setDetectionsList([]);
};


/**
 * repeated TaskLabelItem anchors = 4;
 * @return {!Array<!proto.dataocean.TaskLabelItem>}
 */
proto.dataocean.TaskLabel.prototype.getAnchorsList = function() {
  return /** @type{!Array<!proto.dataocean.TaskLabelItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.TaskLabelItem, 4));
};


/**
 * @param {!Array<!proto.dataocean.TaskLabelItem>} value
 * @return {!proto.dataocean.TaskLabel} returns this
*/
proto.dataocean.TaskLabel.prototype.setAnchorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dataocean.TaskLabelItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.TaskLabelItem}
 */
proto.dataocean.TaskLabel.prototype.addAnchors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dataocean.TaskLabelItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.TaskLabel} returns this
 */
proto.dataocean.TaskLabel.prototype.clearAnchorsList = function() {
  return this.setAnchorsList([]);
};


/**
 * repeated TaskLabelItem segments3d = 5;
 * @return {!Array<!proto.dataocean.TaskLabelItem>}
 */
proto.dataocean.TaskLabel.prototype.getSegments3dList = function() {
  return /** @type{!Array<!proto.dataocean.TaskLabelItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.TaskLabelItem, 5));
};


/**
 * @param {!Array<!proto.dataocean.TaskLabelItem>} value
 * @return {!proto.dataocean.TaskLabel} returns this
*/
proto.dataocean.TaskLabel.prototype.setSegments3dList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.dataocean.TaskLabelItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.TaskLabelItem}
 */
proto.dataocean.TaskLabel.prototype.addSegments3d = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.dataocean.TaskLabelItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.TaskLabel} returns this
 */
proto.dataocean.TaskLabel.prototype.clearSegments3dList = function() {
  return this.setSegments3dList([]);
};


/**
 * repeated TaskLabelItem detections3d = 6;
 * @return {!Array<!proto.dataocean.TaskLabelItem>}
 */
proto.dataocean.TaskLabel.prototype.getDetections3dList = function() {
  return /** @type{!Array<!proto.dataocean.TaskLabelItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.TaskLabelItem, 6));
};


/**
 * @param {!Array<!proto.dataocean.TaskLabelItem>} value
 * @return {!proto.dataocean.TaskLabel} returns this
*/
proto.dataocean.TaskLabel.prototype.setDetections3dList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.dataocean.TaskLabelItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.TaskLabelItem}
 */
proto.dataocean.TaskLabel.prototype.addDetections3d = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.dataocean.TaskLabelItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.TaskLabel} returns this
 */
proto.dataocean.TaskLabel.prototype.clearDetections3dList = function() {
  return this.setDetections3dList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Resource.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Resource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Resource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Resource}
 */
proto.dataocean.Resource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Resource;
  return proto.dataocean.Resource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Resource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Resource}
 */
proto.dataocean.Resource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Resource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Resource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Resource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Resource.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Resource.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Resource.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    resourceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    packageId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    labelUserId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mimeType: (f = msg.getMimeType()) && dataocean_global_pb.MIMEType.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fileName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bucket: jspb.Message.getFieldWithDefault(msg, 9, ""),
    size: jspb.Message.getFieldWithDefault(msg, 10, 0),
    annotation: msg.getAnnotation_asB64(),
    meta: (f = msg.getMeta()) && dataocean_global_pb.MediaMeta.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Resource.Source}
 */
proto.dataocean.Resource.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Resource.Source;
  return proto.dataocean.Resource.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Resource.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Resource.Source}
 */
proto.dataocean.Resource.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResourceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPackageId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLabelUserId(value);
      break;
    case 5:
      var value = new dataocean_global_pb.MIMEType;
      reader.readMessage(value,dataocean_global_pb.MIMEType.deserializeBinaryFromReader);
      msg.setMimeType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBucket(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAnnotation(value);
      break;
    case 12:
      var value = new dataocean_global_pb.MediaMeta;
      reader.readMessage(value,dataocean_global_pb.MediaMeta.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Resource.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Resource.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Resource.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResourceId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPackageId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLabelUserId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMimeType();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dataocean_global_pb.MIMEType.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdateTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBucket();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getAnnotation_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      dataocean_global_pb.MediaMeta.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 resource_id = 1;
 * @return {number}
 */
proto.dataocean.Resource.Source.prototype.getResourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 task_id = 2;
 * @return {number}
 */
proto.dataocean.Resource.Source.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 package_id = 3;
 * @return {number}
 */
proto.dataocean.Resource.Source.prototype.getPackageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 label_user_id = 4;
 * @return {number}
 */
proto.dataocean.Resource.Source.prototype.getLabelUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.setLabelUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional MIMEType mime_type = 5;
 * @return {?proto.dataocean.MIMEType}
 */
proto.dataocean.Resource.Source.prototype.getMimeType = function() {
  return /** @type{?proto.dataocean.MIMEType} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MIMEType, 5));
};


/**
 * @param {?proto.dataocean.MIMEType|undefined} value
 * @return {!proto.dataocean.Resource.Source} returns this
*/
proto.dataocean.Resource.Source.prototype.setMimeType = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.clearMimeType = function() {
  return this.setMimeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Resource.Source.prototype.hasMimeType = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string url = 6;
 * @return {string}
 */
proto.dataocean.Resource.Source.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp update_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.Resource.Source.prototype.getUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.Resource.Source} returns this
*/
proto.dataocean.Resource.Source.prototype.setUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.clearUpdateTime = function() {
  return this.setUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Resource.Source.prototype.hasUpdateTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string file_name = 8;
 * @return {string}
 */
proto.dataocean.Resource.Source.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string bucket = 9;
 * @return {string}
 */
proto.dataocean.Resource.Source.prototype.getBucket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.setBucket = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint64 size = 10;
 * @return {number}
 */
proto.dataocean.Resource.Source.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bytes annotation = 11;
 * @return {string}
 */
proto.dataocean.Resource.Source.prototype.getAnnotation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes annotation = 11;
 * This is a type-conversion wrapper around `getAnnotation()`
 * @return {string}
 */
proto.dataocean.Resource.Source.prototype.getAnnotation_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAnnotation()));
};


/**
 * optional bytes annotation = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAnnotation()`
 * @return {!Uint8Array}
 */
proto.dataocean.Resource.Source.prototype.getAnnotation_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAnnotation()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.setAnnotation = function(value) {
  return jspb.Message.setProto3BytesField(this, 11, value);
};


/**
 * optional MediaMeta meta = 12;
 * @return {?proto.dataocean.MediaMeta}
 */
proto.dataocean.Resource.Source.prototype.getMeta = function() {
  return /** @type{?proto.dataocean.MediaMeta} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MediaMeta, 12));
};


/**
 * @param {?proto.dataocean.MediaMeta|undefined} value
 * @return {!proto.dataocean.Resource.Source} returns this
*/
proto.dataocean.Resource.Source.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Resource.Source} returns this
 */
proto.dataocean.Resource.Source.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Resource.Source.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Resource.BlobSource.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Resource.BlobSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Resource.BlobSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.BlobSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    packageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    packageName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    taskCreator: jspb.Message.getFieldWithDefault(msg, 5, ""),
    packageCreator: jspb.Message.getFieldWithDefault(msg, 6, ""),
    taskPackageCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    taskType: jspb.Message.getFieldWithDefault(msg, 8, 0),
    archiveTime: (f = msg.getArchiveTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    taskLabel: (f = msg.getTaskLabel()) && proto.dataocean.TaskLabel.toObject(includeInstance, f),
    packageResourceCount: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Resource.BlobSource}
 */
proto.dataocean.Resource.BlobSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Resource.BlobSource;
  return proto.dataocean.Resource.BlobSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Resource.BlobSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Resource.BlobSource}
 */
proto.dataocean.Resource.BlobSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskCreator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageCreator(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaskPackageCount(value);
      break;
    case 8:
      var value = /** @type {!proto.dataocean.TaskType} */ (reader.readEnum());
      msg.setTaskType(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setArchiveTime(value);
      break;
    case 10:
      var value = new proto.dataocean.TaskLabel;
      reader.readMessage(value,proto.dataocean.TaskLabel.deserializeBinaryFromReader);
      msg.setTaskLabel(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPackageResourceCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Resource.BlobSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Resource.BlobSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Resource.BlobSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.BlobSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPackageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPackageName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTaskCreator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPackageCreator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTaskPackageCount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTaskType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getArchiveTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTaskLabel();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.dataocean.TaskLabel.serializeBinaryToWriter
    );
  }
  f = message.getPackageResourceCount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.dataocean.Resource.BlobSource.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_name = 2;
 * @return {string}
 */
proto.dataocean.Resource.BlobSource.prototype.getTaskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.setTaskName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string package_id = 3;
 * @return {string}
 */
proto.dataocean.Resource.BlobSource.prototype.getPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string package_name = 4;
 * @return {string}
 */
proto.dataocean.Resource.BlobSource.prototype.getPackageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.setPackageName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string task_creator = 5;
 * @return {string}
 */
proto.dataocean.Resource.BlobSource.prototype.getTaskCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.setTaskCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string package_creator = 6;
 * @return {string}
 */
proto.dataocean.Resource.BlobSource.prototype.getPackageCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.setPackageCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 task_package_count = 7;
 * @return {number}
 */
proto.dataocean.Resource.BlobSource.prototype.getTaskPackageCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.setTaskPackageCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional TaskType task_type = 8;
 * @return {!proto.dataocean.TaskType}
 */
proto.dataocean.Resource.BlobSource.prototype.getTaskType = function() {
  return /** @type {!proto.dataocean.TaskType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.dataocean.TaskType} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.setTaskType = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp archive_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.dataocean.Resource.BlobSource.prototype.getArchiveTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
*/
proto.dataocean.Resource.BlobSource.prototype.setArchiveTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.clearArchiveTime = function() {
  return this.setArchiveTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Resource.BlobSource.prototype.hasArchiveTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional TaskLabel task_label = 10;
 * @return {?proto.dataocean.TaskLabel}
 */
proto.dataocean.Resource.BlobSource.prototype.getTaskLabel = function() {
  return /** @type{?proto.dataocean.TaskLabel} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.TaskLabel, 10));
};


/**
 * @param {?proto.dataocean.TaskLabel|undefined} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
*/
proto.dataocean.Resource.BlobSource.prototype.setTaskLabel = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.clearTaskLabel = function() {
  return this.setTaskLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Resource.BlobSource.prototype.hasTaskLabel = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 package_resource_count = 11;
 * @return {number}
 */
proto.dataocean.Resource.BlobSource.prototype.getPackageResourceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.BlobSource} returns this
 */
proto.dataocean.Resource.BlobSource.prototype.setPackageResourceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Resource.BlobFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Resource.BlobFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Resource.BlobFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.BlobFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    packageId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    packageName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    taskCreator: jspb.Message.getFieldWithDefault(msg, 5, ""),
    packageCreator: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Resource.BlobFilter}
 */
proto.dataocean.Resource.BlobFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Resource.BlobFilter;
  return proto.dataocean.Resource.BlobFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Resource.BlobFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Resource.BlobFilter}
 */
proto.dataocean.Resource.BlobFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskCreator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPackageCreator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Resource.BlobFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Resource.BlobFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Resource.BlobFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.BlobFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPackageId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPackageName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTaskCreator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPackageCreator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string task_id = 1;
 * @return {string}
 */
proto.dataocean.Resource.BlobFilter.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobFilter} returns this
 */
proto.dataocean.Resource.BlobFilter.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_name = 2;
 * @return {string}
 */
proto.dataocean.Resource.BlobFilter.prototype.getTaskName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobFilter} returns this
 */
proto.dataocean.Resource.BlobFilter.prototype.setTaskName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string package_id = 3;
 * @return {string}
 */
proto.dataocean.Resource.BlobFilter.prototype.getPackageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobFilter} returns this
 */
proto.dataocean.Resource.BlobFilter.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string package_name = 4;
 * @return {string}
 */
proto.dataocean.Resource.BlobFilter.prototype.getPackageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobFilter} returns this
 */
proto.dataocean.Resource.BlobFilter.prototype.setPackageName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string task_creator = 5;
 * @return {string}
 */
proto.dataocean.Resource.BlobFilter.prototype.getTaskCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobFilter} returns this
 */
proto.dataocean.Resource.BlobFilter.prototype.setTaskCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string package_creator = 6;
 * @return {string}
 */
proto.dataocean.Resource.BlobFilter.prototype.getPackageCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.Resource.BlobFilter} returns this
 */
proto.dataocean.Resource.BlobFilter.prototype.setPackageCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Resource.Extension.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Resource.Extension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Resource.Extension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.Extension.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    packageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    resourceId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Resource.Extension}
 */
proto.dataocean.Resource.Extension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Resource.Extension;
  return proto.dataocean.Resource.Extension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Resource.Extension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Resource.Extension}
 */
proto.dataocean.Resource.Extension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPackageId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setResourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Resource.Extension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Resource.Extension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Resource.Extension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Resource.Extension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPackageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getResourceId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 task_id = 1;
 * @return {number}
 */
proto.dataocean.Resource.Extension.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.Extension} returns this
 */
proto.dataocean.Resource.Extension.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 package_id = 2;
 * @return {number}
 */
proto.dataocean.Resource.Extension.prototype.getPackageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.Extension} returns this
 */
proto.dataocean.Resource.Extension.prototype.setPackageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 resource_id = 3;
 * @return {number}
 */
proto.dataocean.Resource.Extension.prototype.getResourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.Resource.Extension} returns this
 */
proto.dataocean.Resource.Extension.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.dataocean.TaskType = {
  UNKNOWN: 0,
  CLASSIFICATION: 1,
  DETECTION: 2,
  SEGMENTATION: 3,
  ANCHOR: 4,
  CLIPPING: 5
};

goog.object.extend(exports, proto.dataocean);
