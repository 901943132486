// source: atom/v2/product_activation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js');
goog.object.extend(proto, github_com_envoyproxy_protoc$gen$validate_validate_validate_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var meta_meta_pb = require('../../meta/meta_pb.js');
goog.object.extend(proto, meta_meta_pb);
goog.exportSymbol('proto.atom.v2.ActivatedStatus', null, global);
goog.exportSymbol('proto.atom.v2.GetProductActivationReq', null, global);
goog.exportSymbol('proto.atom.v2.ListProductsActivationReq', null, global);
goog.exportSymbol('proto.atom.v2.ListProductsActivationRes', null, global);
goog.exportSymbol('proto.atom.v2.ProductActivation', null, global);
goog.exportSymbol('proto.atom.v2.UpdateProductActivationReq', null, global);
goog.exportSymbol('proto.atom.v2.UpdateProductActivationRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ProductActivation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.ProductActivation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ProductActivation.displayName = 'proto.atom.v2.ProductActivation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.UpdateProductActivationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.UpdateProductActivationReq.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.UpdateProductActivationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.UpdateProductActivationReq.displayName = 'proto.atom.v2.UpdateProductActivationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.UpdateProductActivationRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.UpdateProductActivationRes.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.UpdateProductActivationRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.UpdateProductActivationRes.displayName = 'proto.atom.v2.UpdateProductActivationRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.GetProductActivationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.atom.v2.GetProductActivationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.GetProductActivationReq.displayName = 'proto.atom.v2.GetProductActivationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListProductsActivationReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.ListProductsActivationReq.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.ListProductsActivationReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListProductsActivationReq.displayName = 'proto.atom.v2.ListProductsActivationReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.atom.v2.ListProductsActivationRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.atom.v2.ListProductsActivationRes.repeatedFields_, null);
};
goog.inherits(proto.atom.v2.ListProductsActivationRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.atom.v2.ListProductsActivationRes.displayName = 'proto.atom.v2.ListProductsActivationRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ProductActivation.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ProductActivation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ProductActivation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ProductActivation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && meta_meta_pb.ID.toObject(includeInstance, f),
    productid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdat: (f = msg.getCreatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedat: (f = msg.getUpdatedat()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ProductActivation}
 */
proto.atom.v2.ProductActivation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ProductActivation;
  return proto.atom.v2.ProductActivation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ProductActivation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ProductActivation}
 */
proto.atom.v2.ProductActivation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 3:
      var value = /** @type {!proto.atom.v2.ActivatedStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedat(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ProductActivation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ProductActivation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ProductActivation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ProductActivation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.ID id = 1;
 * @return {?proto.meta.ID}
 */
proto.atom.v2.ProductActivation.prototype.getId = function() {
  return /** @type{?proto.meta.ID} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.ID, 1));
};


/**
 * @param {?proto.meta.ID|undefined} value
 * @return {!proto.atom.v2.ProductActivation} returns this
*/
proto.atom.v2.ProductActivation.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ProductActivation} returns this
 */
proto.atom.v2.ProductActivation.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ProductActivation.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string productId = 2;
 * @return {string}
 */
proto.atom.v2.ProductActivation.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.ProductActivation} returns this
 */
proto.atom.v2.ProductActivation.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ActivatedStatus status = 3;
 * @return {!proto.atom.v2.ActivatedStatus}
 */
proto.atom.v2.ProductActivation.prototype.getStatus = function() {
  return /** @type {!proto.atom.v2.ActivatedStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.atom.v2.ActivatedStatus} value
 * @return {!proto.atom.v2.ProductActivation} returns this
 */
proto.atom.v2.ProductActivation.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdAt = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.ProductActivation.prototype.getCreatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.ProductActivation} returns this
*/
proto.atom.v2.ProductActivation.prototype.setCreatedat = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ProductActivation} returns this
 */
proto.atom.v2.ProductActivation.prototype.clearCreatedat = function() {
  return this.setCreatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ProductActivation.prototype.hasCreatedat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp updatedAt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.atom.v2.ProductActivation.prototype.getUpdatedat = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.atom.v2.ProductActivation} returns this
*/
proto.atom.v2.ProductActivation.prototype.setUpdatedat = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.atom.v2.ProductActivation} returns this
 */
proto.atom.v2.ProductActivation.prototype.clearUpdatedat = function() {
  return this.setUpdatedat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.atom.v2.ProductActivation.prototype.hasUpdatedat = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.UpdateProductActivationReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.UpdateProductActivationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.UpdateProductActivationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.UpdateProductActivationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateProductActivationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    productidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.UpdateProductActivationReq}
 */
proto.atom.v2.UpdateProductActivationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.UpdateProductActivationReq;
  return proto.atom.v2.UpdateProductActivationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.UpdateProductActivationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.UpdateProductActivationReq}
 */
proto.atom.v2.UpdateProductActivationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductids(value);
      break;
    case 2:
      var value = /** @type {!proto.atom.v2.ActivatedStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.UpdateProductActivationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.UpdateProductActivationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.UpdateProductActivationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateProductActivationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated string productIds = 1;
 * @return {!Array<string>}
 */
proto.atom.v2.UpdateProductActivationReq.prototype.getProductidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.atom.v2.UpdateProductActivationReq} returns this
 */
proto.atom.v2.UpdateProductActivationReq.prototype.setProductidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.UpdateProductActivationReq} returns this
 */
proto.atom.v2.UpdateProductActivationReq.prototype.addProductids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.UpdateProductActivationReq} returns this
 */
proto.atom.v2.UpdateProductActivationReq.prototype.clearProductidsList = function() {
  return this.setProductidsList([]);
};


/**
 * optional ActivatedStatus status = 2;
 * @return {!proto.atom.v2.ActivatedStatus}
 */
proto.atom.v2.UpdateProductActivationReq.prototype.getStatus = function() {
  return /** @type {!proto.atom.v2.ActivatedStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.atom.v2.ActivatedStatus} value
 * @return {!proto.atom.v2.UpdateProductActivationReq} returns this
 */
proto.atom.v2.UpdateProductActivationReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.UpdateProductActivationRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.UpdateProductActivationRes.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.UpdateProductActivationRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.UpdateProductActivationRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateProductActivationRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsactivationList: jspb.Message.toObjectList(msg.getProductsactivationList(),
    proto.atom.v2.ProductActivation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.UpdateProductActivationRes}
 */
proto.atom.v2.UpdateProductActivationRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.UpdateProductActivationRes;
  return proto.atom.v2.UpdateProductActivationRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.UpdateProductActivationRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.UpdateProductActivationRes}
 */
proto.atom.v2.UpdateProductActivationRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.ProductActivation;
      reader.readMessage(value,proto.atom.v2.ProductActivation.deserializeBinaryFromReader);
      msg.addProductsactivation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.UpdateProductActivationRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.UpdateProductActivationRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.UpdateProductActivationRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.UpdateProductActivationRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsactivationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.atom.v2.ProductActivation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProductActivation productsActivation = 1;
 * @return {!Array<!proto.atom.v2.ProductActivation>}
 */
proto.atom.v2.UpdateProductActivationRes.prototype.getProductsactivationList = function() {
  return /** @type{!Array<!proto.atom.v2.ProductActivation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.atom.v2.ProductActivation, 1));
};


/**
 * @param {!Array<!proto.atom.v2.ProductActivation>} value
 * @return {!proto.atom.v2.UpdateProductActivationRes} returns this
*/
proto.atom.v2.UpdateProductActivationRes.prototype.setProductsactivationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.atom.v2.ProductActivation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.ProductActivation}
 */
proto.atom.v2.UpdateProductActivationRes.prototype.addProductsactivation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.atom.v2.ProductActivation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.UpdateProductActivationRes} returns this
 */
proto.atom.v2.UpdateProductActivationRes.prototype.clearProductsactivationList = function() {
  return this.setProductsactivationList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.GetProductActivationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.GetProductActivationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.GetProductActivationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetProductActivationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.GetProductActivationReq}
 */
proto.atom.v2.GetProductActivationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.GetProductActivationReq;
  return proto.atom.v2.GetProductActivationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.GetProductActivationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.GetProductActivationReq}
 */
proto.atom.v2.GetProductActivationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.GetProductActivationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.GetProductActivationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.GetProductActivationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.GetProductActivationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string productId = 1;
 * @return {string}
 */
proto.atom.v2.GetProductActivationReq.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.atom.v2.GetProductActivationReq} returns this
 */
proto.atom.v2.GetProductActivationReq.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.ListProductsActivationReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListProductsActivationReq.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListProductsActivationReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListProductsActivationReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListProductsActivationReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    productidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListProductsActivationReq}
 */
proto.atom.v2.ListProductsActivationReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListProductsActivationReq;
  return proto.atom.v2.ListProductsActivationReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListProductsActivationReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListProductsActivationReq}
 */
proto.atom.v2.ListProductsActivationReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductids(value);
      break;
    case 2:
      var value = /** @type {!proto.atom.v2.ActivatedStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListProductsActivationReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListProductsActivationReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListProductsActivationReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListProductsActivationReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * repeated string productIds = 1;
 * @return {!Array<string>}
 */
proto.atom.v2.ListProductsActivationReq.prototype.getProductidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.atom.v2.ListProductsActivationReq} returns this
 */
proto.atom.v2.ListProductsActivationReq.prototype.setProductidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.ListProductsActivationReq} returns this
 */
proto.atom.v2.ListProductsActivationReq.prototype.addProductids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListProductsActivationReq} returns this
 */
proto.atom.v2.ListProductsActivationReq.prototype.clearProductidsList = function() {
  return this.setProductidsList([]);
};


/**
 * optional ActivatedStatus status = 2;
 * @return {!proto.atom.v2.ActivatedStatus}
 */
proto.atom.v2.ListProductsActivationReq.prototype.getStatus = function() {
  return /** @type {!proto.atom.v2.ActivatedStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.atom.v2.ActivatedStatus} value
 * @return {!proto.atom.v2.ListProductsActivationReq} returns this
 */
proto.atom.v2.ListProductsActivationReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.atom.v2.ListProductsActivationRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.atom.v2.ListProductsActivationRes.prototype.toObject = function(opt_includeInstance) {
  return proto.atom.v2.ListProductsActivationRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.atom.v2.ListProductsActivationRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListProductsActivationRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    productsactivationList: jspb.Message.toObjectList(msg.getProductsactivationList(),
    proto.atom.v2.ProductActivation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.atom.v2.ListProductsActivationRes}
 */
proto.atom.v2.ListProductsActivationRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.atom.v2.ListProductsActivationRes;
  return proto.atom.v2.ListProductsActivationRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.atom.v2.ListProductsActivationRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.atom.v2.ListProductsActivationRes}
 */
proto.atom.v2.ListProductsActivationRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.atom.v2.ProductActivation;
      reader.readMessage(value,proto.atom.v2.ProductActivation.deserializeBinaryFromReader);
      msg.addProductsactivation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.atom.v2.ListProductsActivationRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.atom.v2.ListProductsActivationRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.atom.v2.ListProductsActivationRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.atom.v2.ListProductsActivationRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductsactivationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.atom.v2.ProductActivation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProductActivation productsActivation = 1;
 * @return {!Array<!proto.atom.v2.ProductActivation>}
 */
proto.atom.v2.ListProductsActivationRes.prototype.getProductsactivationList = function() {
  return /** @type{!Array<!proto.atom.v2.ProductActivation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.atom.v2.ProductActivation, 1));
};


/**
 * @param {!Array<!proto.atom.v2.ProductActivation>} value
 * @return {!proto.atom.v2.ListProductsActivationRes} returns this
*/
proto.atom.v2.ListProductsActivationRes.prototype.setProductsactivationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.atom.v2.ProductActivation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.atom.v2.ProductActivation}
 */
proto.atom.v2.ListProductsActivationRes.prototype.addProductsactivation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.atom.v2.ProductActivation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.atom.v2.ListProductsActivationRes} returns this
 */
proto.atom.v2.ListProductsActivationRes.prototype.clearProductsactivationList = function() {
  return this.setProductsactivationList([]);
};


/**
 * @enum {number}
 */
proto.atom.v2.ActivatedStatus = {
  ACTIVATEDSTATUSUNKNOWN: 0,
  ACTIVATEDSTATUSON: 1,
  ACTIVATEDSTATUSOFF: 2
};

goog.object.extend(exports, proto.atom.v2);
