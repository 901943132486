import { Col, Row, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ChatUI from 'components/chat/Chat';
import { useSearchParams } from 'react-router-dom';
import './index.less';
import { makeAutoObservable } from 'mobx';
import { Conversation } from 'types/chat';
import Markdown from 'components/chat/Markdown';

import img1 from 'src/assets/service/1.png';
import img2 from 'src/assets/service/2.png';
import img3 from 'src/assets/service/3.png';
import globalStore from 'src/stores/globalStore';

class Status {
  conversations: Conversation[] = [
    {
      id: '1',
      name: '',
      messages: []
    }
  ];

  constructor() {
    makeAutoObservable(this);
  }
}
const status = new Status();

function sectionBackground(url: string): React.CSSProperties {
  return {
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: '40%'
  };
}

const Chat: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {}, []);
  const [searchParams] = useSearchParams();

  const paramValue = searchParams.get('model');
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <ChatUI
        style={{ height: '100%' }}
        conversions={status.conversations}
        apiHost={globalStore.chat_host}
        model={paramValue || ''}
      ></ChatUI>
    </div>
  );
};

export default Chat;

export enum ModelId {
  'chatglm2-6b' = 'chatglm2-6b'
}

export const models = {
  [ModelId['chatglm2-6b']]: {
    id: ModelId['chatglm2-6b'],
    name: 'chatglm2-6b'
  }
};
