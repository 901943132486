import {
  Button,
  Col,
  Image,
  Input,
  InputNumber,
  List,
  Popconfirm,
  Row,
  Select,
  Slider,
  Spin,
  Upload,
  message
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Conversation, Message, ImageType, Model } from 'types/chat';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

import './Chat.less';
import { divide, last, throttle } from 'lodash';
import { withAbort } from 'utils/index';
import { EventSourceParserStream } from 'eventsource-parser/dist/stream';
import { observer } from 'mobx-react';
import clearIcon from 'src/assets/service/clear.png';
import { DeleteOutlined, PictureOutlined, UploadOutlined } from '@ant-design/icons';
import { makeAutoObservable, toJS } from 'mobx';
import stringUtil from 'utils/stringUtil';
import { UploadFile } from 'antd/es/upload/interface';
import { getServices } from 'api/grpc/service';
import { modelServiceType } from 'utils/resourceUtil';

type IChatProps = {
  conversions: Conversation[];
  onClearConversion?: () => any;
  apiHost: string;
  model: string;
  style?: React.CSSProperties;
};

class Status {
  autoScroll: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }
}
const status = new Status();

const Chat: React.FC<IChatProps> = ({ conversions, style, apiHost, model }) => {
  const conversion = conversions[0];
  const [top_p, setTop] = useState(0.95);
  const [temperature, setTemperature] = useState(0.7);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [prompt, setPrompt] = useState<string>('');

  const [question, setQuestion] = useState('');
  const [answering, setAnswering] = useState(false);
  const [models, setModels] = useState<modelServiceType[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>();

  const chatBody = useRef<HTMLDivElement>(null);

  const abortControlRef = useRef<AbortController>();

  const maxFileLen = 5;
  const scrollToBottom = (behavior: ScrollBehavior = 'smooth') => {
    requestAnimationFrame(() => {
      chatBody.current?.scrollTo({
        top: chatBody.current.scrollHeight,
        behavior
      });
    });
  };

  const throttledScrollToBottom = throttle(scrollToBottom, 250);
  const handleStream = async (response: Response) => {
    setAnswering(true);

    conversion.messages.push({
      content: '',
      role: 'assistant'
    });

    const answerMessage = last(conversion.messages)!;

    const eventStream = response.body!.pipeThrough(new TextDecoderStream()).pipeThrough(new EventSourceParserStream());

    const res = new Response(eventStream);

    const reader = res.body!.getReader();

    while (true) {
      const { value, done } = await reader.read();

      if (done) {
        break;
      }

      try {
        const data = (value as any).data;
        const json = JSON.parse(data);
        if (json.choices[0].finish_reason != null) {
          return;
        }
        const text = json.choices[0].delta.content;
        if (text) {
          answerMessage.content += text;
        }
      } catch (e) {
        console.log(e);
      }

      status.autoScroll && throttledScrollToBottom();
    }
  };
  const onSend = async (question: string) => {
    conversion.messages.push({ content: question, role: 'user' });
    try {
      const abortControl = new AbortController();
      abortControlRef.current = abortControl;

      const response = await fetch(`${apiHost}/v1/chat/completions`, {
        signal: abortControl.signal,
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'ATOM-PRODUCT': selectedModel,
          Authorization: 'Bearer ',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          stream: true,
          model: selectedModel,
          messages: conversion.messages,
          temperature: temperature,
          top_p: top_p
        })
      });
      setQuestion('');
      if (!response.ok) {
        handleError(response);
        return;
      }
      await handleStream(response);
    } catch (err) {
      if (err.name !== 'AbortError') {
        status.autoScroll && throttledScrollToBottom();
        message.error(err.message || '');
      }
    } finally {
      handleResetFiles();
      setAnswering(false);
    }
  };
  const handleError = async (response: Response) => {
    const reader = response.body!.getReader();
    const decoder = new TextDecoder('utf-8');
    let chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks.push(decoder.decode(value, { stream: true }));
    }
    chunks.push(decoder.decode());
    const result = chunks.join('');
    try {
      message.error(JSON.parse(result).message);
    } catch {
      message.error('服务出错');
    }
  };
  const onScroll = () => {
    if (chatBody.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBody.current;
      const bottomTolerance = 20;

      if (scrollTop + clientHeight < scrollHeight - bottomTolerance) {
        status.autoScroll = false;
      } else {
        status.autoScroll = true;
      }
    }
  };
  const getModels = async () => {
    const modelList = await getServices();
    setModels(modelList);
    if (model) {
      setSelectedModel(model);
    } else {
      setSelectedModel(modelList?.[0]?.productid);
    }
  };

  useEffect(() => {
    getModels();
  }, []);

  const handleResetFiles = () => {
    setFileList([]);
  };

  // 处理上传文件限制
  const beforeUpload = (file: UploadFile) => {
    if (fileList.length >= maxFileLen) {
      message.warning(`最多只能上传 ${maxFileLen} 个文件`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = ({ file, fileList }: { file: UploadFile; fileList: UploadFile[] }) => {
    if (file.status === 'done') {
      message.success(`${file.name} 上传成功`);
    } else if (file.status === 'error') {
      message.error(`${file.name} 上传失败`);
    }
    setFileList(fileList);
  };

  const handleRemove = (file: UploadFile) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
  };

  const uploadFileoServer = async (file: string) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) throw new Error('上传失败');

      const result = await response.json();
      return result.url;
    } catch (error) {
      console.error('上传失败', error);
      return null;
    }
  };

  // 处理粘贴的文件上传
  const handlePasteFiles = async (pastedFiles) => {
    if (fileList.length + pastedFiles.length > maxFileLen) {
      message.warning(`最多只能上传 ${maxFileLen} 个文件`);
      return;
    }

    // 先将粘贴的文件添加到 fileList（状态为 uploading）
    const newFileItems = pastedFiles.map((file) => ({
      uid: `${file.name}-${Date.now()}`,
      name: file.name,
      status: 'uploading',
      originFileObj: file
    }));

    setFileList([...fileList, ...newFileItems]);

    for (let item of newFileItems) {
      const uploadedUrl = await uploadFileoServer(item.originFileObj);
      if (uploadedUrl) {
        setFileList((prevList) =>
          prevList.map((f) => (f.uid === item.uid ? { ...f, url: uploadedUrl, status: 'done' } : f))
        );
        message.success(`${item.name} 上传成功`);
      } else {
        setFileList((prevList) => prevList.map((f) => (f.uid === item.uid ? { ...f, status: 'error' } : f)));
        message.error(`${item.name} 上传失败`);
      }
    }
  };

  return (
    <Row style={style}>
      <Col span={20} className="chat" style={{ backgroundColor: '#fff' }}>
        <Select
          value={selectedModel!}
          options={models.map((model) => ({
            value: model.productid,
            label: model.product.product?.getName()
          }))}
          style={{ position: 'relative', top: 0, width: 300 }}
          onChange={(v) => {
            conversion.messages = [];
            setSelectedModel(v);
          }}
        ></Select>

        <div ref={chatBody} onScroll={onScroll} className="chat-body" style={{ flex: 'auto' }}>
          {conversion.messages.map((message, idx) => {
            return <ChatMessage key={idx} message={message}></ChatMessage>;
          })}
        </div>
        <Row>
          <ChatInput
            value={question}
            onSend={onSend}
            onChange={(text) => {
              setQuestion(text);
            }}
            abort={() => {
              abortControlRef?.current?.abort();
              setAnswering(false);
            }}
            answering={answering}
            disabled={
              answering ||
              (!question && fileList.length === 0) ||
              !!fileList.find((item) => item.status === 'uploading' || item.status === 'error')
            }
            style={{ flex: 'auto' }}
            // handleUpload={handlePasteFiles}
          ></ChatInput>
          <Upload
            fileList={fileList}
            accept="image/*"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            onRemove={handleRemove}
            multiple
            maxCount={maxFileLen}
            showUploadList={false}
          >
            <Button
              disabled={fileList.length >= maxFileLen}
              style={{ margin: '0 10px', display: 'none' }}
              size="large"
              icon={<PictureOutlined />}
            ></Button>
          </Upload>
          <Button
            onClick={() => {
              conversion.messages = [];
              setAnswering(false);
            }}
            style={{ margin: '0 10px' }}
            icon={<img src={clearIcon}></img>}
            size="large"
          ></Button>
        </Row>
        {fileList.length > 0 && (
          <List
            dataSource={fileList}
            renderItem={(item) => (
              <List.Item
                style={{
                  display: 'inline-block',
                  marginRight: '10px',
                  position: 'relative'
                }}
              >
                <Spin spinning={item.status !== 'done'}>
                  <div
                    style={{
                      position: 'relative',
                      display: 'inline-block',
                      border: item.status === 'error' ? '2px solid red' : 'none'
                    }}
                  >
                    <Image
                      preview={{ mask: false }}
                      src={item.url || URL.createObjectURL(item.originFileObj)}
                      alt={item.name}
                      style={{
                        width: '80px',
                        height: '80px',
                        objectFit: 'cover',
                        borderRadius: '5px',
                        border: '1px solid #eaeaea'
                      }}
                    />
                  </div>
                </Spin>

                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove(item)}
                  type="text"
                  style={{
                    zIndex: 100,
                    position: 'absolute',
                    top: '15px',
                    right: '5px',
                    background: 'rgba(255,255,255,0.3)',
                    borderRadius: '50%',
                    border: '1px solid #ccc',
                    padding: '0',
                    width: '24px',
                    height: '24px'
                  }}
                />
              </List.Item>
            )}
            style={{
              marginTop: '10px',
              padding: '10px',
              background: '#f9f9f9',
              borderRadius: '10px',
              display: 'flex'
            }}
          />
        )}
      </Col>
      <Col span={4} className="setting" style={{ backgroundColor: '#fff', borderLeft: '1px solid #ccc' }}>
        <div>☰ 模型配置</div>
        {/* <div className="setting-item">
          <div style={{ marginBottom: 10 }}>AI人设</div>{' '}
          <Input.TextArea onChange={(v) => setPrompt(v.target.value)} placeholder="请输入至少五个字符" />
        </div> */}

        <div className="setting-item">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            top_p <InputNumber max={1} min={0} defaultValue={0.95} value={top_p} onChange={(v) => setTop(v || 0)} />
          </div>
          <Slider
            value={top_p}
            max={1}
            min={0}
            step={0.01}
            onChange={(v) => {
              setTop(v);
            }}
          />
        </div>
        <div className="setting-item">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            temperature
            <InputNumber
              max={2}
              min={0}
              defaultValue={0.7}
              step={0.01}
              value={temperature}
              onChange={(v) => setTemperature(v || 0)}
            />
          </div>
          <Slider
            value={temperature}
            max={2}
            min={0}
            step={0.01}
            onChange={(v) => {
              setTemperature(v);
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default observer(Chat);
