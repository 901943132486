// source: dataocean/droplet.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var github_com_alta_protopatch_patch_go_pb = require('../github.com/alta/protopatch/patch/go_pb.js');
goog.object.extend(proto, github_com_alta_protopatch_patch_go_pb);
var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js');
goog.object.extend(proto, github_com_envoyproxy_protoc$gen$validate_validate_validate_pb);
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var meta_meta_pb = require('../meta/meta_pb.js');
goog.object.extend(proto, meta_meta_pb);
var dataocean_annotation_pb = require('../dataocean/annotation_pb.js');
goog.object.extend(proto, dataocean_annotation_pb);
var dataocean_atwork_pb = require('../dataocean/atwork_pb.js');
goog.object.extend(proto, dataocean_atwork_pb);
var dataocean_bubble_pb = require('../dataocean/bubble_pb.js');
goog.object.extend(proto, dataocean_bubble_pb);
var dataocean_global_pb = require('../dataocean/global_pb.js');
goog.object.extend(proto, dataocean_global_pb);
var dataocean_labelx_pb = require('../dataocean/labelx_pb.js');
goog.object.extend(proto, dataocean_labelx_pb);
var dataocean_modelshop_pb = require('../dataocean/modelshop_pb.js');
goog.object.extend(proto, dataocean_modelshop_pb);
var dataocean_teststand_pb = require('../dataocean/teststand_pb.js');
goog.object.extend(proto, dataocean_teststand_pb);
var dataocean_trainspot_pb = require('../dataocean/trainspot_pb.js');
goog.object.extend(proto, dataocean_trainspot_pb);
goog.exportSymbol('proto.dataocean.BulkResponse', null, global);
goog.exportSymbol('proto.dataocean.CreateDropletReq', null, global);
goog.exportSymbol('proto.dataocean.CreateDropletWithContentReq', null, global);
goog.exportSymbol('proto.dataocean.Droplet', null, global);
goog.exportSymbol('proto.dataocean.DropletContent', null, global);
goog.exportSymbol('proto.dataocean.DropletExtension', null, global);
goog.exportSymbol('proto.dataocean.DropletFile', null, global);
goog.exportSymbol('proto.dataocean.DropletSource', null, global);
goog.exportSymbol('proto.dataocean.DropletSpec', null, global);
goog.exportSymbol('proto.dataocean.DropletStatus', null, global);
goog.exportSymbol('proto.dataocean.ErrorDetail', null, global);
goog.exportSymbol('proto.dataocean.GetDropletRawContentReq', null, global);
goog.exportSymbol('proto.dataocean.GetDropletRawContentRes', null, global);
goog.exportSymbol('proto.dataocean.GetDropletReq', null, global);
goog.exportSymbol('proto.dataocean.ListDropletsCondition', null, global);
goog.exportSymbol('proto.dataocean.ListDropletsReq', null, global);
goog.exportSymbol('proto.dataocean.ListDropletsRes', null, global);
goog.exportSymbol('proto.dataocean.UpdateDropletReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.Droplet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.Droplet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.Droplet.displayName = 'proto.dataocean.Droplet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DropletSpec = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.DropletSpec.repeatedFields_, null);
};
goog.inherits(proto.dataocean.DropletSpec, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DropletSpec.displayName = 'proto.dataocean.DropletSpec';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DropletSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.DropletSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DropletSource.displayName = 'proto.dataocean.DropletSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DropletExtension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.DropletExtension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DropletExtension.displayName = 'proto.dataocean.DropletExtension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DropletStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.DropletStatus.repeatedFields_, null);
};
goog.inherits(proto.dataocean.DropletStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DropletStatus.displayName = 'proto.dataocean.DropletStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DropletFile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.DropletFile.repeatedFields_, null);
};
goog.inherits(proto.dataocean.DropletFile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DropletFile.displayName = 'proto.dataocean.DropletFile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.DropletContent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.DropletContent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.DropletContent.displayName = 'proto.dataocean.DropletContent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.CreateDropletReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.CreateDropletReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.CreateDropletReq.displayName = 'proto.dataocean.CreateDropletReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDropletsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListDropletsReq.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListDropletsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDropletsReq.displayName = 'proto.dataocean.ListDropletsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDropletsCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ListDropletsCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDropletsCondition.displayName = 'proto.dataocean.ListDropletsCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ListDropletsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.dataocean.ListDropletsRes.repeatedFields_, null);
};
goog.inherits(proto.dataocean.ListDropletsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ListDropletsRes.displayName = 'proto.dataocean.ListDropletsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GetDropletReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GetDropletReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GetDropletReq.displayName = 'proto.dataocean.GetDropletReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.UpdateDropletReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.UpdateDropletReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.UpdateDropletReq.displayName = 'proto.dataocean.UpdateDropletReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.CreateDropletWithContentReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.CreateDropletWithContentReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.CreateDropletWithContentReq.displayName = 'proto.dataocean.CreateDropletWithContentReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GetDropletRawContentReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GetDropletRawContentReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GetDropletRawContentReq.displayName = 'proto.dataocean.GetDropletRawContentReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.GetDropletRawContentRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.GetDropletRawContentRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.GetDropletRawContentRes.displayName = 'proto.dataocean.GetDropletRawContentRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.BulkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.BulkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.BulkResponse.displayName = 'proto.dataocean.BulkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.dataocean.ErrorDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.dataocean.ErrorDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.dataocean.ErrorDetail.displayName = 'proto.dataocean.ErrorDetail';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.Droplet.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.Droplet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.Droplet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Droplet.toObject = function(includeInstance, msg) {
  var f, obj = {
    meta: (f = msg.getMeta()) && meta_meta_pb.Metadata.toObject(includeInstance, f),
    spec: (f = msg.getSpec()) && proto.dataocean.DropletSpec.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.dataocean.DropletStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.Droplet}
 */
proto.dataocean.Droplet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.Droplet;
  return proto.dataocean.Droplet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.Droplet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.Droplet}
 */
proto.dataocean.Droplet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new meta_meta_pb.Metadata;
      reader.readMessage(value,meta_meta_pb.Metadata.deserializeBinaryFromReader);
      msg.setMeta(value);
      break;
    case 2:
      var value = new proto.dataocean.DropletSpec;
      reader.readMessage(value,proto.dataocean.DropletSpec.deserializeBinaryFromReader);
      msg.setSpec(value);
      break;
    case 3:
      var value = new proto.dataocean.DropletStatus;
      reader.readMessage(value,proto.dataocean.DropletStatus.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.Droplet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.Droplet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.Droplet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.Droplet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMeta();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      meta_meta_pb.Metadata.serializeBinaryToWriter
    );
  }
  f = message.getSpec();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.dataocean.DropletSpec.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.dataocean.DropletStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional meta.Metadata meta = 1;
 * @return {?proto.meta.Metadata}
 */
proto.dataocean.Droplet.prototype.getMeta = function() {
  return /** @type{?proto.meta.Metadata} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.Metadata, 1));
};


/**
 * @param {?proto.meta.Metadata|undefined} value
 * @return {!proto.dataocean.Droplet} returns this
*/
proto.dataocean.Droplet.prototype.setMeta = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Droplet} returns this
 */
proto.dataocean.Droplet.prototype.clearMeta = function() {
  return this.setMeta(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Droplet.prototype.hasMeta = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DropletSpec spec = 2;
 * @return {?proto.dataocean.DropletSpec}
 */
proto.dataocean.Droplet.prototype.getSpec = function() {
  return /** @type{?proto.dataocean.DropletSpec} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DropletSpec, 2));
};


/**
 * @param {?proto.dataocean.DropletSpec|undefined} value
 * @return {!proto.dataocean.Droplet} returns this
*/
proto.dataocean.Droplet.prototype.setSpec = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Droplet} returns this
 */
proto.dataocean.Droplet.prototype.clearSpec = function() {
  return this.setSpec(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Droplet.prototype.hasSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DropletStatus status = 3;
 * @return {?proto.dataocean.DropletStatus}
 */
proto.dataocean.Droplet.prototype.getStatus = function() {
  return /** @type{?proto.dataocean.DropletStatus} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DropletStatus, 3));
};


/**
 * @param {?proto.dataocean.DropletStatus|undefined} value
 * @return {!proto.dataocean.Droplet} returns this
*/
proto.dataocean.Droplet.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.Droplet} returns this
 */
proto.dataocean.Droplet.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.Droplet.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.DropletSpec.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DropletSpec.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DropletSpec.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DropletSpec} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletSpec.toObject = function(includeInstance, msg) {
  var f, obj = {
    pool: jspb.Message.getFieldWithDefault(msg, 1, 0),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bubbleName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    size: jspb.Message.getFieldWithDefault(msg, 6, 0),
    file: (f = msg.getFile()) && proto.dataocean.DropletFile.toObject(includeInstance, f),
    content: (f = msg.getContent()) && proto.dataocean.DropletContent.toObject(includeInstance, f),
    annotations: (f = msg.getAnnotations()) && dataocean_annotation_pb.Annotation.toObject(includeInstance, f),
    extension: (f = msg.getExtension$()) && proto.dataocean.DropletExtension.toObject(includeInstance, f),
    fromList: jspb.Message.toObjectList(msg.getFromList(),
    meta_meta_pb.ID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DropletSpec}
 */
proto.dataocean.DropletSpec.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DropletSpec;
  return proto.dataocean.DropletSpec.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DropletSpec} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DropletSpec}
 */
proto.dataocean.DropletSpec.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dataocean.Pool} */ (reader.readEnum());
      msg.setPool(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBubbleName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 8:
      var value = new proto.dataocean.DropletFile;
      reader.readMessage(value,proto.dataocean.DropletFile.deserializeBinaryFromReader);
      msg.setFile(value);
      break;
    case 9:
      var value = new proto.dataocean.DropletContent;
      reader.readMessage(value,proto.dataocean.DropletContent.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    case 10:
      var value = new dataocean_annotation_pb.Annotation;
      reader.readMessage(value,dataocean_annotation_pb.Annotation.deserializeBinaryFromReader);
      msg.setAnnotations(value);
      break;
    case 11:
      var value = new proto.dataocean.DropletExtension;
      reader.readMessage(value,proto.dataocean.DropletExtension.deserializeBinaryFromReader);
      msg.setExtension$(value);
      break;
    case 12:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addFrom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DropletSpec.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DropletSpec.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DropletSpec} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletSpec.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPool();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBubbleName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getFile();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.dataocean.DropletFile.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.dataocean.DropletContent.serializeBinaryToWriter
    );
  }
  f = message.getAnnotations();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      dataocean_annotation_pb.Annotation.serializeBinaryToWriter
    );
  }
  f = message.getExtension$();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.dataocean.DropletExtension.serializeBinaryToWriter
    );
  }
  f = message.getFromList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * optional Pool pool = 1;
 * @return {!proto.dataocean.Pool}
 */
proto.dataocean.DropletSpec.prototype.getPool = function() {
  return /** @type {!proto.dataocean.Pool} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataocean.Pool} value
 * @return {!proto.dataocean.DropletSpec} returns this
 */
proto.dataocean.DropletSpec.prototype.setPool = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.dataocean.DropletSpec.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.DropletSpec} returns this
 */
proto.dataocean.DropletSpec.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bubble_name = 3;
 * @return {string}
 */
proto.dataocean.DropletSpec.prototype.getBubbleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.DropletSpec} returns this
 */
proto.dataocean.DropletSpec.prototype.setBubbleName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint64 size = 6;
 * @return {number}
 */
proto.dataocean.DropletSpec.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.DropletSpec} returns this
 */
proto.dataocean.DropletSpec.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional DropletFile file = 8;
 * @return {?proto.dataocean.DropletFile}
 */
proto.dataocean.DropletSpec.prototype.getFile = function() {
  return /** @type{?proto.dataocean.DropletFile} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DropletFile, 8));
};


/**
 * @param {?proto.dataocean.DropletFile|undefined} value
 * @return {!proto.dataocean.DropletSpec} returns this
*/
proto.dataocean.DropletSpec.prototype.setFile = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSpec} returns this
 */
proto.dataocean.DropletSpec.prototype.clearFile = function() {
  return this.setFile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSpec.prototype.hasFile = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional DropletContent content = 9;
 * @return {?proto.dataocean.DropletContent}
 */
proto.dataocean.DropletSpec.prototype.getContent = function() {
  return /** @type{?proto.dataocean.DropletContent} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DropletContent, 9));
};


/**
 * @param {?proto.dataocean.DropletContent|undefined} value
 * @return {!proto.dataocean.DropletSpec} returns this
*/
proto.dataocean.DropletSpec.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSpec} returns this
 */
proto.dataocean.DropletSpec.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSpec.prototype.hasContent = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Annotation annotations = 10;
 * @return {?proto.dataocean.Annotation}
 */
proto.dataocean.DropletSpec.prototype.getAnnotations = function() {
  return /** @type{?proto.dataocean.Annotation} */ (
    jspb.Message.getWrapperField(this, dataocean_annotation_pb.Annotation, 10));
};


/**
 * @param {?proto.dataocean.Annotation|undefined} value
 * @return {!proto.dataocean.DropletSpec} returns this
*/
proto.dataocean.DropletSpec.prototype.setAnnotations = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSpec} returns this
 */
proto.dataocean.DropletSpec.prototype.clearAnnotations = function() {
  return this.setAnnotations(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSpec.prototype.hasAnnotations = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional DropletExtension extension = 11;
 * @return {?proto.dataocean.DropletExtension}
 */
proto.dataocean.DropletSpec.prototype.getExtension$ = function() {
  return /** @type{?proto.dataocean.DropletExtension} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.DropletExtension, 11));
};


/**
 * @param {?proto.dataocean.DropletExtension|undefined} value
 * @return {!proto.dataocean.DropletSpec} returns this
*/
proto.dataocean.DropletSpec.prototype.setExtension$ = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSpec} returns this
 */
proto.dataocean.DropletSpec.prototype.clearExtension$ = function() {
  return this.setExtension$(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSpec.prototype.hasExtension$ = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated meta.ID from = 12;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.DropletSpec.prototype.getFromList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 12));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.DropletSpec} returns this
*/
proto.dataocean.DropletSpec.prototype.setFromList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.DropletSpec.prototype.addFrom = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.DropletSpec} returns this
 */
proto.dataocean.DropletSpec.prototype.clearFromList = function() {
  return this.setFromList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DropletSource.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DropletSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DropletSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    atworkMetal: (f = msg.getAtworkMetal()) && dataocean_atwork_pb.Metal.Source.toObject(includeInstance, f),
    labelxResource: (f = msg.getLabelxResource()) && dataocean_labelx_pb.Resource.Source.toObject(includeInstance, f),
    teststandCase: (f = msg.getTeststandCase()) && dataocean_teststand_pb.Case.Source.toObject(includeInstance, f),
    trainspotData: (f = msg.getTrainspotData()) && dataocean_trainspot_pb.Data.Source.toObject(includeInstance, f),
    modelshopGroundTruth: (f = msg.getModelshopGroundTruth()) && dataocean_modelshop_pb.GroundTruth.Source.toObject(includeInstance, f),
    modelshopArtifact: (f = msg.getModelshopArtifact()) && dataocean_modelshop_pb.Artifact.Source.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DropletSource}
 */
proto.dataocean.DropletSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DropletSource;
  return proto.dataocean.DropletSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DropletSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DropletSource}
 */
proto.dataocean.DropletSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dataocean_atwork_pb.Metal.Source;
      reader.readMessage(value,dataocean_atwork_pb.Metal.Source.deserializeBinaryFromReader);
      msg.setAtworkMetal(value);
      break;
    case 2:
      var value = new dataocean_labelx_pb.Resource.Source;
      reader.readMessage(value,dataocean_labelx_pb.Resource.Source.deserializeBinaryFromReader);
      msg.setLabelxResource(value);
      break;
    case 3:
      var value = new dataocean_teststand_pb.Case.Source;
      reader.readMessage(value,dataocean_teststand_pb.Case.Source.deserializeBinaryFromReader);
      msg.setTeststandCase(value);
      break;
    case 4:
      var value = new dataocean_trainspot_pb.Data.Source;
      reader.readMessage(value,dataocean_trainspot_pb.Data.Source.deserializeBinaryFromReader);
      msg.setTrainspotData(value);
      break;
    case 5:
      var value = new dataocean_modelshop_pb.GroundTruth.Source;
      reader.readMessage(value,dataocean_modelshop_pb.GroundTruth.Source.deserializeBinaryFromReader);
      msg.setModelshopGroundTruth(value);
      break;
    case 6:
      var value = new dataocean_modelshop_pb.Artifact.Source;
      reader.readMessage(value,dataocean_modelshop_pb.Artifact.Source.deserializeBinaryFromReader);
      msg.setModelshopArtifact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DropletSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DropletSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DropletSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAtworkMetal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dataocean_atwork_pb.Metal.Source.serializeBinaryToWriter
    );
  }
  f = message.getLabelxResource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dataocean_labelx_pb.Resource.Source.serializeBinaryToWriter
    );
  }
  f = message.getTeststandCase();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      dataocean_teststand_pb.Case.Source.serializeBinaryToWriter
    );
  }
  f = message.getTrainspotData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      dataocean_trainspot_pb.Data.Source.serializeBinaryToWriter
    );
  }
  f = message.getModelshopGroundTruth();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dataocean_modelshop_pb.GroundTruth.Source.serializeBinaryToWriter
    );
  }
  f = message.getModelshopArtifact();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      dataocean_modelshop_pb.Artifact.Source.serializeBinaryToWriter
    );
  }
};


/**
 * optional Metal.Source atwork_metal = 1;
 * @return {?proto.dataocean.Metal.Source}
 */
proto.dataocean.DropletSource.prototype.getAtworkMetal = function() {
  return /** @type{?proto.dataocean.Metal.Source} */ (
    jspb.Message.getWrapperField(this, dataocean_atwork_pb.Metal.Source, 1));
};


/**
 * @param {?proto.dataocean.Metal.Source|undefined} value
 * @return {!proto.dataocean.DropletSource} returns this
*/
proto.dataocean.DropletSource.prototype.setAtworkMetal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSource} returns this
 */
proto.dataocean.DropletSource.prototype.clearAtworkMetal = function() {
  return this.setAtworkMetal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSource.prototype.hasAtworkMetal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Resource.Source labelx_resource = 2;
 * @return {?proto.dataocean.Resource.Source}
 */
proto.dataocean.DropletSource.prototype.getLabelxResource = function() {
  return /** @type{?proto.dataocean.Resource.Source} */ (
    jspb.Message.getWrapperField(this, dataocean_labelx_pb.Resource.Source, 2));
};


/**
 * @param {?proto.dataocean.Resource.Source|undefined} value
 * @return {!proto.dataocean.DropletSource} returns this
*/
proto.dataocean.DropletSource.prototype.setLabelxResource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSource} returns this
 */
proto.dataocean.DropletSource.prototype.clearLabelxResource = function() {
  return this.setLabelxResource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSource.prototype.hasLabelxResource = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Case.Source teststand_case = 3;
 * @return {?proto.dataocean.Case.Source}
 */
proto.dataocean.DropletSource.prototype.getTeststandCase = function() {
  return /** @type{?proto.dataocean.Case.Source} */ (
    jspb.Message.getWrapperField(this, dataocean_teststand_pb.Case.Source, 3));
};


/**
 * @param {?proto.dataocean.Case.Source|undefined} value
 * @return {!proto.dataocean.DropletSource} returns this
*/
proto.dataocean.DropletSource.prototype.setTeststandCase = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSource} returns this
 */
proto.dataocean.DropletSource.prototype.clearTeststandCase = function() {
  return this.setTeststandCase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSource.prototype.hasTeststandCase = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Data.Source trainspot_data = 4;
 * @return {?proto.dataocean.Data.Source}
 */
proto.dataocean.DropletSource.prototype.getTrainspotData = function() {
  return /** @type{?proto.dataocean.Data.Source} */ (
    jspb.Message.getWrapperField(this, dataocean_trainspot_pb.Data.Source, 4));
};


/**
 * @param {?proto.dataocean.Data.Source|undefined} value
 * @return {!proto.dataocean.DropletSource} returns this
*/
proto.dataocean.DropletSource.prototype.setTrainspotData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSource} returns this
 */
proto.dataocean.DropletSource.prototype.clearTrainspotData = function() {
  return this.setTrainspotData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSource.prototype.hasTrainspotData = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional GroundTruth.Source modelshop_ground_truth = 5;
 * @return {?proto.dataocean.GroundTruth.Source}
 */
proto.dataocean.DropletSource.prototype.getModelshopGroundTruth = function() {
  return /** @type{?proto.dataocean.GroundTruth.Source} */ (
    jspb.Message.getWrapperField(this, dataocean_modelshop_pb.GroundTruth.Source, 5));
};


/**
 * @param {?proto.dataocean.GroundTruth.Source|undefined} value
 * @return {!proto.dataocean.DropletSource} returns this
*/
proto.dataocean.DropletSource.prototype.setModelshopGroundTruth = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSource} returns this
 */
proto.dataocean.DropletSource.prototype.clearModelshopGroundTruth = function() {
  return this.setModelshopGroundTruth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSource.prototype.hasModelshopGroundTruth = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Artifact.Source modelshop_artifact = 6;
 * @return {?proto.dataocean.Artifact.Source}
 */
proto.dataocean.DropletSource.prototype.getModelshopArtifact = function() {
  return /** @type{?proto.dataocean.Artifact.Source} */ (
    jspb.Message.getWrapperField(this, dataocean_modelshop_pb.Artifact.Source, 6));
};


/**
 * @param {?proto.dataocean.Artifact.Source|undefined} value
 * @return {!proto.dataocean.DropletSource} returns this
*/
proto.dataocean.DropletSource.prototype.setModelshopArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletSource} returns this
 */
proto.dataocean.DropletSource.prototype.clearModelshopArtifact = function() {
  return this.setModelshopArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletSource.prototype.hasModelshopArtifact = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DropletExtension.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DropletExtension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DropletExtension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletExtension.toObject = function(includeInstance, msg) {
  var f, obj = {
    atworkMetal: (f = msg.getAtworkMetal()) && dataocean_atwork_pb.Metal.Extension.toObject(includeInstance, f),
    labelx: (f = msg.getLabelx()) && dataocean_labelx_pb.Resource.Extension.toObject(includeInstance, f),
    modelshopArtifact: (f = msg.getModelshopArtifact()) && dataocean_modelshop_pb.Artifact.Source.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DropletExtension}
 */
proto.dataocean.DropletExtension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DropletExtension;
  return proto.dataocean.DropletExtension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DropletExtension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DropletExtension}
 */
proto.dataocean.DropletExtension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dataocean_atwork_pb.Metal.Extension;
      reader.readMessage(value,dataocean_atwork_pb.Metal.Extension.deserializeBinaryFromReader);
      msg.setAtworkMetal(value);
      break;
    case 2:
      var value = new dataocean_labelx_pb.Resource.Extension;
      reader.readMessage(value,dataocean_labelx_pb.Resource.Extension.deserializeBinaryFromReader);
      msg.setLabelx(value);
      break;
    case 3:
      var value = new dataocean_modelshop_pb.Artifact.Source;
      reader.readMessage(value,dataocean_modelshop_pb.Artifact.Source.deserializeBinaryFromReader);
      msg.setModelshopArtifact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DropletExtension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DropletExtension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DropletExtension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletExtension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAtworkMetal();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dataocean_atwork_pb.Metal.Extension.serializeBinaryToWriter
    );
  }
  f = message.getLabelx();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      dataocean_labelx_pb.Resource.Extension.serializeBinaryToWriter
    );
  }
  f = message.getModelshopArtifact();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      dataocean_modelshop_pb.Artifact.Source.serializeBinaryToWriter
    );
  }
};


/**
 * optional Metal.Extension atwork_metal = 1;
 * @return {?proto.dataocean.Metal.Extension}
 */
proto.dataocean.DropletExtension.prototype.getAtworkMetal = function() {
  return /** @type{?proto.dataocean.Metal.Extension} */ (
    jspb.Message.getWrapperField(this, dataocean_atwork_pb.Metal.Extension, 1));
};


/**
 * @param {?proto.dataocean.Metal.Extension|undefined} value
 * @return {!proto.dataocean.DropletExtension} returns this
*/
proto.dataocean.DropletExtension.prototype.setAtworkMetal = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletExtension} returns this
 */
proto.dataocean.DropletExtension.prototype.clearAtworkMetal = function() {
  return this.setAtworkMetal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletExtension.prototype.hasAtworkMetal = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Resource.Extension labelx = 2;
 * @return {?proto.dataocean.Resource.Extension}
 */
proto.dataocean.DropletExtension.prototype.getLabelx = function() {
  return /** @type{?proto.dataocean.Resource.Extension} */ (
    jspb.Message.getWrapperField(this, dataocean_labelx_pb.Resource.Extension, 2));
};


/**
 * @param {?proto.dataocean.Resource.Extension|undefined} value
 * @return {!proto.dataocean.DropletExtension} returns this
*/
proto.dataocean.DropletExtension.prototype.setLabelx = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletExtension} returns this
 */
proto.dataocean.DropletExtension.prototype.clearLabelx = function() {
  return this.setLabelx(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletExtension.prototype.hasLabelx = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Artifact.Source modelshop_artifact = 3;
 * @return {?proto.dataocean.Artifact.Source}
 */
proto.dataocean.DropletExtension.prototype.getModelshopArtifact = function() {
  return /** @type{?proto.dataocean.Artifact.Source} */ (
    jspb.Message.getWrapperField(this, dataocean_modelshop_pb.Artifact.Source, 3));
};


/**
 * @param {?proto.dataocean.Artifact.Source|undefined} value
 * @return {!proto.dataocean.DropletExtension} returns this
*/
proto.dataocean.DropletExtension.prototype.setModelshopArtifact = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletExtension} returns this
 */
proto.dataocean.DropletExtension.prototype.clearModelshopArtifact = function() {
  return this.setModelshopArtifact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletExtension.prototype.hasModelshopArtifact = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.DropletStatus.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DropletStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DropletStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DropletStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    bubble: (f = msg.getBubble()) && dataocean_bubble_pb.Bubble.toObject(includeInstance, f),
    blobIdsList: jspb.Message.toObjectList(msg.getBlobIdsList(),
    meta_meta_pb.ID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DropletStatus}
 */
proto.dataocean.DropletStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DropletStatus;
  return proto.dataocean.DropletStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DropletStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DropletStatus}
 */
proto.dataocean.DropletStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new dataocean_bubble_pb.Bubble;
      reader.readMessage(value,dataocean_bubble_pb.Bubble.deserializeBinaryFromReader);
      msg.setBubble(value);
      break;
    case 2:
      var value = new meta_meta_pb.ID;
      reader.readMessage(value,meta_meta_pb.ID.deserializeBinaryFromReader);
      msg.addBlobIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DropletStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DropletStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DropletStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBubble();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      dataocean_bubble_pb.Bubble.serializeBinaryToWriter
    );
  }
  f = message.getBlobIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      meta_meta_pb.ID.serializeBinaryToWriter
    );
  }
};


/**
 * optional Bubble bubble = 1;
 * @return {?proto.dataocean.Bubble}
 */
proto.dataocean.DropletStatus.prototype.getBubble = function() {
  return /** @type{?proto.dataocean.Bubble} */ (
    jspb.Message.getWrapperField(this, dataocean_bubble_pb.Bubble, 1));
};


/**
 * @param {?proto.dataocean.Bubble|undefined} value
 * @return {!proto.dataocean.DropletStatus} returns this
*/
proto.dataocean.DropletStatus.prototype.setBubble = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletStatus} returns this
 */
proto.dataocean.DropletStatus.prototype.clearBubble = function() {
  return this.setBubble(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletStatus.prototype.hasBubble = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated meta.ID blob_ids = 2;
 * @return {!Array<!proto.meta.ID>}
 */
proto.dataocean.DropletStatus.prototype.getBlobIdsList = function() {
  return /** @type{!Array<!proto.meta.ID>} */ (
    jspb.Message.getRepeatedWrapperField(this, meta_meta_pb.ID, 2));
};


/**
 * @param {!Array<!proto.meta.ID>} value
 * @return {!proto.dataocean.DropletStatus} returns this
*/
proto.dataocean.DropletStatus.prototype.setBlobIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.meta.ID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.meta.ID}
 */
proto.dataocean.DropletStatus.prototype.addBlobIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.meta.ID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.DropletStatus} returns this
 */
proto.dataocean.DropletStatus.prototype.clearBlobIdsList = function() {
  return this.setBlobIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.DropletFile.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DropletFile.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DropletFile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DropletFile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletFile.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    digestsList: jspb.Message.toObjectList(msg.getDigestsList(),
    dataocean_annotation_pb.Digest.toObject, includeInstance),
    locationsMap: (f = msg.getLocationsMap()) ? f.toObject(includeInstance, proto.dataocean.BubbleLocation.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DropletFile}
 */
proto.dataocean.DropletFile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DropletFile;
  return proto.dataocean.DropletFile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DropletFile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DropletFile}
 */
proto.dataocean.DropletFile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 4:
      var value = new dataocean_annotation_pb.Digest;
      reader.readMessage(value,dataocean_annotation_pb.Digest.deserializeBinaryFromReader);
      msg.addDigests(value);
      break;
    case 5:
      var value = msg.getLocationsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.dataocean.BubbleLocation.deserializeBinaryFromReader, "", new proto.dataocean.BubbleLocation());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DropletFile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DropletFile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DropletFile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletFile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDigestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      dataocean_annotation_pb.Digest.serializeBinaryToWriter
    );
  }
  f = message.getLocationsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.dataocean.BubbleLocation.serializeBinaryToWriter);
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.dataocean.DropletFile.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.DropletFile} returns this
 */
proto.dataocean.DropletFile.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 size = 2;
 * @return {number}
 */
proto.dataocean.DropletFile.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.dataocean.DropletFile} returns this
 */
proto.dataocean.DropletFile.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string filename = 3;
 * @return {string}
 */
proto.dataocean.DropletFile.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.DropletFile} returns this
 */
proto.dataocean.DropletFile.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Digest digests = 4;
 * @return {!Array<!proto.dataocean.Digest>}
 */
proto.dataocean.DropletFile.prototype.getDigestsList = function() {
  return /** @type{!Array<!proto.dataocean.Digest>} */ (
    jspb.Message.getRepeatedWrapperField(this, dataocean_annotation_pb.Digest, 4));
};


/**
 * @param {!Array<!proto.dataocean.Digest>} value
 * @return {!proto.dataocean.DropletFile} returns this
*/
proto.dataocean.DropletFile.prototype.setDigestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.dataocean.Digest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Digest}
 */
proto.dataocean.DropletFile.prototype.addDigests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.dataocean.Digest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.DropletFile} returns this
 */
proto.dataocean.DropletFile.prototype.clearDigestsList = function() {
  return this.setDigestsList([]);
};


/**
 * map<string, BubbleLocation> locations = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.dataocean.BubbleLocation>}
 */
proto.dataocean.DropletFile.prototype.getLocationsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.dataocean.BubbleLocation>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.dataocean.BubbleLocation));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.dataocean.DropletFile} returns this
 */
proto.dataocean.DropletFile.prototype.clearLocationsMap = function() {
  this.getLocationsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.DropletContent.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.DropletContent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.DropletContent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletContent.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    image: (f = msg.getImage()) && dataocean_global_pb.Image.toObject(includeInstance, f),
    video: (f = msg.getVideo()) && dataocean_global_pb.Video.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.DropletContent}
 */
proto.dataocean.DropletContent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.DropletContent;
  return proto.dataocean.DropletContent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.DropletContent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.DropletContent}
 */
proto.dataocean.DropletContent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubType(value);
      break;
    case 3:
      var value = new dataocean_global_pb.Image;
      reader.readMessage(value,dataocean_global_pb.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 4:
      var value = new dataocean_global_pb.Video;
      reader.readMessage(value,dataocean_global_pb.Video.deserializeBinaryFromReader);
      msg.setVideo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.DropletContent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.DropletContent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.DropletContent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.DropletContent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      dataocean_global_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getVideo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      dataocean_global_pb.Video.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.dataocean.DropletContent.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.DropletContent} returns this
 */
proto.dataocean.DropletContent.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sub_type = 2;
 * @return {string}
 */
proto.dataocean.DropletContent.prototype.getSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.DropletContent} returns this
 */
proto.dataocean.DropletContent.prototype.setSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Image image = 3;
 * @return {?proto.dataocean.Image}
 */
proto.dataocean.DropletContent.prototype.getImage = function() {
  return /** @type{?proto.dataocean.Image} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Image, 3));
};


/**
 * @param {?proto.dataocean.Image|undefined} value
 * @return {!proto.dataocean.DropletContent} returns this
*/
proto.dataocean.DropletContent.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletContent} returns this
 */
proto.dataocean.DropletContent.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletContent.prototype.hasImage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Video video = 4;
 * @return {?proto.dataocean.Video}
 */
proto.dataocean.DropletContent.prototype.getVideo = function() {
  return /** @type{?proto.dataocean.Video} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Video, 4));
};


/**
 * @param {?proto.dataocean.Video|undefined} value
 * @return {!proto.dataocean.DropletContent} returns this
*/
proto.dataocean.DropletContent.prototype.setVideo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.DropletContent} returns this
 */
proto.dataocean.DropletContent.prototype.clearVideo = function() {
  return this.setVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.DropletContent.prototype.hasVideo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.CreateDropletReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.CreateDropletReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.CreateDropletReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateDropletReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    droplet: (f = msg.getDroplet()) && proto.dataocean.Droplet.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.CreateDropletReq}
 */
proto.dataocean.CreateDropletReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.CreateDropletReq;
  return proto.dataocean.CreateDropletReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.CreateDropletReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.CreateDropletReq}
 */
proto.dataocean.CreateDropletReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Droplet;
      reader.readMessage(value,proto.dataocean.Droplet.deserializeBinaryFromReader);
      msg.setDroplet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.CreateDropletReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.CreateDropletReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.CreateDropletReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateDropletReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDroplet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Droplet.serializeBinaryToWriter
    );
  }
};


/**
 * optional Droplet droplet = 1;
 * @return {?proto.dataocean.Droplet}
 */
proto.dataocean.CreateDropletReq.prototype.getDroplet = function() {
  return /** @type{?proto.dataocean.Droplet} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Droplet, 1));
};


/**
 * @param {?proto.dataocean.Droplet|undefined} value
 * @return {!proto.dataocean.CreateDropletReq} returns this
*/
proto.dataocean.CreateDropletReq.prototype.setDroplet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.CreateDropletReq} returns this
 */
proto.dataocean.CreateDropletReq.prototype.clearDroplet = function() {
  return this.setDroplet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.CreateDropletReq.prototype.hasDroplet = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListDropletsReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDropletsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDropletsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDropletsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    condition: (f = msg.getCondition()) && proto.dataocean.ListDropletsCondition.toObject(includeInstance, f),
    sortByList: jspb.Message.toObjectList(msg.getSortByList(),
    dataocean_global_pb.Sorter.toObject, includeInstance),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerReq.toObject(includeInstance, f),
    includeBubbleDetail: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDropletsReq}
 */
proto.dataocean.ListDropletsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDropletsReq;
  return proto.dataocean.ListDropletsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDropletsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDropletsReq}
 */
proto.dataocean.ListDropletsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.ListDropletsCondition;
      reader.readMessage(value,proto.dataocean.ListDropletsCondition.deserializeBinaryFromReader);
      msg.setCondition(value);
      break;
    case 3:
      var value = new dataocean_global_pb.Sorter;
      reader.readMessage(value,dataocean_global_pb.Sorter.deserializeBinaryFromReader);
      msg.addSortBy(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerReq;
      reader.readMessage(value,meta_meta_pb.PagerReq.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeBubbleDetail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDropletsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDropletsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDropletsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCondition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.ListDropletsCondition.serializeBinaryToWriter
    );
  }
  f = message.getSortByList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      dataocean_global_pb.Sorter.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerReq.serializeBinaryToWriter
    );
  }
  f = message.getIncludeBubbleDetail();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional ListDropletsCondition condition = 1;
 * @return {?proto.dataocean.ListDropletsCondition}
 */
proto.dataocean.ListDropletsReq.prototype.getCondition = function() {
  return /** @type{?proto.dataocean.ListDropletsCondition} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.ListDropletsCondition, 1));
};


/**
 * @param {?proto.dataocean.ListDropletsCondition|undefined} value
 * @return {!proto.dataocean.ListDropletsReq} returns this
*/
proto.dataocean.ListDropletsReq.prototype.setCondition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletsReq} returns this
 */
proto.dataocean.ListDropletsReq.prototype.clearCondition = function() {
  return this.setCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletsReq.prototype.hasCondition = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Sorter sort_by = 3;
 * @return {!Array<!proto.dataocean.Sorter>}
 */
proto.dataocean.ListDropletsReq.prototype.getSortByList = function() {
  return /** @type{!Array<!proto.dataocean.Sorter>} */ (
    jspb.Message.getRepeatedWrapperField(this, dataocean_global_pb.Sorter, 3));
};


/**
 * @param {!Array<!proto.dataocean.Sorter>} value
 * @return {!proto.dataocean.ListDropletsReq} returns this
*/
proto.dataocean.ListDropletsReq.prototype.setSortByList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.dataocean.Sorter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Sorter}
 */
proto.dataocean.ListDropletsReq.prototype.addSortBy = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.dataocean.Sorter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListDropletsReq} returns this
 */
proto.dataocean.ListDropletsReq.prototype.clearSortByList = function() {
  return this.setSortByList([]);
};


/**
 * optional meta.PagerReq pager = 2;
 * @return {?proto.meta.PagerReq}
 */
proto.dataocean.ListDropletsReq.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerReq} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerReq, 2));
};


/**
 * @param {?proto.meta.PagerReq|undefined} value
 * @return {!proto.dataocean.ListDropletsReq} returns this
*/
proto.dataocean.ListDropletsReq.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletsReq} returns this
 */
proto.dataocean.ListDropletsReq.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletsReq.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool include_bubble_detail = 4;
 * @return {boolean}
 */
proto.dataocean.ListDropletsReq.prototype.getIncludeBubbleDetail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.ListDropletsReq} returns this
 */
proto.dataocean.ListDropletsReq.prototype.setIncludeBubbleDetail = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDropletsCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDropletsCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDropletsCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletsCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    pool: jspb.Message.getFieldWithDefault(msg, 1, 0),
    creator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mimeType: (f = msg.getMimeType()) && dataocean_global_pb.MIMEType.toObject(includeInstance, f),
    mayMissingBubble: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    blobId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    from: jspb.Message.getFieldWithDefault(msg, 10, ""),
    atworkMetal: (f = msg.getAtworkMetal()) && dataocean_atwork_pb.Metal.Filter.toObject(includeInstance, f),
    testStandCase: (f = msg.getTestStandCase()) && dataocean_teststand_pb.Case.Filter.toObject(includeInstance, f),
    trainSpot: (f = msg.getTrainSpot()) && dataocean_trainspot_pb.Data.Filter.toObject(includeInstance, f),
    groundTruth: (f = msg.getGroundTruth()) && dataocean_modelshop_pb.GroundTruth.Filter.toObject(includeInstance, f),
    hasBlob: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    filesize: (f = msg.getFilesize()) && dataocean_global_pb.Range64.toObject(includeInstance, f),
    filename: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDropletsCondition}
 */
proto.dataocean.ListDropletsCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDropletsCondition;
  return proto.dataocean.ListDropletsCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDropletsCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDropletsCondition}
 */
proto.dataocean.ListDropletsCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.dataocean.Pool} */ (reader.readEnum());
      msg.setPool(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreator(value);
      break;
    case 4:
      var value = new dataocean_global_pb.MIMEType;
      reader.readMessage(value,dataocean_global_pb.MIMEType.deserializeBinaryFromReader);
      msg.setMimeType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMayMissingBubble(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlobId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 5:
      var value = new dataocean_atwork_pb.Metal.Filter;
      reader.readMessage(value,dataocean_atwork_pb.Metal.Filter.deserializeBinaryFromReader);
      msg.setAtworkMetal(value);
      break;
    case 6:
      var value = new dataocean_teststand_pb.Case.Filter;
      reader.readMessage(value,dataocean_teststand_pb.Case.Filter.deserializeBinaryFromReader);
      msg.setTestStandCase(value);
      break;
    case 9:
      var value = new dataocean_trainspot_pb.Data.Filter;
      reader.readMessage(value,dataocean_trainspot_pb.Data.Filter.deserializeBinaryFromReader);
      msg.setTrainSpot(value);
      break;
    case 11:
      var value = new dataocean_modelshop_pb.GroundTruth.Filter;
      reader.readMessage(value,dataocean_modelshop_pb.GroundTruth.Filter.deserializeBinaryFromReader);
      msg.setGroundTruth(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBlob(value);
      break;
    case 13:
      var value = new dataocean_global_pb.Range64;
      reader.readMessage(value,dataocean_global_pb.Range64.deserializeBinaryFromReader);
      msg.setFilesize(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDropletsCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDropletsCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDropletsCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletsCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPool();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCreator();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMimeType();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      dataocean_global_pb.MIMEType.serializeBinaryToWriter
    );
  }
  f = message.getMayMissingBubble();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBlobId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAtworkMetal();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      dataocean_atwork_pb.Metal.Filter.serializeBinaryToWriter
    );
  }
  f = message.getTestStandCase();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      dataocean_teststand_pb.Case.Filter.serializeBinaryToWriter
    );
  }
  f = message.getTrainSpot();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      dataocean_trainspot_pb.Data.Filter.serializeBinaryToWriter
    );
  }
  f = message.getGroundTruth();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      dataocean_modelshop_pb.GroundTruth.Filter.serializeBinaryToWriter
    );
  }
  f = message.getHasBlob();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getFilesize();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      dataocean_global_pb.Range64.serializeBinaryToWriter
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional Pool pool = 1;
 * @return {!proto.dataocean.Pool}
 */
proto.dataocean.ListDropletsCondition.prototype.getPool = function() {
  return /** @type {!proto.dataocean.Pool} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.dataocean.Pool} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.setPool = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string creator = 2;
 * @return {string}
 */
proto.dataocean.ListDropletsCondition.prototype.getCreator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.setCreator = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional MIMEType mime_type = 4;
 * @return {?proto.dataocean.MIMEType}
 */
proto.dataocean.ListDropletsCondition.prototype.getMimeType = function() {
  return /** @type{?proto.dataocean.MIMEType} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.MIMEType, 4));
};


/**
 * @param {?proto.dataocean.MIMEType|undefined} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
*/
proto.dataocean.ListDropletsCondition.prototype.setMimeType = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.clearMimeType = function() {
  return this.setMimeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletsCondition.prototype.hasMimeType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool may_missing_bubble = 7;
 * @return {boolean}
 */
proto.dataocean.ListDropletsCondition.prototype.getMayMissingBubble = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.setMayMissingBubble = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string blob_id = 8;
 * @return {string}
 */
proto.dataocean.ListDropletsCondition.prototype.getBlobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.setBlobId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string from = 10;
 * @return {string}
 */
proto.dataocean.ListDropletsCondition.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional Metal.Filter atwork_metal = 5;
 * @return {?proto.dataocean.Metal.Filter}
 */
proto.dataocean.ListDropletsCondition.prototype.getAtworkMetal = function() {
  return /** @type{?proto.dataocean.Metal.Filter} */ (
    jspb.Message.getWrapperField(this, dataocean_atwork_pb.Metal.Filter, 5));
};


/**
 * @param {?proto.dataocean.Metal.Filter|undefined} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
*/
proto.dataocean.ListDropletsCondition.prototype.setAtworkMetal = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.clearAtworkMetal = function() {
  return this.setAtworkMetal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletsCondition.prototype.hasAtworkMetal = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Case.Filter test_stand_case = 6;
 * @return {?proto.dataocean.Case.Filter}
 */
proto.dataocean.ListDropletsCondition.prototype.getTestStandCase = function() {
  return /** @type{?proto.dataocean.Case.Filter} */ (
    jspb.Message.getWrapperField(this, dataocean_teststand_pb.Case.Filter, 6));
};


/**
 * @param {?proto.dataocean.Case.Filter|undefined} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
*/
proto.dataocean.ListDropletsCondition.prototype.setTestStandCase = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.clearTestStandCase = function() {
  return this.setTestStandCase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletsCondition.prototype.hasTestStandCase = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Data.Filter train_spot = 9;
 * @return {?proto.dataocean.Data.Filter}
 */
proto.dataocean.ListDropletsCondition.prototype.getTrainSpot = function() {
  return /** @type{?proto.dataocean.Data.Filter} */ (
    jspb.Message.getWrapperField(this, dataocean_trainspot_pb.Data.Filter, 9));
};


/**
 * @param {?proto.dataocean.Data.Filter|undefined} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
*/
proto.dataocean.ListDropletsCondition.prototype.setTrainSpot = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.clearTrainSpot = function() {
  return this.setTrainSpot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletsCondition.prototype.hasTrainSpot = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GroundTruth.Filter ground_truth = 11;
 * @return {?proto.dataocean.GroundTruth.Filter}
 */
proto.dataocean.ListDropletsCondition.prototype.getGroundTruth = function() {
  return /** @type{?proto.dataocean.GroundTruth.Filter} */ (
    jspb.Message.getWrapperField(this, dataocean_modelshop_pb.GroundTruth.Filter, 11));
};


/**
 * @param {?proto.dataocean.GroundTruth.Filter|undefined} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
*/
proto.dataocean.ListDropletsCondition.prototype.setGroundTruth = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.clearGroundTruth = function() {
  return this.setGroundTruth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletsCondition.prototype.hasGroundTruth = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool has_blob = 12;
 * @return {boolean}
 */
proto.dataocean.ListDropletsCondition.prototype.getHasBlob = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.setHasBlob = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional Range64 filesize = 13;
 * @return {?proto.dataocean.Range64}
 */
proto.dataocean.ListDropletsCondition.prototype.getFilesize = function() {
  return /** @type{?proto.dataocean.Range64} */ (
    jspb.Message.getWrapperField(this, dataocean_global_pb.Range64, 13));
};


/**
 * @param {?proto.dataocean.Range64|undefined} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
*/
proto.dataocean.ListDropletsCondition.prototype.setFilesize = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.clearFilesize = function() {
  return this.setFilesize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletsCondition.prototype.hasFilesize = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string filename = 14;
 * @return {string}
 */
proto.dataocean.ListDropletsCondition.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ListDropletsCondition} returns this
 */
proto.dataocean.ListDropletsCondition.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.dataocean.ListDropletsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ListDropletsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ListDropletsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ListDropletsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    dropletsList: jspb.Message.toObjectList(msg.getDropletsList(),
    proto.dataocean.Droplet.toObject, includeInstance),
    pager: (f = msg.getPager()) && meta_meta_pb.PagerRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ListDropletsRes}
 */
proto.dataocean.ListDropletsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ListDropletsRes;
  return proto.dataocean.ListDropletsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ListDropletsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ListDropletsRes}
 */
proto.dataocean.ListDropletsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Droplet;
      reader.readMessage(value,proto.dataocean.Droplet.deserializeBinaryFromReader);
      msg.addDroplets(value);
      break;
    case 2:
      var value = new meta_meta_pb.PagerRes;
      reader.readMessage(value,meta_meta_pb.PagerRes.deserializeBinaryFromReader);
      msg.setPager(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ListDropletsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ListDropletsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ListDropletsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ListDropletsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDropletsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.dataocean.Droplet.serializeBinaryToWriter
    );
  }
  f = message.getPager();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      meta_meta_pb.PagerRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Droplet droplets = 1;
 * @return {!Array<!proto.dataocean.Droplet>}
 */
proto.dataocean.ListDropletsRes.prototype.getDropletsList = function() {
  return /** @type{!Array<!proto.dataocean.Droplet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.dataocean.Droplet, 1));
};


/**
 * @param {!Array<!proto.dataocean.Droplet>} value
 * @return {!proto.dataocean.ListDropletsRes} returns this
*/
proto.dataocean.ListDropletsRes.prototype.setDropletsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.dataocean.Droplet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.dataocean.Droplet}
 */
proto.dataocean.ListDropletsRes.prototype.addDroplets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.dataocean.Droplet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.dataocean.ListDropletsRes} returns this
 */
proto.dataocean.ListDropletsRes.prototype.clearDropletsList = function() {
  return this.setDropletsList([]);
};


/**
 * optional meta.PagerRes pager = 2;
 * @return {?proto.meta.PagerRes}
 */
proto.dataocean.ListDropletsRes.prototype.getPager = function() {
  return /** @type{?proto.meta.PagerRes} */ (
    jspb.Message.getWrapperField(this, meta_meta_pb.PagerRes, 2));
};


/**
 * @param {?proto.meta.PagerRes|undefined} value
 * @return {!proto.dataocean.ListDropletsRes} returns this
*/
proto.dataocean.ListDropletsRes.prototype.setPager = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.ListDropletsRes} returns this
 */
proto.dataocean.ListDropletsRes.prototype.clearPager = function() {
  return this.setPager(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.ListDropletsRes.prototype.hasPager = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GetDropletReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GetDropletReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GetDropletReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetDropletReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GetDropletReq}
 */
proto.dataocean.GetDropletReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GetDropletReq;
  return proto.dataocean.GetDropletReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GetDropletReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GetDropletReq}
 */
proto.dataocean.GetDropletReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GetDropletReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GetDropletReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GetDropletReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetDropletReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.dataocean.GetDropletReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GetDropletReq} returns this
 */
proto.dataocean.GetDropletReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.UpdateDropletReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.UpdateDropletReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.UpdateDropletReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.UpdateDropletReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    droplet: (f = msg.getDroplet()) && proto.dataocean.Droplet.toObject(includeInstance, f),
    fieldMask: (f = msg.getFieldMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.UpdateDropletReq}
 */
proto.dataocean.UpdateDropletReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.UpdateDropletReq;
  return proto.dataocean.UpdateDropletReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.UpdateDropletReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.UpdateDropletReq}
 */
proto.dataocean.UpdateDropletReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Droplet;
      reader.readMessage(value,proto.dataocean.Droplet.deserializeBinaryFromReader);
      msg.setDroplet(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setFieldMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.UpdateDropletReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.UpdateDropletReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.UpdateDropletReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.UpdateDropletReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDroplet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Droplet.serializeBinaryToWriter
    );
  }
  f = message.getFieldMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Droplet droplet = 1;
 * @return {?proto.dataocean.Droplet}
 */
proto.dataocean.UpdateDropletReq.prototype.getDroplet = function() {
  return /** @type{?proto.dataocean.Droplet} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Droplet, 1));
};


/**
 * @param {?proto.dataocean.Droplet|undefined} value
 * @return {!proto.dataocean.UpdateDropletReq} returns this
*/
proto.dataocean.UpdateDropletReq.prototype.setDroplet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.UpdateDropletReq} returns this
 */
proto.dataocean.UpdateDropletReq.prototype.clearDroplet = function() {
  return this.setDroplet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.UpdateDropletReq.prototype.hasDroplet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask field_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.dataocean.UpdateDropletReq.prototype.getFieldMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.dataocean.UpdateDropletReq} returns this
*/
proto.dataocean.UpdateDropletReq.prototype.setFieldMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.UpdateDropletReq} returns this
 */
proto.dataocean.UpdateDropletReq.prototype.clearFieldMask = function() {
  return this.setFieldMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.UpdateDropletReq.prototype.hasFieldMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.CreateDropletWithContentReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.CreateDropletWithContentReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.CreateDropletWithContentReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateDropletWithContentReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    droplet: (f = msg.getDroplet()) && proto.dataocean.Droplet.toObject(includeInstance, f),
    content: msg.getContent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.CreateDropletWithContentReq}
 */
proto.dataocean.CreateDropletWithContentReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.CreateDropletWithContentReq;
  return proto.dataocean.CreateDropletWithContentReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.CreateDropletWithContentReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.CreateDropletWithContentReq}
 */
proto.dataocean.CreateDropletWithContentReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Droplet;
      reader.readMessage(value,proto.dataocean.Droplet.deserializeBinaryFromReader);
      msg.setDroplet(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.CreateDropletWithContentReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.CreateDropletWithContentReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.CreateDropletWithContentReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.CreateDropletWithContentReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDroplet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Droplet.serializeBinaryToWriter
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional Droplet droplet = 1;
 * @return {?proto.dataocean.Droplet}
 */
proto.dataocean.CreateDropletWithContentReq.prototype.getDroplet = function() {
  return /** @type{?proto.dataocean.Droplet} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Droplet, 1));
};


/**
 * @param {?proto.dataocean.Droplet|undefined} value
 * @return {!proto.dataocean.CreateDropletWithContentReq} returns this
*/
proto.dataocean.CreateDropletWithContentReq.prototype.setDroplet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.CreateDropletWithContentReq} returns this
 */
proto.dataocean.CreateDropletWithContentReq.prototype.clearDroplet = function() {
  return this.setDroplet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.CreateDropletWithContentReq.prototype.hasDroplet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes content = 2;
 * @return {string}
 */
proto.dataocean.CreateDropletWithContentReq.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.dataocean.CreateDropletWithContentReq.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.dataocean.CreateDropletWithContentReq.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dataocean.CreateDropletWithContentReq} returns this
 */
proto.dataocean.CreateDropletWithContentReq.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GetDropletRawContentReq.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GetDropletRawContentReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GetDropletRawContentReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetDropletRawContentReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GetDropletRawContentReq}
 */
proto.dataocean.GetDropletRawContentReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GetDropletRawContentReq;
  return proto.dataocean.GetDropletRawContentReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GetDropletRawContentReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GetDropletRawContentReq}
 */
proto.dataocean.GetDropletRawContentReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GetDropletRawContentReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GetDropletRawContentReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GetDropletRawContentReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetDropletRawContentReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.GetDropletRawContentReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.GetDropletRawContentReq} returns this
 */
proto.dataocean.GetDropletRawContentReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.GetDropletRawContentRes.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.GetDropletRawContentRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.GetDropletRawContentRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetDropletRawContentRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    droplet: (f = msg.getDroplet()) && proto.dataocean.Droplet.toObject(includeInstance, f),
    content: msg.getContent_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.GetDropletRawContentRes}
 */
proto.dataocean.GetDropletRawContentRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.GetDropletRawContentRes;
  return proto.dataocean.GetDropletRawContentRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.GetDropletRawContentRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.GetDropletRawContentRes}
 */
proto.dataocean.GetDropletRawContentRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.dataocean.Droplet;
      reader.readMessage(value,proto.dataocean.Droplet.deserializeBinaryFromReader);
      msg.setDroplet(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.GetDropletRawContentRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.GetDropletRawContentRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.GetDropletRawContentRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.GetDropletRawContentRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDroplet();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.dataocean.Droplet.serializeBinaryToWriter
    );
  }
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional Droplet droplet = 1;
 * @return {?proto.dataocean.Droplet}
 */
proto.dataocean.GetDropletRawContentRes.prototype.getDroplet = function() {
  return /** @type{?proto.dataocean.Droplet} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.Droplet, 1));
};


/**
 * @param {?proto.dataocean.Droplet|undefined} value
 * @return {!proto.dataocean.GetDropletRawContentRes} returns this
*/
proto.dataocean.GetDropletRawContentRes.prototype.setDroplet = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.GetDropletRawContentRes} returns this
 */
proto.dataocean.GetDropletRawContentRes.prototype.clearDroplet = function() {
  return this.setDroplet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.GetDropletRawContentRes.prototype.hasDroplet = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes content = 2;
 * @return {string}
 */
proto.dataocean.GetDropletRawContentRes.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes content = 2;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.dataocean.GetDropletRawContentRes.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.dataocean.GetDropletRawContentRes.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.dataocean.GetDropletRawContentRes} returns this
 */
proto.dataocean.GetDropletRawContentRes.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.BulkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.BulkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.BulkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BulkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    succeed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    error: (f = msg.getError()) && proto.dataocean.ErrorDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.BulkResponse}
 */
proto.dataocean.BulkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.BulkResponse;
  return proto.dataocean.BulkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.BulkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.BulkResponse}
 */
proto.dataocean.BulkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSucceed(value);
      break;
    case 4:
      var value = new proto.dataocean.ErrorDetail;
      reader.readMessage(value,proto.dataocean.ErrorDetail.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.BulkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.BulkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.BulkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.BulkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSucceed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.dataocean.ErrorDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.dataocean.BulkResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.BulkResponse} returns this
 */
proto.dataocean.BulkResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.dataocean.BulkResponse.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.BulkResponse} returns this
 */
proto.dataocean.BulkResponse.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool succeed = 3;
 * @return {boolean}
 */
proto.dataocean.BulkResponse.prototype.getSucceed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.dataocean.BulkResponse} returns this
 */
proto.dataocean.BulkResponse.prototype.setSucceed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional ErrorDetail error = 4;
 * @return {?proto.dataocean.ErrorDetail}
 */
proto.dataocean.BulkResponse.prototype.getError = function() {
  return /** @type{?proto.dataocean.ErrorDetail} */ (
    jspb.Message.getWrapperField(this, proto.dataocean.ErrorDetail, 4));
};


/**
 * @param {?proto.dataocean.ErrorDetail|undefined} value
 * @return {!proto.dataocean.BulkResponse} returns this
*/
proto.dataocean.BulkResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.dataocean.BulkResponse} returns this
 */
proto.dataocean.BulkResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.dataocean.BulkResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.dataocean.ErrorDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.dataocean.ErrorDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.dataocean.ErrorDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ErrorDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.dataocean.ErrorDetail}
 */
proto.dataocean.ErrorDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.dataocean.ErrorDetail;
  return proto.dataocean.ErrorDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.dataocean.ErrorDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.dataocean.ErrorDetail}
 */
proto.dataocean.ErrorDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.dataocean.ErrorDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.dataocean.ErrorDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.dataocean.ErrorDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.dataocean.ErrorDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.dataocean.ErrorDetail.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ErrorDetail} returns this
 */
proto.dataocean.ErrorDetail.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.dataocean.ErrorDetail.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.dataocean.ErrorDetail} returns this
 */
proto.dataocean.ErrorDetail.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.dataocean);
