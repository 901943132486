import { ProductKeyServicePromiseClient } from 'atom-proto/web/atom/v2/product_key_grpc_web_pb';
import { BillingServicePromiseClient } from 'atom-proto/web/atom/v2/billing_grpc_web_pb';
import { ProductServicePromiseClient } from 'atom-proto/web/atom/v2/product_grpc_web_pb';
import { InstanceServicePromiseClient } from 'atom-proto/web/atom/v2/instance_grpc_web_pb';
import { DatasetServicePromiseClient } from 'atom-proto/web/dataocean/dataset_grpc_web_pb';
import { ProductActivationServicePromiseClient } from 'atom-proto/web/atom/v2/product_activation_grpc_web_pb';
import { PricingServicePromiseClient } from 'atom-proto/web/atom/v2/pricing_grpc_web_pb';
import { DeviceCategoryServicePromiseClient } from 'atom-proto/web/atom/v2/device_grpc_web_pb';
// dataocean
import { BlobServicePromiseClient } from 'atom-proto/web/dataocean/blob_grpc_web_pb';

import { debugGRPC } from 'utils/grpcUtil';
import { GRPCClient } from './grpcClient';

const portalService = {
  billing: {
    client: BillingServicePromiseClient,
    grpc: {} as BillingServicePromiseClient
  },
  product: {
    client: ProductServicePromiseClient,
    grpc: {} as ProductServicePromiseClient
  },
  instance: {
    client: InstanceServicePromiseClient,
    grpc: {} as InstanceServicePromiseClient
  },
  productsKey: {
    client: ProductKeyServicePromiseClient,
    grpc: {} as ProductKeyServicePromiseClient
  },
  productActivation: {
    client: ProductActivationServicePromiseClient,
    grpc: {} as ProductActivationServicePromiseClient
  },
  pricing: {
    client: PricingServicePromiseClient,
    grpc: {} as PricingServicePromiseClient
  },
  Device: {
    client: DeviceCategoryServicePromiseClient,
    grpc: {} as DeviceCategoryServicePromiseClient
  }
} as const;

const doService = {
  blob: {
    client: BlobServicePromiseClient,
    grpc: {} as BlobServicePromiseClient
  },
  dataset: {
    client: DatasetServicePromiseClient,
    grpc: {} as DatasetServicePromiseClient
  }
} as const;

export type IGrpc = {
  [k in keyof typeof portalService]: (typeof portalService)[k]['grpc'];
} & {
  [k in keyof typeof doService]: (typeof doService)[k]['grpc'];
};

const grpc = {} as IGrpc;

function initService(services, config: GRPCClient) {
  Object.keys(services).forEach((serviceName) => {
    const service = services[serviceName];
    const client = (service._client = new service.client(config.getEndpoint(), null, {
      withCredentials: true
    }));
    grpc[serviceName] = {};
    for (let name in client) {
      if (typeof client[name] === 'function') {
        grpc[serviceName][name] = async function (req) {
          let resp;
          try {
            resp = await client[name](req, config.metaInfo);
            return resp;
          } catch (e) {
            console.log(e);
            throw e;
          } finally {
            debugGRPC(name, { req, resp });
          }
        };
      }
    }
  });
}

export function initGrpc(a: GRPCClient) {
  initService(portalService, a);
}

export default grpc;
