/**
 * @fileoverview gRPC-Web generated client stub for atom.v2
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js')

var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var meta_meta_pb = require('../../meta/meta_pb.js')
const proto = {};
proto.atom = {};
proto.atom.v2 = require('./product_activation_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.ProductActivationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.ProductActivationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.ListProductsActivationReq,
 *   !proto.atom.v2.ListProductsActivationRes>}
 */
const methodDescriptor_ProductActivationService_ListProductsActivation = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductActivationService/ListProductsActivation',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.ListProductsActivationReq,
  proto.atom.v2.ListProductsActivationRes,
  /**
   * @param {!proto.atom.v2.ListProductsActivationReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ListProductsActivationRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.ListProductsActivationReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ListProductsActivationRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ListProductsActivationRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductActivationServiceClient.prototype.listProductsActivation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductActivationService/ListProductsActivation',
      request,
      metadata || {},
      methodDescriptor_ProductActivationService_ListProductsActivation,
      callback);
};


/**
 * @param {!proto.atom.v2.ListProductsActivationReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ListProductsActivationRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductActivationServicePromiseClient.prototype.listProductsActivation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductActivationService/ListProductsActivation',
      request,
      metadata || {},
      methodDescriptor_ProductActivationService_ListProductsActivation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.GetProductActivationReq,
 *   !proto.atom.v2.ProductActivation>}
 */
const methodDescriptor_ProductActivationService_GetProductActivation = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductActivationService/GetProductActivation',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.GetProductActivationReq,
  proto.atom.v2.ProductActivation,
  /**
   * @param {!proto.atom.v2.GetProductActivationReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ProductActivation.deserializeBinary
);


/**
 * @param {!proto.atom.v2.GetProductActivationReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ProductActivation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ProductActivation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductActivationServiceClient.prototype.getProductActivation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductActivationService/GetProductActivation',
      request,
      metadata || {},
      methodDescriptor_ProductActivationService_GetProductActivation,
      callback);
};


/**
 * @param {!proto.atom.v2.GetProductActivationReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ProductActivation>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductActivationServicePromiseClient.prototype.getProductActivation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductActivationService/GetProductActivation',
      request,
      metadata || {},
      methodDescriptor_ProductActivationService_GetProductActivation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.UpdateProductActivationReq,
 *   !proto.atom.v2.UpdateProductActivationRes>}
 */
const methodDescriptor_ProductActivationService_UpdateProductActivation = new grpc.web.MethodDescriptor(
  '/atom.v2.ProductActivationService/UpdateProductActivation',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.UpdateProductActivationReq,
  proto.atom.v2.UpdateProductActivationRes,
  /**
   * @param {!proto.atom.v2.UpdateProductActivationReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.UpdateProductActivationRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.UpdateProductActivationReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.UpdateProductActivationRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.UpdateProductActivationRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.ProductActivationServiceClient.prototype.updateProductActivation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.ProductActivationService/UpdateProductActivation',
      request,
      metadata || {},
      methodDescriptor_ProductActivationService_UpdateProductActivation,
      callback);
};


/**
 * @param {!proto.atom.v2.UpdateProductActivationReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.UpdateProductActivationRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.ProductActivationServicePromiseClient.prototype.updateProductActivation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.ProductActivationService/UpdateProductActivation',
      request,
      metadata || {},
      methodDescriptor_ProductActivationService_UpdateProductActivation);
};


module.exports = proto.atom.v2;

