/**
 * @fileoverview gRPC-Web generated client stub for atom.v2
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var github_com_alta_protopatch_patch_go_pb = require('../../github.com/alta/protopatch/patch/go_pb.js')

var github_com_envoyproxy_protoc$gen$validate_validate_validate_pb = require('../../github.com/envoyproxy/protoc-gen-validate/validate/validate_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var meta_meta_pb = require('../../meta/meta_pb.js')

var atom_v2_global_pb = require('../../atom/v2/global_pb.js')
const proto = {};
proto.atom = {};
proto.atom.v2 = require('./device_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.DeviceCategoryServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.atom.v2.DeviceCategoryServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.ListDeviceCategoryKindsReq,
 *   !proto.atom.v2.ListDeviceCategoryKindsRes>}
 */
const methodDescriptor_DeviceCategoryService_ListDeviceCategoryKinds = new grpc.web.MethodDescriptor(
  '/atom.v2.DeviceCategoryService/ListDeviceCategoryKinds',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.ListDeviceCategoryKindsReq,
  proto.atom.v2.ListDeviceCategoryKindsRes,
  /**
   * @param {!proto.atom.v2.ListDeviceCategoryKindsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ListDeviceCategoryKindsRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.ListDeviceCategoryKindsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ListDeviceCategoryKindsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ListDeviceCategoryKindsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.DeviceCategoryServiceClient.prototype.listDeviceCategoryKinds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/ListDeviceCategoryKinds',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_ListDeviceCategoryKinds,
      callback);
};


/**
 * @param {!proto.atom.v2.ListDeviceCategoryKindsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ListDeviceCategoryKindsRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.DeviceCategoryServicePromiseClient.prototype.listDeviceCategoryKinds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/ListDeviceCategoryKinds',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_ListDeviceCategoryKinds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.ListDeviceCategoriesReq,
 *   !proto.atom.v2.ListDeviceCategoriesRes>}
 */
const methodDescriptor_DeviceCategoryService_ListDeviceCategories = new grpc.web.MethodDescriptor(
  '/atom.v2.DeviceCategoryService/ListDeviceCategories',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.ListDeviceCategoriesReq,
  proto.atom.v2.ListDeviceCategoriesRes,
  /**
   * @param {!proto.atom.v2.ListDeviceCategoriesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.ListDeviceCategoriesRes.deserializeBinary
);


/**
 * @param {!proto.atom.v2.ListDeviceCategoriesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.ListDeviceCategoriesRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.ListDeviceCategoriesRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.DeviceCategoryServiceClient.prototype.listDeviceCategories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/ListDeviceCategories',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_ListDeviceCategories,
      callback);
};


/**
 * @param {!proto.atom.v2.ListDeviceCategoriesReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.ListDeviceCategoriesRes>}
 *     Promise that resolves to the response
 */
proto.atom.v2.DeviceCategoryServicePromiseClient.prototype.listDeviceCategories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/ListDeviceCategories',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_ListDeviceCategories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.GetDeviceCategoryReq,
 *   !proto.atom.v2.DeviceCategory>}
 */
const methodDescriptor_DeviceCategoryService_GetDeviceCategory = new grpc.web.MethodDescriptor(
  '/atom.v2.DeviceCategoryService/GetDeviceCategory',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.GetDeviceCategoryReq,
  proto.atom.v2.DeviceCategory,
  /**
   * @param {!proto.atom.v2.GetDeviceCategoryReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.DeviceCategory.deserializeBinary
);


/**
 * @param {!proto.atom.v2.GetDeviceCategoryReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.DeviceCategory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.DeviceCategory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.DeviceCategoryServiceClient.prototype.getDeviceCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/GetDeviceCategory',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_GetDeviceCategory,
      callback);
};


/**
 * @param {!proto.atom.v2.GetDeviceCategoryReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.DeviceCategory>}
 *     Promise that resolves to the response
 */
proto.atom.v2.DeviceCategoryServicePromiseClient.prototype.getDeviceCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/GetDeviceCategory',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_GetDeviceCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.CreateDeviceCategoryReq,
 *   !proto.atom.v2.DeviceCategory>}
 */
const methodDescriptor_DeviceCategoryService_CreateDeviceCategory = new grpc.web.MethodDescriptor(
  '/atom.v2.DeviceCategoryService/CreateDeviceCategory',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.CreateDeviceCategoryReq,
  proto.atom.v2.DeviceCategory,
  /**
   * @param {!proto.atom.v2.CreateDeviceCategoryReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.DeviceCategory.deserializeBinary
);


/**
 * @param {!proto.atom.v2.CreateDeviceCategoryReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.DeviceCategory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.DeviceCategory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.DeviceCategoryServiceClient.prototype.createDeviceCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/CreateDeviceCategory',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_CreateDeviceCategory,
      callback);
};


/**
 * @param {!proto.atom.v2.CreateDeviceCategoryReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.DeviceCategory>}
 *     Promise that resolves to the response
 */
proto.atom.v2.DeviceCategoryServicePromiseClient.prototype.createDeviceCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/CreateDeviceCategory',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_CreateDeviceCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.UpdateDeviceCategoryReq,
 *   !proto.atom.v2.DeviceCategory>}
 */
const methodDescriptor_DeviceCategoryService_UpdateDeviceCategory = new grpc.web.MethodDescriptor(
  '/atom.v2.DeviceCategoryService/UpdateDeviceCategory',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.UpdateDeviceCategoryReq,
  proto.atom.v2.DeviceCategory,
  /**
   * @param {!proto.atom.v2.UpdateDeviceCategoryReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.atom.v2.DeviceCategory.deserializeBinary
);


/**
 * @param {!proto.atom.v2.UpdateDeviceCategoryReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.atom.v2.DeviceCategory)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.atom.v2.DeviceCategory>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.DeviceCategoryServiceClient.prototype.updateDeviceCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/UpdateDeviceCategory',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_UpdateDeviceCategory,
      callback);
};


/**
 * @param {!proto.atom.v2.UpdateDeviceCategoryReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.atom.v2.DeviceCategory>}
 *     Promise that resolves to the response
 */
proto.atom.v2.DeviceCategoryServicePromiseClient.prototype.updateDeviceCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/UpdateDeviceCategory',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_UpdateDeviceCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.atom.v2.RemoveDeviceCategoryReq,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_DeviceCategoryService_RemoveDeviceCategory = new grpc.web.MethodDescriptor(
  '/atom.v2.DeviceCategoryService/RemoveDeviceCategory',
  grpc.web.MethodType.UNARY,
  proto.atom.v2.RemoveDeviceCategoryReq,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.atom.v2.RemoveDeviceCategoryReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.atom.v2.RemoveDeviceCategoryReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.atom.v2.DeviceCategoryServiceClient.prototype.removeDeviceCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/RemoveDeviceCategory',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_RemoveDeviceCategory,
      callback);
};


/**
 * @param {!proto.atom.v2.RemoveDeviceCategoryReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.atom.v2.DeviceCategoryServicePromiseClient.prototype.removeDeviceCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/atom.v2.DeviceCategoryService/RemoveDeviceCategory',
      request,
      metadata || {},
      methodDescriptor_DeviceCategoryService_RemoveDeviceCategory);
};


module.exports = proto.atom.v2;

